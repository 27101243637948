.pricing {
    @include adaptive-value("padding-bottom", 100, 60, 1);

    .container {
        position: relative;
        // z-index: 1;
    }

    &_content {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        background: linear-gradient(180deg, #F8F8F8 3.63%, rgba(248, 248, 248, 0) 85.79%);
        padding-top: 30px;
        @include adaptive-value("padding-bottom", 60, 30, 1);
        @include adaptive-value("margin-bottom", 0, 60, 1);
        position: relative;
        overflow: hidden;
        border-radius: 5px 30px;

        @include xs {
            background-color: var(--whiteF8);
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            @include adaptive-value("top", -1100, 300, 1);
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);

            @include sm {
                width: 638px;
                top: -100px;
                left: -50%;
                transform: translateX(-20%);
            }
        }
    }

    &_text-wrap {
        max-width: 720px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    &_title {
        max-width: 520px;
        width: 100%;
        margin-bottom: 40px;

        @include xs {
            text-align: start;
        }
    }

    &_descr {
        @include adaptive-value("font-size", 18, 16, 1);
        line-height: calc(26 / 18);
        color: var(--grey);

        @include xs {
            text-align: start;
        }
    }

    .tabs__head {
        max-width: 735px;
        width: 100%;
        margin: 0 auto;
        @include adaptive-value("margin-bottom", 50, 40, 1);
    }

    .tabs__head-row {
        overflow: visible;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        margin: 0;

        @include xs {
            justify-content: flex-start;
        }
    }

    .tabs__head-col {
        width: max-content;
    }

    .tabs__btn {
        @include adaptive-value("font-size", 18, 16, 1);
        width: max-content;
    }

    .plan {
        width: 100%;

        &_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 25px;
            @include adaptive-value("margin-bottom", 20, 30, 1);

            @include sm {
                flex-direction: column;
                align-items: flex-start;
            }

            b {
                font-size: 14px;
                color: var(--black2F);
            }
        }

        &_currency,
        &_payment {
            display: flex;
            align-items: center;
            gap: 20px;

            @include xs {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                width: 100%;
            }
        }

        .currency-dropdown {
            position: relative;

            @include xs {
                width: 100%;
            }

            .dropdown-button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                width: 100px;
                padding: 15px;
                background-color: var(--whiteF8);
                border-radius: 50px;
                border: 1px solid transparent;
                transition: all .3s ease 0s;

                @include xs {
                    width: 100%;
                }


                &.active {
                    border-color: var(--blue);
                    transition: all .3s ease 0s;

                    .icon {
                        rotate: 180deg;
                        transition: all .3s ease 0s;
                    }
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--grey);
                }

                .icon {
                    width: 6px;
                    height: 4px;
                    color: var(--blue);
                    transition: all .3s ease 0s;
                }
            }

            @media (hover:hover) {
                .dropdown-button {
                    &:hover {
                        border-color: var(--blue);
                        transition: all .3s ease 0s;
                    }
                }
            }

            .dropdown-list {
                background-color: var(--white);
                box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
                width: 100%;
                border-radius: 10px;
                padding: 0;
                position: absolute;
                z-index: 1;
                margin-top: 5px;
                height: 0;
                opacity: 0;
                visibility: hidden;
                transition: all .3s ease 0s;

                &.open {
                    padding: 5px 0;
                    transition: all .3s ease 0s;
                    height: auto;
                    opacity: 1;
                    visibility: visible;

                    .dropdown-item {
                        height: auto;
                        opacity: 1;
                        font-size: 14px;
                        transition: all .3s ease 0s;
                    }
                }
            }

            .dropdown-item {
                font-size: 0;
                height: 0;
                opacity: 0;
                font-weight: 600;
                color: var(--grey);
                cursor: pointer;
                padding: 10px 15px;
                transition: all .3s ease 0s;

                &.current-item {
                    color: var(--blue);
                }
            }

            @media (hover:hover) {
                .dropdown-item {
                    &:hover {
                        color: var(--blue);
                        transition: all .3s ease 0s;
                        background-color: var(--whiteF8);
                    }
                }
            }
        }

        .payment_toggler-wrap {
            display: flex;
            align-items: center;
            gap: 15px;

            .plan_text {
                font-weight: 600;
                font-size: 14px;
                color: var(--grey);
                transition: all .3s ease 0s;

                i {
                    font-size: 13px;
                }
            }

            .annual {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 5px;
            }

            .current_plan {
                color: var(--blue);
                transition: all .3s ease 0s;
            }

            .payment_toggler {
                width: 40px;
                height: 10px;
                border-radius: 50px;
                background-color: var(--blueCE);
                position: relative;

                &.toggle {
                    &::before {
                        right: 20px;
                        transition: all .3s ease 0s;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: var(--blue);
                    cursor: pointer;
                    transition: all .3s ease 0s;
                }
            }
        }

        .body_tip {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background-color: #E8B52426;
            padding: 20px;
            border-radius: 10px;
            margin-bottom: 30px;

            @include xs {
                flex-direction: column;
                text-align: center;
            }

            .icon {
                width: 30px;
                height: 30px;
                color: var(--yellow);
            }

            p {
                font-weight: 700;
                @include adaptive-value("font-size", 18, 16, 1);
                color: var(--black2F);
            }
        }

        &_cards-wrap {
            display: grid;
            grid-template: 1fr / repeat(auto-fit, minmax(330px, 1fr));
            row-gap: 30px;
            @include adaptive-value("column-gap", 30, 0, 1);
            @include adaptive-value("margin-bottom", 100, 60, 1);

            @include sm {
                grid-template: 1fr / 1fr;
            }
        }

        &_card {
            background-color: var(--white);
            border-radius: 5px 30px;
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
            padding: 35px 25px;
            height: 100%;
            display: flex;
            flex-direction: column;

            &:nth-child(1) {
                --card-color: #007BB3
            }

            &:nth-child(2) {
                --card-color: #E8B524
            }

            &:nth-child(3) {
                --card-color: #03C15C
            }

            .card_head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 25px;

                .icon {
                    width: 35px;
                    height: 35px;
                    color: var(--card-color);
                    opacity: 0.5;
                }

                .popular {
                    font-size: 14px;
                    color: var(--card-color);
                    border: 1px solid var(--card-color);
                    border-radius: 50px;
                    padding: 7px 15px;
                    height: 35px;
                }
            }

            .card_title {
                @include adaptive-value("font-size", 22, 20, 1);
                color: var(--black05);
                margin-bottom: 25px;
            }

            .card_descr {
                font-size: 14px;
                color: var(--grey);
                line-height: calc(20 / 14);
                margin-bottom: 25px;
            }

            .card_price-wrap {
                margin-bottom: 25px;
                padding-bottom: 25px;
                border-bottom: 1px solid #58595B4D;
            }

            .price-wrap {

                align-items: flex-end;
                gap: 10px;
                margin-bottom: 20px;
                transition: all .3s ease 0s;

                &.toggle-price {
                    display: none;
                    opacity: 0;
                }

                &.current-price-wrap {
                    opacity: 1;
                    display: flex;
                    transition: all .3s ease 0s;
                }
            }

            .current-price {
                span {
                    font-size: 40px;
                    color: var(--card-color);
                    font-weight: 700;

                    &::before {
                        content: var(--currency-symbol);
                        margin-right: -10px;
                    }
                }

                small {
                    color: var(--card-color);
                    font-weight: 700;
                    font-size: 28px;
                    margin-right: 10px;

                    @include xs {
                        font-size: 32px;
                    }
                }
            }

            .old-price {

                span {
                    font-size: 28px;
                    color: var(--grey);
                    opacity: 0.5;

                    &::before {
                        content: var(--currency-symbol);
                        margin-right: -10px;
                    }
                }

                position: relative;
                margin-bottom: 5px;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 50%;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: var(--card-color);
                    rotate: -20deg;
                }
            }

            .price-descr,
            .save-descr {
                font-size: 13px;
                color: var(--grey);
                font-style: italic;
                transition: all .3s;
                opacity: 1;

                &.save-descr_hide {
                    opacity: 0;
                    margin-top: 0;
                }

                span {
                    font-weight: 700;

                    &::before {
                        content: var(--currency-symbol);
                        margin-right: -2px;
                    }
                }
            }

            .save-descr {
                margin-top: 10px;
            }

            .card_list-wrap {
                flex: 1;

                span {
                    font-weight: 600;
                    font-size: 14px;
                    color: var(--grey);
                    margin-bottom: 20px;
                    display: inline-block;
                }
            }

            .card_list {
                display: flex;
                flex-direction: column;
                @include adaptive-value("row-gap", 20, 15, 1);
                margin-bottom: 20px;
            }

            .list_item {
                display: flex;
                align-items: center;
                gap: 10px;
                width: 100%;
                font-size: 14px;
                color: var(--grey);

                b {
                    font-weight: 600;
                }

                .icon-check_2 {
                    color: var(--card-color);
                    min-width: 20px;
                    min-height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                }

                .icon-help {
                    color: var(--blue);
                    opacity: 0.3;
                    min-width: 20px;
                    min-height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                }
            }

            .tooltip {
                margin-left: auto;
                position: relative;
                cursor: pointer;

                &:hover {
                    .tooltip_content {
                        opacity: 1;
                        visibility: visible;
                        transition: all .3s ease 0s;
                    }

                    .icon {
                        color: var(--blue);
                        transition: all .3s ease 0s;
                    }
                }

                &_content {
                    position: absolute;
                    z-index: 3;
                    top: -80px;
                    right: 0;
                    opacity: 0;
                    visibility: hidden;
                    width: max-content;
                    border-radius: 10px;
                    box-shadow: 0px 8px 25px 0px #0000001A;
                    background-color: var(--white);
                    font-size: 14px;
                    color: var(--grey);
                    line-height: calc(20 / 14);
                    padding: 15px;
                    transition: all .3s ease 0s;
                    font-weight: 400;

                    &.second_content {
                        @include sm {
                            left: auto;
                            right: 0;
                        }
                    }

                    b {
                        font-weight: 600;
                    }
                }
            }

            .show_btn {
                color: var(--blue);
                font-size: 14px;
                text-decoration: underline;
                text-align: center;
                margin: 20px auto 40px auto;
                @include adaptive-value("margin-bottom", 40, 25, 1);
                font-weight: 700;
                display: block;
            }

            .btn_pimary {
                width: 100%;
                margin-bottom: 25px;
                background-color: var(--card-color);
            }

            .buy_btn {
                display: block;
                text-align: center;
                margin: 0 auto;
                width: max-content;
                color: var(--card-color);
                opacity: 0.5;
                text-decoration: underline;
                font-size: 18px;
                font-weight: 700;
                transition: all .3s ease 0s;
            }

            @media (hover:hover) {
                .buy_btn {
                    &:hover {
                        opacity: 1;
                        transition: all .3s ease 0s;
                    }
                }
            }

            .card_tip {
                font-size: 13px;
                color: var(--grey);
                line-height: calc(18 / 13);
                margin-top: 32px;

                a {
                    color: var(--blue);
                    font-weight: 600;
                }
            }
        }
    }

    .plan_subtitle {
        font-weight: 700;
        font-size: 22px;
    }

    .contact-center_body {
        .contact-center_wrap {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 30px;

            @include md {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }
        }

        .plan_card {
            min-height: max-content;
            max-width: 480px;
            width: 100%;
        }

        .accordion-wrap {
            display: flex;
            flex-direction: column;
            @include adaptive-value("gap", 40, 30, 1);
            max-width: 630px;
            width: 100%;
            padding-top: 35px;

            @include md {
                padding: 0;
            }

        }

        .accordion__item {
            border-radius: 0px;
            box-shadow: none;
            overflow: visible;
            width: 100%;
            background-color: transparent;

            &:not(:last-child) {
                @include adaptive-value("margin-bottom", 20, 10, 1);
            }
        }

        .accordion__toggler {
            border-radius: 5px 30px;
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
            min-height: 70px;

            &:hover {
                background-color: var(--blue);
            }
        }

        .accordion__content {
            padding: 0;
            margin-top: 45px;
            counter-reset: num;

            .item_wrap {
                &:first-child {
                    border-bottom: 1px solid rgba(88, 89, 91, 0.2);
                    padding-bottom: 20px;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(88, 89, 91, 0.2);
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }
            }

            .item_title {
                @include adaptive-value("font-size", 18, 16, 1);
                color: var(--black2F);
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 20px;
                counter-increment: num;

                &::before {
                    content: "0"counter(num);
                    font-size: 18px;
                    color: var(--grey);
                    opacity: 0.5;
                }
            }

            .item_text {
                font-size: 14px;
                color: var(--grey);
            }
        }
    }
}

.addons {
    @include adaptive-value("padding-top", 100, 60, 1);

    &_content {
        display: grid;
        grid-template: 1fr / repeat(auto-fit, minmax(330px, 1fr));
        gap: 20px;
        counter-reset: num;

        @include xs {
            grid-template: 1fr / 1fr;
        }
    }


    &_card {
        display: flex;
        flex-direction: column;
        background-color: var(--whiteF8);
        border-radius: 5px 30px;
        box-shadow: 0px 8px 25px 0px #0000001A;
        padding: 25px;

        &:nth-child(4n + 1) {
            .card_number {
                background-color: var(--blue);
            }
        }

        &:nth-child(4n + 2) {
            .card_number {
                background-color: var(--yellow);
            }
        }

        &:nth-child(4n + 3) {
            .card_number {
                background-color: var(--green);
            }
        }

        &:nth-child(4n + 4) {
            .card_number {
                background-color: var(--black2F);
            }
        }
    }

    .card {

        &_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 15px;
            margin-bottom: 25px;

            @include xs {
                gap: 25px;
            }
        }

        &_number {
            min-width: 50px;
            min-height: 50px;
            max-width: 50px;
            max-height: 50px;
            border-radius: 50%;
            overflow: hidden;
            counter-increment: num;
            display: grid;
            place-items: center;


            &::before {
                content: "0"counter(num);
                color: var(--white);
                font-size: 18px;
                font-weight: 700;
            }
        }

        &_toogler-wrap {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        &_toggler-item {
            display: flex;
            align-items: center;
            gap: 5px;

            &.active {
                span {
                    color: var(--blue);
                }
            }

            span {
                text-transform: capitalize;
                font-weight: 600;
                font-size: 14px;
                color: var(--grey);
            }

            .icon {
                width: 17px;
                height: 17px;
                color: #C6DFEA;
                transition: all .3s ease 0s;
            }

            .tooltip {
                position: relative;
                cursor: pointer;

                &:hover {
                    .tooltip_content {
                        opacity: 1;
                        visibility: visible;
                        transition: all .3s ease 0s;
                    }

                    .icon {
                        color: var(--blue);
                        transition: all .3s ease 0s;
                    }
                }

                &_content {
                    position: absolute;
                    z-index: 3;
                    top: -80px;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    width: max-content;
                    border-radius: 10px;
                    box-shadow: 0px 8px 25px 0px #0000001A;
                    background-color: var(--white);
                    font-size: 14px;
                    color: var(--grey);
                    line-height: calc(20 / 14);
                    padding: 15px;
                    transition: all .3s ease 0s;
                    font-weight: 400;

                    &.second_content {
                        @include sm {
                            left: auto;
                            right: 0;
                        }
                    }

                    b {
                        font-weight: 600;
                    }
                }
            }
        }

        &_toggler {
            width: 40px;
            height: 10px;
            border-radius: 50px;
            background-color: var(--blueCE);
            position: relative;

            span {
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: var(--blue);
                cursor: pointer;
                -webkit-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;

                &.active {
                    left: 20px;
                }
            }
        }

        &_title-wrap {
            margin-bottom: 25px;

            i {
                font-size: 13px;
            }
        }

        &_title {
            font-weight: 700;
            @include adaptive-value("font-size", 22, 20, 1);
            margin-bottom: 10px;
            color: var(--black2F);
        }

        &_text {
            color: var(--grey);
            font-size: 14px;
            line-height: calc(20 / 14);
            margin-bottom: 25px;
            flex: 1 0 auto;
        }

        &_price {
            @include adaptive-value("font-size", 40, 28, 1);
            color: var(--blue);
            margin-bottom: 25px;
            font-weight: 700;
            width: max-content;

            span {
                display: flex;
                align-items: center;

                &::before {
                    content: var(--currency-symbol);
                }
            }
        }

    }

    .btn {
        font-size: 14px;
        width: 100%;
        padding: 15px;
        height: 40px;
    }
}

.pricing-feature {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);
    background-color: var(--whiteF8);

    &_head-row {
        display: grid;
        // grid-template: 1fr / auto auto auto auto auto auto;
        grid-template: 1fr / auto auto 23% 23% 23%;
        margin-bottom: 25px;

        &.top_row {
            margin-bottom: 20px;

            @include md {
                margin-bottom: 25px;
                grid-template: 1fr / auto auto auto;
                gap: 15px;
                display: flex;
                flex-direction: row;
                height: 100%;
                justify-content: space-between;

                b {
                    min-height: 80px;

                    &:nth-child(1) {
                        display: none;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: grid;
                        place-items: center;
                        border-radius: 10px;
                        background-color: var(--white);
                        padding: 15px 10px;
                        height: 100%;
                    }

                    &:nth-child(2) {
                        color: var(--blue);
                    }

                    &:nth-child(3) {
                        color: var(--yellow);
                    }

                    &:nth-child(4) {
                        color: var(--green);
                    }
                }
            }
        }

        &.bottom_row {
            @include md {
                grid-template: 1fr / auto auto auto;
                gap: 15px;

                .row-title {
                    &:nth-child(1) {
                        grid-column: 1 / 4;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        border-radius: 10px;
                        background-color: var(--white);
                        padding: 10px 0;
                        min-width: 100px;
                    }
                }

                .tooltip_content {
                    right: auto;
                    left: 0;
                }
            }
        }

        @media(max-width:360px) {
            &.bottom_row {
                .row-title {

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        min-width: auto;
                    }
                }
            }
        }

        b {
            max-width: 220px;
            width: 100%;
            margin: 0 auto;
            text-align: center;
            @include adaptive-value("font-size", 22, 14, 1);

            &:nth-child(1) {
                text-align: start;
                max-width: 100%;
                grid-column: 1 / 3;
            }

            @include md {
                max-width: 100%;
            }
        }

        .row-title {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            max-width: 250px;
            width: 100%;
            font-weight: 500;
            margin: 0 auto;
            text-align: center;
            @include adaptive-value("font-size", 18, 16, 1);

            &:nth-child(1) {
                justify-content: flex-start;
                max-width: 100%;
                grid-column: 1 / 3;
            }

            &:nth-child(3) {
                max-width: 200px;

                @include md {
                    max-width: 100%;
                }
            }

            @include md {
                max-width: 100%;
            }
        }
    }

    .accordion__item {
        box-shadow: none;
        overflow: visible;
        width: 100%;
        background-color: transparent;

        &:not(:last-child) {
            @include adaptive-value("margin-bottom", 20, 10, 1);
        }

        &.active {
            background-color: transparent;
        }

        &:hover {
            background-color: transparent;

            .accordion__toggler {
                background-color: var(--blue);
            }
        }
    }

    .accordion__toggler {
        box-shadow: 0px 8px 25px 0px #0000001A;
        background-color: var(--white);
        padding: 25px;
        border-radius: 5px 30px 5px 30px;
        min-height: max-content;
    }



    .accordion__content {
        padding: 45px 0 0 0;
    }

    .list_tip {
        max-width: 230px;
        width: 100%;
        margin-left: auto;
        margin-bottom: 20px;
        font-size: 14px;
        color: var(--grey);
    }

    &_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        counter-reset: num;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 12px;
            width: 100%;
            height: 1px;
            background-color: rgba(88, 89, 91, 0.2);
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 12px;
            width: 100%;
            height: 1px;
            background-color: rgba(88, 89, 91, 0.2);
        }
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .col_item {
        display: grid;
        grid-template: 1fr / 65px 275px auto auto auto;
        align-items: center;
        width: 100%;
        @include adaptive-value("gap", 20, 8, 1);
        counter-increment: num;

        @include sm {
            grid-template: 1fr / 50px auto 75px 75px 75px;
        }

        @include xs {
            grid-template: 1fr / 25px auto 50px 75px;
            align-items: flex-start;
        }

        position: relative;

        &:first-child {

            &::before {
                padding-top: 28px;

                @include sm {
                    padding-top: 15px;
                }
            }

            .icon-check_wrap {
                padding-top: 28px;
                border-radius: 30px 30px 0 0;

                @include xs {
                    padding-top: 15px;
                }
            }

            .icon-cross_wrap,
            .title-wrap {
                padding-top: 28px;

                @include xs {
                    padding-top: 15px;
                }
            }
        }

        &:nth-child(-n+10) {
            &::before {
                content: "0"counter(num);
            }
        }

        &:nth-child(n+10) {
            &::before {
                content: counter(num);
            }
        }

        &:last-child {
            &::before {
                padding-bottom: 28px;

                @include xs {
                    padding-bottom: 15px;
                }
            }


            .icon-cross_wrap,
            .title-wrap {
                padding-bottom: 28px;

                @include xs {
                    padding-bottom: 15px;
                }
            }
        }

        &::before {
            color: var(--grey);
            @include adaptive-value("font-size", 18, 14, 1);
            opacity: 0.5;
            padding: 17px 0;
        }

        @media (max-width:400px) {
            &::before {
                padding-top: 15px;
            }
        }

        &:not(:last-child) {
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(88, 89, 91, 0.2);
            }
        }

        .title-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            max-width: 275px;
            width: 100%;
            padding: 17px 0;
            @include adaptive-value("font-size", 18, 14, 1);
            color: var(--grey);

            @include xs {
                padding: 14px 0;
            }

            p {
                @include adaptive-value("font-size", 18, 16, 1);
                color: var(--grey);
            }
        }

        .icon-check_wrap,
        .icon-cross_wrap {
            max-width: 88px;
            width: 100%;
            margin: 0 auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 17px 0;
            position: relative;
            z-index: 1;

            &.bordered {
                padding-bottom: 28px;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;

                @include xs {
                    padding-bottom: 15px;
                }
            }

            @include xs {
                padding: 14px 0;
            }
        }

        .icon-check_wrap {
            background-color: var(--blue);

            .icon {
                color: var(--white);
            }
        }

        .icon-cross_wrap {

            .icon {
                color: var(--black2F);
            }

        }
    }

    @media (max-width:650px) {

        .list_tip {
            display: none;
        }

        &_content {

            &::before,
            &::after {
                display: none;
            }
        }

        .col_item {
            grid-template: auto 1fr / 1fr 1fr 1fr;
            row-gap: 15px;
            margin-bottom: 25px;

            &::before,
            &::after {
                display: none;
            }

            &:first-child,
            &:last-child {
                .title-wrap {
                    padding: 0;
                }
            }

            .title-wrap {
                grid-column: -1 / 1;
                padding: 0;
                max-width: 100%;
            }

            &:first-child {
                .icon-check_wrap {
                    background-color: var(--white);
                    border-radius: 10px;
                    padding: 10px;

                    &.blue {
                        .icon {
                            color: var(--blue);
                        }
                    }
                }
            }

            .icon-check_wrap {
                max-width: 100%;
                height: auto;
                background-color: var(--white);
                border-radius: 10px;
                padding: 10px;

                &.bordered {
                    border-radius: 10px;
                    padding: 10px;
                }

                &.blue {
                    .icon {
                        color: var(--blue);
                    }
                }

                &.yellow {
                    .icon {
                        color: var(--yellow);
                    }
                }

                &.green {
                    .icon {
                        color: var(--green);
                    }
                }
            }
        }

        .bottom_row {
            grid-template: auto 1fr / auto auto auto;
            gap: 15px 10px;

            span {
                font-weight: 500;

                &:nth-child(1) {
                    grid-column: -1 / 1;
                }
            }
        }
    }

    .tooltip {
        position: relative;
        cursor: pointer;
        margin-bottom: 2px;

        &:hover {
            .tooltip_content {
                opacity: 1;
                visibility: visible;
                transition: all .3s ease 0s;
            }

            .icon {
                color: var(--blue);
                transition: all .3s ease 0s;
            }
        }

        .icon {
            width: 17px;
            height: 17px;
            color: #CEE3EC;
            transition: all .3s ease 0s;
        }

        &_content {
            position: absolute;
            z-index: 3;
            top: -100%;
            left: 25px;
            opacity: 0;
            visibility: hidden;
            max-width: 165px;
            width: max-content;
            border-radius: 10px;
            box-shadow: 0px 8px 25px 0px #0000001A;
            background-color: var(--white);
            font-size: 14px;
            color: var(--grey);
            line-height: calc(20 / 14);
            padding: 15px;
            transition: all .3s ease 0s;
            font-weight: 400;

            @include xs {
                top: -65px;
                left: auto;
                right: 0px;
            }

            b {
                font-weight: 600;
            }
        }
    }
}
.reviews {
    @include adaptive-value("padding-top", 70, 60, 1);
    @include adaptive-value("padding-bottom", 70, 60, 1);

    .container {
        padding: 0;

        @include xs {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .reviews_head {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 50px;
        padding: 0 15px;

        @include sm {
            margin-bottom: 25px;
        }

        @include xs {
            display: contents;
        }
    }

    .reviews_title {
        max-width: 606px;
        width: 100%;
        text-align: start;
        margin: 0;

        @include xs {
            order: 1;
            padding: 0 20px;
        }
    }

    .arrow_wrap {
        display: flex;
        align-items: center;
        gap: 15px;

        @include xs {
            order: 3;
            gap: 30px;
        }
    }

    .reviews_swiper {
        padding: 15px 15px 0 15px;

        @include xs {
            width: 100%;
            order: 2;
        }
    }

    .swiper-wrapper {
        margin-bottom: 25px;
    }

    .swiper-slide {
        min-height: 100%;
        height: auto;
    }

    .reviews_slide {
        background-color: var(--white);
        border-radius: 5px 30px 5px 30px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        height: 100%;

        @include md {
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        }
    }

    .reviews_slide-img {
        height: 220px;
        width: 100%;
        background-color: var(--whiteF8);
        border-radius: 5px 30px 5px 30px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        .__image {
            max-width: 100%;

            img {
                object-fit: contain;
            }
        }
    }

    .reviews_slide-text {
        padding: 25px;

        &.expanded .toggle-button {
            display: inline-block;
        }
    }

    @media (max-width: #{$sm-and-sm}) {
        .reviews_slide-text {
            padding: 25px 15px;
        }
    }

    .slide_text {
        font-size: 14px;
        line-height: calc(20 / 14) * 100%;
        color: var(--grey);
        display: -webkit-box;
        -webkit-line-clamp: 12;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: calc(12 * 20px);
        transition: max-height 0.3s ease;
        font-weight: 400;
    }

    .slide_text.expanded {
        -webkit-line-clamp: unset;
        max-height: unset;
    }

    .reviews_swiper-btn {
        color: var(--blue);
        font-size: 14px;
        margin-top: 20px;
        text-decoration: underline;
        font-weight: 700;
    }

    .swiper-pagination {
        margin: 0 auto;

        @include xs {
            display: none;
        }
    }
}
.applications {
  padding: 100px 0;
  text-align: center;

  .applications_text-wrap {
    text-align: center;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  @media (max-width: #{$sm-and-sm}) {
    .applications_text-wrap {
      margin-bottom: 30px;
    }
  }

  .applications_title {
    @include adaptive-value("margin-bottom", 30, 25, 1);

    @include xs {
      text-align: start;
    }
  }

  .applications_text {
    color: var(--grey);
    line-height: calc(26 / 18) * 100%;
    @include adaptive-value("font-size", 18, 16, 1);
    max-width: 532px;
    width: 100%;
    margin: 0 auto;
    font-weight: 400;

    @include xs {
      text-align: start;
    }
  }

  .applications_icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    max-width: 1110px;
    width: 100%;
    margin: 0 auto 50px auto;
    .display_none {
      @include xs {
        display: none;
      }
    }
  }

  .applications_icon__image {
    width: 75px;
    height: auto;

    .applications_icon__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: #{$sm-and-sm}) {
    .applications_icon {
      height: 45px;
    }
  }

  .applications_link {
    font-weight: 400;

    @include xs {
      width: 100%;
    }
  }
}

@media (max-width: #{$sm-and-sm}) {
  .applications {
    padding: 50px 0;
  }
}

.voice-applications {
  .applications_icon__image {
    @media (max-width:460px) {
      &:last-child {
        display: none;
      }
    }
  }
}
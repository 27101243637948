.tariff {

    // .tariff_content

    &_content {
        background-color: var(--blue);
        border-radius: 5px 30px;
        @include adaptive-value("padding-top", 60, 20, 1);
        @include adaptive-value("padding-bottom", 60, 40, 1);
        padding-left: 18px;
        padding-right: 18px;
    }

    // .tariff_body

    &_body {
        max-width: 875px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        @include md {
            flex-direction: column-reverse;
        }
    }

    // .tariff_text-block

    &_text-block {
        max-width: 425px;
        width: 100%;

        @include md {
            max-width: 100%;
            text-align: center;
        }
    }

    // .tariff_text-block-title

    &_text-block-title {
        font-weight: 700;
        @include adaptive-value("font-size", 28, 22, 1);
        margin-bottom: 30px;
        color: var(--white);
    }

    // .tariff_text-block-descr

    &_text-block-descr {
        font-size: 14px;
        font-weight: 700;
        color: var(--white);
        @include adaptive-value("margin-bottom", 75, 30, 1);
        line-height: 142%;
        max-width: 342px;
        width: 100%;

        @include xs {
            max-width: 260px;
            width: 100%;
            margin: 0 auto 30px auto;
        }
    }

    .tariff__image {
        max-width: 460px;
        height: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .btn {
        @include xs {
            width: 100%;
        }
    }
}
.checkbox {
  position: relative;

  display: block;

  // &:hover {
  // }

  &__input {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    appearance: none;
    cursor: pointer;
  }

  &__custom {
    display: block;

    width: 100%;
    height: 100%;

    pointer-events: none;
  }

  // &__input:checked + &__custom {
  // }
}

@charset 'UTF-8';
:root
{
    --iti-hover-color: rgba(0, 0, 0, .05);
    --iti-border-color: #ccc;
    --iti-dialcode-color: #999;
    --iti-dropdown-bg: white;
    --iti-spacer-horizontal: 8px;
    --iti-flag-height: 12px;
    --iti-flag-width: 16px;
    --iti-border-width: 1px;
    --iti-arrow-height: 4px;
    --iti-arrow-width: 6px;
    --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
    --iti-arrow-padding: 6px;
    --iti-arrow-color: #555;
    --iti-path-flags-1x: url('../img/flags.webp');
    --iti-path-flags-2x: url('../img/flags@2x.webp');
    --iti-path-globe-1x: url('../img/globe.webp');
    --iti-path-globe-2x: url('../img/globe@2x.webp');
    --iti-flag-sprite-width: 3904px;
    --iti-flag-sprite-height: 12px;
    --iti-mobile-popup-margin: 30px;
}

.iti
{
    position: relative;

    display: inline-block;
}

.iti *
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.iti__hide
{
    display: none;
}

.iti__v-hide
{
    visibility: hidden;
}

.iti__a11y-text
{
    position: absolute;

    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);

    width: 1px;
    height: 1px;
}

.iti input.iti__tel-input,
.iti input.iti__tel-input[type=text],
.iti input.iti__tel-input[type=tel]
{
    position: relative;
    z-index: 0;

    margin: 0 !important;
}

.iti__country-container
{
    position: absolute;
    top: 0;
    bottom: 0;

    padding: var(--iti-border-width);
}

.iti__selected-country
{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;

    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;
    margin: 0;
    padding: 0;

    text-decoration: none;

    color: inherit;
    border: 0;
    border-radius: 0;
    background: none;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.iti__selected-country-primary
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;
    padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.iti__arrow
{
    width: 0;
    height: 0;
    margin-left: var(--iti-arrow-padding);

    border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
    border-right: var(--iti-triangle-border) solid transparent;
    border-left: var(--iti-triangle-border) solid transparent;
}

[dir=rtl] .iti__arrow
{
    margin-right: var(--iti-arrow-padding);
    margin-left: 0;
}

.iti__arrow--up
{
    border-top: none;
    border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}

.iti__dropdown-content
{
    border-radius: 3px;
    background-color: var(--iti-dropdown-bg);
}

.iti--inline-dropdown .iti__dropdown-content
{
    position: absolute;
    z-index: 2;

    margin-top: 3px;
    margin-left: calc(var(--iti-border-width) * -1);

    border: var(--iti-border-width) solid var(--iti-border-color);
    -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
            box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
}

.iti__search-input
{
    width: 100%;

    border-width: 0;
    border-radius: 3px;
}

.iti__search-input + .iti__country-list
{
    border-top: 1px solid var(--iti-border-color);
}

.iti__country-list
{
    overflow-y: scroll;

    margin: 0;
    padding: 0;

    list-style: none;

    -webkit-overflow-scrolling: touch;
}

.iti--inline-dropdown .iti__country-list
{
    max-height: 185px;
}

.iti--flexible-dropdown-width .iti__country-list
{
    white-space: nowrap;
}

@media (max-width: 500px)
{
    .iti--flexible-dropdown-width .iti__country-list
    {
        white-space: normal;
    }
}
.iti__country
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 8px var(--iti-spacer-horizontal);

    outline: none;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.iti__dial-code
{
    color: var(--iti-dialcode-color);
}

.iti__country.iti__highlight
{
    background-color: var(--iti-hover-color);
}

.iti__country-list .iti__flag,
.iti__country-name
{
    margin-right: var(--iti-spacer-horizontal);
}

[dir=rtl] .iti__country-list .iti__flag,
[dir=rtl] .iti__country-name
{
    margin-right: 0;
    margin-left: var(--iti-spacer-horizontal);
}

.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover button
{
    cursor: pointer;
}

.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary
{
    background-color: var(--iti-hover-color);
}

.iti .iti__selected-dial-code
{
    margin-left: 4px;
}

[dir=rtl] .iti .iti__selected-dial-code
{
    margin-right: 4px;
    margin-left: 0;
}

.iti--container
{
    position: fixed;
    z-index: 1060;
    top: -1000px;
    left: -1000px;

    padding: var(--iti-border-width);
}

.iti--container:hover
{
    cursor: pointer;
}

.iti--fullscreen-popup.iti--container
{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    padding: var(--iti-mobile-popup-margin);

    background-color: rgba(0, 0, 0, .5);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.iti--fullscreen-popup .iti__dropdown-content
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    max-height: 100%;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}

.iti--fullscreen-popup .iti__country
{
    line-height: 1.5em;

    padding: 10px 10px;
}

.iti__flag
{
    width: var(--iti-flag-width);
    height: var(--iti-flag-height);

    border-radius: 1px;
    background-image: var(--iti-path-flags-1x);
    background-repeat: no-repeat;
    background-position: var(--iti-flag-offset) 0;
    background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
    -webkit-box-shadow: 0 0 1px 0 #888;
            box-shadow: 0 0 1px 0 #888;

    --iti-flag-offset: 100px;
}

.iti__ac
{
    --iti-flag-offset: 0;
}

.iti__ad
{
    --iti-flag-offset: -16px;
}

.iti__ae
{
    --iti-flag-offset: -32px;
}

.iti__af
{
    --iti-flag-offset: -48px;
}

.iti__ag
{
    --iti-flag-offset: -64px;
}

.iti__ai
{
    --iti-flag-offset: -80px;
}

.iti__al
{
    --iti-flag-offset: -96px;
}

.iti__am
{
    --iti-flag-offset: -112px;
}

.iti__ao
{
    --iti-flag-offset: -128px;
}

.iti__ar
{
    --iti-flag-offset: -144px;
}

.iti__as
{
    --iti-flag-offset: -160px;
}

.iti__at
{
    --iti-flag-offset: -176px;
}

.iti__au
{
    --iti-flag-offset: -192px;
}

.iti__aw
{
    --iti-flag-offset: -208px;
}

.iti__ax
{
    --iti-flag-offset: -224px;
}

.iti__az
{
    --iti-flag-offset: -240px;
}

.iti__ba
{
    --iti-flag-offset: -256px;
}

.iti__bb
{
    --iti-flag-offset: -272px;
}

.iti__bd
{
    --iti-flag-offset: -288px;
}

.iti__be
{
    --iti-flag-offset: -304px;
}

.iti__bf
{
    --iti-flag-offset: -320px;
}

.iti__bg
{
    --iti-flag-offset: -336px;
}

.iti__bh
{
    --iti-flag-offset: -352px;
}

.iti__bi
{
    --iti-flag-offset: -368px;
}

.iti__bj
{
    --iti-flag-offset: -384px;
}

.iti__bl
{
    --iti-flag-offset: -400px;
}

.iti__bm
{
    --iti-flag-offset: -416px;
}

.iti__bn
{
    --iti-flag-offset: -432px;
}

.iti__bo
{
    --iti-flag-offset: -448px;
}

.iti__bq
{
    --iti-flag-offset: -464px;
}

.iti__br
{
    --iti-flag-offset: -480px;
}

.iti__bs
{
    --iti-flag-offset: -496px;
}

.iti__bt
{
    --iti-flag-offset: -512px;
}

.iti__bw
{
    --iti-flag-offset: -528px;
}

.iti__by
{
    --iti-flag-offset: -544px;
}

.iti__bz
{
    --iti-flag-offset: -560px;
}

.iti__ca
{
    --iti-flag-offset: -576px;
}

.iti__cc
{
    --iti-flag-offset: -592px;
}

.iti__cd
{
    --iti-flag-offset: -608px;
}

.iti__cf
{
    --iti-flag-offset: -624px;
}

.iti__cg
{
    --iti-flag-offset: -640px;
}

.iti__ch
{
    --iti-flag-offset: -656px;
}

.iti__ci
{
    --iti-flag-offset: -672px;
}

.iti__ck
{
    --iti-flag-offset: -688px;
}

.iti__cl
{
    --iti-flag-offset: -704px;
}

.iti__cm
{
    --iti-flag-offset: -720px;
}

.iti__cn
{
    --iti-flag-offset: -736px;
}

.iti__co
{
    --iti-flag-offset: -752px;
}

.iti__cr
{
    --iti-flag-offset: -768px;
}

.iti__cu
{
    --iti-flag-offset: -784px;
}

.iti__cv
{
    --iti-flag-offset: -800px;
}

.iti__cw
{
    --iti-flag-offset: -816px;
}

.iti__cx
{
    --iti-flag-offset: -832px;
}

.iti__cy
{
    --iti-flag-offset: -848px;
}

.iti__cz
{
    --iti-flag-offset: -864px;
}

.iti__de
{
    --iti-flag-offset: -880px;
}

.iti__dj
{
    --iti-flag-offset: -896px;
}

.iti__dk
{
    --iti-flag-offset: -912px;
}

.iti__dm
{
    --iti-flag-offset: -928px;
}

.iti__do
{
    --iti-flag-offset: -944px;
}

.iti__dz
{
    --iti-flag-offset: -960px;
}

.iti__ec
{
    --iti-flag-offset: -976px;
}

.iti__ee
{
    --iti-flag-offset: -992px;
}

.iti__eg
{
    --iti-flag-offset: -1008px;
}

.iti__eh
{
    --iti-flag-offset: -1024px;
}

.iti__er
{
    --iti-flag-offset: -1040px;
}

.iti__es
{
    --iti-flag-offset: -1056px;
}

.iti__et
{
    --iti-flag-offset: -1072px;
}

.iti__fi
{
    --iti-flag-offset: -1088px;
}

.iti__fj
{
    --iti-flag-offset: -1104px;
}

.iti__fk
{
    --iti-flag-offset: -1120px;
}

.iti__fm
{
    --iti-flag-offset: -1136px;
}

.iti__fo
{
    --iti-flag-offset: -1152px;
}

.iti__fr
{
    --iti-flag-offset: -1168px;
}

.iti__ga
{
    --iti-flag-offset: -1184px;
}

.iti__gb
{
    --iti-flag-offset: -1200px;
}

.iti__gd
{
    --iti-flag-offset: -1216px;
}

.iti__ge
{
    --iti-flag-offset: -1232px;
}

.iti__gf
{
    --iti-flag-offset: -1248px;
}

.iti__gg
{
    --iti-flag-offset: -1264px;
}

.iti__gh
{
    --iti-flag-offset: -1280px;
}

.iti__gi
{
    --iti-flag-offset: -1296px;
}

.iti__gl
{
    --iti-flag-offset: -1312px;
}

.iti__gm
{
    --iti-flag-offset: -1328px;
}

.iti__gn
{
    --iti-flag-offset: -1344px;
}

.iti__gp
{
    --iti-flag-offset: -1360px;
}

.iti__gq
{
    --iti-flag-offset: -1376px;
}

.iti__gr
{
    --iti-flag-offset: -1392px;
}

.iti__gt
{
    --iti-flag-offset: -1408px;
}

.iti__gu
{
    --iti-flag-offset: -1424px;
}

.iti__gw
{
    --iti-flag-offset: -1440px;
}

.iti__gy
{
    --iti-flag-offset: -1456px;
}

.iti__hk
{
    --iti-flag-offset: -1472px;
}

.iti__hn
{
    --iti-flag-offset: -1488px;
}

.iti__hr
{
    --iti-flag-offset: -1504px;
}

.iti__ht
{
    --iti-flag-offset: -1520px;
}

.iti__hu
{
    --iti-flag-offset: -1536px;
}

.iti__id
{
    --iti-flag-offset: -1552px;
}

.iti__ie
{
    --iti-flag-offset: -1568px;
}

.iti__il
{
    --iti-flag-offset: -1584px;
}

.iti__im
{
    --iti-flag-offset: -1600px;
}

.iti__in
{
    --iti-flag-offset: -1616px;
}

.iti__io
{
    --iti-flag-offset: -1632px;
}

.iti__iq
{
    --iti-flag-offset: -1648px;
}

.iti__ir
{
    --iti-flag-offset: -1664px;
}

.iti__is
{
    --iti-flag-offset: -1680px;
}

.iti__it
{
    --iti-flag-offset: -1696px;
}

.iti__je
{
    --iti-flag-offset: -1712px;
}

.iti__jm
{
    --iti-flag-offset: -1728px;
}

.iti__jo
{
    --iti-flag-offset: -1744px;
}

.iti__jp
{
    --iti-flag-offset: -1760px;
}

.iti__ke
{
    --iti-flag-offset: -1776px;
}

.iti__kg
{
    --iti-flag-offset: -1792px;
}

.iti__kh
{
    --iti-flag-offset: -1808px;
}

.iti__ki
{
    --iti-flag-offset: -1824px;
}

.iti__km
{
    --iti-flag-offset: -1840px;
}

.iti__kn
{
    --iti-flag-offset: -1856px;
}

.iti__kp
{
    --iti-flag-offset: -1872px;
}

.iti__kr
{
    --iti-flag-offset: -1888px;
}

.iti__kw
{
    --iti-flag-offset: -1904px;
}

.iti__ky
{
    --iti-flag-offset: -1920px;
}

.iti__kz
{
    --iti-flag-offset: -1936px;
}

.iti__la
{
    --iti-flag-offset: -1952px;
}

.iti__lb
{
    --iti-flag-offset: -1968px;
}

.iti__lc
{
    --iti-flag-offset: -1984px;
}

.iti__li
{
    --iti-flag-offset: -2000px;
}

.iti__lk
{
    --iti-flag-offset: -2016px;
}

.iti__lr
{
    --iti-flag-offset: -2032px;
}

.iti__ls
{
    --iti-flag-offset: -2048px;
}

.iti__lt
{
    --iti-flag-offset: -2064px;
}

.iti__lu
{
    --iti-flag-offset: -2080px;
}

.iti__lv
{
    --iti-flag-offset: -2096px;
}

.iti__ly
{
    --iti-flag-offset: -2112px;
}

.iti__ma
{
    --iti-flag-offset: -2128px;
}

.iti__mc
{
    --iti-flag-offset: -2144px;
}

.iti__md
{
    --iti-flag-offset: -2160px;
}

.iti__me
{
    --iti-flag-offset: -2176px;
}

.iti__mf
{
    --iti-flag-offset: -2192px;
}

.iti__mg
{
    --iti-flag-offset: -2208px;
}

.iti__mh
{
    --iti-flag-offset: -2224px;
}

.iti__mk
{
    --iti-flag-offset: -2240px;
}

.iti__ml
{
    --iti-flag-offset: -2256px;
}

.iti__mm
{
    --iti-flag-offset: -2272px;
}

.iti__mn
{
    --iti-flag-offset: -2288px;
}

.iti__mo
{
    --iti-flag-offset: -2304px;
}

.iti__mp
{
    --iti-flag-offset: -2320px;
}

.iti__mq
{
    --iti-flag-offset: -2336px;
}

.iti__mr
{
    --iti-flag-offset: -2352px;
}

.iti__ms
{
    --iti-flag-offset: -2368px;
}

.iti__mt
{
    --iti-flag-offset: -2384px;
}

.iti__mu
{
    --iti-flag-offset: -2400px;
}

.iti__mv
{
    --iti-flag-offset: -2416px;
}

.iti__mw
{
    --iti-flag-offset: -2432px;
}

.iti__mx
{
    --iti-flag-offset: -2448px;
}

.iti__my
{
    --iti-flag-offset: -2464px;
}

.iti__mz
{
    --iti-flag-offset: -2480px;
}

.iti__na
{
    --iti-flag-offset: -2496px;
}

.iti__nc
{
    --iti-flag-offset: -2512px;
}

.iti__ne
{
    --iti-flag-offset: -2528px;
}

.iti__nf
{
    --iti-flag-offset: -2544px;
}

.iti__ng
{
    --iti-flag-offset: -2560px;
}

.iti__ni
{
    --iti-flag-offset: -2576px;
}

.iti__nl
{
    --iti-flag-offset: -2592px;
}

.iti__no
{
    --iti-flag-offset: -2608px;
}

.iti__np
{
    --iti-flag-offset: -2624px;
}

.iti__nr
{
    --iti-flag-offset: -2640px;
}

.iti__nu
{
    --iti-flag-offset: -2656px;
}

.iti__nz
{
    --iti-flag-offset: -2672px;
}

.iti__om
{
    --iti-flag-offset: -2688px;
}

.iti__pa
{
    --iti-flag-offset: -2704px;
}

.iti__pe
{
    --iti-flag-offset: -2720px;
}

.iti__pf
{
    --iti-flag-offset: -2736px;
}

.iti__pg
{
    --iti-flag-offset: -2752px;
}

.iti__ph
{
    --iti-flag-offset: -2768px;
}

.iti__pk
{
    --iti-flag-offset: -2784px;
}

.iti__pl
{
    --iti-flag-offset: -2800px;
}

.iti__pm
{
    --iti-flag-offset: -2816px;
}

.iti__pr
{
    --iti-flag-offset: -2832px;
}

.iti__ps
{
    --iti-flag-offset: -2848px;
}

.iti__pt
{
    --iti-flag-offset: -2864px;
}

.iti__pw
{
    --iti-flag-offset: -2880px;
}

.iti__py
{
    --iti-flag-offset: -2896px;
}

.iti__qa
{
    --iti-flag-offset: -2912px;
}

.iti__re
{
    --iti-flag-offset: -2928px;
}

.iti__ro
{
    --iti-flag-offset: -2944px;
}

.iti__rs
{
    --iti-flag-offset: -2960px;
}

.iti__ru
{
    --iti-flag-offset: -2976px;
}

.iti__rw
{
    --iti-flag-offset: -2992px;
}

.iti__sa
{
    --iti-flag-offset: -3008px;
}

.iti__sb
{
    --iti-flag-offset: -3024px;
}

.iti__sc
{
    --iti-flag-offset: -3040px;
}

.iti__sd
{
    --iti-flag-offset: -3056px;
}

.iti__se
{
    --iti-flag-offset: -3072px;
}

.iti__sg
{
    --iti-flag-offset: -3088px;
}

.iti__sh
{
    --iti-flag-offset: -3104px;
}

.iti__si
{
    --iti-flag-offset: -3120px;
}

.iti__sj
{
    --iti-flag-offset: -3136px;
}

.iti__sk
{
    --iti-flag-offset: -3152px;
}

.iti__sl
{
    --iti-flag-offset: -3168px;
}

.iti__sm
{
    --iti-flag-offset: -3184px;
}

.iti__sn
{
    --iti-flag-offset: -3200px;
}

.iti__so
{
    --iti-flag-offset: -3216px;
}

.iti__sr
{
    --iti-flag-offset: -3232px;
}

.iti__ss
{
    --iti-flag-offset: -3248px;
}

.iti__st
{
    --iti-flag-offset: -3264px;
}

.iti__sv
{
    --iti-flag-offset: -3280px;
}

.iti__sx
{
    --iti-flag-offset: -3296px;
}

.iti__sy
{
    --iti-flag-offset: -3312px;
}

.iti__sz
{
    --iti-flag-offset: -3328px;
}

.iti__tc
{
    --iti-flag-offset: -3344px;
}

.iti__td
{
    --iti-flag-offset: -3360px;
}

.iti__tg
{
    --iti-flag-offset: -3376px;
}

.iti__th
{
    --iti-flag-offset: -3392px;
}

.iti__tj
{
    --iti-flag-offset: -3408px;
}

.iti__tk
{
    --iti-flag-offset: -3424px;
}

.iti__tl
{
    --iti-flag-offset: -3440px;
}

.iti__tm
{
    --iti-flag-offset: -3456px;
}

.iti__tn
{
    --iti-flag-offset: -3472px;
}

.iti__to
{
    --iti-flag-offset: -3488px;
}

.iti__tr
{
    --iti-flag-offset: -3504px;
}

.iti__tt
{
    --iti-flag-offset: -3520px;
}

.iti__tv
{
    --iti-flag-offset: -3536px;
}

.iti__tw
{
    --iti-flag-offset: -3552px;
}

.iti__tz
{
    --iti-flag-offset: -3568px;
}

.iti__ua
{
    --iti-flag-offset: -3584px;
}

.iti__ug
{
    --iti-flag-offset: -3600px;
}

.iti__us
{
    --iti-flag-offset: -3616px;
}

.iti__uy
{
    --iti-flag-offset: -3632px;
}

.iti__uz
{
    --iti-flag-offset: -3648px;
}

.iti__va
{
    --iti-flag-offset: -3664px;
}

.iti__vc
{
    --iti-flag-offset: -3680px;
}

.iti__ve
{
    --iti-flag-offset: -3696px;
}

.iti__vg
{
    --iti-flag-offset: -3712px;
}

.iti__vi
{
    --iti-flag-offset: -3728px;
}

.iti__vn
{
    --iti-flag-offset: -3744px;
}

.iti__vu
{
    --iti-flag-offset: -3760px;
}

.iti__wf
{
    --iti-flag-offset: -3776px;
}

.iti__ws
{
    --iti-flag-offset: -3792px;
}

.iti__xk
{
    --iti-flag-offset: -3808px;
}

.iti__ye
{
    --iti-flag-offset: -3824px;
}

.iti__yt
{
    --iti-flag-offset: -3840px;
}

.iti__za
{
    --iti-flag-offset: -3856px;
}

.iti__zm
{
    --iti-flag-offset: -3872px;
}

.iti__zw
{
    --iti-flag-offset: -3888px;
}

.iti__globe
{
    height: 19px;

    background-image: var(--iti-path-globe-1x);
    background-position: right;
    background-size: contain;
    -webkit-box-shadow: none;
            box-shadow: none;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2x)
{
    .iti__flag
    {
        background-image: var(--iti-path-flags-2x);
    }
    .iti__globe
    {
        background-image: var(--iti-path-globe-2x);
    }
}
/**
 * Functions for ViewPort Calculating
============
  SCSS
  .selector {
    font-size: px(18);
    @include md
      font-size: pxm(14);
    @include xs
      font-size: pxs(12);
  }

  CSS RESULT
  .selector {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    .selector {
      font-size: 14px;
    }
  }
  @media (max-width: 320px) {
    .selector {
      font-size: 12px;
    }
  }
============
*/
/**
  function VA => use for calculate letter-spacing from photoshop
============
  SCSS
  .selector {
    letter-spacing: VA(120, 14);
  }

  CSS RESULT
  .selector {
    letter-spacing: 1.68px;
  }
============
  $unit => VA value in photoshop
  $font_size => how px font-size in photoshop ||| options 16
  $return px | em ||| options px
 */
/* Init */
/*  @include adaptive-value("padding-top", 15, 7, 1);
    @include adaptive-value("padding-bottom", 15, 7, 1);
    @include adaptive-value("padding-right", 15, 7, 1);
    @include adaptive-value("padding-left", 15, 7, 1);

    @include adaptive-value("margin-top", 15, 7, 1);
    @include adaptive-value("margin-bottom", 40 , 20, 1);
    @include adaptive-value("margin-right", 15, 7, 1);
    @include adaptive-value("margin-left", 15, 7, 1);

    @include adaptive-value("font-size", 115, 96, 1);
*/
/* "paddibg" - свойство которое нужно адаптировать */
/* 50 - максимальное значение свойства в px */
/* 20 - минимальное значение свойства в px */
/* 0 или 1 или 2 - ($type) условие, по которому будет выполняться адаптив */
/* Touch screens with fingers */
/* Mouse touch pad */
@font-face
{
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;

    src: url('../fonts/Montserrat-Bold.eot') format('eot');
    src: url('../fonts/Montserrat-Bold.woff') format('woff'), url('../fonts/Montserrat-Bold.woff2') format('woff2'), url('../fonts/Montserrat-Bold.svg') format('svg'), url('../fonts/Montserrat-Bold.ttf') format('ttf');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;

    src: url('../fonts/Montserrat-Regular.eot') format('eot');
    src: url('../fonts/Montserrat-Regular.woff') format('woff'), url('../fonts/Montserrat-Regular.woff2') format('woff2'), url('../fonts/Montserrat-Regular.svg') format('svg'), url('../fonts/Montserrat-Regular.ttf') format('ttf');
    font-display: swap;
}
:root
{
    --white: #fff;
    --whiteF8: #f8f8f8;
    --whiteFC: #fcfff5;
    --black: #000;
    --black05: #050b1a;
    --black2F: #2f2f31;
    --blueCE: #cee3ec80;
    --blue: #007bb3;
    --hover-blue: #004565;
    --grey: #58595b;
    --grey70: #707173;
    --yellow: #e8b524;
    --green: #03c15c;
    --red: #ac1e1e;
    --font-montserrat: 'Montserrat',
    sans-serif;
    --font-primary: var(--font-montserrat);
    --font-size: 14px;
    --bg-primary: var(--white);
    --text-color-primary: var(--black);
    --text-color-primary-hover: var(--black);
    --current-scrollbar-width: 0;
    --header-height: 0;
}

*,
*::before,
*::after
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

*
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html
{
    font-family: var(--font-primary);
    font-size: var(--font-size);
    font-weight: normal;
    line-height: 1.3;

    color: var(--text-color-primary);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    scroll-behavior: smooth;
}
html::-webkit-scrollbar
{
    width: 10px;
}
html::-webkit-scrollbar-track
{
    background-color: var(--white);
}
html::-webkit-scrollbar-thumb
{
    background-color: var(--blue);
}

body
{
    padding-right: var(--current-scrollbar-width);

    background-color: var(--bg-primary);
}
body.lock
{
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    margin: 0;
}

ul,
ol,
li
{
    margin: 0;
    padding: 0;

    list-style-type: none;
}

p
{
    margin: 0;
}

a
{
    text-decoration: none;

    color: black;
}

a:hover
{
    text-decoration: none;
}

button
{
    padding: 0;

    cursor: pointer;

    border: none;
    background-color: initial;
}

audio,
canvas,
iframe,
img,
svg,
video
{
    vertical-align: middle;
}

video
{
    width: 100%;
    height: auto;
}

textarea
{
    resize: none;
}

section
{
    position: relative;
}

input,
select,
button,
textarea
{
    font: inherit;

    color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
{
    margin: 0;

    -webkit-appearance: none;
            appearance: none;
}

input[type=number]
{
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
}

fieldset
{
    margin: 0;
    padding: 0;

    border-width: 0;
    border-style: none;
    border-color: transparent;
    -o-border-image: none;
       border-image: none;

    min-inline-size: -webkit-min-content;
    min-inline-size:    -moz-min-content;
    min-inline-size:         min-content;
}

.wrapper
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    max-width: 100vw;
    min-height: 100vh;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}

.content
{
    position: relative;
    z-index: 500;

    overflow: hidden;

    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
}

.container
{
    max-width: 1180px;
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
}

.container-fluid
{
    padding-right: 20px;
    padding-left: 20px;
}

.important_none
{
    display: none;
}

.swiper-button-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-top: 20px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 30px;
}

.arrow_wrap-mob
{
    display: none;
}
@media (max-width: 576px)
{
    .arrow_wrap-mob
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        margin-top: 20px;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 30px;
    }
}

.swiper-button-prev,
.swiper-button-next
{
    position: static;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 47px;
    height: 47px;
    margin-top: 0;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50%;
    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.swiper-button-prev:hover,
.swiper-button-next:hover
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--hover-blue);
}
.swiper-button-prev::after,
.swiper-button-next::after
{
    display: none;
}

.swiper-button-prev .button__image
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 6px;
    height: 100%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.swiper-button-next .button__image
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 6px;
    height: 100%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.swiper-pagination
{
    position: static;

    width: -webkit-max-content !important;
    width:    -moz-max-content !important;
    width:         max-content !important;
}

.swiper-pagination-bullet
{
    width: 8px;
    height: 8px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50%;
}
.swiper-pagination-bullet:hover
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
    background-color: var(--blue);
}

.swiper-pagination-bullet-active
{
    width: 24px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50px;
    background-color: var(--blue);
}

.visually-hidden
{
    position: absolute;

    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;

    clip-path: inset(50%);
}

.section_bg_gray
{
    background-color: var(--whiteF8);
}

.dial_tariff
{
    background-color: var(--whiteF8);
}
@media (max-width: 576px)
{
    .dial_tariff
    {
        padding-bottom: 60px;
    }
}

@media (max-width: 576px)
{
    .swiper-pagination
    {
        display: none;
    }
}

.drop-shadow
{
    position: fixed;
    z-index: 2;

    display: none;

    background-color: rgba(0, 0, 0, .4);

    inset: 0;
}
.drop-shadow.show
{
    display: block;
}

.title_blue_text b,
.title_blue_text strong
{
    color: var(--blue);
}

.blog_card
{
    position: relative;

    overflow: hidden;

    width: 100%;
    max-width: 588px;

    border-radius: 5px 30px;
    background: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
}
.blog_category-wrap
{
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.blog_category
{
    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    padding: 10px;

    border-radius: 5px;
    background-color: var(--whiteF8);
}
.blog_category span
{
    font-size: 13px;
    font-weight: 600;

    color: var(--hover-blue);
}
@media (max-width: 1200px)
{
    .blog_descr
    {
        font-size: 16px;
    }
}
@media (max-width: 992px)
{
    .blog_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .blog_text-wrap
    {
        max-width: 100%;
    }
    .blog_category-wrap
    {
        top: 10px;
        left: 10px;
    }
}
@media (max-width: 992px)
{
    .blog_category-wrap
    {
        top: 10px;
        left: 10px;
    }
}

.hot
{
    background-color: var(--yellow);
}
.hot span
{
    color: var(--white);
}

.blog_card .card_text
{
    padding: 35px 25px;
}
.blog_card .card_title
{
    font-weight: 700;
    line-height: 144%;

    display: -webkit-box;
    overflow: hidden;

    margin-bottom: 15px;

    color: var(--black2F);

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.blog_card .card_bottom
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
}
.blog_card .card_info-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 10px 20px;
}
.blog_card .card_btn
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 47px;
    max-width: 47px;
    min-height: 47px;
    max-height: 47px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50%;
    background-color: var(--blue);

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (hover: hover)
{
    .blog_card .card_btn:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        background-color: var(--hover-blue);
    }
}
@media (hover: none)
{
    .blog_card .card_btn:active
    {
        background-color: var(--hover-blue);
    }
}
@media (max-width: 576px)
{
    .blog_card .card_info-wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 15px;
    }
    .blog_card .card_text
    {
        padding: 25px 15px;
    }
}

.big_card .card_title
{
    font-size: 18px;
}

@media (max-width: 1200px)
{
    .big_card .card_title
    {
        font-size: 14px;

        margin-bottom: 25px;
    }
}
.small_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    max-height: 167px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.small_card .blog_category-wrap
{
    top: 10px;
    left: 10px;
}
.small_card .blog__image
{
    width: 170px;
    height: 167px;
}
.small_card .card_text
{
    width: 100%;
    max-width: 340px;
    padding: 25px;
    /* Только если меньше контейнера */
    padding-top: 25px;
    /* Только если меньше контейнера */
    padding-right: 25px;
    /* Только если меньше контейнера */
    padding-bottom: 25px;
    /* Только если меньше контейнера */
    padding-left: 25px;
}
@media (max-width: 1320px)
{
    .small_card .card_text
    {
        padding-top: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .small_card .card_text
    {
        padding-bottom: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .small_card .card_text
    {
        padding-right: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .small_card .card_text
    {
        padding-left: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
.small_card .card_title
{
    font-size: 14px;
}
.small_card .card_info-wrap
{
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 5px;
}

@media (max-width: 576px)
{
    .small_card
    {
            flex-direction: column;

        height: auto;
        max-height: 100%;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .small_card .blog__image
    {
        width: 100%;
    }
}
.icon-calendar,
.icon-clock
{
    color: var(--blue);
}

.icon-calendar
{
    width: 18px;
    height: 18px;
}

.icon-clock
{
    width: 20px;
    height: 20px;
}

.icon-arrow_right
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 12px;
    height: 10px;

    color: var(--white);

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog__image
{
    position: relative;

    display: block;
    overflow: hidden;

    width: 100%;
    height: 250px;

    border-radius: 5px 30px;
}
.blog__image .blog__img
{
    position: absolute;

    width: 100%;
    height: 100%;

    inset: 0;
    -o-object-fit: cover;
       object-fit: cover;
}

@media (max-width: 576px)
{
    .blog__image
    {
        height: 200px;
    }
}
.info_body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}
.info_text
{
    font-size: 14px;

    white-space: nowrap;

    color: var(--grey);
}

.success_modal-container
{
    position: fixed;
    z-index: 15;

    visibility: hidden;

    width: 100%;
    height: 100%;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    background-color: rgba(0, 0, 0, .4);

    inset: 0;
}
.success_modal-container.openRequest
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;

    scale: 1;
}
.success_modal-container .success_modal
{
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;

    width: calc(100% - 40px);
    max-width: 600px;
    padding: 50px 20px;
    /* Только если меньше контейнера */
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-right: 40px;
    /* Только если меньше контейнера */
    padding-bottom: 30px;
    /* Только если меньше контейнера */
    padding-left: 40px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;

    border-radius: 30px;
    background-color: var(--white);

    scale: 1;
}
@media (max-width: 1320px)
{
    .success_modal-container .success_modal
    {
        padding-top: calc(50px + -20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .success_modal-container .success_modal
    {
        padding-bottom: calc(25px + 5 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .success_modal-container .success_modal
    {
        padding-right: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .success_modal-container .success_modal
    {
        padding-left: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
.success_modal-container .success_modal.successful_modal_eng .text-wrap
{
    max-width: 440px;
}
.success_modal-container .success_modal .close-btn
{
    position: absolute;
    top: 18px;
    right: 18px;

    width: 20px;
    height: 20px;
}
.success_modal-container .success_modal .close-btn::after,
.success_modal-container .success_modal .close-btn::before
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 1px;

    content: '';

    background-color: var(--black2F);

    translate: -50% -50%;
}
.success_modal-container .success_modal .close-btn::after
{
    rotate: -45deg;
}
.success_modal-container .success_modal .close-btn::before
{
    rotate: 45deg;
}
.success_modal-container .success_modal .icon
{
    width: 97px;
    height: 100px;
    /* Только если меньше контейнера */
    margin-bottom: 60px;

    color: var(--green);
}
@media (max-width: 1320px)
{
    .success_modal-container .success_modal .icon
    {
        margin-bottom: calc(30px + 30 * (100vw - 360px) / 960);
    }
}
.success_modal-container .success_modal .text-wrap
{
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
}
.success_modal-container .success_modal .text-wrap h3
{
    font-size: 18px;
    font-weight: 700;

    margin-bottom: 25px;

    color: var(--black2F);
}
.success_modal-container .success_modal .text-wrap p
{
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4285714286;

    margin-bottom: 25px;

    color: var(--grey);
}
.success_modal-container .success_modal .text-wrap span
{
    font-size: 14px;

    color: var(--grey);
}
.success_modal-container .success_modal .text-wrap a
{
    font-weight: 600;

    text-decoration: underline;

    color: var(--blue);
}

.section-title
{
    /* Только если меньше контейнера */
    font-size: 40px;
    font-weight: 700;
    line-height: 125%;

    margin: 0 auto 50px auto;
    /* Только если меньше контейнера */
    margin-bottom: 50px;

    text-align: center;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .section-title
    {
        font-size: calc(28px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .section-title
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}

.primary-link
{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
.primary-link:hover
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--hover-blue);
}

.btn
{
    font-size: 14px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;

    position: relative;

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
        flex-direction: row;

    max-width: 100%;
    height: 60px;
    padding: 5px 30px;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: .2s ease;
            transition: .2s ease;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;

    color: var(--white);
    border: 1px solid transparent;
    border-radius: 8px;
    border-radius: 50px;
    outline: 0;
    background-color: transparent;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.btn_pimary
{
    color: var(--white);
    background-color: var(--blue);
}
.btn_pimary:hover
{
    background-color: var(--hover-blue);
}
.btn_outline
{
    color: var(--blue);
    border: 1px solid var(--blue);
}
.btn_outline:hover
{
    color: var(--white);
    background-color: var(--blue);
}
.btn_white
{
    color: var(--blue, #007bb3);
    background-color: var(--white);
}
.btn_white:hover
{
    color: var(--white);
    border: 1px solid #fff;
    background-color: var(--blue);
}

.play_btn
{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;

    width: 104px;
    height: 104px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 2px solid var(--white);
    border-radius: 50%;
    background-color: var(--hover-blue);

    translate: -50% -50%;
}
.play_btn .icon-play
{
    width: 22px;
    height: 19px;

    color: var(--white);
}
.play_btn.hide_btn
{
    -webkit-animation: scaleDown 1s linear forwards;
            animation: scaleDown 1s linear forwards;
}
@media (max-width: 768px)
{
    .play_btn
    {
        width: 48px;
        height: 48px;
    }
    .play_btn .icon-play
    {
        width: 16px;
        height: 13px;
    }
}

@media (hover: hover)
{
    .play_btn:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        border-color: var(--blue);
        background-color: var(--blue);
    }
}
@-webkit-keyframes scaleDown
{
    0%
    {
        scale: 1;
    }
    25%
    {
        scale: 1.3;
    }
    50%
    {
        scale: 1;
    }
    100%
    {
        display: none;
        visibility: hidden;

        opacity: 0;

        scale: 0;
    }
}
@keyframes scaleDown
{
    0%
    {
        scale: 1;
    }
    25%
    {
        scale: 1.3;
    }
    50%
    {
        scale: 1;
    }
    100%
    {
        display: none;
        visibility: hidden;

        opacity: 0;

        scale: 0;
    }
}
.burger-btn
{
    position: relative;
    z-index: 2;

    display: none;

    width: 34px;
    height: 20px;
}
.burger-btn i
{
    position: absolute;
    left: 0;

    display: inline-block;

    width: 100%;
    height: 4px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50px;
    background-color: var(--blue);
}
.burger-btn i:nth-child(1)
{
    top: 0;
}
.burger-btn i:nth-child(2)
{
    top: 50%;
    right: 0;
    left: auto;

    width: 75%;
}
.burger-btn i:nth-child(3)
{
    top: 100%;
}
.burger-btn.active i
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
}
.burger-btn.active i:nth-child(1)
{
    top: 50%;

    rotate: 45deg;
}
.burger-btn.active i:nth-child(2)
{
    opacity: 0;
}
.burger-btn.active i:nth-child(3)
{
    top: 50%;

    rotate: -45deg;
}

@media (max-width: 1024px)
{
    .burger-btn
    {
        display: block;
    }
}
.accordion .accordion__item
{
    overflow: hidden;

    width: calc(50% - 20px);

    border-radius: 5px 30px 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.accordion .accordion__item:hover
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--blue);
}
.accordion .accordion__item:hover span
{
    -webkit-transition: all .5s ease 0s;
            transition: all .5s ease 0s;

    color: var(--white);
}
.accordion .accordion__item:hover .open_status
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--white);
}
.accordion .accordion__item:hover .open_status::after,
.accordion .accordion__item:hover .open_status::before
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--blue);
}
.accordion .accordion__item.active
{
    background-color: var(--blue);
}
.accordion .accordion__item.active .accordion__toggler
{
    background-color: var(--blue);
}
.accordion .accordion__item.active span
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--white);
}
.accordion .accordion__item.active .item_text-wrap p
{
    margin-bottom: 25px;
}
.accordion .accordion__item.active .open_status
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--white);
}
.accordion .accordion__item.active .open_status::after,
.accordion .accordion__item.active .open_status::before
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--blue);
}
.accordion .accordion__item.active .open_status::after
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
            transform: translate(-50%, -50%) rotate(180deg);
}
.accordion .accordion__content
{
    display: none;

    padding: 25px 25px 0 25px;

    -webkit-transform: translate(0px, -25px);
            transform: translate(0px, -25px);
    text-align: left;

    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 30px;
}
.accordion .accordion__toggler
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    min-height: 105px;
    padding: 25px;

    cursor: pointer;
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-align: start;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 15px;
}
@media (max-width: 576px)
{
    .accordion .accordion__toggler
    {
        min-height: auto;
    }
}
@media (max-width: 1024px)
{
    .accordion .accordion__item
    {
        width: 100%;
        height: auto;
    }
}
.accordion span
{
    font-size: 18px;
    font-weight: 700;
    line-height: 144.4444444444%;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-align: start;

    color: var(--blue);
}
@media (max-width: 576px)
{
    .accordion span
    {
        font-size: 16px;
    }
}
.accordion .open_status
{
    position: relative;

    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50%;
    background-color: var(--blue);
}
.accordion .open_status::after,
.accordion .open_status::before
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 7.45px;
    height: 2px;

    content: '';
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    background-color: var(--white);
}
.accordion .open_status::after
{
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
            transform: translate(-50%, -50%) rotate(90deg);
}
.accordion p,
.accordion li,
.accordion b
{
    font-size: 14px;
    line-height: 142.8571428571%;

    height: auto;

    color: var(--white);
}
.accordion b
{
    display: inline-block;
}
.accordion ul,
.accordion ol
{
    padding-left: 15px;
}
.accordion ul li
{
    list-style: outside;
}
.accordion ul li b
{
    margin: 0;
}
.accordion ol li
{
    list-style: auto;
}
.accordion ol li b
{
    margin: 0;
}
.accordion li
{
    margin-top: 0;
    margin-bottom: 3px;
}

.breadcrumbs
{
    position: relative;
    z-index: 1;
    /* Только если меньше контейнера */

    margin-bottom: 60px;
}
@media (max-width: 1320px)
{
    .breadcrumbs
    {
        margin-bottom: calc(50px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .breadcrumbs
    {
        display: none;
    }
}
.breadcrumbs .icon-bc_icon
{
    width: 6px;
    height: 10px;

    color: var(--blue);
}
.breadcrumbs__list
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.breadcrumbs__item
{
    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
}
.breadcrumbs__item:last-child
{
    overflow: hidden;

    width: 100%;
    max-width: 315px;

    white-space: nowrap;
    text-overflow: ellipsis;
}
.breadcrumbs__item:last-child .icon
{
    display: none;
}
@media (max-width: 576px)
{
    .breadcrumbs__item:last-child .icon
    {
        display: block;
    }
}
.breadcrumbs__link
{
    font-size: 14px;
    font-weight: 300;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-transition: all .3s;
            transition: all .3s;

    color: var(--grey);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}
@media (max-width: 576px)
{
    .breadcrumbs__link
    {
            flex-direction: row-reverse;

        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .breadcrumbs__link .icon
    {
        rotate: 180deg;
    }
}

a.breadcrumbs__link:hover
{
    color: var(--blue);
}

.breadcrumbs_mob
{
    display: none;
}
@media (max-width: 576px)
{
    .breadcrumbs_mob
    {
        display: block;

        margin-bottom: 50px;
    }
}

.tabs .tabs__head-row
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 30px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
}
@media (max-width: 768px)
{
    .tabs .tabs__head-row
    {
        -webkit-column-gap: 25px;
           -moz-column-gap: 25px;
                column-gap: 25px;
    }
}
@media (max-width: 576px)
{
    .tabs .tabs__head-row
    {
        overflow: scroll;

        white-space: nowrap;

        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        scrollbar-width: none;
    }
    .tabs .tabs__head-row::-webkit-scrollbar
    {
        display: none;
    }
}
.tabs .tabs__head-col
{
    width: 100%;
}
.tabs .tabs__btn
{
    line-height: 144.4444444444%;

    width: 100%;
    padding: 13px 5px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: .5;
    color: var(--grey);
    border-bottom: 1px solid var(--grey);
}
.tabs .tabs__btn.active
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
    color: var(--blue);
    border-color: var(--blue);
}
.tabs .tabs__item
{
    display: none;

    opacity: 0;
}
.tabs .tabs__item.active
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    opacity: 1;
}

.not-found
{
    position: relative;

    overflow: hidden;

    width: calc(100% - 20px);
    max-width: 1400px;
    margin: 0 auto;
    padding: 60px 20px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
}
.not-found::before
{
    position: absolute;
    /* Только если меньше контейнера */
    top: -1100px;
    left: 0;

    width: 100%;
    height: auto;

    content: '';

    border-radius: 50%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(-.96%, rgba(255, 255, 255, .5)), color-stop(45.36%, rgba(255, 255, 255, 0)));
    background: linear-gradient(360deg, rgba(255, 255, 255, .5) -.96%, rgba(255, 255, 255, 0) 45.36%);

    aspect-ratio: 1/1;
}
@media (max-width: 1320px)
{
    .not-found::before
    {
        top: calc(300px + -1400 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .not-found::before
    {
        top: -100px;
        left: -50%;

        width: 638px;

        -webkit-transform: translateX(-20%);
                transform: translateX(-20%);
    }
}
.not-found_content
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* Только если меньше контейнера */
    gap: 60px;
}
@media (max-width: 1320px)
{
    .not-found_content
    {
        gap: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .not-found_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.not-found .not-found__image
{
    width: 100%;
    max-width: 500px;
}
.not-found .not-found__image img
{
    width: 100%;
    height: 100%;
}
.not-found_text-wrap
{
    width: 100%;
    max-width: 580px;

    text-align: start;
}
@media (max-width: 768px)
{
    .not-found_text-wrap
    {
        text-align: center;
    }
}
.not-found__title
{
    margin-bottom: 40px;

    text-align: start;

    color: var(--blue);
}
@media (max-width: 768px)
{
    .not-found__title
    {
        text-align: center;
    }
}
.not-found__title br
{
    display: none;
}
@media (max-width: 576px)
{
    .not-found__title br
    {
        display: block;
    }
}
.not-found__text
{
    /* Только если меньше контейнера */
    font-size: 18px;
    line-height: 1.4444444444;

    width: 100%;
    max-width: 339px;
    /* Только если меньше контейнера */
    margin-bottom: 60px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .not-found__text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .not-found__text
    {
        margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .not-found__text
    {
        margin: 0 auto 40px auto;
    }
}
.not-found .not-found__link
{
    /* Только если меньше контейнера */
    font-size: 18px;
}
@media (max-width: 1320px)
{
    .not-found .not-found__link
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .not-found .not-found__link
    {
        width: 100%;
    }
}

.not_found_ua .not-found__text
{
    max-width: 445px;
}

.modal
{
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;

    visibility: hidden;

    width: 100%;
    height: 100%;

    pointer-events: none;

    opacity: 0;
    background-color: rgba(0, 0, 0, .5);

    padding-block: 10vh;
}
.modal._show
{
    visibility: visible;

    pointer-events: auto;

    opacity: 1;
}
.modal__dialog
{
    width: 100%;
    max-width: 800px;
    height: calc(100% - 20vh);
    margin: 0 auto;

    background-color: #fff;
}
.modal__header-close-btn
{
    display: block;

    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    height: -webkit-fit-content;
    height:    -moz-fit-content;
    height:         fit-content;
    margin-left: auto;
}

.modal_cookies
{
    position: fixed;
    z-index: 999;
    right: -100%;
    bottom: 35px;

    width: 100%;
    max-width: 400px;
    padding: 25px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 15px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
}
.modal_cookies.show
{
    right: 35px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
}
@media (max-width: 576px)
{
    .modal_cookies.show
    {
        right: 50%;

        width: calc(100% - 30px);
        max-width: 100%;

        -webkit-transform: translateX(50%);
                transform: translateX(50%);
    }
}
.modal_cookies .modal__header-title
{
    font-size: 14px;
    line-height: 1.4285714286;

    margin-bottom: 20px;
}
.modal_cookies .modal__header-title a
{
    text-decoration: underline;

    color: var(--blue);
}
@media (max-width: 576px)
{
    .modal_cookies .modal__header-title
    {
        text-align: center;
    }
}
.modal_cookies .modal__btn-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
@media (max-width: 480px)
{
    .modal_cookies .modal__btn-wrap
    {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
}
.modal_cookies button,
.modal_cookies a
{
    font-size: 14px;
    font-weight: 400;

    width: 100%;
    height: 40px;
}

.dropdown__item
{
    visibility: hidden;

    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
    -webkit-transition-property: opacity, visibility;
            transition-property: opacity, visibility;
    pointer-events: none;

    opacity: 0;
}
.dropdown__item._active
{
    visibility: visible;

    pointer-events: all;

    opacity: 1;
}

.radio
{
    position: relative;

    display: block;
}
.radio__input
{
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    cursor: pointer;

    border-radius: inherit;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.radio__custom
{
    display: block;

    width: 100%;
    height: 100%;

    pointer-events: none;
}

.checkbox
{
    position: relative;

    display: block;
}
.checkbox__input
{
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    cursor: pointer;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.checkbox__custom
{
    display: block;

    width: 100%;
    height: 100%;

    pointer-events: none;
}

.input_body
{
    position: relative;
}
.input_body .alert-name__image,
.input_body .alert-email__image,
.input_body .alert-phone__image
{
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 20px;

    width: 20px;
    height: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

    scale: 1;
}
.input_body .alert-name__image.hide,
.input_body .alert-email__image.hide,
.input_body .alert-phone__image.hide
{
    scale: 0;
}

.form_input
{
    font-size: 16px;

    width: 100%;
    height: 60px;
    padding: 25px 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--white);
    border: 1px solid transparent;
    border-radius: 500px;
    background-color: rgba(0, 0, 0, .2);
}
.form_input::-webkit-input-placeholder
{
    opacity: .7;
    color: #fcfff5;
}
.form_input::-moz-placeholder
{
    opacity: .7;
    color: #fcfff5;
}
.form_input:-ms-input-placeholder
{
    opacity: .7;
    color: #fcfff5;
}
.form_input::-ms-input-placeholder
{
    opacity: .7;
    color: #fcfff5;
}
.form_input::placeholder
{
    opacity: .7;
    color: #fcfff5;
}
.form_input:focus
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--white);
    outline: none;
}
.form_input:focus-visible
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--white);
    outline: none;
}

#phone
{
    padding-left: 75px;
}

.iti
{
    width: 100%;
}

.iti--inline-dropdown .iti__dropdown-content
{
    overflow: hidden;

    width: 330px !important;
    margin-top: 5px;

    border-radius: 10px;
    background: var(--white);
}

.iti__country-container
{
    padding: 0;
}

.iti__flag-container:hover
{
    padding: 0;
}

.iti__arrow--up
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-top: 4px solid transparent !important;
    border-bottom: 4px solid var(--white);
}

.iti__selected-country-primary
{
    position: relative;

    padding: 0 10px 0 20px;

    border-radius: 500px 0 0 500px;
}

.iti__arrow
{
    margin-left: 10px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-top: 4px solid var(--white);
}

.iti__search-input
{
    height: 30px;
    padding: 8px;

    border-bottom: 1px solid var(--blue);
    border-radius: 0;
    outline: none;
    -webkit-box-shadow: 0 0 10px var(--blue);
            box-shadow: 0 0 10px var(--blue);

    caret-color: var(--blue);
}

.iti__country-list::-webkit-scrollbar
{
    width: 10px;
}
.iti__country-list::-webkit-scrollbar-track
{
    background-color: var(--white);
}
.iti__country-list::-webkit-scrollbar-thumb
{
    background-color: var(--blue);
}

.form_title
{
    margin-bottom: 40px;
}

@media (max-width: 768px)
{
    .form_title
    {
        font-size: 18px;

        margin-bottom: 25px;

        text-align: center;
    }
}
.tel_label
{
    font-size: 14px;
    font-weight: 500;

    display: inline-block;

    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;

    text-align: center;

    color: var(--white);
}
@media (max-width: 576px)
{
    .tel_label
    {
        display: none;
    }
}

.check_wrap
{
    font-size: 14px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 40px;

    cursor: pointer;

    color: var(--white);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.check_wrap span b
{
    text-decoration: underline;
}
@media (max-width: 768px)
{
    .check_wrap
    {
        margin-top: 15px;
        margin-bottom: 25px;
    }
}

.checkbox
{
    display: none;
}

.custom-checkbox
{
    position: relative;

    min-width: 24px;
    min-height: 24px;
    margin-right: 10px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 1px solid var(--white);
    border-radius: 6px;
    background-color: transparent;

    accent-color: var(--white);
}

.checkbox:checked + .custom-checkbox
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--white);
}

.checkbox:checked + .custom-checkbox:before
{
    position: absolute;
    top: 50%;
    left: 50%;

    height: 100%;

    content: url(../svg/check-arrow.svg);
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    translate: -50% -50%;
}

.form-btn
{
    width: 100%;
    height: 60px;
}

@media (max-width: 768px)
{
    .form-btn
    {
        padding: 10px;
    }
}
.error-message
{
    /* Только если меньше контейнера */
    font-size: 14px;

    position: absolute;
    bottom: -20px;
    left: 10px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .error-message
    {
        font-size: calc(12px + 2 * (100vw - 360px) / 960);
    }
}

.form-btn:disabled
{
    pointer-events: none;

    opacity: .4;
}

.icon-alert
{
    width: 20px;
    height: 20px;

    color: var(--red);
}

.hide
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    scale: 0;
}

.header
{
    position: relative;
    z-index: 1000;

    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    background: var(--white);
}
.header__container
{
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
}
.header__wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-column-gap: 80px;
       -moz-column-gap: 80px;
            column-gap: 80px;
    /* Только если меньше контейнера */
            column-gap: 80px;
}
@media (max-width: 1320px)
{
    .header__wrap
    {
        -webkit-column-gap: calc(-80px + 160 * (100vw - 360px) / 960);
           -moz-column-gap: calc(-80px + 160 * (100vw - 360px) / 960);
                column-gap: calc(-80px + 160 * (100vw - 360px) / 960);
    }
}
.header__logo
{
    position: relative;
    z-index: 3;
}
@media (max-width: 1024px)
{
    .header__logo
    {
        width: 90px;
        height: auto;
    }
}
.header .logo__image
{
    display: inline-block;

    width: 100%;
    height: 100%;
}
@media (max-width: 1024px)
{
    .header .logo__image img
    {
        width: 100%;
        height: 100%;
    }
}
.header__nav-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
}
@media (max-width: 1024px)
{
    .header__nav-wrap
    {
        position: fixed;
        z-index: 2;
        top: 0;
        right: -100%;

            flex-direction: column;

        width: 100%;
        max-width: 500px;
        height: 100dvh;
        padding: 90px 0 35px 0;
        padding-top: 90px;

        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        border-left: 1px solid var(--blue);
        background-color: var(--white);
        -webkit-box-shadow: 3px 0 15px var(--blue);
                box-shadow: 3px 0 15px var(--blue);

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 25px;
    }
}
@media (max-width: 576px)
{
    .header__nav-wrap
    {
        padding: 90px 0 35px 0;
    }
}
.header .activeMenu
{
    right: 0;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
}
.header__navbar
{
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
@media (max-width: 1024px)
{
    .header__navbar
    {
        overflow-y: auto;

        width: 100%;

        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }
}
.header__menu
{
    z-index: 3;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 25px;
       -moz-column-gap: 25px;
            column-gap: 25px;
}
.header__menu .link_wrap
{
    font-size: 13px;

    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;

    color: var(--black2F);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 7px;
       -moz-column-gap: 7px;
            column-gap: 7px;
}
.header__menu .link_wrap::before
{
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 2px;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;
    -webkit-transform: scale(0);
            transform: scale(0);

    border-radius: 5px;
    background: var(--blue);
}
@media (hover: hover)
{
    .header__menu .link_wrap:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--blue);
    }
    .header__menu .link_wrap:hover::before
    {
        -webkit-transition: .3s;
                transition: .3s;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@media (max-width: 1024px)
{
    .header__menu .link_wrap
    {
        font-size: 16px;

        width: 100%;
        padding: 25px 15px;

        border-radius: 5px;
        background-color: var(--white);

        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
.header__menu .icon-arrow_drop
{
    width: 6px;
    height: 4px;
    margin-top: 2px;

    -webkit-transition: .3s;
            transition: .3s;

    color: var(--blue);
}
@media (max-width: 1024px)
{
    .header__menu .icon-arrow_drop
    {
        width: 9px;
        height: 6px;
    }
}
@media (max-width: 1024px)
{
    .header__menu
    {
            flex-direction: column;

        padding: 15px 20px 30px 20px;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        row-gap: 5px;
    }
}
.header .lang_switcher
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 3px;

    border-radius: 50px;
    background-color: var(--whiteF8);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.header .lang_switcher .lang_btn
{
    font-size: 14px;

    width: 34px;
    height: 34px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--black2F);
    border-radius: 50%;
}
.header .lang_switcher .lang_btn.active_lang
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--white);
    background-color: var(--blue);
}
.header__buttons
{
    position: relative;
    z-index: 3;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* Только если меньше контейнера */

    gap: 40px;
}
@media (max-width: 1320px)
{
    .header__buttons
    {
        gap: calc(-40px + 80 * (100vw - 360px) / 960);
    }
}
.header__buttons .btn
{
    font-size: 14px;
    font-weight: 400;

    width: 100%;
    height: 40px;
}
.header__buttons .phone_link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 3px;
}
.header__buttons .phone_link .icon-phone_2
{
    width: 12px;
    height: 12px;

    color: var(--blue);
}
.header__buttons .phone_link span
{
    font-size: 14px;
    font-weight: 600;

    white-space: nowrap;

    color: var(--black2F);
}
.header .hide_switcher
{
    position: relative;
    z-index: 5;

    display: none;
}
@media (max-width: 1024px)
{
    .header .hidden_login
    {
        display: none;
    }
}
.header .login_mob
{
    font-size: 14px;
    font-weight: 400;

    position: relative;
    z-index: 5;

    display: none;

    height: 40px;

    background-color: var(--white);
}
@media (max-width: 1024px)
{
    .header .login_mob
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
@media (max-width: 1024px)
{
    .header__buttons
    {
        position: absolute;
        z-index: -1;
        bottom: 20%;
        left: 50%;

            flex-direction: column;

        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .header__buttons .phone_link
    {
        margin-bottom: 40px;
    }
    .header__buttons .phone_link .icon
    {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
    .header__buttons .phone_link span
    {
        font-size: 20px;
    }
    .header .lang_switcher
    {
        display: none;
    }
    .header .hide_switcher
    {
        display: block;
    }
}
@media (max-height: 740px)
{
    .header__buttons
    {
        bottom: 5%;
    }
}
.header .header_backdrop
{
    position: fixed;
    z-index: 2;
    top: 89px;
    left: 0;

    display: none;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, .2);
}
.header .header_backdrop.show
{
    display: block;
}
.header .dropdown.open .dropdown_content
{
    visibility: visible;

    -webkit-transition: opacity .4s ease-in .1s, max-height .4s ease-in 0s;
            transition: opacity .4s ease-in .1s, max-height .4s ease-in 0s;
    pointer-events: all;

    opacity: 1;
}
.header .dropdown.open .drop_btn .icon-arrow_drop
{
    -webkit-transition: .3s;
            transition: .3s;

    rotate: 180deg;
}
@media (max-width: 1024px)
{
    .header .dropdown
    {
        width: 100%;

        background-color: var(--white);
        -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
                box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
    }
    .header .dropdown.open .dropdown_content
    {
        height: 330px;
        padding: 20px 15px;

        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;
    }
    .header .dropdown.open .link_wrap
    {
        color: var(--blue);
    }
}
.header .dropdown_content
{
    position: absolute;
    z-index: 3;
    top: 89px;
    left: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;

    width: 100%;
    margin: 0 auto;
    padding: 40px 15px 40px 15px;
    padding-right: 60px;
    padding-left: 60px;

    pointer-events: none;

    opacity: 0;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 60px;
}
.header .dropdown_content .close_sub-menu
{
    display: none;
}
@media (max-width: 1024px)
{
    .header .dropdown_content
    {
        position: static;

        visibility: hidden;
        overflow: auto;
            flex-direction: column;

        width: calc(100% - 40px);
        max-width: 100%;
        height: 0;
        padding: 0;

        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        opacity: 0;
        border-radius: 2px;
        background-color: var(--whiteF8);
        -webkit-box-shadow: none;
                box-shadow: none;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        row-gap: 20px;
    }
}
.header .dropdown_row
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 60px;
}
@media (max-width: 1024px)
{
    .header .dropdown_row
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        row-gap: 20px;
    }
}
.header .column
{
    -webkit-transition: all .3s linear;
            transition: all .3s linear;
}
.header .column .column_title
{
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 25px;

    color: var(--blue);
}
@media (max-width: 1024px)
{
    .header .column .column_title
    {
        width: 100%;
        margin-bottom: 20px;
    }
}
.header .column .column_link-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    row-gap: 20px;
}
.header .column .features .column_link
{
    margin-bottom: 0;
}
.header .column .features .primary-link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-top: 5px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px;
}
.header .column .features .primary-link .icon-arrow_drop
{
    width: 6px;
    height: 4px;

    rotate: -90deg;
}
@media (max-width: 1024px)
{
    .header .column .features .primary-link
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.header .column .column_link
{
    font-size: 14px;
    font-weight: 500;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 10px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--black2F);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px;
}
@media (hover: hover)
{
    .header .column .column_link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--blue);
    }
}
@media (hover: none)
{
    .header .column .column_link:active
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--blue);
    }
}
.header .column .column_text
{
    font-size: 13px;
    font-weight: 400;
    line-height: 123%;

    width: 100%;
    max-width: 161px;

    color: var(--grey70);
}
@media (max-width: 1024px)
{
    .header .column .column_text
    {
        max-width: 100%;
    }
}
.header .column .primary-link:hover .icon-arrow_drop
{
    -webkit-transform: translatey(5px);
            transform: translatey(5px);

    color: var(--hover-blue);
}
.header .list_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    row-gap: 40px;
}
@media (max-width: 1024px)
{
    .header .list_wrap
    {
        row-gap: 25px;
    }
    .header .column
    {
        width: 100%;
    }
}
.header .integrations_content
{
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.header .integrations_content .column_link-wrap
{
    row-gap: 15px;
}
.header .integrations_content .column_link
{
    margin-bottom: 0;
}
.header .integrations_content .column_link span
{
    opacity: .8;
}
.header .integrations_content .column_text
{
    max-width: 197px;
}
.header .integrations_content .integrations_link
{
    font-size: 14px;
    font-weight: 500;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px;
}
.header .integrations_content .integrations_link .icon-arrow_drop
{
    rotate: -90deg;
}
.header .integrations_content .integrations_link
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--hover-blue);
}
.header .integrations_content .integrations_link:hover .icon-arrow_drop
{
    -webkit-transform: translatey(5px);
            transform: translatey(5px);

    color: var(--hover-blue);
}
@media (max-width: 1024px)
{
    .header .integrations_content .column_text
    {
        max-width: 100%;
    }
}
@media (max-width: 1024px)
{
    .header .integrations_content .column_list,
    .header .integrations_content .list_item
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        width: 100%;

        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .header .integrations_content .column_link-body
    {
        width: 130px;
    }
    .header .integrations_content .column_link-body span
    {
        white-space: nowrap;
    }
    .header .integrations_content .column_title
    {
        margin: 0;
    }
    .header .integrations_content .features_list
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.header .resources_content .column_link
{
    margin-bottom: 0;
}
.header .divider_col
{
    width: 100%;
    max-width: 227px;
    min-height: 100%;
    padding-left: 60px;

    border-left: 1px solid var(--grey);
}
@media (max-width: 1024px)
{
    .header .divider_col
    {
        max-width: 100%;
        min-height: -webkit-max-content;
        min-height:    -moz-max-content;
        min-height:         max-content;
        padding: 0;
        padding-left: 10px;

        border: none;
    }
    .header .divider_col .column_title
    {
        border: none;
    }
}

.header_eng .header__buttons
{
    gap: 15px;
}

.hero
{
    /* Только если меньше контейнера */
    padding-bottom: 175px;
}
@media (max-width: 1320px)
{
    .hero
    {
        padding-bottom: calc(0px + 175 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .hero
    {
        overflow: hidden;
    }
}
.hero .container
{
    position: relative;
    z-index: 2;
}
@media (max-width: 576px)
{
    .hero .container
    {
        padding: 0;
    }
}
.hero__wrap
{
    position: relative;

    max-width: 1400px;
    margin: 0 auto;
    padding-top: 60px;

    text-align: center;

    border-radius: 5px 30px;
    background: #f8f8f8;
    background: var(--light-grey, #f8f8f8);
}
@media (max-width: 576px)
{
    .hero__wrap
    {
        margin-bottom: 110px;
    }
}
.hero__wrap::after,
.hero__wrap::before
{
    position: absolute;
    z-index: 0;
    top: 0;
}
.hero__wrap::after
{
    right: 0;

    content: url(../img/hero/ellipse_right.png);
}
@media (max-width: 992px)
{
    .hero__wrap::after
    {
        display: none;
    }
}
.hero__wrap::before
{
    left: 0;

    content: url(../img/hero/ellipse_left.png);
}
.hero__content
{
    position: relative;
    z-index: 1;

    width: 100%;
    max-width: 840px;
    margin: 0 auto;

    text-align: center;
}
.hero__title
{
    margin-bottom: 40px;
}
.hero__title h1
{
    /* Только если меньше контейнера */
    font-size: 40px;
    font-weight: 700;
    line-height: 1.25;

    text-align: center;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .hero__title h1
    {
        font-size: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .hero__title h1
    {
        font-size: 20px;

        text-align: start;
    }
}
.hero__title h1 br
{
    display: none;
}
@media (max-width: 576px)
{
    .hero__title h1 br
    {
        display: block;
    }
}
@media (max-width: 576px)
{
    .hero__title h1 .big_text,
    .hero__title h1 strong
    {
        font-size: 120px;
        line-height: 120px;

        color: var(--blue);
    }
}
.hero__title h1 em
{
    font-style: normal;

    color: var(--blue);
}
@media (max-width: 576px)
{
    .hero__title
    {
        padding: 0 20px;
    }
}
.hero__text
{
    width: 100%;
    max-width: 685px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    margin-bottom: 60px;
}
@media (max-width: 1320px)
{
    .hero__text
    {
        margin-bottom: calc(50px + 10 * (100vw - 360px) / 960);
    }
}
.hero__text p
{
    /* Только если меньше контейнера */
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4444444444;

    text-align: center;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .hero__text p
    {
        font-size: calc(16px + 4 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .hero__text p
    {
        padding: 0 20px;

        text-align: start;
    }
}
.hero__action-btn
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 30px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 20px 30px;
}
.hero__action-btn .blue_btn
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-decoration: underline;

    color: var(--blue);
}
.hero__action-btn .blue_btn:hover
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--hover-blue);
}
@media (max-width: 576px)
{
    .hero__action-btn
    {
        padding: 0 20px;
    }
}
.hero__btn
{
    font-size: 18px;
    font-weight: 700;
    line-height: 144.144%;

    text-align: center;

    color: var(--white);
}
@media (max-width: 576px)
{
    .hero__btn
    {
        width: 100%;
    }
}
.hero__action-descr
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    margin-bottom: 50px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 15px;
}
@media (max-width: 1320px)
{
    .hero__action-descr
    {
        margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .hero__action-descr
    {
        padding: 0 20px;

        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
.hero__action-item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}
.hero__action-item .icon-check
{
    min-width: 7px;
    max-width: 7px;
    min-height: 5px;
    max-height: 5px;

    color: var(--blue);
}
.hero__action-item p
{
    font-size: 14px;
    font-weight: 300;

    color: var(--grey);
}
@media (max-width: 576px)
{
    .hero__action-item p
    {
        text-align: start;
    }
}
.hero__video
{
    position: relative;
    z-index: 1;
    top: 60px;

    overflow: hidden;

    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    border-radius: 5px 30px;
}
@media (max-width: 576px)
{
    .hero__video
    {
        top: 0;

        width: calc(100% - 40px);
        margin: -110px auto 0 auto;

        -webkit-transform: translate(0, 110px);
                transform: translate(0, 110px);
    }
}
.hero .video_poster__image
{
    display: inline-block;

    width: 100%;
    height: auto;
}
.hero .video_poster__image .video_poster__img
{
    width: 100%;
    height: auto;
}
.hero .hero__image
{
    width: 45px;
    height: auto;
}
.hero .hero__image .hero__img
{
    width: 100%;
    height: 100%;
}
@media (max-width: 992px)
{
    .hero__icons
    {
        display: none;
    }
}
.hero__icons-wrap
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    gap: 20px;
}
.hero__icons-mob
{
    display: none;

    min-width: 100%;
    margin-bottom: 40px;

    -webkit-animation: scroll 20s linear infinite;
            animation: scroll 20s linear infinite;

    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 15px;
}
@media (max-width: 576px)
{
    .hero__icons-mob
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
@-webkit-keyframes scroll
{
    from
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    to
    {
        -webkit-transform: translateX(calc(-100% - 20px));
                transform: translateX(calc(-100% - 20px));
    }
}
@keyframes scroll
{
    from
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    to
    {
        -webkit-transform: translateX(calc(-100% - 20px));
                transform: translateX(calc(-100% - 20px));
    }
}
.hero__icon
{
    position: absolute;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 80px;
    height: 80px;

    border-radius: 5px 25px;
    background: var(--white);
    -webkit-box-shadow: 0 5.398px 16.87px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 5.398px 16.87px 0 rgba(0, 0, 0, .1);

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.hero__icon_1
{
    top: 3%;
    left: 9%;

    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
}
@media (max-width: 576px)
{
    .hero__icon_1 .hero__image
    {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
}
.hero__icon_2
{
    top: 15%;
    left: 6%;

    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
}
@media (max-width: 576px)
{
    .hero__icon_2 .hero__image
    {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
}
.hero__icon_3
{
    top: 25%;
    left: 13%;

    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
}
@media (max-width: 576px)
{
    .hero__icon_3 .hero__image
    {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
}
.hero__icon_4
{
    top: 35.67%;
    left: 8.64%;

    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
}
@media (max-width: 576px)
{
    .hero__icon_4 .hero__image
    {
        -webkit-transform: rotate(15deg);
                transform: rotate(15deg);
    }
}
.hero__icon_5
{
    top: 2.64%;
    right: 9%;

    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
}
@media (max-width: 576px)
{
    .hero__icon_5 .hero__image
    {
        -webkit-transform: rotate(12deg);
                transform: rotate(12deg);
    }
}
.hero__icon_6
{
    top: 14.64%;
    right: 6%;

    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
}
@media (max-width: 576px)
{
    .hero__icon_6 .hero__image
    {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }
}
.hero__icon_7
{
    top: 25.64%;
    right: 15.64%;

    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
}
@media (max-width: 576px)
{
    .hero__icon_7 .hero__image
    {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
}
.hero__icon_8
{
    top: 35.64%;
    right: 8.64%;

    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
}
@media (max-width: 576px)
{
    .hero__icon_8 .hero__image
    {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
}
@media (max-width: 1200px)
{
    .hero__icon_1
    {
        top: 30%;
        left: 3%;
    }
    .hero__icon_2
    {
        top: 27%;
        left: 12%;
    }
    .hero__icon_3
    {
        top: 38%;
        left: 15%;
    }
    .hero__icon_4
    {
        top: 40%;
        left: 4%;
    }
    .hero__icon_5
    {
        top: 30%;
        right: 3%;
    }
    .hero__icon_6
    {
        top: 27%;
        right: 13%;
    }
    .hero__icon_7
    {
        top: 38%;
        right: 15%;
    }
    .hero__icon_8
    {
        top: 40%;
        right: 4%;
    }
}
@media (max-width: 576px)
{
    .hero__icon
    {
        position: static;

        min-width: 80px;
        min-height: 80px;

        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
}

.pluses
{
    /* Только если меньше контейнера */
    padding-top: 50px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .pluses
    {
        padding-top: calc(60px + -10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .pluses
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.pluses__wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 80px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 768px)
{
    .pluses__wrap
    {
        gap: 30px;
    }
}
.pluses__col
{
    max-width: 235px;
}
.pluses__col:nth-child(1) .icon
{
    color: var(--blue);
}
.pluses__col:nth-child(2) .icon
{
    color: var(--yellow);
}
.pluses__col:nth-child(3) .icon
{
    color: var(--green);
}
@media (max-width: 576px)
{
    .pluses__col
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        max-width: 100%;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
    }
}
.pluses__icon
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 110px;
    height: 110px;
    margin-bottom: 40px;

    border-radius: 5px 25px;
    background: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 576px)
{
    .pluses__icon
    {
        min-width: 80px;
        max-width: 80px;
        min-height: 80px;
        max-height: 80px;
        margin: 0;
    }
}
.pluses__icon .icon
{
    width: 43px;
    height: 43px;
}
.pluses__descr-title
{
    margin-bottom: 20px;
}
.pluses__descr-title h2
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 700;
}
@media (max-width: 1320px)
{
    .pluses__descr-title h2
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.pluses__descr-title_blue h2
{
    color: var(--blue);
}
.pluses__descr-title_yellow h2
{
    color: var(--yellow);
}
.pluses__descr-title_green h2
{
    color: var(--green);
}
.pluses__descr-text
{
    padding-right: 31px;
}
.pluses__descr-text p
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 400;
    line-height: 144.444%;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .pluses__descr-text p
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .pluses__descr-text
    {
        padding-right: 0;
    }
}

.product
{
    padding: 100px 0;

    background-color: var(--whiteF8);
}
@media (max-width: 768px)
{
    .product
    {
        padding: 50px 0;
    }
}
.product .container
{
    padding: 0;
}
.product_title
{
    line-height: 128%;

    width: 100%;
    max-width: 700px;
    padding: 0 20px;
}
@media (max-width: 576px)
{
    .product_title
    {
        text-align: start;
    }
}
.product .swiper-slide
{
    width: 365px;
}
@media (max-width: 768px)
{
    .product .swiper-slide
    {
        width: auto;
    }
}
.product .product_swiper
{
    padding: 0 20px 20px 20px;
}
.product .swiper-button-wrap
{
    display: none;
}
@media (max-width: 768px)
{
    .product .swiper-button-wrap
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
@media (max-width: 576px)
{
    .product .swiper-button-wrap
    {
        gap: 30px;
    }
}
.product_cards-wrapper
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 20px;
}
@media (max-width: 768px)
{
    .product_cards-wrapper
    {
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        gap: 0;
    }
}
.product_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
        flex-direction: column;

    width: 365px;
    height: 480px;

    border-radius: 5px 30px 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 20px rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 768px)
{
    .product_card
    {
        width: 100%;
    }
}
@media (max-width: 576px)
{
    .product_card
    {
        min-height: 455px;
    }
}
.product ._modify
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 35px 15px;

    text-align: center;

    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.product ._modify .product_card-text
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: calc(100% - 30px);
    height: -webkit-max-content;
    height:    -moz-max-content;
    height:         max-content;
    padding: 0;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 40px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.product ._modify .card_title
{
    font-size: 28px;
    line-height: 128.5714285714%;

    margin: 0;

    color: var(--white);
}
.product ._modify .card_descr
{
    font-size: 18px;
    line-height: 144.4444444444%;

    color: var(--white);
}
.product ._modify .product_link
{
    font-size: 18px;
    font-weight: 700;
    line-height: 144.4444444444%;

    width: 100%;
    height: 60px;

    color: var(--blue);
    background-color: var(--white);
}
@media (hover: hover)
{
    .product ._modify .product_link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--white);
        border-color: var(--white);
        background-color: var(--blue);
    }
}
@media (hover: none)
{
    .product ._modify .product_link:active
    {
        color: var(--white);
        border-color: var(--white);
        background-color: var(--blue);
    }
}
.product_card-img
{
    width: 100%;
    height: 240px;
}
.product_card-img img
{
    width: 100%;
    height: 100%;

    -o-object-fit: cover;
       object-fit: cover;
}
@media (max-width: 430px)
{
    .product_card-img
    {
        height: auto;
    }
}
.product_card-text
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    height: 50%;
    padding: 25px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
}
@media (max-width: 430px)
{
    .product_card-text
    {
        height: auto;

        gap: 20px;
    }
}
.product .card_title
{
    font-size: 22px;
    font-weight: 700;
    line-height: 127.2727272727%;

    margin-bottom: 20px;

    color: var(--blue);
}
@media (max-width: 430px)
{
    .product .card_title
    {
        margin-bottom: 0;
    }
}
.product .card_descr
{
    font-size: 14px;
    line-height: 142.8571428571%;

    display: -webkit-box;
    overflow: hidden;

    color: var(--grey);

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.product_link
{
    font-size: 13px;
    font-weight: 400;
    line-height: 123.0769230769%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    max-width: -webkit-min-content;
    max-width:    -moz-min-content;
    max-width:         min-content;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 25px;
    padding: 15px 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-align: center;

    color: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 50px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.product-en .product_title
{
    max-width: 550px;
}

.benefit
{
    padding: 100px 0;
}
@media (max-width: 576px)
{
    .benefit .benefit_tabs
    {
        display: none;
    }
}
.benefit .benefit_tabs .tabs__head-col
{
    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
}
.benefit .benefit_tabs .tabs__btn
{
    font-size: 18px;
}
.benefit .benefit_tabs .benefit_card-title
{
    min-height: 55px;
}
.benefit_ip-pbx .benefit_tabs .benefit_card-title
{
    min-height: auto;
}
.benefit_ip-pbx .benefit_accordion .benefit_card-title
{
    font-size: 18px;

    min-height: 40px;
}
.benefit .benefit_accordion
{
    display: none;
}
@media (max-width: 576px)
{
    .benefit .benefit_accordion
    {
        display: block;
    }
}
.benefit .benefit_accordion .accordion__item.active .accordion__toggler
{
    color: var(--white);
}
.benefit .benefit_accordion .accordion__item:not(:last-child)
{
    margin-bottom: 10px;
}
.benefit .benefit_accordion .accordion__toggler
{
    font-size: 16px;
    font-weight: 700;

    color: var(--blue);
}
.benefit .benefit_card-wrap
{
    display: grid;

    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    counter-reset: num;

    grid-template: 1fr/repeat(auto-fit, minmax(240px, 270px));
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
}
@media (max-width: 1170px)
{
    .benefit .benefit_card-wrap
    {
        grid-template: 1fr/repeat(auto-fit, minmax(330px, 48%));
    }
}
@media (max-width: 720px)
{
    .benefit .benefit_card-wrap
    {
        grid-template: 1fr/1fr;
    }
}
.benefit .benefit_card
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    padding: 25px 20px 30px 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 5px 30px 5px 30px;
    background-color: var(--whiteF8);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    row-gap: 25px;
}
.benefit .benefit_card.familiar
{
    row-gap: 0;
}
@media (max-width: 768px)
{
    .benefit .benefit_card
    {
        row-gap: 20px;
    }
}
.benefit .benefit_card:nth-child(4n+1) .benefit_card-number
{
    background-color: var(--blue);
}
.benefit .benefit_card:nth-child(4n+2) .benefit_card-number
{
    background-color: var(--green);
}
.benefit .benefit_card:nth-child(4n+3) .benefit_card-number
{
    background-color: var(--yellow);
}
.benefit .benefit_card:nth-child(4n+4) .benefit_card-number
{
    background-color: var(--black2F);
}
.benefit .benefit_card-number
{
    font-size: 18px;
    font-weight: 700;
    line-height: 155.5555555556%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;

    counter-increment: num;

    color: var(--white);
    border-radius: 50%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.benefit .benefit_card-number::before
{
    content: '0' counter(num);
}
.benefit .benefit_card-title
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;
    line-height: 155.5555555556%;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .benefit .benefit_card-title
    {
        font-size: calc(14px + 4 * (100vw - 360px) / 960);
    }
}
.benefit .benefit_card-text
{
    font-size: 14px;
    line-height: 142.8571428571%;

    color: var(--grey);
}
.benefit .benefit_tab_pane
{
    visibility: hidden;

    height: 0;

    opacity: 0;
}
.benefit .benefit_tab_pane .benefit_card
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
}
.benefit .active-pane
{
    display: block;
    visibility: visible;

    height: auto;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.benefit .active-pane .benefit_card
{
    -webkit-transition: all 1s ease 0s;
            transition: all 1s ease 0s;

    opacity: 1;
}
.benefit .active-pane .benefit_card:nth-child(1)
{
    -webkit-transition-delay: .1s;
            transition-delay: .1s;
}
.benefit .active-pane .benefit_card:nth-child(2)
{
    -webkit-transition-delay: .4s;
            transition-delay: .4s;
}
.benefit .active-pane .benefit_card:nth-child(3)
{
    -webkit-transition-delay: .8s;
            transition-delay: .8s;
}
.benefit .active-pane .benefit_card:nth-child(4)
{
    -webkit-transition-delay: 1.2s;
            transition-delay: 1.2s;
}

@media (max-width: 768px)
{
    .benefit
    {
        padding: 50px 0;
    }
}
.works
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .works
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .works
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.works__row
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 12px;
}
@media (max-width: 1200px)
{
    .works__row
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        gap: 50px;
    }
}
.works__circle
{
    position: relative;
    z-index: 10;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 273px;
    height: 273px;

    border-radius: 50%;
    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 992px)
{
    .works__circle
    {
            flex-direction: column;

        width: auto;
        height: auto;

        background-color: transparent;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.works__circle-title
{
    /* Только если меньше контейнера */
    font-size: 22px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .works__circle-title
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .works__circle-title
    {
        margin: 0 auto 20px auto;
    }
}
.works__left,
.works__right
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 992px)
{
    .works__left,
    .works__right
    {
        padding: 25px 15px;

        border-radius: 5px 30px;
        background-color: var(--blue);
    }
}
.works__left
{
    position: relative;
    right: -20px;
}
.works__left .icon-long_arrow
{
    left: -30px;
}
@media (max-width: 1200px)
{
    .works__left .icon-long_arrow
    {
        display: none;
    }
}
@media (max-width: 992px)
{
    .works__left .icon-long_arrow
    {
        z-index: 2;
        bottom: -40px;
        left: 50%;

        display: block;

        -webkit-transform: translateY(50%);
                transform: translateY(50%);

        rotate: 90deg;
    }
}
@media (max-width: 1200px)
{
    .works__left
    {
        right: 0;
    }
}
.works .tip_wrap
{
    position: absolute;
    top: -13%;
    right: -8%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}
.works .tip_wrap .icon-tip_arrow
{
    width: 24px;
    height: 15px;

    color: var(--grey);
}
.works .tip_wrap .tip_text
{
    font-size: 13px;

    display: inline-block;

    max-width: 150px;

    color: var(--grey);
}
@media (max-width: 1200px)
{
    .works .tip_wrap
    {
        top: -15%;
        right: -55%;
    }
}
@media (max-width: 992px)
{
    .works .tip_wrap
    {
        display: none;
    }
}
.works__icon-body-left
{
    position: absolute;
}
@media (max-width: 992px)
{
    .works__icon-body-left
    {
        position: static;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        row-gap: 25px;
    }
}
@media (max-width: 576px)
{
    .works__icon-body-left
    {
        row-gap: 10px;
    }
}
.works__icon-body-left .works__icon-wrap:nth-child(1)
{
    -webkit-transform: rotate(15deg) translateY(-155px) rotate(-15deg);
            transform: rotate(15deg) translateY(-155px) rotate(-15deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(1) .icon
{
    color: var(--blue);
}
@media (max-width: 992px)
{
    .works__icon-body-left .works__icon-wrap:nth-child(1) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--blue);
    }
}
.works__icon-body-left .works__icon-wrap:nth-child(2)
{
    -webkit-transform: rotate(48deg) translateY(-120px) rotate(-48deg);
            transform: rotate(48deg) translateY(-120px) rotate(-48deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(2) .icon
{
    color: var(--yellow);
}
@media (max-width: 992px)
{
    .works__icon-body-left .works__icon-wrap:nth-child(2) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--yellow);
    }
}
.works__icon-body-left .works__icon-wrap:nth-child(3)
{
    -webkit-transform: rotate(94deg) translateY(-89px) rotate(-94deg);
            transform: rotate(94deg) translateY(-89px) rotate(-94deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(3) .icon
{
    color: var(--green);
}
@media (max-width: 992px)
{
    .works__icon-body-left .works__icon-wrap:nth-child(3) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--green);
    }
}
.works__icon-body-left .works__icon-wrap:nth-child(4)
{
    -webkit-transform: rotate(153deg) translateY(-85px) rotate(-153deg);
            transform: rotate(153deg) translateY(-85px) rotate(-153deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(4) .icon
{
    color: var(--black2F);
}
@media (max-width: 992px)
{
    .works__icon-body-left .works__icon-wrap:nth-child(4) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--black2F);
    }
}
@media (max-width: 1200px)
{
    .works__icon-body-left .works__icon-wrap:nth-child(1)
    {
        -webkit-transform: rotate(15deg) translateY(-155px) rotate(-15deg);
                transform: rotate(15deg) translateY(-155px) rotate(-15deg);
    }
    .works__icon-body-left .works__icon-wrap:nth-child(2)
    {
        -webkit-transform: rotate(48deg) translateY(-120px) rotate(-48deg);
                transform: rotate(48deg) translateY(-120px) rotate(-48deg);
    }
    .works__icon-body-left .works__icon-wrap:nth-child(3)
    {
        -webkit-transform: rotate(94deg) translateY(-89px) rotate(-94deg);
                transform: rotate(94deg) translateY(-89px) rotate(-94deg);
    }
    .works__icon-body-left .works__icon-wrap:nth-child(4)
    {
        -webkit-transform: rotate(153deg) translateY(-85px) rotate(-153deg);
                transform: rotate(153deg) translateY(-85px) rotate(-153deg);
    }
}
@media (max-width: 992px)
{
    .works__icon-body-left .works__icon-wrap:nth-child(1),
    .works__icon-body-left .works__icon-wrap:nth-child(2),
    .works__icon-body-left .works__icon-wrap:nth-child(3),
    .works__icon-body-left .works__icon-wrap:nth-child(4)
    {
        -webkit-transform: none;
                transform: none;
    }
}
.works__right
{
    position: relative;
    left: -20px;

        flex-direction: row-reverse;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
}
.works__right .icon-long_arrow
{
    right: -30px;

    rotate: 180deg;
}
@media (max-width: 1200px)
{
    .works__right .icon-long_arrow
    {
        display: none;
    }
}
@media (max-width: 992px)
{
    .works__right .icon-long_arrow
    {
        z-index: 1;
        top: -40px;
        left: 50%;

        display: block;

        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);

        rotate: -90deg;
    }
}
@media (max-width: 1200px)
{
    .works__right
    {
        left: 0;
    }
}
.works__icon-body-right
{
    position: absolute;
}
@media (max-width: 992px)
{
    .works__icon-body-right
    {
        position: static;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        row-gap: 25px;
    }
}
@media (max-width: 576px)
{
    .works__icon-body-right
    {
        row-gap: 10px;
    }
}
.works__icon-body-right .works__icon-wrap:nth-child(1)
{
    -webkit-transform: rotate(349deg) translateY(-180px) rotate(-349deg);
            transform: rotate(349deg) translateY(-180px) rotate(-349deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(1) .icon
{
    color: var(--black2F);
}
@media (max-width: 992px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(1) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--black2F);
    }
}
.works__icon-body-right .works__icon-wrap:nth-child(2)
{
    -webkit-transform: rotate(-38deg) translateY(-190px) rotate(38deg);
            transform: rotate(-38deg) translateY(-190px) rotate(38deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(2) .icon
{
    color: var(--blue);
}
@media (max-width: 992px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(2) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--blue);
    }
}
.works__icon-body-right .works__icon-wrap:nth-child(3)
{
    -webkit-transform: rotate(-65deg) translateY(-190px) rotate(65deg);
            transform: rotate(-65deg) translateY(-190px) rotate(65deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(3) .icon
{
    color: var(--yellow);
}
@media (max-width: 992px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(3) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--yellow);
    }
}
.works__icon-body-right .works__icon-wrap:nth-child(4)
{
    -webkit-transform: rotate(-92deg) translateY(-172px) rotate(93deg);
            transform: rotate(-92deg) translateY(-172px) rotate(93deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(4) .icon
{
    color: var(--green);
}
@media (max-width: 992px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(4) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--green);
    }
}
.works__icon-body-right .works__icon-wrap:nth-child(5)
{
    -webkit-transform: rotate(-122deg) translateY(-140px) rotate(123deg);
            transform: rotate(-122deg) translateY(-140px) rotate(123deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(5) .icon
{
    color: var(--black2F);
}
@media (max-width: 992px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(5) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--black2F);
    }
}
.works__icon-body-right .works__icon-wrap:nth-child(6)
{
    -webkit-transform: rotate(-160deg) translateY(-100px) rotate(160deg);
            transform: rotate(-160deg) translateY(-100px) rotate(160deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(6) .icon
{
    color: var(--blue);
}
@media (max-width: 992px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(6) .icon_body
    {
        padding-right: 15px;

        border-right: 1px solid var(--blue);
    }
}
@media (max-width: 1200px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(1)
    {
        -webkit-transform: rotate(349deg) translateY(-180px) rotate(-349deg);
                transform: rotate(349deg) translateY(-180px) rotate(-349deg);
    }
    .works__icon-body-right .works__icon-wrap:nth-child(2)
    {
        -webkit-transform: rotate(-38deg) translateY(-190px) rotate(38deg);
                transform: rotate(-38deg) translateY(-190px) rotate(38deg);
    }
    .works__icon-body-right .works__icon-wrap:nth-child(3)
    {
        -webkit-transform: rotate(-65deg) translateY(-190px) rotate(65deg);
                transform: rotate(-65deg) translateY(-190px) rotate(65deg);
    }
    .works__icon-body-right .works__icon-wrap:nth-child(4)
    {
        -webkit-transform: rotate(-92deg) translateY(-172px) rotate(92deg);
                transform: rotate(-92deg) translateY(-172px) rotate(92deg);
    }
    .works__icon-body-right .works__icon-wrap:nth-child(5)
    {
        -webkit-transform: rotate(-122deg) translateY(-140px) rotate(122deg);
                transform: rotate(-122deg) translateY(-140px) rotate(122deg);
    }
    .works__icon-body-right .works__icon-wrap:nth-child(6)
    {
        -webkit-transform: rotate(-160deg) translateY(-100px) rotate(160deg);
                transform: rotate(-160deg) translateY(-100px) rotate(160deg);
    }
}
@media (max-width: 992px)
{
    .works__icon-body-right .works__icon-wrap:nth-child(1),
    .works__icon-body-right .works__icon-wrap:nth-child(2),
    .works__icon-body-right .works__icon-wrap:nth-child(3),
    .works__icon-body-right .works__icon-wrap:nth-child(4),
    .works__icon-body-right .works__icon-wrap:nth-child(5),
    .works__icon-body-right .works__icon-wrap:nth-child(6)
    {
        -webkit-transform: none;
                transform: none;
    }
}
.works .works__icon-wrap
{
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 75px;
    height: 75px;

    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transform-origin: center;
            transform-origin: center;

    border-radius: 50%;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.works .works__icon-wrap .tooltip
{
    position: absolute;
    z-index: 3;
    top: -80%;
    left: 50%;

    visibility: hidden;

    height: 0;
    padding: 24px;

    -webkit-transition: opacity .3s ease 0s, -webkit-transform .3s ease 0s;
            transition: opacity .3s ease 0s, -webkit-transform .3s ease 0s;
            transition: opacity .3s ease 0s, transform .3s ease 0s;
            transition: opacity .3s ease 0s, transform .3s ease 0s, -webkit-transform .3s ease 0s;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);

    opacity: 0;
    border-radius: 5px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.works .works__icon-wrap .tooltip .icon-tooltip_arrow
{
    position: absolute;
    z-index: 1;
    z-index: -1;
    bottom: -13px;
    left: 50%;

    width: 25px;
    height: 19px;

    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    color: var(--white);
}
@media (max-width: 992px)
{
    .works .works__icon-wrap .tooltip .icon-tooltip_arrow
    {
        display: none;
    }
}
@media (max-width: 992px)
{
    .works .works__icon-wrap .tooltip
    {
        position: static;

        visibility: visible;

        height: auto;
        padding: 0;

        -webkit-transform: none;
                transform: none;

        opacity: 1;
        border-radius: 0;
        background-color: transparent;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
}
.works .works__icon-wrap .tooltip_text
{
    font-size: 14px;

    white-space: nowrap;

    color: var(--black2F);
}
.works .works__icon-wrap .tooltip_text br
{
    display: none;
}
@media (max-width: 768px)
{
    .works .works__icon-wrap .tooltip_text
    {
        white-space: normal;
    }
    .works .works__icon-wrap .tooltip_text br
    {
        display: block;
    }
}
.works .works__icon-wrap .icon
{
    width: 32px;
    height: 32px;
}
@media (min-width: 992px)
{
    .works .works__icon-wrap:hover .tooltip
    {
        visibility: visible;

        height: auto;

        -webkit-transition: opacity .3s ease 0s, -webkit-transform .3s ease 0s;
                transition: opacity .3s ease 0s, -webkit-transform .3s ease 0s;
                transition: opacity .3s ease 0s, transform .3s ease 0s;
                transition: opacity .3s ease 0s, transform .3s ease 0s, -webkit-transform .3s ease 0s;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);

        opacity: 1;
    }
}
@media (max-width: 992px)
{
    .works .works__icon-wrap
    {
        position: static;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        width: auto;
        height: auto;
        padding: 20px;

        border-radius: 5px 25px;
        background-color: var(--white);
        -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
                box-shadow: 0 8px 25px rgba(0, 0, 0, .1);

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        gap: 15px;
    }
}
.works .icon-long_arrow
{
    position: relative;

    width: 130px;

    color: var(--blue);
}
@media (max-width: 1200px)
{
    .works .icon-long_arrow
    {
        position: absolute;
    }
}
.works__center
{
    min-width: 370px;
}
@media (max-width: 1200px)
{
    .works__center
    {
        min-width: 100%;

        text-align: center;
    }
}
.works .works-logo__image
{
    display: inline-block;

    width: 366px;
    height: auto;
    margin-bottom: 18px;
}
@media (max-width: 1200px)
{
    .works .works-logo__image
    {
        width: 100%;
        max-width: 366px;
        margin: 0 auto 25px auto;
    }
}
.works .works-logo__image .works-logo__img
{
    width: 100%;
    height: auto;
}
.works__center-text
{
    /* Только если меньше контейнера */
    font-size: 22px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .works__center-text
    {
        font-size: calc(16px + 6 * (100vw - 360px) / 960);
    }
}

.free
{
    padding-top: 60px;
    padding-bottom: 60px;

    background: var(--blue);
}
.free__wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 40px 50px;
}
@media (max-width: 992px)
{
    .free__wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.free .applications_icon__image
{
    display: inline-block;

    width: 100%;
    max-width: 450px;
    height: auto;
}
.free .applications_icon__image .applications_icon__img
{
    width: 100%;
    height: auto;
}
@media (max-width: 992px)
{
    .free .applications_icon__image
    {
        padding-left: 0;
    }
}
.free__content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    padding-right: 100px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 50px;
}
@media (max-width: 992px)
{
    .free__content
    {
        padding-right: 0;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 40px;
    }
}
@media (max-width: 576px)
{
    .free__content
    {
        -webkit-box-align: normal;
        -ms-flex-align: normal;
        align-items: normal;
    }
}
.free__title h4
{
    font-size: 28px;
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 400;
    line-height: 128.571429%;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .free__title h4
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .free__title
    {
        text-align: center;
    }
}
@media (max-width: 576px)
{
    .free__title
    {
        text-align: start;
    }
}
.free__text
{
    max-width: 346px;
}
.free__text p
{
    font-size: 14px;
    font-weight: 700;
    line-height: 142.857%;

    color: var(--white);
}
@media (max-width: 992px)
{
    .free__text
    {
        text-align: center;
    }
}
@media (max-width: 576px)
{
    .free__text
    {
        text-align: start;
    }
}
@media (max-width: 576px)
{
    .free .btn
    {
        width: 100%;
    }
}

@media (max-width: 992px)
{
    .demo-v2 .free__wrap
    {
            flex-direction: column-reverse;

        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
    }
}
.demo-v2 .free__content
{
    width: 100%;
    max-width: 425px;
    padding: 0;
}
.demo-v2 .free__title h4
{
    font-weight: 400;
}
.demo-v2 .free__text p
{
    width: 100%;
    max-width: 280px;
}
.demo-v2 .orders__image
{
    width: 100%;
    max-width: 450px;
    height: auto;
}
.demo-v2 .orders__image img
{
    width: 100%;
    height: 100%;
}
.demo-v2 .free__link .btn
{
    width: 100%;
    max-width: 315px;
}

.applications
{
    padding: 100px 0;

    text-align: center;
}
.applications .applications_text-wrap
{
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 50px;

    text-align: center;
}
@media (max-width: 768px)
{
    .applications .applications_text-wrap
    {
        margin-bottom: 30px;
    }
}
.applications .applications_title
{
    /* Только если меньше контейнера */
    margin-bottom: 30px;
}
@media (max-width: 1320px)
{
    .applications .applications_title
    {
        margin-bottom: calc(25px + 5 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .applications .applications_title
    {
        text-align: start;
    }
}
.applications .applications_text
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 400;
    line-height: 144.4444444444%;

    width: 100%;
    max-width: 532px;
    margin: 0 auto;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .applications .applications_text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .applications .applications_text
    {
        text-align: start;
    }
}
.applications .applications_icon-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 1110px;
    margin: 0 auto 50px auto;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 40px;
}
@media (max-width: 576px)
{
    .applications .applications_icon-wrap .display_none
    {
        display: none;
    }
}
.applications .applications_icon__image
{
    width: 75px;
    height: auto;
}
.applications .applications_icon__image .applications_icon__img
{
    width: 100%;
    height: 100%;

    -o-object-fit: contain;
       object-fit: contain;
}
@media (max-width: 768px)
{
    .applications .applications_icon
    {
        height: 45px;
    }
}
.applications .applications_link
{
    font-weight: 400;
}
@media (max-width: 576px)
{
    .applications .applications_link
    {
        width: 100%;
    }
}

@media (max-width: 768px)
{
    .applications
    {
        padding: 50px 0;
    }
}
@media (max-width: 460px)
{
    .voice-applications .applications_icon__image:last-child
    {
        display: none;
    }
}

.advantages
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .advantages
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .advantages
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.advantages__row
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 40px;
    /* Только если меньше контейнера */
    -webkit-column-gap: 60px;
       -moz-column-gap: 60px;
            column-gap: 60px;
}
@media (max-width: 1320px)
{
    .advantages__row
    {
        -webkit-column-gap: calc(0px + 60 * (100vw - 360px) / 960);
           -moz-column-gap: calc(0px + 60 * (100vw - 360px) / 960);
                column-gap: calc(0px + 60 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .advantages__row
    {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
.advantages__row .advantages__col:nth-child(4n+1) .icon,
.advantages__row .advantages__col:nth-child(4n+1) .advantages__title
{
    color: var(--blue);
}
.advantages__row .advantages__col:nth-child(4n+2) .icon,
.advantages__row .advantages__col:nth-child(4n+2) .advantages__title
{
    color: var(--yellow);
}
.advantages__row .advantages__col:nth-child(4n+3) .icon,
.advantages__row .advantages__col:nth-child(4n+3) .advantages__title
{
    color: var(--green);
}
.advantages__row .advantages__col:nth-child(4n+4) .icon,
.advantages__row .advantages__col:nth-child(4n+4) .advantages__title
{
    color: var(--black2F);
}
.advantages__col
{
    width: 100%;
    max-width: 340px;
}
@media (max-width: 992px)
{
    .advantages__col
    {
        text-align: center;
    }
}
@media (max-width: 576px)
{
    .advantages__col
    {
        max-width: 100%;
    }
}
.advantages__icon
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 110px;
    max-width: 110px;
    min-height: 110px;
    max-height: 110px;
    margin-bottom: 40px;

    border-radius: 5px 25px;
    background: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 992px)
{
    .advantages__icon
    {
        margin: 0 auto 20px auto;
    }
}
@media (max-width: 576px)
{
    .advantages__icon
    {
        min-width: 80px;
        max-width: 80px;
        min-height: 80px;
        max-height: 80px;
        margin: 0 0 30px 0;
    }
}
.advantages__icon .icon
{
    width: 48px;
    height: 48px;
}
@media (max-width: 576px)
{
    .advantages__icon .icon
    {
        width: 36px;
        height: 36px;
    }
}
.advantages__title
{
    margin-bottom: 20px;
}
.advantages__title h5
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;
    line-height: 144.444%;
}
@media (max-width: 1320px)
{
    .advantages__title h5
    {
        font-size: calc(20px + -2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .advantages__title
    {
        text-align: start;
    }
}
.advantages__text p
{
    font-size: 14px;
    font-weight: 400;
    line-height: 142.857%;

    color: var(--grey);
}
@media (max-width: 576px)
{
    .advantages__text p
    {
        text-align: start;
    }
}

.pluses
{
    /* Только если меньше контейнера */
    padding-top: 50px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .pluses
    {
        padding-top: calc(60px + -10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .pluses
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.pluses .advantages__row
{
    /* Только если меньше контейнера */
    -webkit-column-gap: 80px;
       -moz-column-gap: 80px;
            column-gap: 80px;
}
@media (max-width: 1320px)
{
    .pluses .advantages__row
    {
        -webkit-column-gap: calc(0px + 80 * (100vw - 360px) / 960);
           -moz-column-gap: calc(0px + 80 * (100vw - 360px) / 960);
                column-gap: calc(0px + 80 * (100vw - 360px) / 960);
    }
}
.pluses .advantages__col
{
    width: 100%;
    max-width: 230px;
}
@media (max-width: 576px)
{
    .pluses .advantages__col
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        max-width: 100%;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
    }
}
@media (max-width: 576px)
{
    .pluses .advantages__col .advantages__icon
    {
        margin: 0;
    }
}
.pluses .advantages__col .advantages__title h5
{
    /* Только если меньше контейнера */
    font-size: 22px;
}
@media (max-width: 1320px)
{
    .pluses .advantages__col .advantages__title h5
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.pluses .advantages__col .advantages__text p
{
    /* Только если меньше контейнера */
    font-size: 18px;
}
@media (max-width: 1320px)
{
    .pluses .advantages__col .advantages__text p
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}

.advantages_dial .advantages__row,
.autodialer-page .advantages__row
{
    /* Только если меньше контейнера */
    row-gap: 80px;
}
@media (max-width: 1320px)
{
    .advantages_dial .advantages__row,
    .autodialer-page .advantages__row
    {
        row-gap: calc(40px + 40 * (100vw - 360px) / 960);
    }
}
.advantages_dial .advantages__title-main,
.autodialer-page .advantages__title-main
{
    max-width: 770px;
}
@media (max-width: 576px)
{
    .advantages_dial .advantages__title-main,
    .autodialer-page .advantages__title-main
    {
        max-width: 100%;

        text-align: start;
    }
    .advantages_dial .advantages__title-main br,
    .autodialer-page .advantages__title-main br
    {
        display: none;
    }
}
.advantages_dial .advantages__text-wrap,
.autodialer-page .advantages__text-wrap
{
    width: 100%;
    max-width: 250px;
}
@media (max-width: 576px)
{
    .advantages_dial .advantages__text-wrap,
    .autodialer-page .advantages__text-wrap
    {
        max-width: 100%;
    }
    .advantages_dial .advantages__text-wrap br,
    .autodialer-page .advantages__text-wrap br
    {
        display: none;
    }
}
.advantages_dial .advantages__title h5,
.autodialer-page .advantages__title h5
{
    /* Только если меньше контейнера */
    font-size: 18px;
}
@media (max-width: 1320px)
{
    .advantages_dial .advantages__title h5,
    .autodialer-page .advantages__title h5
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}

.advantages_dial_ua .advantages__text-wrap
{
    max-width: 280px;
}

.advantages_voice .advantages__title-main
{
    max-width: 555px;
}
.advantages_voice .advantages__text-wrap
{
    max-width: 280px;
}

.advantages_ua .service__bottom
{
    padding: 47px 39px;
}
@media (max-width: 576px)
{
    .advantages_ua .service__bottom
    {
        padding: 48px 15.5px;
    }
}

.advantages_contact_center .advantages__text-wrap
{
    width: 100%;
    max-width: 255px;
}
@media (max-width: 992px)
{
    .advantages_contact_center .advantages__text-wrap
    {
        margin: 0 auto;
    }
}
@media (max-width: 576px)
{
    .advantages_contact_center .advantages__text-wrap
    {
        max-width: 100%;
        margin: 0 auto 0 0;
    }
}
.advantages_contact_center .advantages__title h5
{
    /* Только если меньше контейнера */
    font-size: 18px;
}
@media (max-width: 1320px)
{
    .advantages_contact_center .advantages__title h5
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .advantages_contact_center .advantages__title
    {
        max-width: 300px;
    }
}
.advantages_contact_center .advantages__col:nth-child(1) .advantages__icon .icon
{
    width: 70px;
    height: auto;
}
@media (max-width: 576px)
{
    .advantages_contact_center .advantages__col:nth-child(1) .advantages__icon .icon
    {
        width: 45px;
    }
}

.robot .advantages__title-main
{
    text-align: center;
}

.advantages_ip_pbx .advantages__text
{
    max-width: 310px;
}

.service
{
    /* Только если меньше контейнера */
    padding-top: 50px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .service
    {
        padding-top: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .service
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.service__title h3
{
    font-size: 22px;
    font-weight: 700;
    line-height: 127.273%;

    color: var(--white);
}
.service__text p
{
    font-size: 14px;
    font-weight: 400;
    line-height: 142.857%;

    color: var(--white);
}
.service__btn
{
    margin-top: 20px;
}
.service__btn .btn
{
    color: var(--hover-blue);
}
.service__row
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    gap: 20px;
}
@media (max-width: 992px)
{
    .service__row
    {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
@media (max-width: 576px)
{
    .service__row
    {
        gap: 10px;
    }
}
.service__left
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 33.33%;
    padding-right: 60px;
    padding-left: 60px;

    border-radius: 5px 30px;
    background: var(--white);
    background: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 992px)
{
    .service__left
    {
        width: 38%;
        min-height: 330px;
        padding-right: 30px;
        padding-left: 30px;
    }
}
@media (max-width: 576px)
{
    .service__left
    {
        width: 100%;
        min-height: auto;
        padding: 30px 25px;

        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.service__num
{
    font-size: 70px;
    font-weight: 700;
    line-height: 107.143%;

    text-align: center;
    white-space: nowrap;

    color: var(--blue);
}
.service__descr
{
    font-size: 14px;
    font-weight: 600;
    line-height: 142.857%;

    width: 100%;
    max-width: 160px;

    color: var(--black2F);
}
@media (max-width: 992px)
{
    .service__descr
    {
        max-width: 100%;

        text-align: center;
    }
}
@media (max-width: 576px)
{
    .service__descr
    {
        text-align: start;
    }
}
.service__middle
{
    width: 33.33%;
}
@media (max-width: 992px)
{
    .service__middle
    {
        width: 58%;
    }
}
@media (max-width: 576px)
{
    .service__middle
    {
        width: 100%;
    }
}
.service__right
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 33.33%;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 20px;
}
@media (max-width: 992px)
{
    .service__right
    {
        width: 100%;
    }
}
@media (max-width: 576px)
{
    .service__right
    {
        gap: 10px;
    }
}
.service__bottom
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    padding: 34px 50px;

    text-align: center;

    border-radius: 5px 30px;
    background: var(--deep-blue, #004565);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 20px;
}
@media (max-width: 576px)
{
    .service__bottom
    {
        padding: 34px;
    }
}

.middle
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    row-gap: 20px;
}
@media (max-width: 992px)
{
    .middle
    {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
@media (max-width: 576px)
{
    .middle
    {
        gap: 10px;
    }
}
.middle__col
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 42px 40px;

    border-radius: 5px 30px;
    background: var(--blue);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}
@media (max-width: 992px)
{
    .middle__col
    {
        padding: 25px;
    }
}
@media (max-width: 576px)
{
    .middle__col
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.middle__col-descr
{
    font-size: 14px;
    font-weight: 600;
    line-height: 142.857%;

    color: var(--white);
}
.middle__col-num
{
    font-size: 50px;
    font-weight: 700;
    line-height: 110%;

    color: var(--white);
}

.right
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    gap: 20px;
}
@media (max-width: 992px)
{
    .right
    {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
@media (max-width: 576px)
{
    .right
    {
        gap: 10px;
    }
}
.right__col
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    padding: 22px 30px;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
@media (max-width: 1200px)
{
    .right__col
    {
        padding: 30px 20px;
    }
}
@media (max-width: 992px)
{
    .right__col
    {
        width: 48%;
    }
}
.right .icon-infinity
{
    width: 47px;
    height: 35px;

    color: var(--blue);
}
@media (max-width: 576px)
{
    .right .icon-infinity
    {
        margin-bottom: 15px;
    }
}
.right__col-descr
{
    font-size: 14px;
    font-weight: 600;
    line-height: 142.857%;

    display: inline-block;

    color: var(--black2F);
}
@media (max-width: 375px)
{
    .right__col
    {
        width: 100%;
    }
}
.right__col-num
{
    font-size: 50px;
    font-weight: 700;
    line-height: 110%;

    color: var(--blue);
}

.reviews_second
{
    padding: 90px 0;
}
.reviews_second .container
{
    padding: 0;
}
.reviews_second .reviews_title
{
    margin: 0 0 auto 0;
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_title
    {
        margin-bottom: 30px;
    }
}
.reviews_second .reviews_second_content
{
    display: grid;

    grid-template: 1fr/1fr 1fr;
    gap: 50px;
}
@media (max-width: 1200px)
{
    .reviews_second .reviews_second_content
    {
        gap: 25px;
    }
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_second_content
    {
        grid-template: 1fr/1fr;
        gap: 40px;
    }
}
.reviews_second .reviews_second-content_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    max-width: 530px;
    padding: 0 15px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_second-content_wrap
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: column;

        max-width: 100%;

        text-align: center;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}
.reviews_second .reviews_title
{
    text-align: start;
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_title
    {
        text-align: center;
    }
}
.reviews_second .reviews_second-text
{
    font-size: 18px;
    line-height: 144.4444444444%;

    width: 100%;
    max-width: 460px;
    margin-bottom: 25px;

    color: var(--grey);
}
.reviews_second .reviews_items-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 15px;
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_items-wrap
    {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
@media (max-width: 576px)
{
    .reviews_second .reviews_items-wrap
    {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.reviews_second .reviews_item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    height: 160px;
    padding: 25px 18px;

    border-radius: 5px 30px;
    background-color: var(--white);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 20px;
    -webkit-filter: drop-shadow(0 8px 10px rgba(0, 0, 0, .1));
            filter: drop-shadow(0 8px 10px rgba(0, 0, 0, .1));
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_item
    {
        padding: 25px 15px;
    }
}
.reviews_second .current-score
{
    font-size: 20px;
    font-weight: 700;

    color: var(--black2F);
}
.reviews_second .total-score
{
    color: var(--grey);
}
.reviews_second .reviews_second_swiper
{
    width: 100%;
    max-width: 600px;
    max-height: -webkit-max-content;
    max-height:    -moz-max-content;
    max-height:         max-content;
    padding: 0 20px;
}
.reviews_second .reviews_second_swiper .swiper-wrapper
{
    height: 440px;
    margin-bottom: 20px;
}
.reviews_second .reviews_second_swiper .swiper-slide
{
    height: auto;
    min-height: 100%;
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_second_swiper
    {
        max-width: 100%;
        padding: 15px;
    }
}
.reviews_second .reviews_slide
{
    overflow: hidden;

    height: 100%;
    max-height: 440px;
    padding: 40px;

    border-radius: 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
}
@media (max-width: 1024px)
{
    .reviews_second .reviews_slide
    {
        padding: 25px;
    }
}
.reviews_second .reviews_slide-img
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;

    width: 100%;
    height: 160px;
    margin-bottom: 25px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.reviews_second .reviews_slide-img .__image
{
    width: 100%;
    height: 100%;
    padding: 10px;
}
.reviews_second .reviews_slide-img .__img
{
    width: 100%;
    height: 100%;

    -o-object-fit: contain;
       object-fit: contain;
}
.reviews_second .reviews_slide-text
{
    overflow: auto;

    height: 200px;
}
.reviews_second .reviews_slide-text::-webkit-scrollbar
{
    display: none;
}

.slide_text
{
    font-size: 14px;
    line-height: 142.8571428571%;

    color: var(--grey);
}

.reviews_second-nav
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 1024px)
{
    .reviews_second-nav
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media (max-width: 768px)
{
    .reviews_second
    {
        padding: 40px 0;
    }
}
.reviews
{
    /* Только если меньше контейнера */
    padding-top: 70px;
    /* Только если меньше контейнера */
    padding-bottom: 70px;
}
@media (max-width: 1320px)
{
    .reviews
    {
        padding-top: calc(60px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .reviews
    {
        padding-bottom: calc(60px + 10 * (100vw - 360px) / 960);
    }
}
.reviews .container
{
    padding: 0;
}
@media (max-width: 576px)
{
    .reviews .container
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}
.reviews .reviews_head
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 50px;
    padding: 0 15px;

    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
@media (max-width: 768px)
{
    .reviews .reviews_head
    {
        margin-bottom: 25px;
    }
}
@media (max-width: 576px)
{
    .reviews .reviews_head
    {
        display: contents;
    }
}
.reviews .reviews_title
{
    width: 100%;
    max-width: 606px;
    margin: 0;

    text-align: start;
}
@media (max-width: 576px)
{
    .reviews .reviews_title
    {
        padding: 0 20px;

        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}
.reviews .arrow_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}
@media (max-width: 576px)
{
    .reviews .arrow_wrap
    {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        gap: 30px;
    }
}
.reviews .reviews_swiper
{
    padding: 15px 15px 0 15px;
}
@media (max-width: 576px)
{
    .reviews .reviews_swiper
    {
        width: 100%;

        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
}
.reviews .swiper-wrapper
{
    margin-bottom: 25px;
}
.reviews .swiper-slide
{
    height: auto;
    min-height: 100%;
}
.reviews .reviews_slide
{
    overflow: hidden;

    height: 100%;

    border-radius: 5px 30px 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
            box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
}
@media (max-width: 992px)
{
    .reviews .reviews_slide
    {
        -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
                box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
    }
}
.reviews .reviews_slide-img
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;

    width: 100%;
    height: 220px;
    padding: 10px;

    border-radius: 5px 30px 5px 30px;
    background-color: var(--whiteF8);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.reviews .reviews_slide-img .__image
{
    max-width: 100%;
}
.reviews .reviews_slide-img .__image img
{
    -o-object-fit: contain;
       object-fit: contain;
}
.reviews .reviews_slide-text
{
    padding: 25px;
}
.reviews .reviews_slide-text.expanded .toggle-button
{
    display: inline-block;
}
@media (max-width: 768px)
{
    .reviews .reviews_slide-text
    {
        padding: 25px 15px;
    }
}
.reviews .slide_text
{
    font-size: 14px;
    font-weight: 400;
    line-height: 142.8571428571%;

    display: -webkit-box;
    overflow: hidden;

    max-height: 240px;

    -webkit-transition: max-height .3s ease;
            transition: max-height .3s ease;

    color: var(--grey);

    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
}
.reviews .slide_text.expanded
{
    max-height: unset;

    -webkit-line-clamp: unset;
}
.reviews .reviews_swiper-btn
{
    font-size: 14px;
    font-weight: 700;

    margin-top: 20px;

    text-decoration: underline;

    color: var(--blue);
}
.reviews .swiper-pagination
{
    margin: 0 auto;
}
@media (max-width: 576px)
{
    .reviews .swiper-pagination
    {
        display: none;
    }
}

.proposal
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    text-align: center;

    background-color: var(--blue);
}
@media (max-width: 1320px)
{
    .proposal
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .proposal
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .proposal
    {
        padding: 60px 0;
    }
}
.proposal .proposal_content
{
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}
.proposal .proposal_title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;
    line-height: 128.5714285714%;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .proposal .proposal_title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .proposal .proposal_title
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .proposal .proposal_title
    {
        max-width: 305px;

        text-align: start;
    }
}
.proposal .proposal_btn
{
    width: 316px;
    padding: 23.5px 0;
}
.proposal .proposal_popup_content
{
    display: grid;

    width: 100%;
    max-width: 945px;
    margin: 0 auto;

    grid-template-columns: 49% 49%;
    grid-template-rows: 1fr;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 40px;
}
@media (max-width: 1024px)
{
    .proposal .proposal_popup_content
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: column-reverse;

        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
    }
}
.proposal .popup_form-wrap
{
    width: 100%;
    max-width: 465px;

    text-align: start;
}
@media (max-width: 1024px)
{
    .proposal .popup_form-wrap
    {
        max-width: 650px;
        margin: 0 auto;
    }
}
.proposal .popup_form-wrap form
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.proposal .input-wrap
{
    position: relative;

    width: 100%;
    margin-bottom: 25px;
}
.proposal .form_input
{
    margin: 0;
}
.proposal .error-message
{
    color: var(--white);
}
.proposal .manager
{
    overflow: hidden;

    width: 100%;
    max-width: 440px;
    height: 100%;

    border: 1px solid var(--white);
    border-radius: 5px 30px;
}
.proposal .info_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    min-height: 85px;
    padding: 25px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
@media (max-width: 576px)
{
    .proposal .info_wrap
    {
        text-align: start;
    }
}
.proposal .manager__image
{
    display: inline-block;

    width: 100%;
    height: 100%;
    max-height: 425px;

    border-radius: 5px 30px;
}
.proposal .manager__img
{
    width: 100%;
    height: 100%;
}
.proposal .name
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 700;
    line-height: 127.2727272727%;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .proposal .name
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.proposal .role
{
    font-size: 14px;

    color: var(--white);
}
.proposal_popup-container
{
    position: fixed;
    z-index: 4;

    visibility: hidden;

    width: 100%;
    height: 100%;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    background-color: rgba(0, 0, 0, .4);

    inset: 0;
}
.proposal .showProposalContainer
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.proposal .iti__selected-dial-code
{
    color: var(--white);
}
.proposal .close_popUp
{
    display: none;
}

.proposal_modal
{
    padding: 60px 0;
}
.proposal_modal .modal_proposal
{
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;

    width: calc(100% - 40px);
    max-width: 1200px;
    padding: 100px 40px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    border: none;
    border-radius: 8px;
    outline: none;
    background-color: var(--blue);

    translate: -50% -50%;
    scale: 0;
}
@media (max-width: 768px)
{
    .proposal_modal .modal_proposal
    {
        padding: 50px 15px 25px 15px;
    }
}
@media (max-width: 1024px)
{
    .proposal_modal .modal_proposal
    {
        overflow-y: auto;

        height: calc(100% - 60px);
    }
    .proposal_modal .modal_proposal::-webkit-scrollbar
    {
        display: none;
    }
}
.proposal_modal .openProposalPopUp
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;

    scale: 1;
}
.proposal_modal .close_popUp
{
    position: absolute;
    top: 15px;
    right: 15px;

    display: block;

    width: 24px;
    height: 24px;
}
.proposal_modal .close_popUp::before,
.proposal_modal .close_popUp::after
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 1px;

    content: '';

    background-color: var(--white);

    translate: -50% -50%;
}
.proposal_modal .close_popUp::before
{
    rotate: 45deg;
}
.proposal_modal .close_popUp::after
{
    rotate: -45deg;
}
@media (max-width: 576px)
{
    .proposal_modal .proposal_title
    {
        margin: 0 auto 40px auto;

        text-align: center;
    }
}
@media (max-width: 576px)
{
    .proposal_modal .form_title,
    .proposal_modal .tel_label
    {
        text-align: start;
    }
}

.results
{
    padding: 100px 0;

    text-align: center;

    background-color: var(--whiteF8);
}
@media (max-width: 768px)
{
    .results .container
    {
        padding: 0;
    }
}
.results .results_title
{
    margin-bottom: 30px;
    padding: 0 20px;
}
.results .results_descr
{
    font-size: 18px;
    line-height: 144.4444444444%;

    margin-bottom: 50px;
    padding: 0 20px;

    color: var(--grey);
}
@media (max-width: 768px)
{
    .results .results_descr
    {
        margin-bottom: 30px;
    }
}
.results .result_swiper
{
    padding: 0 10px;
}
.results .swiper-wrapper
{
    margin-bottom: 50px;
}
@media (max-width: 768px)
{
    .results .swiper-wrapper
    {
        margin-bottom: 30px;
    }
}
.results .result_slide
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
        flex-direction: column;

    min-height: 475px;

    border-radius: 5px 30px 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 10px rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.results .slide__image
{
    display: inline-block;
    overflow: hidden;

    width: 100%;
    height: 240px;

    border-radius: 5px 30px 5px 30px;
}
.results .slide__image .slide__img
{
    width: 100%;
    height: 100%;

    -o-object-fit: cover;
       object-fit: cover;
}
@media (max-width: 768px)
{
    .results .slide_img
    {
        min-height: 200px;
    }
}
.results .slide_title-wrap
{
    width: 100%;
    margin-bottom: 25px;
    padding: 25px 25px 0 25px;

    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
}
.results .slide_title
{
    font-size: 18px;
    font-weight: 700;
    line-height: 145%;

    display: -webkit-box;
    overflow: hidden;

    text-align: start;

    color: var(--blue);

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
@media (max-width: 768px)
{
    .results .slide_title
    {
        font-size: 16px;
    }
}
.results .btn
{
    font-size: 14px;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    height: 40px;
    margin-bottom: 25px;
    padding: 15px 20px;
}
.results .swiper_nav
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 40px;
}

@media (max-width: 768px)
{
    .results
    {
        padding: 50px 0;
    }
}
.faq
{
    padding: 100px 0;
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    text-align: center;

    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .faq
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .faq
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.faq .faq_title
{
    width: 100%;
    max-width: 726px;
    margin: 0 auto 50px auto;
}
@media (max-width: 576px)
{
    .faq .faq_title
    {
        text-align: start;
    }
    .faq .faq_title br
    {
        display: none;
    }
}
@media (max-width: 768px)
{
    .faq .faq_title
    {
        margin-bottom: 30px;
    }
}
.faq .faq_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    margin-bottom: 50px;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 768px)
{
    .faq .faq_wrap
    {
        margin-bottom: 30px;
    }
}
@media (max-width: 576px)
{
    .faq .faq_wrap
    {
        gap: 10px;
    }
}
@media (max-width: 576px)
{
    .faq .accordion__toggler
    {
        padding: 20px 15px;
    }
}
.faq .accordion__content
{
    padding: 5px 25px 0 25px;
}
@media (max-width: 576px)
{
    .faq .accordion__content
    {
        padding: 20px 15px 0 15px;
    }
}
@media (max-width: 576px)
{
    .faq .item_title br
    {
        display: none;
    }
}
.faq .item_descr
{
    font-size: 14px;
    line-height: 142.8571428571%;

    color: var(--white);
}
.faq .question_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 8px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 576px)
{
    .faq .question_wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.faq .question_text
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 500;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .faq .question_text
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.faq .question_link
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-decoration: underline;

    color: var(--blue);
}
.faq .question_link:hover
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--hover-blue);
}

.faq_home .item_title
{
    max-width: 430px;
}

.faq_contact_center_ua .accordion__item:last-child b
{
    text-decoration: underline;
}

.additional-product
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .additional-product
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .additional-product
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.additional-product_title
{
    width: 100%;
    max-width: 600px;
}
.additional-product_card-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.additional-product .product_card
{
    height: auto;
    min-height: 395px;

    -webkit-box-flex: 0;
    -ms-flex: 0 1 365px;
        flex: 0 1 365px;
}
@media (max-width: 576px)
{
    .additional-product .product_card
    {
        min-height: auto;
    }
}
.additional-product .product_card-img
{
    padding: 20px 36px;

    border-radius: 5px 30px;
    background-color: var(--blueCE);
}
.additional-product .product_card-img .additional__image
{
    display: inline-block;

    width: 100%;
    height: 100%;

    border-radius: 5px;
}
.additional-product .product_card-img .additional__image img
{
    width: 100%;
    height: 100%;
}
.additional-product .product_card-text
{
    gap: 20px;
}
.additional-product .product_card-title
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 700;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .additional-product .product_card-title
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.additional-product .product_card-descr
{
    font-size: 14px;
    line-height: 1.4285714286;

    color: var(--grey);
}

.additional-benefit
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .additional-benefit
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .additional-benefit
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.additional-benefit_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 50px;
}
.additional-benefit .accordion
{
    width: 100%;
    max-width: 545px;
}
.additional-benefit .accordion__item
{
    width: 100%;
}
.additional-benefit .accordion__item:not(:last-child)
{
    margin-bottom: 20px;
}
.additional-benefit .accordion__toggler
{
    min-height: auto;
}
.additional-benefit .additional__image
{
    display: inline-block;

    width: 100%;
    max-width: 545px;
}
@media (max-width: 992px)
{
    .additional-benefit .additional__image
    {
        display: none;
    }
}
.additional-benefit .additional__image img
{
    width: 100%;
    height: 100%;
}
.additional-benefit .additional_mob__image
{
    position: relative;

    display: none;

    width: auto;
    max-width: 330px;
    min-height: 180px;
    max-height: 250px;
    margin: 0 auto;
    margin-top: 25px;

    border-radius: 5px 25px;
}
@media (max-width: 992px)
{
    .additional-benefit .additional_mob__image
    {
        display: block;
    }
}
@media (max-width: 576px)
{
    .additional-benefit .additional_mob__image
    {
        max-height: 180px;
    }
}
.additional-benefit .additional_mob__image img
{
    position: absolute;

    width: 100%;
    height: 100%;
}

.additional-get
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .additional-get
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .additional-get
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.additional-get_title
{
    /* Только если меньше контейнера */
    margin-bottom: 30px;
}
@media (max-width: 1320px)
{
    .additional-get_title
    {
        margin-bottom: calc(25px + 5 * (100vw - 360px) / 960);
    }
}
.additional-get_descr
{
    font-size: 18px;
    /* Только если меньше контейнера */

    margin-bottom: 100px;

    text-align: center;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .additional-get_descr
    {
        margin-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.additional-get_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 50px;
}
@media (max-width: 992px)
{
    .additional-get_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        gap: 30px;
    }
}
.additional-get_text-wrap
{
    width: 100%;
    max-width: 500px;
}
.additional-get .text-head
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 25px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 25px;
}
@media (max-width: 576px)
{
    .additional-get .text-head
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.additional-get .text_icon
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 110px;
    max-width: 110px;
    min-height: 110px;
    max-height: 110px;

    border-radius: 5px 25px;
    background: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 768px)
{
    .additional-get .text_icon
    {
        min-width: 80px;
        max-width: 80px;
        min-height: 80px;
        max-height: 80px;
    }
}
.additional-get .text_icon .icon
{
    width: 48px;
    height: 48px;

    color: var(--blue);
}
@media (max-width: 768px)
{
    .additional-get .text_icon .icon
    {
        width: 36px;
        height: 36px;
    }
}
.additional-get .text_title
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 700;
}
@media (max-width: 1320px)
{
    .additional-get .text_title
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.additional-get .descr
{
    font-size: 14px;
    line-height: 1.4285714286;

    color: var(--grey);
}
.additional-get .additional__image
{
    display: inline-block;

    width: 100%;
    max-width: 560px;
}
.additional-get .additional__image img
{
    width: 100%;
    height: 100%;
}

.marquee-section
{
    /* Только если меньше контейнера */
    padding-top: 80px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;
}
@media (max-width: 1320px)
{
    .marquee-section
    {
        padding-top: calc(60px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .marquee-section
    {
        padding-bottom: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.marquee-section_title
{
    font-size: 20px;
    font-weight: 700;
    /* Только если меньше контейнера */

    margin-bottom: 40px;

    text-align: center;
    text-transform: uppercase;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .marquee-section_title
    {
        margin-bottom: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
.marquee-section .marquee
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    /* Только если меньше контейнера */

    gap: 60px;
}
@media (max-width: 1320px)
{
    .marquee-section .marquee
    {
        gap: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.marquee-section .marquee.right-direction
{
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.marquee-section .marquee-row
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* Только если меньше контейнера */

    height: 100px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* Только если меньше контейнера */
    gap: 60px;
}
@media (max-width: 1320px)
{
    .marquee-section .marquee-row
    {
        gap: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .marquee-section .marquee-row
    {
        height: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.marquee-section .marquee__image
{
    display: inline-block;
}
.marquee-section .marquee__image img
{
    -o-object-fit: contain;
       object-fit: contain;
}

.blog
{
    width: calc(100% - 20px);
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .blog
    {
        padding-bottom: calc(10px + 50 * (100vw - 360px) / 960);
    }
}
.blog .container
{
    padding: 0 10px;
}
.blog_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
.blog_text-wrap
{
    width: 100%;
    max-width: 460px;
}
.blog_title
{
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;

    margin-bottom: 40px;

    color: var(--blue);
}
.blog_descr
{
    font-size: 18px;
    font-weight: 400;
    line-height: 144%;

    color: var(--grey);
}
@media (max-width: 1200px)
{
    .blog_descr
    {
        font-size: 16px;
    }
}
@media (max-width: 992px)
{
    .blog_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .blog_text-wrap
    {
        max-width: 100%;
    }
}

.articles
{
    /* Только если меньше контейнера */
    padding-top: 50px;
    /* Только если меньше контейнера */
    padding-bottom: 50px;
}
@media (max-width: 1320px)
{
    .articles
    {
        padding-top: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles
    {
        padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
.articles .container
{
    padding: 0;
}
.articles_header
{
    position: relative;
    /* Только если меньше контейнера */

    margin-bottom: 50px;
}
@media (max-width: 1320px)
{
    .articles_header
    {
        margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
.articles_nav
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 0 15px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
@media (max-width: 658px)
{
    .articles_nav
    {
        padding: 0;
    }
}
.articles_button-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: scroll;

    width: 100%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.articles_button-wrap::-webkit-scrollbar
{
    display: none;
}
.articles_button-wrap.terms_nav
{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 658px)
{
    .articles_button-wrap
    {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;

        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .articles_button-wrap.terms_nav .articles_button:first-child
    {
        margin-left: 0;
    }
    .articles_button-wrap.terms_nav .articles_button:last-child
    {
        margin-right: 0;
    }
}
.articles_button
{
    font-size: 13px;

    min-width: 110px;
    height: 33px;
    padding: 0 15px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-align: center;
    white-space: nowrap;

    color: var(--blue);
    border-radius: 50px;
    background: rgba(206, 227, 236, .5);
}
@media (max-width: 768px)
{
    .articles_button
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        width: calc(50% - 5px);
        min-width: auto;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.articles_button:hover
{
    color: var(--white);
    background-color: var(--hover-blue);
}
.articles_button.active_btn
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--white);
    background-color: var(--blue);
}
@media (max-width: 658px)
{
    .articles_button:last-child
    {
        margin-right: 15px;
    }
}
.articles_search-btn
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 10px 15px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 2px solid var(--blue);
    border-radius: 50px;
    background: var(--white);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}
@media (max-width: 658px)
{
    .articles_search-btn
    {
        width: 100%;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.articles_search-btn.activeSearchBtn
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--blue);
}
.articles_search-btn.activeSearchBtn .articles_search-text,
.articles_search-btn.activeSearchBtn .icon-search
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--white);
}
@media (hover: hover)
{
    .articles_search-btn:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        background-color: var(--blue);
    }
    .articles_search-btn:hover .articles_search-text,
    .articles_search-btn:hover .icon-search
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--white);
    }
}
@media (hover: none)
{
    .articles_search-btn:active
    {
        background-color: var(--blue);
    }
    .articles_search-btn:active .articles_search-text,
    .articles_search-btn:active .icon-search
    {
        color: var(--white);
    }
}
.articles_search-text
{
    font-size: 13px;

    text-align: center;

    color: var(--blue);
}
.articles_search-input-wrap
{
    position: absolute;
    z-index: 2;
    right: 50%;
    /* Только если меньше контейнера */
    bottom: -160px;

    visibility: hidden;

    width: 0;
    /* Только если меньше контейнера */
    padding-top: 40px;
    /* Только если меньше контейнера */
    padding-right: 40px;
    /* Только если меньше контейнера */
    padding-bottom: 40px;
    /* Только если меньше контейнера */
    padding-left: 40px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    pointer-events: none;

    opacity: 0;
    border-radius: 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .25);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .25);
}
@media (max-width: 1320px)
{
    .articles_search-input-wrap
    {
        bottom: calc(-80px + -80 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles_search-input-wrap
    {
        padding-top: calc(10px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles_search-input-wrap
    {
        padding-bottom: calc(10px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles_search-input-wrap
    {
        padding-right: calc(10px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles_search-input-wrap
    {
        padding-left: calc(10px + 30 * (100vw - 360px) / 960);
    }
}
.articles_search-input-wrap.openInput
{
    visibility: visible;

    width: calc(100% - 30px);

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    pointer-events: all;

    opacity: 1;
}
.articles_search-input-body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* Только если меньше контейнера */

    padding-top: 20px;
    /* Только если меньше контейнера */
    padding-right: 20px;
    /* Только если меньше контейнера */
    padding-bottom: 20px;
    /* Только если меньше контейнера */
    padding-left: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 1px solid transparent;
    border-radius: 500px;
    background-color: var(--whiteF8);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    -webkit-backdrop-filter: blur(7.5px);
            backdrop-filter: blur(7.5px);
}
@media (max-width: 1320px)
{
    .articles_search-input-body
    {
        padding-top: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles_search-input-body
    {
        padding-bottom: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles_search-input-body
    {
        padding-right: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .articles_search-input-body
    {
        padding-left: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
.articles_search-input-body:focus-within
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
@media (max-width: 658px)
{
    .articles_search-wrap
    {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }
}
.articles_search-input
{
    font-size: 14px;

    width: 100%;

    color: var(--black2F);
    border: none;
    outline: none;
    background-color: transparent;
}
.articles_search-input::-webkit-input-placeholder
{
    color: var(--grey);
}
.articles_search-input::-moz-placeholder
{
    color: var(--grey);
}
.articles_search-input:-ms-input-placeholder
{
    color: var(--grey);
}
.articles_search-input::-ms-input-placeholder
{
    color: var(--grey);
}
.articles_search-input::placeholder
{
    color: var(--grey);
}
.articles_search-input::-webkit-search-cancel-button
{
    cursor: pointer;
}
.articles_content
{
    padding: 0 15px;
}
.articles_content-category-name
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;

    margin-bottom: 30px;

    text-align: start;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .articles_content-category-name
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .articles_content-category-name
    {
        font-size: 28px;
    }
}
.articles_search-result
{
    /* Только если меньше контейнера */
    font-size: 22px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 40px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 8px;
}
@media (max-width: 1320px)
{
    .articles_search-result
    {
        font-size: calc(18px + 4 * (100vw - 360px) / 960);
    }
}
.articles_search-result span
{
    color: var(--black2F);
}
.articles_search-feild
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}
.articles_search-feild strong
{
    font-weight: 700;
}
.articles_search-feild .clear_btn
{
    position: relative;

    width: 40px;
    height: 40px;
    margin-left: 10px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50%;
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
}
.articles_search-feild .clear_btn::after,
.articles_search-feild .clear_btn::before
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 15px;
    height: 2px;

    content: '';
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    background-color: var(--grey);
}
.articles_search-feild .clear_btn::after
{
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
}
.articles_search-feild .clear_btn::before
{
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
}
@media (hover: hover)
{
    .articles_search-feild .clear_btn:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        background-color: var(--blue);
    }
    .articles_search-feild .clear_btn:hover::after,
    .articles_search-feild .clear_btn:hover::before
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        background-color: var(--white);
    }
}
.articles .highlight
{
    color: var(--blue);
}
.articles_content-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 40px;
}
.articles_content-wrap .big_card
{
    min-height: 100%;
}
.articles_content-wrap .big_card .blog__image
{
    height: 332px;
    /* Только если меньше контейнера */
    height: 332px;
}
@media (max-width: 1320px)
{
    .articles_content-wrap .big_card .blog__image
    {
        height: calc(230px + 102 * (100vw - 360px) / 960);
    }
}
.articles_all-category
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Только если меньше контейнера */
    row-gap: 100px;
}
@media (max-width: 1320px)
{
    .articles_all-category
    {
        row-gap: calc(40px + 60 * (100vw - 360px) / 960);
    }
}
.articles_selected-category
{
    text-align: center;
}
.articles .btn
{
    font-weight: 400;
}
.articles_content-selected
{
    display: grid;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    grid-template: 1fr/repeat(auto-fit, minmax(330px, 1fr));
    gap: 30px 20px;
}
@media (max-width: 1320px)
{
    .articles_content-selected
    {
        margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
.articles_content-selected .category_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: visible;
        flex-direction: column;

    height: auto;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
.articles_content-selected .category_card.hidden
{
    display: none;
    visibility: hidden;

    height: 0;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
}
.articles_content-selected .blog__image
{
    height: 200px;
}
.articles_content-selected .card_title
{
    /* Только если меньше контейнера */
    font-size: 18px;

    margin-bottom: 15px;
    padding: 35px 25px 0 25px;

    text-align: start;

    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
}
@media (max-width: 1320px)
{
    .articles_content-selected .card_title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.articles_content-selected .card_bottom
{
    padding: 0 25px 25px 25px;
}
.articles_content-small-cards
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    max-width: 512px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 20px;
}
@media (max-width: 992px)
{
    .articles_nav
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .articles_content-wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .articles_content-wrap .big_card
    {
        max-width: 100%;
    }
    .articles_content-small-cards
    {
        max-width: 100%;
    }
    .articles_content-small-cards .blog__image
    {
        min-width: 170px;
    }
    .articles_content-small-cards .card_text
    {
        max-width: 100%;
    }
    .articles_content-small-cards .blog_card
    {
        max-width: 100%;
    }
}

.icon-search
{
    width: 20px;
    height: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}

.contact
{
    /* Только если меньше контейнера */
    padding-top: 50px;
    /* Только если меньше контейнера */
    padding-bottom: 50px;
}
@media (max-width: 1320px)
{
    .contact
    {
        padding-top: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact
    {
        padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
.contact_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 35px 25px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
}
.contact_col
{
    width: 48%;
}
.contact_title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;

    margin-bottom: 25px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .contact_title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
.contact_title span
{
    color: var(--blue);
}
.contact_text
{
    /* Только если меньше контейнера */
    font-size: 18px;
    line-height: 144%;

    margin-bottom: 25px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .contact_text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.contact_social-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}
.contact_social-link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 36px;
    height: 36px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50%;
    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.contact_social-link .icon
{
    width: 16px;
    height: 16px;

    color: var(--white);
}
@media (hover: hover)
{
    .contact_social-link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        background-color: var(--hover-blue);
    }
}
@media (hover: none)
{
    .contact_social-link:active
    {
        background-color: var(--hover-blue);
    }
}
.contact .contact-form b
{
    font-weight: 600;

    text-decoration: underline;

    color: var(--blue);
}
.contact_input-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 30px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.contact .input_body
{
    width: 100%;
}
.contact input
{
    font-size: 14px;

    width: 100%;
    width: 100%;
    max-width: 375px;
    height: 40px;
    padding: 15px 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--black2F);
    border: none;
    border: 1px solid transparent;
    border-radius: 500px;
    outline: none;
    background-color: var(--white);
}
.contact input:focus-visible
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
.contact input:focus
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
.contact input::-webkit-input-placeholder
{
    color: var(--grey);
}
.contact input::-moz-placeholder
{
    color: var(--grey);
}
.contact input:-ms-input-placeholder
{
    color: var(--grey);
}
.contact input::-ms-input-placeholder
{
    color: var(--grey);
}
.contact input::placeholder
{
    color: var(--grey);
}
.contact .form-btn
{
    font-size: 14px;

    width: 100%;
    max-width: 160px;
    height: 40px;
}
.contact .check_wrap
{
    font-size: 14px;

    margin: 0;

    color: var(--grey);
}
.contact .check_wrap .custom-checkbox
{
    border-color: var(--blue);
}
.contact .check_wrap .checkbox:checked + .custom-checkbox
{
    background-color: var(--blue);
}
.contact .check_wrap .checkbox:checked + .custom-checkbox:before
{
    content: url(../svg/check-arrow-white.svg);
}
.contact .check_wrap p,
.contact .check_wrap span
{
    font-size: 14px;

    color: var(--grey);
}
@media (max-width: 992px)
{
    .contact_content
    {
            flex-direction: column;

        text-align: start;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 50px;
    }
    .contact_col
    {
        width: 100%;
    }
    .contact_input-wrap
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .contact_input
    {
        max-width: 100%;
    }
}
@media (max-width: 576px)
{
    .contact_input-wrap
    {
            flex-direction: column;

        margin-bottom: 20px;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .contact .form-btn
    {
        max-width: 100%;
    }
}

@media (max-width: 576px)
{
    .blog-page .content section:last-of-type
    {
        padding-bottom: 0;
    }
}

@media (max-width: 576px)
{
    .blog-page .content section:last-of-type .container
    {
        padding: 0;
    }
}

@media (max-width: 576px)
{
    .blog-page .content section:last-of-type .demo_content
    {
        border-radius: 0;
    }
}

.demo
{
    /* Только если меньше контейнера */
    padding-top: 20px;
    /* Только если меньше контейнера */
    padding-bottom: 50px;
}
@media (max-width: 1320px)
{
    .demo
    {
        padding-top: calc(25px + -5 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .demo
    {
        padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
.demo_content
{
    /* Только если меньше контейнера */
    padding-top: 60px;
    /* Только если меньше контейнера */
    padding-right: 40px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;
    /* Только если меньше контейнера */
    padding-left: 40px;

    border-radius: 5px 30px;
    background-color: var(--blue);
}
@media (max-width: 1320px)
{
    .demo_content
    {
        padding-top: calc(20px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .demo_content
    {
        padding-bottom: calc(20px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .demo_content
    {
        padding-right: calc(15px + 25 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .demo_content
    {
        padding-left: calc(15px + 25 * (100vw - 360px) / 960);
    }
}
.demo_content-body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
@media (max-width: 768px)
{
    .demo_content-body
    {
            flex-direction: column-reverse;

        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
    }
}
.demo_text-wrap
{
    width: 100%;
    max-width: 402px;
}
@media (max-width: 768px)
{
    .demo_text-wrap
    {
        max-width: 100%;

        text-align: center;
    }
}
.demo_title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 400;
    line-height: 128%;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .demo_title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .demo_title
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .demo_title br
    {
        display: none;
    }
}
@media (max-width: 576px)
{
    .demo_title
    {
        width: 100%;
        max-width: 297px;

        text-align: start;
    }
}
.demo_text
{
    font-size: 14px;
    font-weight: 700;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .demo_text
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .demo_text
    {
        text-align: start;
    }
}
.demo .btn
{
    width: 315px;
}
@media (max-width: 576px)
{
    .demo .btn
    {
        width: 100%;
    }
}
.demo .demo__image
{
    width: 100%;
    max-width: 500px;
    height: auto;
}
.demo .demo__image .demo__img
{
    width: 100%;
    height: auto;
}

.article
{
    /* Только если меньше контейнера */
    padding-top: 30px;
    padding-bottom: 30px;
}
@media (max-width: 1320px)
{
    .article
    {
        padding-top: calc(20px + 10 * (100vw - 360px) / 960);
    }
}
.article_content-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 40px;
}
.article_content
{
    width: 100%;
    max-width: 740px;
}
.article_content h1
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;
    line-height: 128.5714285714%;
    /* Только если меньше контейнера */

    margin-bottom: 40px;

    text-align: left;
}
@media (max-width: 1320px)
{
    .article_content h1
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article_content h1
    {
        margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
    }
}
.article_content p
{
    /* Только если меньше контейнера */
    font-size: 18px;

    margin-bottom: 30px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .article_content p
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article_content h2
{
    /* Только если меньше контейнера */
    font-size: 22px;

    margin-bottom: 30px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .article_content h2
    {
        font-size: calc(18px + 4 * (100vw - 360px) / 960);
    }
}
.article_content ul,
.article_content ol
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Только если меньше контейнера */
    gap: 20px;
}
@media (max-width: 1320px)
{
    .article_content ul,
    .article_content ol
    {
        gap: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article_content ul,
    .article_content ol
    {
        margin-bottom: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
.article_content ul li,
.article_content ol li
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* Только если меньше контейнера */

    padding-left: 40px;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    /* Только если меньше контейнера */
    gap: 20px;
}
@media (max-width: 1320px)
{
    .article_content ul li,
    .article_content ol li
    {
        gap: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article_content ul li,
    .article_content ol li
    {
        padding-left: calc(30px + 10 * (100vw - 360px) / 960);
    }
}
.article_content ul li a,
.article_content ol li a
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .article_content ul li a,
    .article_content ol li a
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article_content ul li p,
.article_content ol li p
{
    margin: 0;
}
@media (hover: hover)
{
    .article_content ul li a:hover,
    .article_content ol li a:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--hover-blue);
    }
}
@media (hover: hover)
{
    .article_content ul li a:active,
    .article_content ol li a:active
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--hover-blue);
    }
}
.article_content ul li
{
    position: relative;
}
.article_content ul li::before
{
    position: absolute;
    top: 3px;
    left: 0;

    width: 20px;
    height: 16px;

    content: '';

    background-image: url(/img/article/arrow_3.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.article_content ol
{
    counter-reset: zeroNum;
}
.article_content ol li
{
    padding-left: 0;

    counter-increment: zeroNum;
}
.article_content ol li::before
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;

    content: '0' counter(zeroNum);

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .article_content ol li::before
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article_content table
{
    overflow: hidden;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    border-collapse: collapse;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
@media (max-width: 1320px)
{
    .article_content table
    {
        margin-bottom: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
.article_content table thead th
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;

    padding: 23px 10px 23px 25px;

    text-align: start;

    color: var(--white);
    background-color: var(--blue);
}
@media (max-width: 1320px)
{
    .article_content table thead th
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article_content table thead th:nth-child(1),
.article_content table thead th:nth-child(2)
{
    border-right: 1px solid rgba(223, 223, 223, .15);
}
.article_content table tbody tr
{
    border-bottom: 1px solid rgba(88, 89, 91, .15);
}
.article_content table tbody td
{
    font-size: 14px;

    padding: 14px 25px;

    vertical-align: top;

    color: var(--grey);
}
.article_content table tbody td:first-child
{
    font-weight: 600;

    white-space: nowrap;

    color: var(--black2F);
    border-right: 1px solid rgba(88, 89, 91, .15);
}
.article_content table tbody td:nth-child(2)
{
    border-right: 1px solid rgba(88, 89, 91, .15);
}
.article_caterory
{
    font-size: 13px;

    display: inline-block;

    margin-bottom: 20px;
    padding: 12px 15px;

    text-align: center;

    color: var(--blue);
    border-radius: 50px;
    background-color: var(--blueCE);
}
@media (max-width: 768px)
{
    .article_caterory
    {
        font-size: 12px;

        padding: 10px;
    }
}
.article_info-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 20px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.article_info-body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 20px;
}
.article_info-body span
{
    font-size: 14px;

    color: var(--grey);
}
.article_info-body b
{
    font-weight: 700;

    color: var(--black2F);
}
.article_info-body .time,
.article_info-body .publish
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}
@media (max-width: 576px)
{
    .article_info-body .publish b
    {
        display: none;
    }
}
.article_info-body .publish .icon-calendar
{
    display: none;
}
@media (max-width: 576px)
{
    .article_info-body .publish .icon-calendar
    {
        display: block;
    }
}
.article_social-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}
@media (max-width: 576px)
{
    .article_social-wrap
    {
            flex-direction: column;

        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.article_social-body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}
.article_social-link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 36px;
    height: 36px;

    border-radius: 50%;
    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.article_social-link .icon
{
    width: 16px;
    height: 16px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--white);
}
@media (hover: hover)
{
    .article_social-link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        background-color: var(--hover-blue);
    }
}
@media (hover: none)
{
    .article_social-link:active
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        background-color: var(--hover-blue);
    }
}
.article .link-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    row-gap: 20px;
}
@media (max-width: 1320px)
{
    .article .link-wrap
    {
        margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
.article .link-wrap a
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .article .link-wrap a
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article .article_image
{
    display: inline-block;
    overflow: hidden;

    width: 100%;
    height: auto;
    /* Только если меньше контейнера */
    margin-bottom: 40px;

    border-radius: 5px 30px;
}
@media (max-width: 1320px)
{
    .article .article_image
    {
        margin-bottom: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
.article .article_image .article__image
{
    width: 100%;
    height: 100%;
    margin-bottom: 0;
}
.article .article_image img
{
    width: 100%;
    height: 100%;

    -o-object-fit: cover;
       object-fit: cover;
}
.article .info_banner
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding-top: 20px;
    /* Только если меньше контейнера */
    padding-right: 40px;
    padding-bottom: 20px;
    /* Только если меньше контейнера */
    padding-left: 40px;

    border-radius: 5px 30px;
    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* Только если меньше контейнера */
    gap: 30px;
}
@media (max-width: 1320px)
{
    .article .info_banner
    {
        padding-right: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article .info_banner
    {
        padding-left: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article .info_banner
    {
        gap: calc(20px + 10 * (100vw - 360px) / 960);
    }
}
.article .info_banner .banner_img
{
    max-width: 110px;
    height: auto;
}
.article .info_banner .banner_img .banner__image
{
    width: 100%;
    height: 100%;
}
.article .info_banner .banner_img .banner__image img
{
    width: 100%;
    height: 100%;
}
.article .info_banner .banner_text-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
}
@media (max-width: 576px)
{
    .article .info_banner .banner_text-wrap
    {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
    }
}
.article .info_banner h3
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 700;

    margin-bottom: 20px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .article .info_banner h3
    {
        font-size: calc(18px + 4 * (100vw - 360px) / 960);
    }
}
.article .info_banner p
{
    font-size: 14px;

    width: 100%;
    max-width: 450px;
    margin: 0;

    color: var(--white);
}
.article .info_banner p br
{
    display: none;
}
@media (max-width: 576px)
{
    .article .info_banner p
    {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
    }
    .article .info_banner p br
    {
        display: block;
    }
}
.article .info_banner .arrow_wrap
{
    background-color: var(--white);
}
.article .info_banner .icon-arrow_top
{
    color: var(--blue);
}
@media (hover: hover)
{
    .article .info_banner .arrow_wrap:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        border-color: var(--white);
        background-color: var(--blue);
    }
    .article .info_banner .arrow_wrap:hover .icon-arrow_top
    {
        color: var(--white);
    }
}
@media (hover: none)
{
    .article .info_banner .arrow_wrap:active
    {
        border-color: var(--white);
        background-color: var(--blue);
    }
    .article .info_banner .arrow_wrap:active .icon-arrow_top
    {
        color: var(--white);
    }
}
.article blockquote
{
    overflow: hidden;

    margin: 50px 0;
    /* Только если меньше контейнера */
    padding-top: 25px;
    /* Только если меньше контейнера */
    padding-right: 25px;
    /* Только если меньше контейнера */
    padding-bottom: 25px;
    /* Только если меньше контейнера */
    padding-left: 25px;

    border-radius: 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
@media (max-width: 1320px)
{
    .article blockquote
    {
        padding-top: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article blockquote
    {
        padding-bottom: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article blockquote
    {
        padding-right: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article blockquote
    {
        padding-left: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
.article blockquote .read_banner-text_wrap
{
    padding-left: 0;

    border-left: none;
}
.article blockquote .read_banner-text_wrap.showDivider
{
    padding-left: 20px;

    border-left: 1px solid var(--blue);
}
.article blockquote span
{
    font-size: 18px;
    font-weight: 700;

    display: block;

    margin-bottom: 5px;

    color: var(--black2F);
}
.article blockquote p
{
    margin: 0;
}
.article blockquote a
{
    /* Только если меньше контейнера */
    font-size: 18px;

    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);

    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}
@media (max-width: 1320px)
{
    .article blockquote a
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article blockquote a::after
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;

    content: url(../svg/arrow-white.svg);

    border-radius: 50%;
    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (hover: hover)
{
    .article blockquote a:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--hover-blue);
    }
}
@media (hover: none)
{
    .article blockquote a:active
    {
        color: var(--hover-blue);
    }
}
.article .user_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin: 50px 0;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
}
.article .user_wrap .user__image
{
    display: inline-block;
    overflow: hidden;

    width: 64px;
    height: 64px;

    border-radius: 50%;
}
.article .user_wrap .user__image .user__img
{
    width: 100%;
    height: 100%;
}
.article .user_wrap .without_icon
{
    display: none;
}
.article .user_wrap .user_info-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    row-gap: 5px;
}
.article .user_wrap .user_name
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .article .user_wrap .user_name
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article .user_wrap .user_position
{
    font-size: 14px;

    color: var(--grey);
}
.article .grade_banner
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 25px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
}
.article .grade_banner .rated
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 407px;
    padding: 20px;

    border-radius: 5px 30px;
    background-color: var(--white);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 40px;
    /* Только если меньше контейнера */
    gap: 40px;
}
@media (max-width: 1320px)
{
    .article .grade_banner .rated
    {
        gap: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
.article .grade_banner .rated b
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;

    display: inline-block;

    margin-bottom: 15px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .article .grade_banner .rated b
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article .grade_banner .stars__image
{
    display: inline-block;

    max-width: 207px;
    height: auto;
}
.article .grade_banner .stars__image img
{
    width: 100%;
    height: 100%;
}
.article .grade_banner .rating,
.article .grade_banner .rating_num,
.article .grade_banner .votes_num
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.article .grade_banner .rating
{
    margin-bottom: 15px;

    gap: 10px;
}
.article .grade_banner .rating_num
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2px;
}
.article .grade_banner .rating_num span
{
    font-size: 14px;

    color: var(--grey);
}
.article .grade_banner .rating_num .curent_rating
{
    font-size: 28px;
    font-weight: 700;

    color: var(--black2F);
}
.article .grade_banner .rating_title
{
    /* Только если меньше контейнера */
    font-size: 18px;

    opacity: .7;
    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .article .grade_banner .rating_title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article .grade_banner .article_social-wrap
{
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    row-gap: 15px;
}
.article .grade_banner .votes_num
{
    gap: 3px;
}
.article .grade_banner .votes_num span
{
    font-size: 14px;

    white-space: nowrap;

    color: var(--grey);
}
.article .banner
{
    /* Только если меньше контейнера */
    margin-bottom: 50px;
}
@media (max-width: 1320px)
{
    .article .banner
    {
        margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
.article .arrow_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 1px solid transparent;
    border-radius: 50%;
    background-color: var(--white);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.article .icon-arrow_top
{
    min-width: 12px;
    max-width: 12px;
    min-height: 12px;
    max-height: 12px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
@media (hover: hover)
{
    .article .arrow_wrap:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        border-color: var(--white);
        background-color: var(--blue);
    }
    .article .arrow_wrap:hover .icon-arrow_top
    {
        color: var(--white);
    }
}
@media (hover: none)
{
    .article .arrow_wrap:active
    {
        border-color: var(--white);
        background-color: var(--blue);
    }
    .article .arrow_wrap:active .icon-arrow_top
    {
        color: var(--white);
    }
}
@media (max-width: 1200px)
{
    .article_content-wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}
@media (max-width: 768px)
{
    .article .grade_banner
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .article .grade_banner .rated
    {
        max-width: 100%;
    }
    .article .grade_banner .stars_wrap
    {
        max-width: 230px;
    }
    .article .table-wrap
    {
        overflow-x: auto;

        margin-bottom: 50px;
    }
    .article .table-wrap::-webkit-scrollbar
    {
        width: 100%;
        height: 6px;

        border-radius: 5px;
    }
    .article .table-wrap::-webkit-scrollbar-track
    {
        background-color: rgba(88, 89, 91, .1);
    }
    .article .table-wrap::-webkit-scrollbar-thumb
    {
        border-radius: 5px;
        background-color: var(--blue);
    }
    .article table
    {
        margin-bottom: 10px;
    }
}
@media (max-width: 576px)
{
    .article .info_banner
    {
            flex-direction: column;

        padding: 15px;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .article .banner_text-wrap,
    .article .read_banner
    {
            flex-direction: column;

        text-align: center;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .article .read_banner,
    .article .grade_banner
    {
        padding: 15px;
    }
    .article .grade_banner .rated
    {
            flex-direction: column;

        padding: 20px 15px;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .article .grade_banner .stars_wrap
    {
        text-align: center;
    }
    .article .read_banner-text_wrap
    {
        padding-left: 0;

        border: none;
    }
}

.help_cards
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    max-width: 340px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}
.help_cards .surbscribe
{
    width: 100%;
    max-width: 320px;
    padding: 25px;

    text-align: center;

    border-radius: 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.help_cards .surbscribe .input_body
{
    margin-bottom: 30px;
}
.help_cards .surbscribe_card-title
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 700;

    margin-bottom: 20px;
}
@media (max-width: 1320px)
{
    .help_cards .surbscribe_card-title
    {
        font-size: calc(18px + 4 * (100vw - 360px) / 960);
    }
}
.help_cards .surbscribe_card-text
{
    font-size: 14px;
    /* Только если меньше контейнера */

    margin-bottom: 30px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .help_cards .surbscribe_card-text
    {
        margin-bottom: calc(20px + 10 * (100vw - 360px) / 960);
    }
}
.help_cards .surbscribe_card-input
{
    font-size: 14px;

    width: 100%;
    padding: 15px 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--black2F);
    border: none;
    border: 1px solid transparent;
    border-radius: 500px;
    outline: none;
    background-color: rgba(47, 47, 49, .0509803922);
}
.help_cards .surbscribe_card-input:focus
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
.help_cards .surbscribe_card-input:focus-visible
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
.help_cards .surbscribe .check_wrap
{
    margin: 20px 0;
}
.help_cards .surbscribe .check_wrap p
{
    text-align: start;

    color: var(--grey);
}
.help_cards .surbscribe .check_wrap a
{
    font-weight: 600;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-decoration: underline;

    color: var(--blue);
}
.help_cards .surbscribe .check_wrap a:hover
{
    color: var(--hover-blue);
}
.help_cards .surbscribe .custom-checkbox
{
    border-color: var(--blue);
}
.help_cards .surbscribe .checkbox:checked + .custom-checkbox
{
    background-color: var(--blue);
}
.help_cards .surbscribe .checkbox:checked + .custom-checkbox:before
{
    content: url(../svg/check_arrow-white.svg);
}
.help_cards .surbscribe .form-btn
{
    font-size: 14px;

    height: 40px;
}
.help_cards .call_swiper
{
    width: 100%;
    max-width: 340px;
    padding: 0 10px;
}
.help_cards .call_swiper .swiper-pagination
{
    position: static;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin: 20px auto 0 auto;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.help_cards .call
{
    overflow: hidden;

    width: 100%;

    text-align: center;

    border-radius: 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}
.help_cards .call .call__image
{
    width: 100%;
    max-height: 180px;
}
.help_cards .call .call__image img
{
    width: 100%;
    height: 100%;
}
.help_cards .call_text-wrap
{
    padding: 20px;
}
.help_cards .call_title
{
    /* Только если меньше контейнера */
    font-size: 28px;

    margin-bottom: 20px;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .help_cards .call_title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
.help_cards .call_sale-price
{
    font-size: 14px;

    display: inline-block;

    margin-bottom: 10px;

    text-decoration: line-through;

    opacity: .5;
    color: var(--grey);
}
.help_cards .call_text
{
    font-size: 14px;
    font-weight: 600;

    margin-bottom: 20px;

    color: var(--black2F);
}
.help_cards .call_text span
{
    color: var(--blue);
}
.help_cards .call .btn
{
    font-size: 14px;
    font-weight: 400;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    height: 40px;
    padding: 15px 20px;
}
@media (max-width: 1200px)
{
    .help_cards .surbscribe
    {
        max-width: 740px;
    }
    .help_cards .call_swiper
    {
        max-width: 740px;
    }
}

@media (max-width: 1200px)
{
    .help_cards
    {
        max-width: 100%;
    }
}
.article-nav
{
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .article-nav
    {
        padding-bottom: calc(40px + 60 * (100vw - 360px) / 960);
    }
}
.article-nav_categories
{
    /* Только если меньше контейнера */
    margin-bottom: 100px;
}
@media (max-width: 1320px)
{
    .article-nav_categories
    {
        margin-bottom: calc(40px + 60 * (100vw - 360px) / 960);
    }
}
.article-nav_categories-title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    /* Только если меньше контейнера */

    margin-bottom: 30px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .article-nav_categories-title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article-nav_categories-title
    {
        margin-bottom: calc(20px + 10 * (100vw - 360px) / 960);
    }
}
.article-nav .articles-button-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 10px;
}
.article-nav .articles_button
{
    min-width: 90px;
    margin: 0;
}
@media (max-width: 576px)
{
    .article-nav .articles_button
    {
        padding: 10px;
    }
}
.article-nav .articles-move
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 40px;
}
.article-nav .articles-move.no_prev
{
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}
.article-nav .left_col .articles-move-link
{
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.article-nav .right_col .articles-move-link
{
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
@media (max-width: 576px)
{
    .article-nav .right_col .articles-move-link
    {
        text-align: end;
    }
}
.article-nav .right_col .icon-arrow_2
{
    rotate: 180deg;
}
.article-nav .articles-move-link
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 15px;

    color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
@media (max-width: 1320px)
{
    .article-nav .articles-move-link
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.article-nav .articles-move-link .icon-arrow_2
{
    width: 19px;
    height: 16px;

    color: var(--blue);
}
@media (hover: hover)
{
    .article-nav .articles-move-link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--hover-blue);
    }
    .article-nav .articles-move-link:hover .icon-arrow_2
    {
        color: var(--hover-blue);
    }
}
@media (hover: none)
{
    .article-nav .articles-move-link:active
    {
        background-color: var(--hover-blue);
    }
    .article-nav .articles-move-link:active .icon-arrow_2
    {
        color: var(--hover-blue);
    }
}
.article-nav .articles-move-title-wrap
{
    max-width: 365px;
    /* Только если меньше контейнера */
    padding-top: 25px;
    /* Только если меньше контейнера */
    padding-right: 25px;
    /* Только если меньше контейнера */
    padding-bottom: 25px;
    /* Только если меньше контейнера */
    padding-left: 25px;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
}
@media (max-width: 1320px)
{
    .article-nav .articles-move-title-wrap
    {
        padding-top: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article-nav .articles-move-title-wrap
    {
        padding-bottom: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article-nav .articles-move-title-wrap
    {
        padding-right: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .article-nav .articles-move-title-wrap
    {
        padding-left: calc(15px + 10 * (100vw - 360px) / 960);
    }
}
.article-nav .articles-move-title
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;
    line-height: 144.4444444444%;

    display: -webkit-box;
    overflow: hidden;

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
@media (max-width: 1320px)
{
    .article-nav .articles-move-title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .article-nav .articles-move-title-wrap
    {
        display: none;
    }
}

.article-similar .container
{
    padding: 0;
}
.article-similar_title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;

    margin-bottom: 30px;
    padding: 0 20px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .article-similar_title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
.article-similar .info_body
{
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;

    padding: 10px;

    border-radius: 5px;
    background-color: var(--white);
}
.article-similar .blog_card
{
    max-width: 100%;

    -webkit-box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .1);
}
.article-similar .card_text
{
    padding: 25px;
}
.article-similar .card_title
{
    margin: 0;

    color: var(--blue);

    -webkit-line-clamp: 5;
}
@media (max-width: 576px)
{
    .article-similar .blog__image
    {
        height: 220px;
    }
}
.article-similar .article_swiper
{
    padding: 0 20px 20px 20px;
    /* Только если меньше контейнера */
    padding-bottom: 20px;
}
@media (max-width: 1320px)
{
    .article-similar .article_swiper
    {
        padding-bottom: calc(25px + -5 * (100vw - 360px) / 960);
    }
}
.article-similar .swiper-button-wrap
{
    display: none;

    margin-top: 20px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 30px;
}
@media (max-width: 1024px)
{
    .article-similar .swiper-button-wrap
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .article-similar .swiper-button-wrap .swiper-button-prev,
    .article-similar .swiper-button-wrap .swiper-button-next
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
.article-similar .btn
{
    display: none;
}
@media (max-width: 768px)
{
    .article-similar .btn
    {
        font-size: 14px;

        display: block;

        width: -webkit-max-content;
        width:    -moz-max-content;
        width:         max-content;
        height: 40px;
        margin: 30px auto 0 auto;
        padding: 10px 20px;
    }
}

.dial-main
{
    position: relative;

    overflow: hidden;

    width: calc(100% - 20px);
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .dial-main
    {
        padding-bottom: calc(10px + 50 * (100vw - 360px) / 960);
    }
}
.dial-main::before
{
    position: absolute;
    /* Только если меньше контейнера */
    top: -1100px;
    left: 0;

    width: 100%;
    height: auto;

    content: '';

    border-radius: 50%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(-.96%, rgba(255, 255, 255, .5)), color-stop(45.36%, rgba(255, 255, 255, 0)));
    background: linear-gradient(360deg, rgba(255, 255, 255, .5) -.96%, rgba(255, 255, 255, 0) 45.36%);

    aspect-ratio: 1/1;
}
@media (max-width: 1320px)
{
    .dial-main::before
    {
        top: calc(300px + -1400 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .dial-main::before
    {
        top: -100px;
        left: -50%;

        width: 638px;

        -webkit-transform: translateX(-20%);
                transform: translateX(-20%);
    }
}
.dial-main .container
{
    position: relative;
    z-index: 2;

    padding: 0 10px;
}
.dial-main_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
@media (max-width: 576px)
{
    .dial-main_content
    {
        gap: 50px;
    }
}
.dial-main_text-block
{
    width: 100%;
    max-width: 495px;
}
.dial-main_title
{
    /* Только если меньше контейнера */
    font-size: 40px;
    font-weight: 700;
    /* Только если меньше контейнера */

    margin-bottom: 40px;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .dial-main_title
    {
        font-size: calc(28px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .dial-main_title
    {
        margin-bottom: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
.dial-main_text
{
    /* Только если меньше контейнера */
    font-size: 18px;
    line-height: 144%;
    /* Только если меньше контейнера */

    margin-bottom: 60px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .dial-main_text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .dial-main_text
    {
        margin-bottom: calc(30px + 30 * (100vw - 360px) / 960);
    }
}
.dial-main_video-block
{
    width: 100%;
    max-width: 600px;
}
.dial-main_video
{
    position: relative;

    overflow: hidden;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.dial-main_video.disabled
{
    pointer-events: none;

    -webkit-filter: grayscale(.5);
            filter: grayscale(.5);
}
.dial-main_video .dial_video
{
    width: 100%;
    height: auto;
}
@media (max-width: 992px)
{
    .dial-main_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .dial-main_text-block
    {
        max-width: 100%;
    }
}
@media (max-width: 576px)
{
    .dial-main .btn
    {
        width: 100%;
    }
}

.familiar
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .familiar
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .familiar
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.familiar_no_titles .familiar_card-text
{
    /* Только если меньше контейнера */
    font-size: 18px;
}
@media (max-width: 1320px)
{
    .familiar_no_titles .familiar_card-text
    {
        font-size: calc(14px + 4 * (100vw - 360px) / 960);
    }
}
.familiar_no_titles .familiar_card-num
{
    margin: 0;
}
.familiar_small_format .familiar_card-wrap
{
    display: grid;

    grid-template: 1fr/repeat(auto-fit, minmax(270px, 1fr));
    gap: 15px 20px;
}
.familiar_small_format .familiar_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 768px)
{
    .familiar_small_format .familiar_card.number_center
    {
            flex-direction: row;

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
    }
}
.familiar_small_format .familiar_card.number_center .card_title-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    gap: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 768px)
{
    .familiar_small_format .familiar_card.number_center .card_title-wrap
    {
            flex-direction: column;

        margin-bottom: 0;
        margin-left: 20px;

        gap: 15px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
@media (max-width: 768px)
{
    .familiar_small_format .familiar_card.number_center .familiar_card-num
    {
        margin: auto 0;
    }
}
@media (max-width: 768px)
{
    .familiar_small_format .familiar_card
    {
        padding: 25px 20px;
    }
}
.familiar_small_format .card-text-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 768px)
{
    .familiar_small_format .card-text-wrap
    {
        gap: 15px;
    }
}
.familiar_small_format .familiar_card-num
{
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
}
.familiar_is_slider
{
    /* Только если меньше контейнера */
    padding-bottom: 50px;
}
@media (max-width: 1320px)
{
    .familiar_is_slider
    {
        padding-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
.familiar_is_slider .card-text-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 768px)
{
    .familiar_is_slider .card-text-wrap
    {
        gap: 15px;
    }
}
.familiar_is_slider .familiar_title
{
    max-width: 730px;
    margin-right: auto;
    margin-left: auto;
}
@media (max-width: 1024px)
{
    .familiar_is_slider .familiar_card-wrap
    {
        display: block;
    }
}
.familiar_is_slider .familiar_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    min-height: 175px;

    -webkit-box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 10px rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 768px)
{
    .familiar_is_slider .familiar_card
    {
        padding: 25px 25px 30px 25px;
    }
}
.familiar_is_slider .familiar_card:not(:last-child)
{
    margin-bottom: 10px;
}
.familiar_is_slider .familiar_card-text
{
    font-size: 14px;
}
.familiar_is_slider .swiper
{
    padding: 0 15px 15px 15px;
}
.familiar_is_slider .swiper-button-wrap
{
    display: none;

    margin-top: 20px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 30px;
}
@media (max-width: 1024px)
{
    .familiar_is_slider .swiper-button-wrap
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
@media (max-width: 1024px)
{
    .familiar_is_slider .swiper-button-prev
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
@media (max-width: 1024px)
{
    .familiar_is_slider .swiper-button-next
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
.familiar_title
{
    /* Только если меньше контейнера */
    font-size: 40px;
    font-weight: 700;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    text-align: center;
}
@media (max-width: 1320px)
{
    .familiar_title
    {
        font-size: calc(30px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .familiar_title
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
.familiar_card-wrap
{
    display: grid;

    counter-reset: num;

    grid-template: 1fr/repeat(auto-fit, minmax(300px, 366px));
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
}
@media (max-width: 576px)
{
    .familiar_card-wrap
    {
        gap: 10px;
    }
}
.familiar_card
{
    padding: 25px;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.familiar_card:nth-child(4n+1) .familiar_card-num
{
    background-color: var(--blue);
}
.familiar_card:nth-child(4n+2) .familiar_card-num
{
    background-color: var(--green);
}
.familiar_card:nth-child(4n+3) .familiar_card-num
{
    background-color: var(--yellow);
}
.familiar_card:nth-child(4n+4) .familiar_card-num
{
    background-color: var(--black2F);
}
.familiar .card_title-wrap
{
    margin-bottom: 25px;
}
@media (max-width: 576px)
{
    .familiar .card_title-wrap
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        margin-bottom: 20px;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 15px;
    }
}
.familiar_card-num
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;

    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 20px;
    margin-bottom: 25px;

    counter-increment: num;

    color: var(--white);
    border-radius: 50%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1320px)
{
    .familiar_card-num
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .familiar_card-num
    {
        margin-bottom: 0;
    }
}
.familiar_card-num::before
{
    content: '0' counter(num);
}
.familiar_card-title
{
    /* Только если меньше контейнера */
    font-size: 18px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .familiar_card-title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.familiar_card-title br
{
    display: none;
}
@media (max-width: 576px)
{
    .familiar_card-title
    {
        margin: 0;
    }
    .familiar_card-title br
    {
        display: block;
    }
}
.familiar_card-text
{
    font-size: 14px;
    line-height: 142%;

    color: var(--grey);
}

.voice_familiar
{
    padding-bottom: 0;
}
.voice_familiar .familiar_card-wrap
{
    /* Только если меньше контейнера */
    margin-bottom: 50px;
}
@media (max-width: 1320px)
{
    .voice_familiar .familiar_card-wrap
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}

.try-dial
{
    /* Только если меньше контейнера */
    padding-top: 0;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .try-dial
    {
        padding-top: calc(60px + -60 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .try-dial
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.try-dial_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Только если меньше контейнера */
    row-gap: 50px;
}
@media (max-width: 1320px)
{
    .try-dial_content
    {
        row-gap: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
.try-dial_text-block
{
    max-width: 675px;

    text-align: center;
}
.try-dial_title
{
    /* Только если меньше контейнера */
    font-size: 40px;
    /* Только если меньше контейнера */

    margin-bottom: 30px;
}
@media (max-width: 1320px)
{
    .try-dial_title
    {
        font-size: calc(28px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .try-dial_title
    {
        margin-bottom: calc(20px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .try-dial_title
    {
        text-align: start;
    }
}
.try-dial_text
{
    /* Только если меньше контейнера */
    font-size: 18px;

    width: 100%;
    margin: 0 auto;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .try-dial_text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .try-dial_text
    {
        text-align: start;
    }
}
.try-dial .try-dial-deck__image
{
    display: inline-block;

    width: 100%;
    max-width: 1140px;
    height: auto;
}
@media (max-width: 768px)
{
    .try-dial .try-dial-deck__image
    {
        display: none;
    }
}
.try-dial .try-dial-deck__image img
{
    width: 100%;
    height: 100%;
}
.try-dial .try-dial-mob__image
{
    display: none;

    width: 100%;
    max-width: 350px;
    height: auto;
}
@media (max-width: 768px)
{
    .try-dial .try-dial-mob__image
    {
        display: block;
    }
}
.try-dial .try-dial-mob__image img
{
    width: 100%;
    height: 100%;
}
@media (max-width: 992px)
{
    .try-dial_content
    {
            flex-direction: column;

        text-align: center;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
    .try-dial_text-block
    {
        max-width: 100%;
    }
}

.try-dial-en .try-dial_text-block
{
    max-width: 675px;
}
.try-dial-en .try-dial_text
{
    max-width: 100%;
}

.dial-benefit
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .dial-benefit
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .dial-benefit
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .dial-benefit .tabs
    {
        display: none;
    }
}
@media (max-width: 992px)
{
    .dial-benefit .tabs__head
    {
        display: none;
    }
}
.dial-benefit .tabs__head-col
{
    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
}
.dial-benefit .tabs__item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
.dial-benefit .dial_accrordion
{
    width: 100%;
    max-width: 560px;
}
.dial-benefit .dial_accrordion .accordion__content
{
    padding-top: 5px;
}
.dial-benefit .accordion__item
{
    width: 100%;
}
.dial-benefit .accordion__item:not(:last-child)
{
    margin-bottom: 20px;
}
.dial-benefit .dial__image
{
    width: 100%;
    max-width: 540px;
    height: auto;
}
.dial-benefit .dial__image img
{
    width: 100%;
    height: 100%;
}
@media (max-width: 992px)
{
    .dial-benefit .dial_accrordion
    {
        max-width: 100%;
    }
    .dial-benefit .dial__image
    {
        display: none;
    }
}
.dial-benefit .dial_accrordion-mob
{
    display: none;
}
@media (max-width: 576px)
{
    .dial-benefit .dial_accrordion-mob
    {
        display: block;
    }
}
.dial-benefit .dial_accrordion-mob .accordion__content
{
    padding: 25px 15px 0 15px;
}
.dial-benefit .dial_accrordion-mob .accordion__toggler
{
    padding: 25px 15px;
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item
{
    padding: 25px 20px 30px 20px;

    border-radius: 5px 30px;
    background-color: var(--white);
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item:not(:last-child)
{
    margin-bottom: 15px;
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item span
{
    color: var(--black2F);
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item p
{
    margin: 20px 0;

    color: var(--grey);
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item img
{
    width: 100%;
    height: auto;
}

.dial_choise
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    background-color: var(--white);
}
@media (max-width: 1320px)
{
    .dial_choise
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .dial_choise
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.dial_choise_title
{
    width: 100%;
    max-width: 770px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    margin-bottom: 50px;
}
@media (max-width: 1320px)
{
    .dial_choise_title
    {
        margin-bottom: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
.dial_choise_card-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* Только если меньше контейнера */
    gap: 60px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1320px)
{
    .dial_choise_card-wrap
    {
        gap: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.dial_choise_card
{
    width: 100%;
    max-width: 340px;
}
.dial_choise_card:nth-child(4n+1) .icon
{
    color: var(--blue);
}
.dial_choise_card:nth-child(4n+1) .card_title
{
    color: var(--blue);
}
.dial_choise_card:nth-child(4n+2) .icon
{
    color: var(--yellow);
}
.dial_choise_card:nth-child(4n+2) .card_title
{
    color: var(--yellow);
}
.dial_choise_card:nth-child(4n+3) .icon
{
    color: var(--green);
}
.dial_choise_card:nth-child(4n+3) .card_title
{
    color: var(--green);
}
.dial_choise_card:nth-child(4n+4) .icon
{
    color: var(--black2F);
}
.dial_choise_card:nth-child(4n+4) .card_title
{
    color: var(--black2F);
}
.dial_choise .card_list li
{
    font-size: 14px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--grey);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.dial_choise .card_list li.hide
{
    visibility: hidden;

    height: 0;
    margin-bottom: 0;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
}
.dial_choise .card_list li.hide:not(:last-child)
{
    margin-bottom: 0;
}
.dial_choise .card_list li::before
{
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;

    content: '';

    background-color: var(--black2F);

    -webkit-mask: url(../icon/icons/icons.svg#check_2) no-repeat center/cover;
            mask: url(../icon/icons/icons.svg#check_2) no-repeat center/cover;
}
.dial_choise .card_list.yellow li::before
{
    background-color: var(--yellow);
}
.dial_choise .card_list.blue li::before
{
    background-color: var(--blue);
}
.dial_choise .card_list.green li::before
{
    background-color: var(--green);
}
.dial_choise .card_list.black li::before
{
    background-color: var(--black2F);
}
.dial_choise .card_item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.dial_choise .card_item.hide
{
    visibility: hidden;

    height: 0;
    margin-bottom: 0;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
}
.dial_choise .card_item.hide:not(:last-child)
{
    margin-bottom: 0;
}
.dial_choise .card_item span
{
    font-size: 14px;

    color: var(--grey);
}
.dial_choise .card_item .icon
{
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
}
.dial_choise .card_icon
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* Только если меньше контейнера */

    width: 110px;
    /* Только если меньше контейнера */
    height: 110px;
    /* Только если меньше контейнера */
    margin-bottom: 40px;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1320px)
{
    .dial_choise .card_icon
    {
        width: calc(80px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .dial_choise .card_icon
    {
        height: calc(80px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .dial_choise .card_icon
    {
        margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
    }
}
.dial_choise .card_icon .icon
{
    width: 48px;
    /* Только если меньше контейнера */
    width: 48px;
    height: 48px;
    /* Только если меньше контейнера */
    height: 48px;
}
@media (max-width: 1320px)
{
    .dial_choise .card_icon .icon
    {
        width: calc(36px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .dial_choise .card_icon .icon
    {
        height: calc(36px + 12 * (100vw - 360px) / 960);
    }
}
.dial_choise .card_text-wrap
{
    width: 100%;
    max-width: 285px;
}
@media (max-width: 576px)
{
    .dial_choise .card_text-wrap
    {
        max-width: 100%;
    }
}
.dial_choise .card_title
{
    /* Только если меньше контейнера */
    font-size: 18px;

    margin-bottom: 20px;
}
@media (max-width: 1320px)
{
    .dial_choise .card_title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .dial_choise .card_title br
    {
        display: none;
    }
}
.dial_choise .card_text
{
    font-size: 14px;

    color: var(--grey);
}
.dial_choise .show_btn
{
    font-size: 14px;
    font-weight: 700;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-decoration: underline;

    color: var(--blue);
}
.dial_choise .show_btn.hidden
{
    display: none;
}
@media (hover: hover)
{
    .dial_choise .show_btn:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--hover-blue);
    }
}
@media (hover: none)
{
    .dial_choise .show_btn:active
    {
        color: var(--hover-blue);
    }
}

.dial_choise-en .dial_choise_title
{
    max-width: 770px;
}

.dial_choise .dial_choise_title
{
    text-align: center;
}

.voice-robot .container
{
    position: relative;
    z-index: 2;

    padding: 0 10px;
}
.voice-robot_wrap
{
    position: relative;

    overflow: hidden;

    width: calc(100% - 20px);
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .voice-robot_wrap
    {
        padding-bottom: calc(10px + 50 * (100vw - 360px) / 960);
    }
}
.voice-robot_wrap::before
{
    position: absolute;
    z-index: 1;
    /* Только если меньше контейнера */
    top: -1100px;
    left: 0;

    width: 100%;
    height: auto;

    content: '';

    border-radius: 50%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(-.96%, rgba(255, 255, 255, .5)), color-stop(45.36%, rgba(255, 255, 255, 0)));
    background: linear-gradient(360deg, rgba(255, 255, 255, .5) -.96%, rgba(255, 255, 255, 0) 45.36%);

    aspect-ratio: 1/1;
}
@media (max-width: 1320px)
{
    .voice-robot_wrap::before
    {
        top: calc(300px + -1400 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .voice-robot_wrap::before
    {
        top: -100px;
        left: -50%;

        width: 638px;

        -webkit-transform: translateX(-20%);
                transform: translateX(-20%);
    }
}
.voice-robot_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 50px 30px;
}
@media (max-width: 992px)
{
    .voice-robot_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.voice-robot_text-wrap
{
    width: 100%;
    max-width: 492px;
}
@media (max-width: 992px)
{
    .voice-robot_text-wrap
    {
        max-width: 100%;

        text-align: center;
    }
}
@media (max-width: 576px)
{
    .voice-robot_text-wrap
    {
        text-align: start;
    }
}
.voice-robot_title
{
    /* Только если меньше контейнера */
    margin-bottom: 40px;

    text-align: start;
}
@media (max-width: 1320px)
{
    .voice-robot_title
    {
        margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .voice-robot_title
    {
        text-align: center;
    }
}
@media (max-width: 576px)
{
    .voice-robot_title
    {
        text-align: start;
    }
}
.voice-robot_descr
{
    /* Только если меньше контейнера */
    font-size: 18px;
    line-height: 1.4444444444;

    width: 100%;
    max-width: 380px;
    margin-bottom: 60px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .voice-robot_descr
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .voice-robot_descr
    {
        margin: 0 auto 50px auto;
    }
}
@media (max-width: 576px)
{
    .voice-robot_descr
    {
        max-width: 100%;
    }
}
@media (max-width: 576px)
{
    .voice-robot .btn
    {
        width: 100%;
    }
}
.voice-robot_video-wrap
{
    position: relative;

    overflow: hidden;

    max-width: 588px;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
}
.voice-robot_reviews
{
    /* Только если меньше контейнера */
    margin-top: 30px;
}
@media (max-width: 1320px)
{
    .voice-robot_reviews
    {
        margin-top: calc(50px + -20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .voice-robot_reviews
    {
        text-align: center;
    }
}
.voice-robot .reviews_text
{
    font-size: 14px;
    font-weight: 300;

    color: var(--grey);
}
.voice-robot .reviews_logo-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-top: 15px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}
@media (max-width: 992px)
{
    .voice-robot .reviews_logo-wrap
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media (max-width: 576px)
{
    .voice-robot .reviews_logo-wrap
    {
        gap: 5px;
    }
}
.voice-robot .logo-img_wrap
{
    width: 100%;
    max-width: 128px;
    height: auto;
    padding: 5px 7px;

    border-radius: 5px 10px;
    background-color: var(--white);
}
.voice-robot .logo-img_wrap .logo__img
{
    display: block;

    width: 100%;
    height: auto;
}

.voice-page-en .proposal
{
    padding: 60px 0;
}

.voice-familiar
{
    /* Только если меньше контейнера */
    padding-top: 0;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .voice-familiar
    {
        padding-top: calc(0px + 0 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-familiar
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.voice-familiar_board
{
    /* Только если меньше контейнера */
    padding-top: 60px;
    /* Только если меньше контейнера */
    padding-right: 60px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;
    /* Только если меньше контейнера */
    padding-left: 60px;

    text-align: center;

    border-radius: 5px 30px;
    background-color: var(--blue);
}
@media (max-width: 1320px)
{
    .voice-familiar_board
    {
        padding-top: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-familiar_board
    {
        padding-bottom: calc(20px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-familiar_board
    {
        padding-right: calc(20px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-familiar_board
    {
        padding-left: calc(20px + 40 * (100vw - 360px) / 960);
    }
}
.voice-familiar_board-title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    line-height: 145%;

    width: 100%;
    max-width: 590px;
    margin: 0 auto 30px auto;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .voice-familiar_board-title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .voice-familiar_board-title
    {
        text-align: start;
    }
}
.voice-familiar_board-text
{
    /* Только если меньше контейнера */
    font-size: 18px;
    line-height: 150%;

    margin: 0 auto 50px auto;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .voice-familiar_board-text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .voice-familiar_board-text
    {
        text-align: start;
    }
}
.voice-familiar_board-columns
{
    display: grid;

    margin-bottom: 50px;

    grid-template: 1fr/230px 1px 230px 1px 230px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 40px;
}
@media (max-width: 992px)
{
    .voice-familiar_board-columns
    {
        grid-template: 1fr/1fr;
    }
}
.voice-familiar_board-column
{
    width: 100%;

    text-align: start;
}
.voice-familiar_board-column .icon
{
    /* Только если меньше контейнера */
    width: 48px;
    /* Только если меньше контейнера */
    height: 48px;
    /* Только если меньше контейнера */
    margin-bottom: 40px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .voice-familiar_board-column .icon
    {
        margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-familiar_board-column .icon
    {
        width: calc(36px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-familiar_board-column .icon
    {
        height: calc(36px + 12 * (100vw - 360px) / 960);
    }
}
.voice-familiar .column-title
{
    /* Только если меньше контейнера */
    font-size: 22px;
    line-height: 150%;

    margin-bottom: 20px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .voice-familiar .column-title
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.voice-familiar .column-text
{
    font-size: 14px;
    line-height: 142%;

    color: var(--white);
}
.voice-familiar .divider
{
    width: 1px;
    height: 100%;

    opacity: .25;
    background-color: var(--white);
}
@media (max-width: 992px)
{
    .voice-familiar .divider
    {
        width: 100%;
        height: 1px;
    }
}
@media (max-width: 576px)
{
    .voice-familiar .btn
    {
        width: 100%;
    }
}

.voice-works
{
    /* Только если меньше контейнера */
    padding-top: 72px;
    /* Только если меньше контейнера */
    padding-bottom: 72px;

    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .voice-works
    {
        padding-top: calc(60px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-works
    {
        padding-bottom: calc(60px + 12 * (100vw - 360px) / 960);
    }
}
.voice-works_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 992px)
{
    .voice-works_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.voice-works_left-side
{
    width: 100%;
    min-width: 330px;
    max-width: 400px;
}
@media (max-width: 992px)
{
    .voice-works_left-side
    {
        display: none;
    }
}
.voice-works_left-side .voice-works_card:nth-child(1) .icon-wrap
{
    border-right: 1px solid var(--blue);
}
.voice-works_left-side .voice-works_card:nth-child(1) .icon
{
    color: var(--blue);
}
.voice-works_left-side .voice-works_card:nth-child(2) .icon-wrap
{
    border-right: 1px solid var(--yellow);
}
.voice-works_left-side .voice-works_card:nth-child(2) .icon
{
    color: var(--yellow);
}
.voice-works_card
{
    display: grid;

    max-width: 400px;
    min-height: 150px;
    padding: 20px;

    border-radius: 5px 25px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    grid-template: 1fr/56px 1fr;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 20px;
}
.voice-works_card:not(:last-child)
{
    margin-bottom: 30px;
}
.voice-works_card .icon
{
    width: 36px;
    height: 36px;
}
.voice-works_center-side
{
    position: relative;

    width: 100%;
    max-width: 340px;

    text-align: center;
}
@media (max-width: 992px)
{
    .voice-works_center-side
    {
        max-width: 100%;
    }
}
.voice-works .robot__image
{
    position: relative;
    z-index: 1;

    display: inline-block;
    /* Только если меньше контейнера */

    width: 216px;
    height: auto;

    border-radius: 50%;
    -webkit-box-shadow: 0 10.7px 26.75px rgba(0, 0, 0, .1);
            box-shadow: 0 10.7px 26.75px rgba(0, 0, 0, .1);
}
@media (max-width: 1320px)
{
    .voice-works .robot__image
    {
        width: calc(160px + 56 * (100vw - 360px) / 960);
    }
}
.voice-works .robot__image img
{
    width: 100%;
    height: 100%;
}
.voice-works .line
{
    position: absolute;
}
@media (max-width: 992px)
{
    .voice-works .line
    {
        display: none;
    }
}
.voice-works .top_left
{
    top: 30px;
    right: 0;
}
.voice-works .top_right
{
    top: 30px;
    left: 0;
}
.voice-works .bottom_left
{
    bottom: 30px;
    left: 0;
}
.voice-works .bottom_right
{
    right: 0;
    bottom: 30px;
}
.voice-works .lines
{
    display: none;

    margin: 0 auto;
}
@media (max-width: 992px)
{
    .voice-works .lines
    {
        display: block;

        width: 75%;
    }
}
.voice-works_right-side
{
    width: 100%;
    min-width: 330px;
    max-width: 400px;
}
@media (max-width: 992px)
{
    .voice-works_right-side
    {
        display: none;
    }
}
.voice-works_right-side .voice-works_card:nth-child(1) .icon-wrap
{
    border-right: 1px solid var(--green);
}
.voice-works_right-side .voice-works_card:nth-child(1) .icon
{
    color: var(--green);
}
.voice-works_right-side .voice-works_card:nth-child(2) .icon-wrap
{
    border-right: 1px solid var(--black2F);
}
.voice-works_right-side .voice-works_card:nth-child(2) .icon
{
    color: var(--black2F);
}
.voice-works .icon-wrap
{
    height: 100%;
    padding-right: 20px;
}
.voice-works .card_title
{
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4285714286;

    margin-bottom: 20px;

    color: var(--black2F);
}
.voice-works .card_text
{
    font-size: 14px;
    line-height: 1.4285714286;

    color: var(--grey);
}
.voice-works .voice_tabs
{
    display: none;
    overflow: hidden;

    width: 100%;

    border-radius: 25px;
    background-color: var(--white);
}
@media (max-width: 992px)
{
    .voice-works .voice_tabs
    {
        display: block;
    }
}
.voice-works .voice_tabs .tabs__head-row
{
    position: relative;

    margin: 0;
    padding-bottom: 2px;

    background-color: var(--whiteF8);

    gap: 0;
}
.voice-works .voice_tabs .tabs__head-row::before
{
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 2px;

    content: '';

    opacity: .25;
    background-color: var(--grey);
}
.voice-works .voice_tabs .tabs__head-row::after
{
    position: absolute;
    bottom: 0;
    left: 0;

    width: 52px;
    height: 2px;

    content: '';
    -webkit-transition: left .3s ease, background-color .3s ease;
            transition: left .3s ease, background-color .3s ease;

    background-color: var(--after-color, var(--blue));
}
.voice-works .voice_tabs .tabs__head-col:nth-child(1) .tabs__btn.active
{
    color: var(--blue);
}
.voice-works .voice_tabs .tabs__head-col:nth-child(2) .tabs__btn.active
{
    color: var(--yellow);
}
.voice-works .voice_tabs .tabs__head-col:nth-child(3) .tabs__btn.active
{
    color: var(--green);
}
.voice-works .voice_tabs .tabs__head-col:nth-child(4) .tabs__btn.active
{
    color: var(--black2F);
}
.voice-works .voice_tabs .tabs__btn
{
    padding: 11px 26px;

    border: none;
    background-color: var(--whiteF8);
}
.voice-works .voice_tabs .tabs__btn.active
{
    background-color: var(--white);
}
.voice-works .voice_tabs .tabs__btn.active .icon
{
    opacity: 1;
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(1)
{
    color: var(--blue);
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(2)
{
    color: var(--yellow);
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(3)
{
    color: var(--green);
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(4)
{
    color: var(--black2F);
}
.voice-works .voice_tabs .icon
{
    width: 28px;
    height: 28px;

    opacity: .15;
}
.voice-works .voice_tabs .tabs__body
{
    padding: 25px;
}

.voice-use
{
    /* Только если меньше контейнера */
    padding-top: 0;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .voice-use
    {
        padding-top: calc(0px + 0 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-use
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.voice-use_example
{
    margin: 0 auto;
    /* Только если меньше контейнера */
    padding-top: 70px;
    padding-right: 20px;
    /* Только если меньше контейнера */
    padding-bottom: 70px;
    padding-left: 20px;

    border-radius: 5px 30px;
    background-color: var(--blue);
}
@media (max-width: 1320px)
{
    .voice-use_example
    {
        padding-top: calc(40px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .voice-use_example
    {
        padding-bottom: calc(40px + 30 * (100vw - 360px) / 960);
    }
}
.voice-use .example_body
{
    width: 100%;
    max-width: 736px;
    margin: 0 auto;
}
.voice-use .example_title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;

    margin-bottom: 60px;

    text-align: center;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .voice-use .example_title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .voice-use .example_title
    {
        width: 100%;
        max-width: 280px;
        margin: 0 auto 50px auto;
    }
}
.voice-use .example_item-wrap
{
    counter-reset: num;
}
.voice-use .example_item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* Только если меньше контейнера */
    gap: 30px;
}
@media (max-width: 1320px)
{
    .voice-use .example_item
    {
        gap: calc(25px + 5 * (100vw - 360px) / 960);
    }
}
.voice-use .example_item:not(:last-child)
{
    /* Только если меньше контейнера */
    margin-bottom: 40px;
}
@media (max-width: 1320px)
{
    .voice-use .example_item:not(:last-child)
    {
        margin-bottom: calc(50px + -10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .voice-use .example_item
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.voice-use .item_title
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4444444444;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 275px;

    counter-increment: num;

    color: var(--white);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}
@media (max-width: 1320px)
{
    .voice-use .item_title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.voice-use .item_title::before
{
    font-weight: 400;

    display: block;

    content: '0' counter(num);

    opacity: .7;
}
@media (max-width: 576px)
{
    .voice-use .item_title
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.voice-use .record_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 390px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}
@media (max-width: 768px)
{
    .voice-use .record_wrap
    {
        max-width: 100%;
    }
}
.voice-use .record_track
{
    position: relative;

    width: 100%;
    max-width: 289px;
    height: 3px;

    border-radius: 50px;
}
@media (max-width: 768px)
{
    .voice-use .record_track
    {
        max-width: 100%;
    }
}
.voice-use .record_progress
{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50px;
    outline: none;
    background: rgba(255, 255, 255, .3);

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.voice-use .record_progress::-webkit-slider-thumb
{
    width: 11px;
    height: 11px;

    cursor: pointer;
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-radius: 50%;
    background-color: var(--thumb-color, #fff);

    -webkit-appearance: none;
            appearance: none;
}
.voice-use .record_progress::-moz-range-thumb
{
    width: 11px;
    height: 11px;

    cursor: pointer;
    -moz-transition: all .3s ease 0s;
         transition: all .3s ease 0s;

    border-radius: 50%;
    background-color: var(--thumb-color, #fff);
}
.voice-use .record_progress::-ms-thumb
{
    width: 11px;
    height: 11px;

    cursor: pointer;
    -ms-transition: all .3s ease 0s;
        transition: all .3s ease 0s;

    border-radius: 50%;
    background-color: var(--thumb-color, #fff);
}
.voice-use .record_current-time
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;

    display: inline-block;

    min-width: 33px;
    max-width: 33px;
    height: auto;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-align: center;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .voice-use .record_current-time
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.voice-use .record-play_btn
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 1px solid transparent;
    border-radius: 50%;
    background-color: var(--white);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (hover: hover)
{
    .voice-use .record-play_btn:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        border-color: var(--white);
        background-color: var(--blue);
    }
    .voice-use .record-play_btn:hover .icon
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--white);
    }
}
.voice-use .icon
{
    width: 7px;
    height: 8px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}

.contact-center_title
{
    color: var(--black2F);
}

.contact-center-try
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .contact-center-try
    {
        padding-top: calc(50px + 50 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact-center-try
    {
        padding-bottom: calc(50px + 50 * (100vw - 360px) / 960);
    }
}
.contact-center-try_title
{
    max-width: 700px;
    margin-bottom: 30px;
}
@media (max-width: 576px)
{
    .contact-center-try_title
    {
        text-align: start;
    }
}
.contact-center-try_text
{
    /* Только если меньше контейнера */
    font-size: 18px;
    line-height: 1.5;

    width: 100%;
    max-width: 532px;
    margin: 0 auto 50px auto;

    text-align: center;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .contact-center-try_text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .contact-center-try_text
    {
        margin-bottom: 40px;

        text-align: start;
    }
}
.contact-center-try_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 44px;
}
@media (max-width: 768px)
{
    .contact-center-try_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        gap: 10px;
    }
}
.contact-center-try_left-side,
.contact-center-try_right-side
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 40px;
}
@media (max-width: 768px)
{
    .contact-center-try_left-side,
    .contact-center-try_right-side
    {
        gap: 10px;
    }
}
@media (max-width: 576px)
{
    .contact-center-try_left-side,
    .contact-center-try_right-side
    {
        width: 100%;
    }
}
.contact-center-try_left-side
{
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.contact-center-try_left-side .contact-center-try_card:nth-child(1)
{
    width: 100%;
    max-width: 326px;
}
@media (max-width: 768px)
{
    .contact-center-try_left-side .contact-center-try_card:nth-child(1)
    {
        max-width: 380px;
    }
}
.contact-center-try_left-side .contact-center-try_card:nth-child(1) .icon-wrap
{
    border-color: var(--blue);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(1) .icon
{
    color: var(--blue);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(2)
{
    width: 100%;
    max-width: 175px;
}
@media (max-width: 768px)
{
    .contact-center-try_left-side .contact-center-try_card:nth-child(2)
    {
        max-width: 380px;
    }
}
.contact-center-try_left-side .contact-center-try_card:nth-child(2) .icon-wrap
{
    border-color: var(--yellow);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(2) .icon
{
    color: var(--yellow);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(3)
{
    width: 100%;
    max-width: 326px;
}
@media (max-width: 768px)
{
    .contact-center-try_left-side .contact-center-try_card:nth-child(3)
    {
        max-width: 380px;
    }
}
.contact-center-try_left-side .contact-center-try_card:nth-child(3) .icon-wrap
{
    border-color: var(--green);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(3) .icon
{
    color: var(--green);
}
.contact-center-try_right-side
{
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.contact-center-try_right-side .contact-center-try_card:nth-child(1)
{
    width: 100%;
    max-width: 326px;
}
@media (max-width: 768px)
{
    .contact-center-try_right-side .contact-center-try_card:nth-child(1)
    {
        max-width: 380px;
    }
}
.contact-center-try_right-side .contact-center-try_card:nth-child(1) .icon-wrap
{
    border-color: var(--blue);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(1) .icon
{
    color: var(--blue);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(2)
{
    width: 100%;
    max-width: 175px;
}
@media (max-width: 768px)
{
    .contact-center-try_right-side .contact-center-try_card:nth-child(2)
    {
        max-width: 380px;
    }
}
.contact-center-try_right-side .contact-center-try_card:nth-child(2) .icon-wrap
{
    border-color: var(--green);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(2) .icon
{
    color: var(--green);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(3)
{
    width: 100%;
    max-width: 326px;
}
@media (max-width: 768px)
{
    .contact-center-try_right-side .contact-center-try_card:nth-child(3)
    {
        max-width: 380px;
    }
}
.contact-center-try_right-side .contact-center-try_card:nth-child(3) .icon-wrap
{
    border-color: var(--yellow);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(3) .icon
{
    color: var(--yellow);
}
.contact-center-try_card
{
    position: relative;
}
.contact-center-try .card_content
{
    position: relative;
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    padding: 20px 35px 20px 22px;

    border-radius: 5px 25px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 25px;
}
@media (max-width: 576px)
{
    .contact-center-try .card_content
    {
        padding: 15px;

        gap: 15px;
    }
}
.contact-center-try .card_content .icon
{
    width: 36px;
    height: 36px;
}
@media (max-width: 992px)
{
    .contact-center-try_center-side
    {
        display: none;
    }
}
.contact-center-try_center-side .logo__image
{
    display: inline-block;

    min-width: 250px;
    max-width: 330px;
    height: auto;
}
.contact-center-try_center-side .logo__image img
{
    width: 100%;
    height: 100%;
}
.contact-center-try .icon-wrap
{
    padding-right: 22px;

    border-right: 1px solid transparent;
}
@media (max-width: 576px)
{
    .contact-center-try .icon-wrap
    {
        padding-right: 15px;
    }
}
.contact-center-try .card_title
{
    font-size: 14px;
    font-weight: 400;

    max-width: 180px;

    color: var(--black2F);
}
.contact-center-try .line
{
    position: absolute;
}
@media (max-width: 992px)
{
    .contact-center-try .line
    {
        display: none;
    }
}
.contact-center-try .top_right
{
    top: 50%;
    /* Только если меньше контейнера */
    right: -80px;
}
@media (max-width: 1320px)
{
    .contact-center-try .top_right
    {
        right: calc(-40px + -40 * (100vw - 360px) / 960);
    }
}
.contact-center-try .top_left
{
    top: 50%;
    /* Только если меньше контейнера */
    left: -80px;
}
@media (max-width: 1320px)
{
    .contact-center-try .top_left
    {
        left: calc(-40px + -40 * (100vw - 360px) / 960);
    }
}
.contact-center-try .left
{
    top: 50%;
    /* Только если меньше контейнера */
    right: -170px;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
@media (max-width: 1320px)
{
    .contact-center-try .left
    {
        right: calc(-80px + -90 * (100vw - 360px) / 960);
    }
}
.contact-center-try .bottom_right
{
    bottom: 50%;
    /* Только если меньше контейнера */
    left: -80px;
}
@media (max-width: 1320px)
{
    .contact-center-try .bottom_right
    {
        left: calc(-40px + -40 * (100vw - 360px) / 960);
    }
}
.contact-center-try .bottom_left
{
    /* Только если меньше контейнера */
    right: -80px;
    bottom: 50%;
}
@media (max-width: 1320px)
{
    .contact-center-try .bottom_left
    {
        right: calc(-40px + -40 * (100vw - 360px) / 960);
    }
}
.contact-center-try .right
{
    top: 50%;
    /* Только если меньше контейнера */
    left: -170px;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
@media (max-width: 1320px)
{
    .contact-center-try .right
    {
        left: calc(-80px + -90 * (100vw - 360px) / 960);
    }
}

.contact-center-choose
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .contact-center-choose
    {
        padding-top: calc(50px + 50 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact-center-choose
    {
        padding-bottom: calc(50px + 50 * (100vw - 360px) / 960);
    }
}
.contact-center-choose_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    max-width: 760px;
    margin: 0 auto;

    counter-reset: num;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.contact-center-choose .icon
{
    width: 20px;
    height: 20px;
}
.contact-center-choose .col_head
{
    display: grid;

    width: 100%;
    margin-bottom: 20px;

    grid-template: 1fr/auto 88px 170px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    /* Только если меньше контейнера */
    gap: 20px;
}
@media (max-width: 1320px)
{
    .contact-center-choose .col_head
    {
        gap: calc(8px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .contact-center-choose .col_head
    {
        grid-template: 1fr/auto 75px 170px;
    }
}
@media (max-width: 576px)
{
    .contact-center-choose .col_head
    {
        grid-template: 1fr/auto 50px 75px;
    }
}
.contact-center-choose .col_head span
{
    /* Только если меньше контейнера */
    font-size: 22px;

    text-align: center;
}
@media (max-width: 1320px)
{
    .contact-center-choose .col_head span
    {
        font-size: calc(16px + 6 * (100vw - 360px) / 960);
    }
}
.contact-center-choose .col_head span:nth-child(2)
{
    font-weight: 500;

    color: var(--blue);
}
.contact-center-choose .col_head span:nth-child(3)
{
    color: var(--grey);
}
.contact-center-choose .col_item
{
    display: grid;

    width: 100%;

    counter-increment: num;

    grid-template: 1fr/75px auto 88px 170px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* Только если меньше контейнера */
    gap: 20px;
}
@media (max-width: 1320px)
{
    .contact-center-choose .col_item
    {
        gap: calc(8px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .contact-center-choose .col_item
    {
        grid-template: 1fr/50px auto 75px 170px;
    }
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item
    {
        grid-template: 1fr/25px auto 50px 75px;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.contact-center-choose .col_item:nth-child(2)::before
{
    padding-top: 28px;
}
@media (max-width: 768px)
{
    .contact-center-choose .col_item:nth-child(2)::before
    {
        padding-top: 15px;
    }
}
.contact-center-choose .col_item:nth-child(-n+10)::before
{
    content: '0' counter(num);
}
.contact-center-choose .col_item:nth-child(n+11)::before
{
    content: counter(num);
}
.contact-center-choose .col_item:last-child::before
{
    padding-bottom: 28px;
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item:last-child::before
    {
        padding-bottom: 15px;
    }
}
.contact-center-choose .col_item::before
{
    /* Только если меньше контейнера */
    font-size: 18px;

    padding: 17px 0;

    opacity: .5;
    color: var(--grey);
}
@media (max-width: 1320px)
{
    .contact-center-choose .col_item::before
    {
        font-size: calc(14px + 4 * (100vw - 360px) / 960);
    }
}
@media (max-width: 400px)
{
    .contact-center-choose .col_item::before
    {
        padding-top: 15px;
    }
}
.contact-center-choose .col_item:nth-child(2) .icon-check_wrap
{
    padding-top: 28px;

    border-radius: 30px 30px 0 0;
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item:nth-child(2) .icon-check_wrap
    {
        padding-top: 15px;
    }
}
.contact-center-choose .col_item:nth-child(2) .icon-cross_wrap,
.contact-center-choose .col_item:nth-child(2) p
{
    padding-top: 28px;
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item:nth-child(2) .icon-cross_wrap,
    .contact-center-choose .col_item:nth-child(2) p
    {
        padding-top: 15px;
    }
}
.contact-center-choose .col_item.last-child .icon-check_wrap
{
    padding-bottom: 28px;

    border-radius: 0 0 30px 30px;
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item.last-child .icon-check_wrap
    {
        padding-bottom: 15px;
    }
}
.contact-center-choose .col_item.last-child .icon-cross_wrap,
.contact-center-choose .col_item.last-child p
{
    padding-bottom: 28px;
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item.last-child .icon-cross_wrap,
    .contact-center-choose .col_item.last-child p
    {
        padding-bottom: 15px;
    }
}
.contact-center-choose .col_item p
{
    /* Только если меньше контейнера */
    font-size: 18px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 245px;
    padding: 17px 0;

    color: var(--grey);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
@media (max-width: 1320px)
{
    .contact-center-choose .col_item p
    {
        font-size: calc(14px + 4 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item p
    {
        padding: 14px 0;
    }
}
@media (max-width: 400px)
{
    .contact-center-choose .col_item p
    {
        max-width: 120px;
    }
}
.contact-center-choose .col_item .icon-check_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;
    padding: 17px 0;

    background-color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item .icon-check_wrap
    {
        padding: 14px 0;
    }
}
.contact-center-choose .col_item .icon-check_wrap .icon
{
    color: var(--white);
}
.contact-center-choose .col_item .icon-cross_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;
    padding: 17px 0;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 576px)
{
    .contact-center-choose .col_item .icon-cross_wrap
    {
        padding: 14px 0;
    }
}

.trial
{
    padding: 60px 0;

    background-color: var(--blue);
}
.trial_without-additional-content .trial_content
{
    max-width: 700px;
}
.trial_without-additional-content .trial_text-block
{
    width: 100%;
    max-width: 100%;

    text-align: center;
}
.trial_without-additional-content .trial_text-block-title
{
    font-weight: 700;
}
.trial_without-additional-content .trial__image
{
    display: none;
}
.trial_modal
{
    position: fixed;
    z-index: 3;
    top: 50%;
    left: 50%;

    display: none;

    width: calc(100% - 40px);

    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    border-radius: 5px;
}
.trial_modal.open
{
    display: block;
}
@media (max-width: 768px)
{
    .trial_modal .trial_content
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.trial_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
@media (max-width: 992px)
{
    .trial_content
    {
            flex-direction: column-reverse;

        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
    }
}
.trial_text-block
{
    width: 100%;
    max-width: 346px;
}
@media (max-width: 992px)
{
    .trial_text-block
    {
        max-width: 100%;

        text-align: center;
    }
}
@media (max-width: 576px)
{
    .trial_text-block
    {
        text-align: start;
    }
}
.trial_text-block-title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 400;
    line-height: 145%;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .trial_text-block-title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .trial_text-block-title
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
.trial_text-block-descr
{
    font-size: 14px;
    font-weight: 700;
    line-height: 142%;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .trial_text-block-descr
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
.trial .trial__image
{
    width: 100%;
    max-width: 480px;
    height: auto;
}
.trial .trial__image img
{
    width: 100%;
    height: 100%;
}
@media (max-width: 576px)
{
    .trial .btn
    {
        width: 100%;
    }
}

.tariff_content
{
    /* Только если меньше контейнера */
    padding-top: 60px;
    padding-right: 18px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;
    padding-left: 18px;

    border-radius: 5px 30px;
    background-color: var(--blue);
}
@media (max-width: 1320px)
{
    .tariff_content
    {
        padding-top: calc(20px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .tariff_content
    {
        padding-bottom: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.tariff_body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 875px;
    margin: 0 auto;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
@media (max-width: 992px)
{
    .tariff_body
    {
            flex-direction: column-reverse;

        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
    }
}
.tariff_text-block
{
    width: 100%;
    max-width: 425px;
}
@media (max-width: 992px)
{
    .tariff_text-block
    {
        max-width: 100%;

        text-align: center;
    }
}
.tariff_text-block-title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;

    margin-bottom: 30px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .tariff_text-block-title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
.tariff_text-block-descr
{
    font-size: 14px;
    font-weight: 700;
    line-height: 142%;

    width: 100%;
    max-width: 342px;
    /* Только если меньше контейнера */
    margin-bottom: 75px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .tariff_text-block-descr
    {
        margin-bottom: calc(30px + 45 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .tariff_text-block-descr
    {
        width: 100%;
        max-width: 260px;
        margin: 0 auto 30px auto;
    }
}
.tariff .tariff__image
{
    max-width: 460px;
    height: auto;
}
.tariff .tariff__image img
{
    width: 100%;
    height: 100%;
}
@media (max-width: 576px)
{
    .tariff .btn
    {
        width: 100%;
    }
}

.clients-result
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .clients-result
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .clients-result
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.clients-result .container
{
    text-align: center;
}
.clients-result_content
{
    display: grid;

    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    margin-bottom: 50px;

    grid-template: 1fr/303px 1fr;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
}
@media (max-width: 1320px)
{
    .clients-result_content
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .clients-result_content
    {
        grid-template: 1fr/250px 1fr;
    }
}
@media (max-width: 768px)
{
    .clients-result_content
    {
        grid-template: 1fr/1fr;
    }
}
.clients-result .clients-result__image
{
    overflow: hidden;

    width: 315px;
    height: 100%;

    border-radius: 5px 30px;
}
@media (max-width: 768px)
{
    .clients-result .clients-result__image
    {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
    }
}
.clients-result .clients-result__image img
{
    width: 100%;
    height: 100%;

    -o-object-fit: cover;
       object-fit: cover;
}
.clients-result_numbers-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    /* Только если меньше контейнера */
    padding-top: 50px;
    /* Только если меньше контейнера */
    padding-right: 50px;
    /* Только если меньше контейнера */
    padding-bottom: 50px;
    /* Только если меньше контейнера */
    padding-left: 50px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Только если меньше контейнера */
    row-gap: 45px;
}
@media (max-width: 1320px)
{
    .clients-result_numbers-wrap
    {
        padding-top: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .clients-result_numbers-wrap
    {
        padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .clients-result_numbers-wrap
    {
        padding-right: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .clients-result_numbers-wrap
    {
        padding-left: calc(25px + 25 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .clients-result_numbers-wrap
    {
        row-gap: calc(40px + 5 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .clients-result_numbers-wrap
    {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.clients-result_number-top,
.clients-result_number-bottom
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    /* Только если меньше контейнера */
    gap: 30px;
}
@media (max-width: 1320px)
{
    .clients-result_number-top,
    .clients-result_number-bottom
    {
        gap: calc(40px + -10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .clients-result_number-top,
    .clients-result_number-bottom
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.clients-result_number-top
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 576px)
{
    .clients-result_number-top
    {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.clients-result_number-top .clients-result_number:nth-child(1)
{
    width: 100%;
    max-width: 300px;
}
.clients-result_number-top .clients-result_number:nth-child(1) .percent
{
    color: var(--blue);
}
.clients-result_number-top .clients-result_number:nth-child(2)
{
    width: 100%;
    max-width: 207px;
}
.clients-result_number-top .clients-result_number:nth-child(2) .percent
{
    color: var(--yellow);
}
.clients-result_number-bottom
{
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
@media (max-width: 576px)
{
    .clients-result_number-bottom
    {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}
.clients-result_number-bottom .clients-result_number:first-child
{
    width: 100%;
    max-width: 300px;
}
.clients-result_number-bottom .clients-result_number:first-child .percent
{
    color: var(--green);
}
.clients-result_number-bottom .clients-result_number:last-child
{
    width: 100%;
    max-width: 207px;
}
.clients-result_number
{
    width: 50%;

    text-align: start;
}
@media (max-width: 576px)
{
    .clients-result_number
    {
        width: 100%;
    }
}
.clients-result_number:nth-child(3) .percent
{
    color: var(--green);
}
.clients-result .percent
{
    /* Только если меньше контейнера */
    font-size: 70px;
    font-weight: 600;
    /* Только если меньше контейнера */

    margin-bottom: 20px;
}
@media (max-width: 1320px)
{
    .clients-result .percent
    {
        font-size: calc(50px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .clients-result .percent
    {
        margin-bottom: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
.clients-result .number_text
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 400;

    width: 100%;
    max-width: 270px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .clients-result .number_text
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.clients-result .number_link
{
    font-size: 18px;
    font-weight: 600;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 8px;
}
@media (max-width: 576px)
{
    .clients-result .number_link
    {
        margin-top: 10px;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.clients-result .number_link span
{
    white-space: nowrap;
}
.clients-result .number_link .icon
{
    min-width: 13px;
    max-width: 13px;
    min-height: 11px;
    max-height: 11px;

    color: var(--blue);

    rotate: 180deg;
}
@media (hover: hover)
{
    .clients-result .number_link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--hover-blue);
    }
    .clients-result .number_link:hover .icon
    {
        color: var(--hover-blue);
    }
}
@media (hover: none)
{
    .clients-result .number_link:active
    {
        color: var(--hover-blue);
    }
    .clients-result .number_link:active .icon
    {
        color: var(--hover-blue);
    }
}
.clients-result .btn
{
    font-weight: 400;
}

.contacts
{
    position: relative;

    overflow: hidden;

    width: calc(100% - 20px);
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-bottom: 50px;

    border-radius: 5px 30px 0 0;
    background: linear-gradient(175.44deg, #f8f8f8 3.63%, rgba(248, 248, 248, 0) 85.79%);
}
@media (max-width: 1320px)
{
    .contacts
    {
        padding-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
.contacts::before
{
    position: absolute;
    /* Только если меньше контейнера */
    top: -1100px;
    left: 0;

    width: 100%;
    height: auto;

    content: '';

    border-radius: 50%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(-.96%, rgba(255, 255, 255, .5)), color-stop(45.36%, rgba(255, 255, 255, 0)));
    background: linear-gradient(360deg, rgba(255, 255, 255, .5) -.96%, rgba(255, 255, 255, 0) 45.36%);

    aspect-ratio: 1/1;
}
@media (max-width: 1320px)
{
    .contacts::before
    {
        top: calc(300px + -1400 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .contacts::before
    {
        top: -100px;
        left: -50%;

        width: 638px;

        -webkit-transform: translateX(-20%);
                transform: translateX(-20%);
    }
}
.contacts_content
{
    position: relative;
    z-index: 1;
}
.contacts_title
{
    text-align: center;

    color: var(--blue);
}
.contacts_card-wrap
{
    display: grid;

    width: 100%;
    max-width: 900px;
    margin: 0 auto 50px auto;

    grid-template: 1fr/repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    justify-items: center;
}
@media (max-width: 768px)
{
    .contacts_card-wrap
    {
        grid-template: 1fr/repeat(auto-fit, minmax(300px, 1fr));
    }
}
.contacts_card
{
    width: 100%;
    max-width: 440px;
    padding: 25px;

    border-radius: 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.contacts_card-title
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 25px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.contacts_card-title .icon
{
    width: 24px;
    height: 24px;

    color: var(--blue);
}
.contacts_card-title span
{
    font-size: 18px;
    font-weight: 700;

    color: var(--black2F);
}
.contacts_card-text p,
.contacts_card-text a
{
    /* Только если меньше контейнера */
    font-size: 22px;
    line-height: 1.2727272727;
}
@media (max-width: 1320px)
{
    .contacts_card-text p,
    .contacts_card-text a
    {
        font-size: calc(18px + 4 * (100vw - 360px) / 960);
    }
}
.contacts_card-text a
{
    color: var(--blue);
}
.contacts_card-text p
{
    color: var(--grey);
}
.contacts_card-tip
{
    font-size: 12px;
    line-height: 1.4285714286;

    margin-top: 20px;

    color: var(--grey);
}
.contacts .contacts-social_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 60px;
}
@media (max-width: 992px)
{
    .contacts .contacts-social_wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.contacts .contacts-social_media,
.contacts .contacts-social_messengers
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}
@media (max-width: 576px)
{
    .contacts .contacts-social_media,
    .contacts .contacts-social_messengers
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.contacts .media_title
{
    font-size: 18px;
}
.contacts .media_link-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
}
.contacts .media_link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 60px;
    height: 60px;

    border-radius: 50%;
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.contacts .media_link img
{
    width: 24px;
    height: 24px;
}
.contacts_offices
{
    /* Только если меньше контейнера */
    margin-bottom: 60px;
}
@media (max-width: 1320px)
{
    .contacts_offices
    {
        margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.contacts .offices-title
{
    /* Только если меньше контейнера */
    font-size: 28px;
    /* Только если меньше контейнера */

    margin-bottom: 60px;

    text-align: center;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .contacts .offices-title
    {
        font-size: calc(20px + 8 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contacts .offices-title
    {
        margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.contacts .offices_col-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 50px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.contacts .offices_col b
{
    /* Только если меньше контейнера */
    font-size: 18px;

    display: inline-block;

    margin-bottom: 20px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .contacts .offices_col b
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.contacts .offices_number
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}
.contacts .offices_number:not(:last-child)
{
    margin-bottom: 20px;
}
.contacts .offices_number .icon
{
    width: 24px;
    height: 24px;

    color: var(--blue);
}
.contacts .offices_number a
{
    font-size: 18px;

    color: var(--blue);
}

.contacts_ua .contacts_card-wrap
{
    /* Только если меньше контейнера */
    margin-bottom: 60px;
}
@media (max-width: 1320px)
{
    .contacts_ua .contacts_card-wrap
    {
        margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.contacts_ua .contacts-social_wrap
{
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    padding-top: 60px;

    border-top: 1px solid rgba(128, 128, 128, .2);

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 1320px)
{
    .contacts_ua .contacts-social_wrap
    {
        padding-top: calc(40px + 20 * (100vw - 360px) / 960);
    }
}

.contacts_ua-v2 .contacts_card-wrap
{
    max-width: 980px;

    grid-template: 1fr/repeat(auto-fit, minmax(300px, 1fr));
}
.contacts_ua-v2 .contacts-social_wrap
{
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    padding-top: 60px;

    border-top: 1px solid rgba(128, 128, 128, .2);

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 1320px)
{
    .contacts_ua-v2 .contacts-social_wrap
    {
        padding-top: calc(40px + 20 * (100vw - 360px) / 960);
    }
}
.contacts_ua-v2 .contacts_number
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    margin-bottom: 60px;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}
@media (max-width: 1320px)
{
    .contacts_ua-v2 .contacts_number
    {
        margin-bottom: calc(30px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .contacts_ua-v2 .contacts_number
    {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 30px;
    }
}
.contacts_ua-v2 .number_col
{
    width: 29%;
}
@media (max-width: 992px)
{
    .contacts_ua-v2 .number_col
    {
        width: 48%;

        text-align: center;
    }
}
@media (max-width: 768px)
{
    .contacts_ua-v2 .number_col
    {
        width: 100%;
    }
}
@media (max-width: 576px)
{
    .contacts_ua-v2 .number_col
    {
        text-align: start;
    }
}
.contacts_ua-v2 .number_title
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 25px;

    text-align: start;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 5px;
}
@media (max-width: 992px)
{
    .contacts_ua-v2 .number_title
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media (max-width: 576px)
{
    .contacts_ua-v2 .number_title
    {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
.contacts_ua-v2 .number_title .icon
{
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;

    color: var(--blue);
}
.contacts_ua-v2 .number_title span
{
    font-size: 18px;
    font-weight: 700;
}
.contacts_ua-v2 .number_body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
@media (max-width: 992px)
{
    .contacts_ua-v2 .number_body
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media (max-width: 576px)
{
    .contacts_ua-v2 .number_body
    {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
.contacts_ua-v2 .number_body span
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .contacts_ua-v2 .number_body span
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.contacts_ua-v2 .numbers_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 15px;
}
.contacts_ua-v2 .numbers_wrap a
{
    font-size: 18px;

    display: block;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;

    color: var(--blue);
}
.contacts_ua-v2 .numbers_wrap p
{
    font-size: 12px;
    line-height: 1.5;

    color: var(--grey);
}

.pricing
{
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .pricing
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.pricing .container
{
    position: relative;
}
.pricing_content
{
    position: relative;

    overflow: hidden;

    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    margin-bottom: 0;
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-bottom: 60px;

    border-radius: 5px 30px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(3.63%, #f8f8f8), color-stop(85.79%, rgba(248, 248, 248, 0)));
    background: linear-gradient(180deg, #f8f8f8 3.63%, rgba(248, 248, 248, 0) 85.79%);
}
@media (max-width: 1320px)
{
    .pricing_content
    {
        padding-bottom: calc(30px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .pricing_content
    {
        margin-bottom: calc(60px + -60 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .pricing_content
    {
        background-color: var(--whiteF8);
    }
}
.pricing_content::before
{
    position: absolute;
    /* Только если меньше контейнера */
    top: -1100px;
    left: 0;

    width: 100%;
    height: auto;

    content: '';

    border-radius: 50%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(-.96%, rgba(255, 255, 255, .5)), color-stop(45.36%, rgba(255, 255, 255, 0)));
    background: linear-gradient(360deg, rgba(255, 255, 255, .5) -.96%, rgba(255, 255, 255, 0) 45.36%);

    aspect-ratio: 1/1;
}
@media (max-width: 1320px)
{
    .pricing_content::before
    {
        top: calc(300px + -1400 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .pricing_content::before
    {
        top: -100px;
        left: -50%;

        width: 638px;

        -webkit-transform: translateX(-20%);
                transform: translateX(-20%);
    }
}
.pricing_text-wrap
{
    width: 100%;
    max-width: 720px;
    margin: 0 auto;

    text-align: center;
}
.pricing_title
{
    width: 100%;
    max-width: 520px;
    margin-bottom: 40px;
}
@media (max-width: 576px)
{
    .pricing_title
    {
        text-align: start;
    }
}
.pricing_descr
{
    /* Только если меньше контейнера */
    font-size: 18px;
    line-height: 1.4444444444;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .pricing_descr
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .pricing_descr
    {
        text-align: start;
    }
}
.pricing .tabs__head
{
    width: 100%;
    max-width: 735px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    margin-bottom: 50px;
}
@media (max-width: 1320px)
{
    .pricing .tabs__head
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
.pricing .tabs__head-row
{
    overflow: visible;

    margin: 0;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
}
@media (max-width: 576px)
{
    .pricing .tabs__head-row
    {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
.pricing .tabs__head-col
{
    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
}
.pricing .tabs__btn
{
    /* Только если меньше контейнера */
    font-size: 18px;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
}
@media (max-width: 1320px)
{
    .pricing .tabs__btn
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.pricing .plan
{
    width: 100%;
}
.pricing .plan_head
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    /* Только если меньше контейнера */
    margin-bottom: 20px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
}
@media (max-width: 1320px)
{
    .pricing .plan_head
    {
        margin-bottom: calc(30px + -10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .pricing .plan_head
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.pricing .plan_head b
{
    font-size: 14px;

    color: var(--black2F);
}
.pricing .plan_currency,
.pricing .plan_payment
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}
@media (max-width: 576px)
{
    .pricing .plan_currency,
    .pricing .plan_payment
    {
            flex-direction: column;

        width: 100%;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 15px;
    }
}
.pricing .plan .currency-dropdown
{
    position: relative;
}
@media (max-width: 576px)
{
    .pricing .plan .currency-dropdown
    {
        width: 100%;
    }
}
.pricing .plan .currency-dropdown .dropdown-button
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100px;
    padding: 15px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 1px solid transparent;
    border-radius: 50px;
    background-color: var(--whiteF8);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}
@media (max-width: 576px)
{
    .pricing .plan .currency-dropdown .dropdown-button
    {
        width: 100%;
    }
}
.pricing .plan .currency-dropdown .dropdown-button.active
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
.pricing .plan .currency-dropdown .dropdown-button.active .icon
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    rotate: 180deg;
}
.pricing .plan .currency-dropdown .dropdown-button span
{
    font-size: 14px;
    font-weight: 600;

    color: var(--grey);
}
.pricing .plan .currency-dropdown .dropdown-button .icon
{
    width: 6px;
    height: 4px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
@media (hover: hover)
{
    .pricing .plan .currency-dropdown .dropdown-button:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        border-color: var(--blue);
    }
}
.pricing .plan .currency-dropdown .dropdown-list
{
    position: absolute;
    z-index: 1;

    visibility: hidden;

    width: 100%;
    height: 0;
    margin-top: 5px;
    padding: 0;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    border-radius: 10px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.pricing .plan .currency-dropdown .dropdown-list.open
{
    visibility: visible;

    height: auto;
    padding: 5px 0;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.pricing .plan .currency-dropdown .dropdown-list.open .dropdown-item
{
    font-size: 14px;

    height: auto;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.pricing .plan .currency-dropdown .dropdown-item
{
    font-size: 0;
    font-weight: 600;

    height: 0;
    padding: 10px 15px;

    cursor: pointer;
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    color: var(--grey);
}
.pricing .plan .currency-dropdown .dropdown-item.current-item
{
    color: var(--blue);
}
@media (hover: hover)
{
    .pricing .plan .currency-dropdown .dropdown-item:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--blue);
        background-color: var(--whiteF8);
    }
}
.pricing .plan .payment_toggler-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}
.pricing .plan .payment_toggler-wrap .plan_text
{
    font-size: 14px;
    font-weight: 600;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--grey);
}
.pricing .plan .payment_toggler-wrap .plan_text i
{
    font-size: 13px;
}
.pricing .plan .payment_toggler-wrap .annual
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 5px;
}
.pricing .plan .payment_toggler-wrap .current_plan
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
.pricing .plan .payment_toggler-wrap .payment_toggler
{
    position: relative;

    width: 40px;
    height: 10px;

    border-radius: 50px;
    background-color: var(--blueCE);
}
.pricing .plan .payment_toggler-wrap .payment_toggler.toggle::before
{
    right: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
}
.pricing .plan .payment_toggler-wrap .payment_toggler::before
{
    position: absolute;
    top: 50%;
    right: 0;

    width: 20px;
    height: 20px;

    content: '';
    cursor: pointer;
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

    border-radius: 50%;
    background-color: var(--blue);
}
.pricing .plan .body_tip
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 30px;
    padding: 20px;

    border-radius: 10px;
    background-color: rgba(232, 181, 36, .1490196078);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
}
@media (max-width: 576px)
{
    .pricing .plan .body_tip
    {
            flex-direction: column;

        text-align: center;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.pricing .plan .body_tip .icon
{
    width: 30px;
    height: 30px;

    color: var(--yellow);
}
.pricing .plan .body_tip p
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .pricing .plan .body_tip p
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.pricing .plan_cards-wrap
{
    display: grid;
    /* Только если меньше контейнера */

    margin-bottom: 100px;

    grid-template: 1fr/repeat(auto-fit, minmax(330px, 1fr));
    row-gap: 30px;
    /* Только если меньше контейнера */
    -webkit-column-gap: 30px;
       -moz-column-gap: 30px;
            column-gap: 30px;
}
@media (max-width: 1320px)
{
    .pricing .plan_cards-wrap
    {
        -webkit-column-gap: calc(0px + 30 * (100vw - 360px) / 960);
           -moz-column-gap: calc(0px + 30 * (100vw - 360px) / 960);
                column-gap: calc(0px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .pricing .plan_cards-wrap
    {
        margin-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .pricing .plan_cards-wrap
    {
        grid-template: 1fr/1fr;
    }
}
.pricing .plan_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    height: 100%;
    padding: 35px 25px;

    border-radius: 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
.pricing .plan_card:nth-child(1)
{
    --card-color: #007bb3 ;
}
.pricing .plan_card:nth-child(2)
{
    --card-color: #e8b524 ;
}
.pricing .plan_card:nth-child(3)
{
    --card-color: #03c15c ;
}
.pricing .plan_card .card_head
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 25px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
}
.pricing .plan_card .card_head .icon
{
    width: 35px;
    height: 35px;

    opacity: .5;
    color: var(--card-color);
}
.pricing .plan_card .card_head .popular
{
    font-size: 14px;

    height: 35px;
    padding: 7px 15px;

    color: var(--card-color);
    border: 1px solid var(--card-color);
    border-radius: 50px;
}
.pricing .plan_card .card_title
{
    /* Только если меньше контейнера */
    font-size: 22px;

    margin-bottom: 25px;

    color: var(--black05);
}
@media (max-width: 1320px)
{
    .pricing .plan_card .card_title
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.pricing .plan_card .card_descr
{
    font-size: 14px;
    line-height: 1.4285714286;

    margin-bottom: 25px;

    color: var(--grey);
}
.pricing .plan_card .card_price-wrap
{
    margin-bottom: 25px;
    padding-bottom: 25px;

    border-bottom: 1px solid rgba(88, 89, 91, .3019607843);
}
.pricing .plan_card .price-wrap
{
    margin-bottom: 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    gap: 10px;
}
.pricing .plan_card .price-wrap.toggle-price
{
    display: none;

    opacity: 0;
}
.pricing .plan_card .price-wrap.current-price-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.pricing .plan_card .current-price span
{
    font-size: 40px;
    font-weight: 700;

    color: var(--card-color);
}
.pricing .plan_card .current-price span::before
{
    margin-right: -10px;

    content: var(--currency-symbol);
}
.pricing .plan_card .current-price small
{
    font-size: 28px;
    font-weight: 700;

    margin-right: 10px;

    color: var(--card-color);
}
@media (max-width: 576px)
{
    .pricing .plan_card .current-price small
    {
        font-size: 32px;
    }
}
.pricing .plan_card .old-price
{
    position: relative;

    margin-bottom: 5px;
}
.pricing .plan_card .old-price span
{
    font-size: 28px;

    opacity: .5;
    color: var(--grey);
}
.pricing .plan_card .old-price span::before
{
    margin-right: -10px;

    content: var(--currency-symbol);
}
.pricing .plan_card .old-price::before
{
    position: absolute;
    bottom: 50%;
    left: 0;

    width: 100%;
    height: 2px;

    content: '';

    background-color: var(--card-color);

    rotate: -20deg;
}
.pricing .plan_card .price-descr,
.pricing .plan_card .save-descr
{
    font-size: 13px;
    font-style: italic;

    -webkit-transition: all .3s;
            transition: all .3s;

    opacity: 1;
    color: var(--grey);
}
.pricing .plan_card .price-descr.save-descr_hide,
.pricing .plan_card .save-descr.save-descr_hide
{
    margin-top: 0;

    opacity: 0;
}
.pricing .plan_card .price-descr span,
.pricing .plan_card .save-descr span
{
    font-weight: 700;
}
.pricing .plan_card .price-descr span::before,
.pricing .plan_card .save-descr span::before
{
    margin-right: -2px;

    content: var(--currency-symbol);
}
.pricing .plan_card .save-descr
{
    margin-top: 10px;
}
.pricing .plan_card .card_list-wrap
{
    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
}
.pricing .plan_card .card_list-wrap span
{
    font-size: 14px;
    font-weight: 600;

    display: inline-block;

    margin-bottom: 20px;

    color: var(--grey);
}
.pricing .plan_card .card_list
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    margin-bottom: 20px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Только если меньше контейнера */
    row-gap: 20px;
}
@media (max-width: 1320px)
{
    .pricing .plan_card .card_list
    {
        row-gap: calc(15px + 5 * (100vw - 360px) / 960);
    }
}
.pricing .plan_card .list_item
{
    font-size: 14px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    color: var(--grey);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.pricing .plan_card .list_item b
{
    font-weight: 600;
}
.pricing .plan_card .list_item .icon-check_2
{
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;

    color: var(--card-color);
}
.pricing .plan_card .list_item .icon-help
{
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;

    opacity: .3;
    color: var(--blue);
}
.pricing .plan_card .tooltip
{
    position: relative;

    margin-left: auto;

    cursor: pointer;
}
.pricing .plan_card .tooltip:hover .tooltip_content
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.pricing .plan_card .tooltip:hover .icon
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
.pricing .plan_card .tooltip_content
{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285714286;

    position: absolute;
    z-index: 3;
    top: -80px;
    right: 0;

    visibility: hidden;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    padding: 15px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    color: var(--grey);
    border-radius: 10px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
}
@media (max-width: 768px)
{
    .pricing .plan_card .tooltip_content.second_content
    {
        right: 0;
        left: auto;
    }
}
.pricing .plan_card .tooltip_content b
{
    font-weight: 600;
}
.pricing .plan_card .show_btn
{
    font-size: 14px;
    font-weight: 700;

    display: block;

    margin: 20px auto 40px auto;
    /* Только если меньше контейнера */
    margin-bottom: 40px;

    text-align: center;
    text-decoration: underline;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .pricing .plan_card .show_btn
    {
        margin-bottom: calc(25px + 15 * (100vw - 360px) / 960);
    }
}
.pricing .plan_card .btn_pimary
{
    width: 100%;
    margin-bottom: 25px;

    background-color: var(--card-color);
}
.pricing .plan_card .buy_btn
{
    font-size: 18px;
    font-weight: 700;

    display: block;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    margin: 0 auto;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    text-align: center;
    text-decoration: underline;

    opacity: .5;
    color: var(--card-color);
}
@media (hover: hover)
{
    .pricing .plan_card .buy_btn:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        opacity: 1;
    }
}
.pricing .plan_card .card_tip
{
    font-size: 13px;
    line-height: 1.3846153846;

    margin-top: 32px;

    color: var(--grey);
}
.pricing .plan_card .card_tip a
{
    font-weight: 600;

    color: var(--blue);
}
.pricing .plan_subtitle
{
    font-size: 22px;
    font-weight: 700;
}
.pricing .contact-center_body .contact-center_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}
@media (max-width: 992px)
{
    .pricing .contact-center_body .contact-center_wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 50px;
    }
}
.pricing .contact-center_body .plan_card
{
    width: 100%;
    max-width: 480px;
    min-height: -webkit-max-content;
    min-height:    -moz-max-content;
    min-height:         max-content;
}
.pricing .contact-center_body .accordion-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    max-width: 630px;
    padding-top: 35px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Только если меньше контейнера */
    gap: 40px;
}
@media (max-width: 1320px)
{
    .pricing .contact-center_body .accordion-wrap
    {
        gap: calc(30px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .pricing .contact-center_body .accordion-wrap
    {
        padding: 0;
    }
}
.pricing .contact-center_body .accordion__item
{
    overflow: visible;

    width: 100%;

    border-radius: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.pricing .contact-center_body .accordion__item:not(:last-child)
{
    /* Только если меньше контейнера */
    margin-bottom: 20px;
}
@media (max-width: 1320px)
{
    .pricing .contact-center_body .accordion__item:not(:last-child)
    {
        margin-bottom: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
.pricing .contact-center_body .accordion__toggler
{
    min-height: 70px;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
.pricing .contact-center_body .accordion__toggler:hover
{
    background-color: var(--blue);
}
.pricing .contact-center_body .accordion__content
{
    margin-top: 45px;
    padding: 0;

    counter-reset: num;
}
.pricing .contact-center_body .accordion__content .item_wrap:first-child
{
    padding-bottom: 20px;

    border-bottom: 1px solid rgba(88, 89, 91, .2);
}
.pricing .contact-center_body .accordion__content .item_wrap:not(:last-child)
{
    margin-bottom: 20px;
    padding-bottom: 20px;

    border-bottom: 1px solid rgba(88, 89, 91, .2);
}
.pricing .contact-center_body .accordion__content .item_title
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 400;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 20px;

    counter-increment: num;

    color: var(--black2F);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}
@media (max-width: 1320px)
{
    .pricing .contact-center_body .accordion__content .item_title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.pricing .contact-center_body .accordion__content .item_title::before
{
    font-size: 18px;

    content: '0' counter(num);

    opacity: .5;
    color: var(--grey);
}
.pricing .contact-center_body .accordion__content .item_text
{
    font-size: 14px;

    color: var(--grey);
}

.addons
{
    /* Только если меньше контейнера */
    padding-top: 100px;
}
@media (max-width: 1320px)
{
    .addons
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.addons_content
{
    display: grid;

    counter-reset: num;

    grid-template: 1fr/repeat(auto-fit, minmax(330px, 1fr));
    gap: 20px;
}
@media (max-width: 576px)
{
    .addons_content
    {
        grid-template: 1fr/1fr;
    }
}
.addons_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    padding: 25px;

    border-radius: 5px 30px;
    background-color: var(--whiteF8);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
.addons_card:nth-child(4n+1) .card_number
{
    background-color: var(--blue);
}
.addons_card:nth-child(4n+2) .card_number
{
    background-color: var(--yellow);
}
.addons_card:nth-child(4n+3) .card_number
{
    background-color: var(--green);
}
.addons_card:nth-child(4n+4) .card_number
{
    background-color: var(--black2F);
}
.addons .card_head
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 25px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 15px;
}
@media (max-width: 576px)
{
    .addons .card_head
    {
        gap: 25px;
    }
}
.addons .card_number
{
    display: grid;
    overflow: hidden;

    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;

    counter-increment: num;

    border-radius: 50%;

    place-items: center;
}
.addons .card_number::before
{
    font-size: 18px;
    font-weight: 700;

    content: '0' counter(num);

    color: var(--white);
}
.addons .card_toogler-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}
.addons .card_toggler-item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}
.addons .card_toggler-item.active span
{
    color: var(--blue);
}
.addons .card_toggler-item span
{
    font-size: 14px;
    font-weight: 600;

    text-transform: capitalize;

    color: var(--grey);
}
.addons .card_toggler-item .icon
{
    width: 17px;
    height: 17px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: #c6dfea;
}
.addons .card_toggler-item .tooltip
{
    position: relative;

    cursor: pointer;
}
.addons .card_toggler-item .tooltip:hover .tooltip_content
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.addons .card_toggler-item .tooltip:hover .icon
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
.addons .card_toggler-item .tooltip_content
{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285714286;

    position: absolute;
    z-index: 3;
    top: -80px;
    left: 0;

    visibility: hidden;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    padding: 15px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    color: var(--grey);
    border-radius: 10px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
}
@media (max-width: 768px)
{
    .addons .card_toggler-item .tooltip_content.second_content
    {
        right: 0;
        left: auto;
    }
}
.addons .card_toggler-item .tooltip_content b
{
    font-weight: 600;
}
.addons .card_toggler
{
    position: relative;

    width: 40px;
    height: 10px;

    border-radius: 50px;
    background-color: var(--blueCE);
}
.addons .card_toggler span
{
    position: absolute;
    top: 50%;
    left: 0;

    width: 20px;
    height: 20px;

    cursor: pointer;
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

    border-radius: 50%;
    background-color: var(--blue);
}
.addons .card_toggler span.active
{
    left: 20px;
}
.addons .card_title-wrap
{
    margin-bottom: 25px;
}
.addons .card_title-wrap i
{
    font-size: 13px;
}
.addons .card_title
{
    /* Только если меньше контейнера */
    font-size: 22px;
    font-weight: 700;

    margin-bottom: 10px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .addons .card_title
    {
        font-size: calc(20px + 2 * (100vw - 360px) / 960);
    }
}
.addons .card_text
{
    font-size: 14px;
    line-height: 1.4285714286;

    margin-bottom: 25px;

    color: var(--grey);

    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
}
.addons .card_price
{
    /* Только если меньше контейнера */
    font-size: 40px;
    font-weight: 700;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    margin-bottom: 25px;

    color: var(--blue);
}
@media (max-width: 1320px)
{
    .addons .card_price
    {
        font-size: calc(28px + 12 * (100vw - 360px) / 960);
    }
}
.addons .card_price span
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.addons .card_price span::before
{
    content: var(--currency-symbol);
}
.addons .btn
{
    font-size: 14px;

    width: 100%;
    height: 40px;
    padding: 15px;
}

.pricing-feature
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    background-color: var(--whiteF8);
}
@media (max-width: 1320px)
{
    .pricing-feature
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .pricing-feature
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.pricing-feature_head-row
{
    display: grid;

    margin-bottom: 25px;

    grid-template: 1fr/auto auto 23% 23% 23%;
}
.pricing-feature_head-row.top_row
{
    margin-bottom: 20px;
}
@media (max-width: 992px)
{
    .pricing-feature_head-row.top_row
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: row;

        height: 100%;
        margin-bottom: 25px;

        grid-template: 1fr/auto auto auto;
        gap: 15px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .pricing-feature_head-row.top_row b
    {
        min-height: 80px;
    }
    .pricing-feature_head-row.top_row b:nth-child(1)
    {
        display: none;
    }
    .pricing-feature_head-row.top_row b:nth-child(2),
    .pricing-feature_head-row.top_row b:nth-child(3),
    .pricing-feature_head-row.top_row b:nth-child(4)
    {
        display: grid;

        height: 100%;
        padding: 15px 10px;

        border-radius: 10px;
        background-color: var(--white);

        place-items: center;
    }
    .pricing-feature_head-row.top_row b:nth-child(2)
    {
        color: var(--blue);
    }
    .pricing-feature_head-row.top_row b:nth-child(3)
    {
        color: var(--yellow);
    }
    .pricing-feature_head-row.top_row b:nth-child(4)
    {
        color: var(--green);
    }
}
@media (max-width: 992px)
{
    .pricing-feature_head-row.bottom_row
    {
        grid-template: 1fr/auto auto auto;
        gap: 15px;
    }
    .pricing-feature_head-row.bottom_row .row-title:nth-child(1)
    {
        grid-column: 1/4;
    }
    .pricing-feature_head-row.bottom_row .row-title:nth-child(2),
    .pricing-feature_head-row.bottom_row .row-title:nth-child(3),
    .pricing-feature_head-row.bottom_row .row-title:nth-child(4)
    {
        min-width: 100px;
        padding: 10px 0;

        border-radius: 10px;
        background-color: var(--white);
    }
    .pricing-feature_head-row.bottom_row .tooltip_content
    {
        right: auto;
        left: 0;
    }
}
@media (max-width: 360px)
{
    .pricing-feature_head-row.bottom_row .row-title:nth-child(2),
    .pricing-feature_head-row.bottom_row .row-title:nth-child(3),
    .pricing-feature_head-row.bottom_row .row-title:nth-child(4)
    {
        min-width: auto;
    }
}
.pricing-feature_head-row b
{
    /* Только если меньше контейнера */
    font-size: 22px;

    width: 100%;
    max-width: 220px;
    margin: 0 auto;

    text-align: center;
}
@media (max-width: 1320px)
{
    .pricing-feature_head-row b
    {
        font-size: calc(14px + 8 * (100vw - 360px) / 960);
    }
}
.pricing-feature_head-row b:nth-child(1)
{
    max-width: 100%;

    text-align: start;

    grid-column: 1/3;
}
@media (max-width: 992px)
{
    .pricing-feature_head-row b
    {
        max-width: 100%;
    }
}
.pricing-feature_head-row .row-title
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 250px;
    margin: 0 auto;

    text-align: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
}
@media (max-width: 1320px)
{
    .pricing-feature_head-row .row-title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.pricing-feature_head-row .row-title:nth-child(1)
{
    max-width: 100%;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    grid-column: 1/3;
}
.pricing-feature_head-row .row-title:nth-child(3)
{
    max-width: 200px;
}
@media (max-width: 992px)
{
    .pricing-feature_head-row .row-title:nth-child(3)
    {
        max-width: 100%;
    }
}
@media (max-width: 992px)
{
    .pricing-feature_head-row .row-title
    {
        max-width: 100%;
    }
}
.pricing-feature .accordion__item
{
    overflow: visible;

    width: 100%;

    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.pricing-feature .accordion__item:not(:last-child)
{
    /* Только если меньше контейнера */
    margin-bottom: 20px;
}
@media (max-width: 1320px)
{
    .pricing-feature .accordion__item:not(:last-child)
    {
        margin-bottom: calc(10px + 10 * (100vw - 360px) / 960);
    }
}
.pricing-feature .accordion__item.active
{
    background-color: transparent;
}
.pricing-feature .accordion__item:hover
{
    background-color: transparent;
}
.pricing-feature .accordion__item:hover .accordion__toggler
{
    background-color: var(--blue);
}
.pricing-feature .accordion__toggler
{
    min-height: -webkit-max-content;
    min-height:    -moz-max-content;
    min-height:         max-content;
    padding: 25px;

    border-radius: 5px 30px 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
}
.pricing-feature .accordion__content
{
    padding: 45px 0 0 0;
}
.pricing-feature .list_tip
{
    font-size: 14px;

    width: 100%;
    max-width: 230px;
    margin-bottom: 20px;
    margin-left: auto;

    color: var(--grey);
}
.pricing-feature_content
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    counter-reset: num;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.pricing-feature_content::before
{
    position: absolute;
    top: 12px;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';

    background-color: rgba(88, 89, 91, .2);
}
.pricing-feature_content::after
{
    position: absolute;
    bottom: 12px;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';

    background-color: rgba(88, 89, 91, .2);
}
.pricing-feature .icon
{
    width: 20px;
    height: 20px;
}
.pricing-feature .col_item
{
    position: relative;

    display: grid;

    width: 100%;

    counter-increment: num;

    grid-template: 1fr/65px 275px auto auto auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* Только если меньше контейнера */
    gap: 20px;
}
@media (max-width: 1320px)
{
    .pricing-feature .col_item
    {
        gap: calc(8px + 12 * (100vw - 360px) / 960);
    }
}
@media (max-width: 768px)
{
    .pricing-feature .col_item
    {
        grid-template: 1fr/50px auto 75px 75px 75px;
    }
}
@media (max-width: 576px)
{
    .pricing-feature .col_item
    {
        grid-template: 1fr/25px auto 50px 75px;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.pricing-feature .col_item:first-child::before
{
    padding-top: 28px;
}
@media (max-width: 768px)
{
    .pricing-feature .col_item:first-child::before
    {
        padding-top: 15px;
    }
}
.pricing-feature .col_item:first-child .icon-check_wrap
{
    padding-top: 28px;

    border-radius: 30px 30px 0 0;
}
@media (max-width: 576px)
{
    .pricing-feature .col_item:first-child .icon-check_wrap
    {
        padding-top: 15px;
    }
}
.pricing-feature .col_item:first-child .icon-cross_wrap,
.pricing-feature .col_item:first-child .title-wrap
{
    padding-top: 28px;
}
@media (max-width: 576px)
{
    .pricing-feature .col_item:first-child .icon-cross_wrap,
    .pricing-feature .col_item:first-child .title-wrap
    {
        padding-top: 15px;
    }
}
.pricing-feature .col_item:nth-child(-n+10)::before
{
    content: '0' counter(num);
}
.pricing-feature .col_item:nth-child(n+10)::before
{
    content: counter(num);
}
.pricing-feature .col_item:last-child::before
{
    padding-bottom: 28px;
}
@media (max-width: 576px)
{
    .pricing-feature .col_item:last-child::before
    {
        padding-bottom: 15px;
    }
}
.pricing-feature .col_item:last-child .icon-cross_wrap,
.pricing-feature .col_item:last-child .title-wrap
{
    padding-bottom: 28px;
}
@media (max-width: 576px)
{
    .pricing-feature .col_item:last-child .icon-cross_wrap,
    .pricing-feature .col_item:last-child .title-wrap
    {
        padding-bottom: 15px;
    }
}
.pricing-feature .col_item::before
{
    /* Только если меньше контейнера */
    font-size: 18px;

    padding: 17px 0;

    opacity: .5;
    color: var(--grey);
}
@media (max-width: 1320px)
{
    .pricing-feature .col_item::before
    {
        font-size: calc(14px + 4 * (100vw - 360px) / 960);
    }
}
@media (max-width: 400px)
{
    .pricing-feature .col_item::before
    {
        padding-top: 15px;
    }
}
.pricing-feature .col_item:not(:last-child)::after
{
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';

    background-color: rgba(88, 89, 91, .2);
}
.pricing-feature .col_item .title-wrap
{
    /* Только если меньше контейнера */
    font-size: 18px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 275px;
    padding: 17px 0;

    color: var(--grey);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 10px;
}
@media (max-width: 1320px)
{
    .pricing-feature .col_item .title-wrap
    {
        font-size: calc(14px + 4 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .pricing-feature .col_item .title-wrap
    {
        padding: 14px 0;
    }
}
.pricing-feature .col_item .title-wrap p
{
    /* Только если меньше контейнера */
    font-size: 18px;

    color: var(--grey);
}
@media (max-width: 1320px)
{
    .pricing-feature .col_item .title-wrap p
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.pricing-feature .col_item .icon-check_wrap,
.pricing-feature .col_item .icon-cross_wrap
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 88px;
    height: 100%;
    margin: 0 auto;
    padding: 17px 0;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.pricing-feature .col_item .icon-check_wrap.bordered,
.pricing-feature .col_item .icon-cross_wrap.bordered
{
    padding-bottom: 28px;

    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}
@media (max-width: 576px)
{
    .pricing-feature .col_item .icon-check_wrap.bordered,
    .pricing-feature .col_item .icon-cross_wrap.bordered
    {
        padding-bottom: 15px;
    }
}
@media (max-width: 576px)
{
    .pricing-feature .col_item .icon-check_wrap,
    .pricing-feature .col_item .icon-cross_wrap
    {
        padding: 14px 0;
    }
}
.pricing-feature .col_item .icon-check_wrap
{
    background-color: var(--blue);
}
.pricing-feature .col_item .icon-check_wrap .icon
{
    color: var(--white);
}
.pricing-feature .col_item .icon-cross_wrap .icon
{
    color: var(--black2F);
}
@media (max-width: 650px)
{
    .pricing-feature .list_tip
    {
        display: none;
    }
    .pricing-feature_content::before,
    .pricing-feature_content::after
    {
        display: none;
    }
    .pricing-feature .col_item
    {
        margin-bottom: 25px;

        grid-template: auto 1fr/1fr 1fr 1fr;
        row-gap: 15px;
    }
    .pricing-feature .col_item::before,
    .pricing-feature .col_item::after
    {
        display: none;
    }
    .pricing-feature .col_item:first-child .title-wrap,
    .pricing-feature .col_item:last-child .title-wrap
    {
        padding: 0;
    }
    .pricing-feature .col_item .title-wrap
    {
        max-width: 100%;
        padding: 0;

        grid-column: -1/1;
    }
    .pricing-feature .col_item:first-child .icon-check_wrap
    {
        padding: 10px;

        border-radius: 10px;
        background-color: var(--white);
    }
    .pricing-feature .col_item:first-child .icon-check_wrap.blue .icon
    {
        color: var(--blue);
    }
    .pricing-feature .col_item .icon-check_wrap
    {
        max-width: 100%;
        height: auto;
        padding: 10px;

        border-radius: 10px;
        background-color: var(--white);
    }
    .pricing-feature .col_item .icon-check_wrap.bordered
    {
        padding: 10px;

        border-radius: 10px;
    }
    .pricing-feature .col_item .icon-check_wrap.blue .icon
    {
        color: var(--blue);
    }
    .pricing-feature .col_item .icon-check_wrap.yellow .icon
    {
        color: var(--yellow);
    }
    .pricing-feature .col_item .icon-check_wrap.green .icon
    {
        color: var(--green);
    }
    .pricing-feature .bottom_row
    {
        grid-template: auto 1fr/auto auto auto;
        gap: 15px 10px;
    }
    .pricing-feature .bottom_row span
    {
        font-weight: 500;
    }
    .pricing-feature .bottom_row span:nth-child(1)
    {
        grid-column: -1/1;
    }
}
.pricing-feature .tooltip
{
    position: relative;

    margin-bottom: 2px;

    cursor: pointer;
}
.pricing-feature .tooltip:hover .tooltip_content
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}
.pricing-feature .tooltip:hover .icon
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--blue);
}
.pricing-feature .tooltip .icon
{
    width: 17px;
    height: 17px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: #cee3ec;
}
.pricing-feature .tooltip_content
{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285714286;

    position: absolute;
    z-index: 3;
    top: -100%;
    left: 25px;

    visibility: hidden;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    max-width: 165px;
    padding: 15px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    color: var(--grey);
    border-radius: 10px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1019607843);
}
@media (max-width: 576px)
{
    .pricing-feature .tooltip_content
    {
        top: -65px;
        right: 0;
        left: auto;
    }
}
.pricing-feature .tooltip_content b
{
    font-weight: 600;
}

.orders
{
    /* Только если меньше контейнера */
    padding-top: 100px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    background-color: var(--blue);
}
@media (max-width: 1320px)
{
    .orders
    {
        padding-top: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .orders
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.orders__wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 976px;
    margin: 0 auto;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 50px;
}
@media (max-width: 1024px)
{
    .orders__wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        gap: 40px;
    }
}
.orders__text p
{
    /* Только если меньше контейнера */
    font-size: 28px;
    line-height: 128.571%;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .orders__text p
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
.orders__text p span
{
    font-weight: 400;
}
.orders .orders__image
{
    display: inline-block;

    width: 100%;
    margin-top: 68px;
}
@media (max-width: 768px)
{
    .orders .orders__image
    {
        max-width: 420px;
    }
}
.orders .orders__image .orders__img
{
    width: 100%;
    height: auto;
}
.orders .orders__form,
.orders .orders__content
{
    width: 100%;
    max-width: 465px;
}
@media (max-width: 1024px)
{
    .orders .orders__content
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: column-reverse;

        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        gap: 40px;
    }
    .orders .orders__image
    {
        margin: 0;
    }
}
.orders .input-wrap
{
    position: relative;

    margin-bottom: 25px;
}
.orders__form-text p
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 500;
    line-height: 144.4444444444%;

    margin-bottom: 40px;

    color: var(--white);
}
@media (max-width: 1320px)
{
    .orders__form-text p
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.orders .iti__selected-dial-code
{
    color: var(--white);
}

.orders_pricing .orders__text
{
    width: 100%;
    max-width: 430px;
}
.orders_pricing .form-wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
}
.orders_pricing .input-wrap:nth-child(1)
{
    width: 48%;

    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}
@media (max-width: 768px)
{
    .orders_pricing .input-wrap:nth-child(1)
    {
        width: 100%;
    }
}
.orders_pricing .input-wrap:nth-child(2)
{
    width: 100%;

    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}
.orders_pricing .input-wrap:nth-child(3)
{
    width: 48%;

    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}
@media (max-width: 768px)
{
    .orders_pricing .input-wrap:nth-child(3)
    {
        width: 100%;
    }
}
.orders_pricing .input-wrap:nth-child(4)
{
    width: 100%;

    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
}
.orders_pricing .textarea
{
    height: 120px;
    margin-bottom: 15px;

    border-radius: 30px;
}
.orders_pricing .check_wrap b
{
    text-decoration: none;
}

.contact-us-modal
{
    position: fixed;
    z-index: 5;
    top: 50%;
    left: 50%;

    visibility: hidden;

    width: calc(100% - 40px);
    max-width: 600px;
    height: -webkit-max-content;
    height:    -moz-max-content;
    height:         max-content;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    opacity: 0;
    border-radius: 30px;
    background-color: var(--white);

    scale: 1;
}
@media (max-width: 576px)
{
    .contact-us-modal
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.contact-us-modal.openContactForm
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;

    scale: 1;
}
.contact-us-modal.form_modal_pricing .contact-us-modal_title,
.contact-us-modal.form_modal_request .contact-us-modal_title
{
    max-width: 375px;
}
.contact-us-modal.form_modal_pricing .check_wrap,
.contact-us-modal.form_modal_request .check_wrap
{
    margin: 0;
}
.contact-us-modal.form_modal_pricing .check_wrap .custom-checkbox,
.contact-us-modal.form_modal_request .check_wrap .custom-checkbox
{
    background-color: var(--whiteF8);
}
.contact-us-modal.form_modal_pricing .check_wrap p,
.contact-us-modal.form_modal_pricing .check_wrap span,
.contact-us-modal.form_modal_request .check_wrap p,
.contact-us-modal.form_modal_request .check_wrap span
{
    color: var(--black2F);
}
.contact-us-modal.form_modal_pricing .check_wrap p ins,
.contact-us-modal.form_modal_pricing .check_wrap span ins,
.contact-us-modal.form_modal_request .check_wrap p ins,
.contact-us-modal.form_modal_request .check_wrap span ins
{
    font-weight: 600;

    color: var(--blue);
}
.contact-us-modal.form_modal_request .contact-us-modal_title
{
    max-width: 425px;
}
.contact-us-modal .contact-us-modal-body
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    height: 100%;
    /* Только если меньше контейнера */
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-right: 40px;
    /* Только если меньше контейнера */
    padding-bottom: 30px;
    /* Только если меньше контейнера */
    padding-left: 40px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1320px)
{
    .contact-us-modal .contact-us-modal-body
    {
        padding-top: calc(50px + -20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact-us-modal .contact-us-modal-body
    {
        padding-bottom: calc(25px + 5 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact-us-modal .contact-us-modal-body
    {
        padding-right: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact-us-modal .contact-us-modal-body
    {
        padding-left: calc(20px + 20 * (100vw - 360px) / 960);
    }
}
.contact-us-modal .close-form-btn
{
    position: absolute;
    z-index: 9;
    top: 18px;
    right: 18px;

    width: 20px;
    height: 20px;
}
.contact-us-modal .close-form-btn::after,
.contact-us-modal .close-form-btn::before
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 1px;

    content: '';

    background-color: var(--black2F);

    translate: -50% -50%;
}
.contact-us-modal .close-form-btn::after
{
    rotate: -45deg;
}
.contact-us-modal .close-form-btn::before
{
    rotate: 45deg;
}
.contact-us-modal_title
{
    /* Только если меньше контейнера */
    font-size: 18px;
    font-weight: 700;

    width: 100%;
    max-width: 332px;
    margin: 0 auto 25px auto;

    text-align: center;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .contact-us-modal_title
    {
        font-size: calc(16px + 2 * (100vw - 360px) / 960);
    }
}
.contact-us-modal_text
{
    font-size: 14px;
    font-weight: 600;

    width: 100%;
    max-width: 342px;
    margin: 0 auto 25px auto;

    text-align: center;

    color: var(--grey);
}
.contact-us-modal_form
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    row-gap: 35px;
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
}
@media (max-width: 768px)
{
    .contact-us-modal_form
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    }
}
.contact-us-modal .input_body
{
    position: relative;

    width: 100%;
}
.contact-us-modal .input_body::before
{
    position: absolute;
    top: 3px;
    left: 0;

    width: 6px;
    height: 6px;

    content: '*';

    color: var(--red);
}
.contact-us-modal_input
{
    font-size: 14px;

    width: 100%;
    padding: 25px 20px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--black);
    border: 1px solid transparent;
    border-radius: 500px;
    outline: none;
    background-color: var(--whiteF8);

    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}
.contact-us-modal_input:focus
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
.contact-us-modal_input:focus-visible
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border-color: var(--blue);
}
.contact-us-modal_input::-webkit-input-placeholder
{
    color: var(--grey);
}
.contact-us-modal_input::-moz-placeholder
{
    color: var(--grey);
}
.contact-us-modal_input:-ms-input-placeholder
{
    color: var(--grey);
}
.contact-us-modal_input::-ms-input-placeholder
{
    color: var(--grey);
}
.contact-us-modal_input::placeholder
{
    color: var(--grey);
}
.contact-us-modal .short_input
{
    width: 100%;
    max-width: 252px;
}
.contact-us-modal .iti-body .iti__arrow
{
    border-top: 4px solid var(--grey);
}
.contact-us-modal .iti__selected-dial-code
{
    color: var(--grey);
}
.contact-us-modal .form-btn
{
    font-weight: 400;

    width: -webkit-max-content;
    width:    -moz-max-content;
    width:         max-content;
    width: 153px;
    margin: 0 auto;
}
@media (max-width: 576px)
{
    .contact-us-modal .form-btn
    {
        width: 100%;
        height: 40px;
        margin-top: auto;
        margin-bottom: 25px;
    }
}
.contact-us-modal .textarea
{
    height: 120px;
    min-height: 120px;
    max-height: 220px;

    border-radius: 30px;
}
@media (max-width: 576px)
{
    .contact-us-modal .textarea
    {
        -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    }
}
@media (max-width: 768px)
{
    .contact-us-modal .short_input
    {
        max-width: 100%;
    }
}

@media (max-width: 480px)
{
    .contact-us-modal
    {
        overflow: auto;

        width: 100%;
        height: 100vh;

        border-radius: 0;
    }
}
.contact-form-container
{
    position: fixed;
    z-index: 4;

    visibility: hidden;

    width: 100%;
    height: 100%;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 0;
    background-color: rgba(0, 0, 0, .4);

    inset: 0;
}
.contact-form-container.showContactFormContainer
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;
}

.contact_page_form
{
    position: static;

    visibility: visible;

    width: calc(100% - 20px);
    max-width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 30px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;

    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);

    opacity: 1;
    border-radius: 0 0 5px 30px;
}
@media (max-width: 1320px)
{
    .contact_page_form
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.contact_page_form .contact-us-modal-body
{
    position: relative;

    overflow: hidden;

    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    /* Только если меньше контейнера */
    padding-top: 50px;
    /* Только если меньше контейнера */
    padding-right: 50px;
    /* Только если меньше контейнера */
    padding-bottom: 50px;
    /* Только если меньше контейнера */
    padding-left: 50px;

    border-radius: 5px 30px;
    background-color: var(--white);
    -webkit-box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px rgba(0, 0, 0, .1);
}
@media (max-width: 1320px)
{
    .contact_page_form .contact-us-modal-body
    {
        padding-top: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact_page_form .contact-us-modal-body
    {
        padding-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact_page_form .contact-us-modal-body
    {
        padding-right: calc(20px + 30 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .contact_page_form .contact-us-modal-body
    {
        padding-left: calc(20px + 30 * (100vw - 360px) / 960);
    }
}
.contact_page_form .close-form-btn
{
    display: none;
}
.contact_page_form .contact-us-modal_title
{
    /* Только если меньше контейнера */
    font-size: 28px;

    max-width: 100%;
    margin-bottom: 40px;
}
@media (max-width: 1320px)
{
    .contact_page_form .contact-us-modal_title
    {
        font-size: calc(22px + 6 * (100vw - 360px) / 960);
    }
}
.contact_page_form .contact-us-modal_form
{
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.contact_page_form .input_body
{
    width: 32%;
}
@media (max-width: 992px)
{
    .contact_page_form .input_body:nth-child(1),
    .contact_page_form .input_body:nth-child(2)
    {
        width: 48.5%;
    }
    .contact_page_form .input_body:nth-child(3)
    {
        width: 100%;
    }
}
@media (max-width: 768px)
{
    .contact_page_form .input_body:nth-child(1),
    .contact_page_form .input_body:nth-child(2)
    {
        width: 100%;
    }
}
.contact_page_form .short_input
{
    max-width: 100%;
}
.contact_page_form .textarea
{
    height: 150px;
}
.contact_page_form .form-btn
{
    width: 200px;
    margin: 0 auto;
}

.succesfull_request
{
    position: absolute;
    z-index: 2;

    visibility: hidden;

    opacity: 0;
    background-color: var(--white);

    inset: 0;
    scale: 0;
}
.succesfull_request.openRequest
{
    visibility: visible;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: 1;

    scale: 1;
}
.succesfull_request .request_content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: calc(100% - 40px);
    max-width: 496px;
    height: 100%;
    margin: 0 auto;

    text-align: center;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.succesfull_request .request_content img
{
    width: 100px;
    height: 100px;
    /* Только если меньше контейнера */
    margin-bottom: 50px;
}
@media (max-width: 1320px)
{
    .succesfull_request .request_content img
    {
        margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
.succesfull_request .request_content h2
{
    margin-bottom: 30px;
}
.succesfull_request .request_content p
{
    font-size: 18px;
    line-height: 1.4444444444;

    color: var(--grey);
}

.features-main
{
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 0;
    /* Только если меньше контейнера */
    padding-bottom: 30px;

    border-radius: 5px 30px 0 0;
    background: linear-gradient(175.44deg, #f8f8f8 3.63%, rgba(248, 248, 248, 0) 85.79%);
}
@media (max-width: 1320px)
{
    .features-main
    {
        padding-bottom: calc(0px + 30 * (100vw - 360px) / 960);
    }
}
.features-main_title
{
    margin: 0;
}
@media (max-width: 576px)
{
    .features-main_title
    {
        text-align: start;
    }
}

.features-body
{
    /* Только если меньше контейнера */
    padding-top: 20px;
    /* Только если меньше контейнера */
    padding-bottom: 100px;
}
@media (max-width: 1320px)
{
    .features-body
    {
        padding-top: calc(30px + -10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .features-body
    {
        padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
    }
}
.features-body_content
{
    text-align: center;
}
.features-body_wrap:not(:last-child)
{
    margin-bottom: 120px;
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+1) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+1) h3
{
    color: var(--blue);
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+2) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+2) h3
{
    color: var(--green);
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+3) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+3) h3
{
    color: var(--yellow);
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+4) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+4) h3
{
    color: var(--black2F);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+1) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+1) h3
{
    color: var(--green);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+2) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+2) h3
{
    color: var(--yellow);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+3) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+3) h3
{
    color: var(--black2F);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+4) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+4) h3
{
    color: var(--blue);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+1) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+1) h3
{
    color: var(--black2F);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+2) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+2) h3
{
    color: var(--blue);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+3) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+3) h3
{
    color: var(--green);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+4) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+4) h3
{
    color: var(--yellow);
}
.features-body_wrap .btn
{
    font-weight: 400;
}
.features-body_subtitle
{
    /* Только если меньше контейнера */
    font-size: 28px;
    font-weight: 700;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    color: var(--black2F);
}
@media (max-width: 1320px)
{
    .features-body_subtitle
    {
        font-size: calc(20px + 8 * (100vw - 360px) / 960);
    }
}
@media (max-width: 1320px)
{
    .features-body_subtitle
    {
        margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .features-body_subtitle
    {
        text-align: start;
    }
}
.features-body_card-wrap
{
    display: grid;
    /* Только если меньше контейнера */

    margin-bottom: 50px;

    grid-template: 1fr/repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
}
@media (max-width: 1320px)
{
    .features-body_card-wrap
    {
        margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 576px)
{
    .features-body_card-wrap
    {
        grid-template: auto/1fr;
    }
}
.features-body_card
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    min-height: 292px;
    padding: 25px 25px 42px 25px;
    /* Только если меньше контейнера */
    padding-bottom: 42px;

    text-align: start;

    border-radius: 5px 30px;
    -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .1);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1320px)
{
    .features-body_card
    {
        padding-bottom: calc(35px + 7 * (100vw - 360px) / 960);
    }
}
.features-body_card.hide
{
    display: none;

    opacity: 0;
}
.features-body_card.active
{
    display: block;

    opacity: 1;
}
@media (max-width: 576px)
{
    .features-body_card
    {
        min-height: auto;
    }
}
.features-body_card p
{
    /* Только если меньше контейнера */
    font-size: 14px;
    line-height: 1.4285714286;

    margin-bottom: 25px;

    color: var(--grey);

    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
}
@media (max-width: 1320px)
{
    .features-body_card p
    {
        font-size: calc(16px + -2 * (100vw - 360px) / 960);
    }
}
.features-body_card a
{
    font-size: 14px;
    font-weight: 700;

    text-decoration: underline;

    color: var(--blue);
}
.features-body .card_head
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    margin-bottom: 25px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 25px 15px;
}
.features-body .card_head .icon
{
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
}
@media (max-width: 576px)
{
    .features-body .card_head .icon
    {
        min-width: 36px;
        max-width: 36px;
        min-height: 36px;
        max-height: 36px;
    }
}
.features-body .card_head h3
{
    font-size: 18px;
    font-weight: 700;
}

.footer
{
    padding: 60px 0;

    background-color: var(--hover-blue);
}
.footer .container
{
    max-width: 1360px;
}
.footer .footer_top
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 100px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 35px;
}
@media (max-width: 1024px)
{
    .footer .footer_top
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
@media (max-width: 1200px)
{
    .footer .footer_top
    {
        margin-bottom: 60px;
    }
}
.footer .logo_wrap
{
    width: 100%;
    max-width: 295px;
}
@media (max-width: 1024px)
{
    .footer .logo_wrap
    {
        max-width: 100%;
        margin: 0 auto;
    }
}
.footer .footer__image
{
    display: inline-block;

    margin-bottom: 20px;
}
@media (max-width: 1024px)
{
    .footer .footer__image
    {
        max-width: 200px;
        height: auto;
    }
    .footer .footer__image .footer__img
    {
        width: 100%;
        height: auto;
    }
}
.footer .footer_text
{
    font-size: 14px;
    font-weight: 600;
    line-height: 142.8571428571%;

    color: var(--white);
}
.footer .col_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 875px;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
}
@media (max-width: 576px)
{
    .footer .col_wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
@media (max-width: 1024px)
{
    .footer .col_wrap
    {
        max-width: 100%;

        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        row-gap: 40px;
    }
}
@media (max-width: 576px)
{
    .footer .footer_list
    {
        visibility: hidden;

        height: 0;

        text-align: start;

        opacity: 0;
    }
    .footer .footer_list.open
    {
        visibility: visible;

        height: auto;

        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        opacity: 1;
    }
}
.footer .footer_col
{
    width: 100%;
    max-width: 140px;
}
@media (max-width: 1024px)
{
    .footer .footer_col
    {
        min-width: 140px;
    }
}
@media (max-width: 768px)
{
    .footer .footer_col
    {
        max-width: 40%;

        text-align: center;
    }
}
@media (max-width: 480px)
{
    .footer .footer_col
    {
        min-width: 100%;
    }
}
.footer .footer_title
{
    font-size: 18px;
    font-weight: 700;

    margin-bottom: 25px;

    color: var(--white);
}
@media (max-width: 576px)
{
    .footer .footer_title
    {
        position: relative;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        width: -webkit-max-content;
        width:    -moz-max-content;
        width:         max-content;
        margin: 0;

        text-align: start;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .footer .footer_title::after
    {
        position: absolute;
        top: 40%;
        right: -15px;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        width: 10px;
        height: 8px;

        content: url(../svg/footer_arrow.svg);
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        -webkit-transform-origin: center;
                transform-origin: center;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .footer .footer_title.active
    {
        margin-bottom: 20px;
    }
    .footer .footer_title.active::after
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
    }
}
.footer .list_item:not(:last-child)
{
    margin-bottom: 15px;
}
.footer .item_link
{
    font-size: 14px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    opacity: .8;
    color: var(--white);
}
@media (hover: hover)
{
    .footer .item_link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        opacity: 1;
    }
}
@media (hover: none)
{
    .footer .item_link:active
    {
        opacity: 1;
    }
}
.footer .footer_bottom
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    /* Только если меньше контейнера */
    gap: 30px;
}
@media (max-width: 1320px)
{
    .footer .footer_bottom
    {
        gap: calc(20px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .footer .footer_bottom
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.footer .contact_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    /* Только если меньше контейнера */
    gap: 30px;
}
@media (max-width: 1320px)
{
    .footer .contact_wrap
    {
        gap: calc(20px + 10 * (100vw - 360px) / 960);
    }
}
@media (max-width: 992px)
{
    .footer .contact_wrap
    {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.footer .contact_item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    gap: 15px;
}
.footer .contact_item .email
{
    font-size: 14px;

    color: var(--white);
}
.footer .contact_item .email_link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}
.footer .contact_item .icon
{
    width: 18px;
    height: 18px;

    color: var(--white);
}
@media (max-width: 992px)
{
    .footer .contact_item
    {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media (max-width: 576px)
{
    .footer .contact_item
    {
        width: 100%;
    }
}
.footer .item_link-text,
.footer .follow
{
    font-size: 18px;
    font-weight: 800;

    color: var(--white);
}
.footer .underline
{
    text-decoration: underline;
}
.footer .contact_item-link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}
.footer .contact_item-link .icon
{
    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
}
.footer .contact_item-link .icon-phone_2
{
    width: 15px;
    height: 15px;
}
@media (hover: hover)
{
    .footer .contact_item-link:hover .item_link-text
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--blue);
    }
    .footer .contact_item-link:hover .icon
    {
        color: var(--blue);
    }
}
@media (hover: none)
{
    .footer .contact_item-link:active
    {
        color: var(-blue);
    }
    .footer .contact_item-link:active .icon
    {
        color: var(--blue);
    }
}
.footer .social_links_wrap,
.footer .links_wrap
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.footer .social_links_wrap
{
    gap: 40px;
}
@media (max-width: 768px)
{
    .footer .social_links_wrap
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        gap: 20px;
    }
}
.footer .follow
{
    text-decoration: none;
}
.footer .links_wrap
{
    gap: 12px;
}
@media (max-width: 576px)
{
    .footer .links_wrap
    {
        gap: 40px;
    }
}
.footer .link,
.footer .link__image
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.footer .link
{
    width: 44px;
    height: 44px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    border: 1px solid transparent;
    border-radius: 50%;
    background-color: var(--white);
}
.footer .link .icon
{
    width: 16px;
    height: 16px;

    -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;

    color: var(--hover-blue);
}
@media (hover: hover)
{
    .footer .link:hover
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        border-color: var(--white);
        background-color: var(--hover-blue);
    }
    .footer .link:hover .icon
    {
        -webkit-transition: all .3s ease 0s;
                transition: all .3s ease 0s;

        color: var(--white);
    }
}
@media (hover: none)
{
    .footer .link:active
    {
        border-color: var(--white);
        background-color: var(--hover-blue);
    }
    .footer .link:active .icon
    {
        color: var(--white);
    }
}
.footer_media-links
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 50px 0 0 0;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 12px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

@media (max-width: 768px)
{
    .footer
    {
        padding: 35px 0;
    }
}
@media (max-width: 576px)
{
    .footer_ua .links_wrap
    {
        gap: 12px;
    }
}

// .main-title {
// }

// .hero-title {
// }

.section-title {
    text-align: center;
    font-weight: 700;
    @include adaptive-value("font-size", 40, 28, 1);
    line-height: calc((50 / 40) * 100%);
    margin: 0 auto 50px auto;
    color: var(--black2F);
    @include adaptive-value("margin-bottom", 50, 40, 1);
}
.trial {
    padding: 60px 0;
    background-color: var(--blue);

    &_without-additional-content {

        .trial_content {
            max-width: 700px;
        }

        .trial_text-block {
            max-width: 100%;
            width: 100%;
            text-align: center;
        }

        .trial_text-block-title {
            font-weight: 700;
        }

        .trial__image {
            display: none;
        }
    }

    &_modal {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        width: calc(100% - 40px);
        border-radius: 5px;

        &.open {
            display: block;
        }

        .trial_content {
            @include sm {
                flex-direction: column;
            }
        }
    }

    // .trial_content

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        max-width: 900px;
        width: 100%;
        margin: 0 auto;

        @include md {
            flex-direction: column-reverse;
        }
    }

    // .trial_text-block

    &_text-block {
        max-width: 346px;
        width: 100%;

        @include md {
            max-width: 100%;
            text-align: center;
        }

        @include xs {
            text-align: start;
        }
    }

    // .trial_text-block-title

    &_text-block-title {
        font-weight: 400;
        @include adaptive-value("font-size", 28, 22, 1);
        @include adaptive-value("margin-bottom", 50, 40, 1);
        color: var(--white);
        line-height: 145%;
    }

    // .trial_text-block-descr

    &_text-block-descr {
        font-weight: 700;
        font-size: 14px;
        @include adaptive-value("margin-bottom", 50, 40, 1);
        color: var(--white);
        line-height: 142%;
    }

    .trial__image {
        max-width: 480px;
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .btn {
        @include xs {
            width: 100%;
        }
    }
}
.header {
  background: var(--white);
  z-index: 1000;
  position: relative;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;

  &__container {
    max-width: 1320px;
    padding: 0 20px;
    margin: 0 auto;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 80px;
    @include adaptive-value("column-gap", 80, -80, 1);
  }

  &__logo {
    position: relative;
    z-index: 3;
  }

  @media (max-width: 1024px) {
    &__logo {
      width: 90px;
      height: auto;
    }
  }

  .logo__image {
    display: inline-block;
    width: 100%;
    height: 100%;


    @media (max-width: 1024px) {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__nav-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 20px;
  }

  @media (max-width: 1024px) {
    &__nav-wrap {
      position: fixed;
      top: 0;
      right: -100%;
      z-index: 2;
      transition: all .3s ease 0s;
      max-width: 500px;
      width: 100%;
      height: 100dvh;
      background-color: var(--white);
      padding: 90px 0 35px 0;
      padding-top: 90px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 25px;
      border-left: 1px solid var(--blue);
      box-shadow: 3px 0px 15px var(--blue);
    }
  }

  @media (max-width: #{$xs-and-sm}) {
    &__nav-wrap {
      padding: 90px 0 35px 0;
    }
  }

  .activeMenu {
    right: 0;
    transition: all .3s ease 0s;
  }

  &__navbar {
    flex-grow: 1;
  }

  @media (max-width: 1024px) {

    &__navbar {
      overflow-y: auto;
      width: 100%;
      flex-grow: 0;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    z-index: 3;
    column-gap: 25px;

    .link_wrap {
      display: flex;
      align-items: center;
      column-gap: 7px;
      color: var(--black2F);
      font-size: 13px;
      width: max-content;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0px;
        background: var(--blue);
        transition: .3s;
        border-radius: 5px;
        transform: scale(0);
      }
    }

    @media (hover:hover) {
      .link_wrap {
        &:hover {
          color: var(--blue);
          transition: all .3s ease 0s;

          &::before {
            transform: scale(1);
            transition: .3s;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      .link_wrap {
        padding: 25px 15px;
        background-color: var(--white);
        width: 100%;
        font-size: 16px;
        border-radius: 5px;
        justify-content: space-between;
      }
    }

    .icon-arrow_drop {
      width: 6px;
      height: 4px;
      color: var(--blue);
      margin-top: 2px;
      transition: .3s;
    }

    @media (max-width: 1024px) {
      .icon-arrow_drop {
        width: 9px;
        height: 6px;
      }
    }
  }

  @media (max-width: 1024px) {
    &__menu {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 5px;
      padding: 15px 20px 30px 20px;
    }
  }

  .lang_switcher {
    display: flex;
    align-items: center;
    background-color: var(--whiteF8);
    border-radius: 50px;
    padding: 3px;

    .lang_btn {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: var(--black2F);
      font-size: 14px;
      transition: all .3s ease 0s;

      &.active_lang {
        background-color: var(--blue);
        color: var(--white);
        transition: all .3s ease 0s;
      }
    }
  }

  &__buttons {
    display: flex;
    @include adaptive-value("gap", 40, -40, 1);
    position: relative;
    z-index: 3;

    .btn {
      font-weight: 400;
      width: 100%;
      height: 40px;
      font-size: 14px;
    }



    .phone_link {
      display: flex;
      align-items: center;
      gap: 3px;

      .icon-phone_2 {
        width: 12px;
        height: 12px;
        color: var(--blue);
      }

      span {
        color: var(--black2F);
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }

  .hide_switcher {
    display: none;
    position: relative;
    z-index: 5;
  }

  .hidden_login {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .login_mob {
    display: none;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    background-color: var(--white);
    z-index: 5;

    @media (max-width: 1024px) {
      display: flex;
    }
  }

  @media (max-width: 1024px) {
    &__buttons {
      // width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // height: 30%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20%;
      z-index: -1;

      .phone_link {
        margin-bottom: 40px;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        span {
          font-size: 20px;
        }
      }
    }


    .lang_switcher {
      display: none;
    }

    .hide_switcher {
      display: block;
    }
  }

  @media (max-height: 740px) {
    &__buttons {
      bottom: 5%;
    }
  }

  .header_backdrop {
    display: none;
    position: fixed;
    top: 89px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);

    &.show {
      display: block;
    }
  }

  .dropdown {

    &.open {
      .dropdown_content {
        pointer-events: all;
        opacity: 1;
        visibility: visible;
        transition: opacity .4s ease-in .1s, max-height .4s ease-in 0s;
      }

      .drop_btn {
        .icon-arrow_drop {
          transition: .3s;
          rotate: 180deg;
        }
      }
    }
  }

  @media (max-width: 1024px) {

    .dropdown {
      width: 100%;
      background-color: var(--white);
      box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);

      &.open {
        .dropdown_content {
          height: 330px;
          transition: all .3s ease 0s;
          padding: 20px 15px;
        }

        .link_wrap {
          color: var(--blue);
        }
      }
    }
  }

  .dropdown_content {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 89px;
    left: 0;
    background-color: var(--white);
    z-index: 3;
    padding-left: 60px;
    padding-right: 60px;
    padding: 40px 15px 40px 15px;
    box-shadow: 0px 8px 25px 0px #0000001A;

    .close_sub-menu {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .dropdown_content {
      position: static;
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 20px;
      box-shadow: none;
      height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: auto;
      padding: 0;
      transition: all .3s ease 0s;
      background-color: var(--whiteF8);
      border-radius: 2px;
      max-width: 100%;
      width: calc(100% - 40px);
    }
  }

  .dropdown_row {
    display: flex;
    justify-content: center;
    gap: 60px;
  }

  @media (max-width: 1024px) {
    .dropdown_row {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
    }
  }

  .column {
    transition: all .3s linear;

    .column_title {
      color: var(--blue);
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    @media (max-width: 1024px) {

      .column_title {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .column_link-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .features {

      .column_link {
        margin-bottom: 0;
      }

      .primary-link {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-top: 5px;

        .icon-arrow_drop {
          width: 6px;
          height: 4px;
          rotate: -90deg;
        }
      }

      @media (max-width: 1024px) {

        .primary-link {
          justify-content: center;
        }
      }

    }

    .column_link {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--black2F);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      transition: all .3s ease 0s;
    }

    @media (hover:hover) {
      .column_link {
        &:hover {
          color: var(--blue);
          transition: all .3s ease 0s;
        }
      }
    }

    @media (hover:none) {
      .column_link {
        &:active {
          color: var(--blue);
          transition: all .3s ease 0s;
        }
      }
    }

    .column_text {
      color: var(--grey70);
      font-size: 13px;
      font-weight: 400;
      line-height: 123%;
      max-width: 161px;
      width: 100%;
    }

    @media (max-width: 1024px) {
      .column_text {
        max-width: 100%;
      }
    }

    .primary-link {
      &:hover {
        .icon-arrow_drop {
          color: var(--hover-blue);
          transform: translatey(5px);
        }
      }
    }
  }

  .list_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  @media (max-width: 1024px) {

    .list_wrap {
      row-gap: 25px;
    }

    .column {
      width: 100%;
    }
  }

  .integrations_content {
    flex-direction: column;
    align-items: center;

    .column_link-wrap {
      row-gap: 15px;
    }

    .column_link {
      margin-bottom: 0;

      span {
        opacity: 0.8;
      }
    }

    .column_text {
      max-width: 197px;
    }

    .integrations_link {
      display: flex;
      align-items: center;
      gap: 6px;
      color: var(--blue);
      font-size: 14px;
      font-weight: 500;

      .icon-arrow_drop {
        rotate: -90deg;
      }
    }

    .integrations_link {
      color: var(--hover-blue);
      transition: all .3s ease 0s;

      &:hover {
        .icon-arrow_drop {
          color: var(--hover-blue);
          transform: translatey(5px);
        }
      }
    }

    @media (max-width: 1024px) {

      .column_text {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 1024px) {
    .integrations_content {

      .column_list,
      .list_item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
      }

      .column_link-body {
        width: 130px;

        span {
          white-space: nowrap;
        }
      }

      .column_title {
        margin: 0;
      }

      .features_list {
        flex-direction: column;
      }
    }
  }

  .resources_content {
    .column_link {
      margin-bottom: 0;
    }
  }

  .divider_col {
    padding-left: 60px;
    border-left: 1px solid var(--grey);
    min-height: 100%;
    max-width: 227px;
    width: 100%;
  }

  @media (max-width: 1024px) {

    .divider_col {
      padding-left: 10px;
      min-height: max-content;
      max-width: 100%;
      border: none;
      padding: 0;

      .column_title {
        border: none;
      }
    }
  }
}

.header_eng {
  .header__buttons {
    gap: 15px;
  }
}
// .form-field {
//   width: 100%;
//   &._invalid {
//   }
//   &._valid {
//   }
//   &__head {
//   }
//   &__title {
//   }
//   &__optional {
//   }
//   &__textarea-container,
//   &__input-container {
//   }
//   &__input-container {
//     .icon {
//     }
//     .icon + .form-field__input {
//     }
//   }
//   &__textarea-container {
//     .icon {
//     }
//     .icon + .form-field__textarea {
//     }
//   }
//   &__textarea,
//   &__input {
//   }
//   &__input {
//     &::placeholder {
//     }
//   }
//   &__textarea {
//   }
//   &__error,
//   &__message {
//   }
//   &__error {
//   }
//   &__row {
//   }
//   &__col {
//   }
// }

.input_body {
    position: relative;

    .alert-name__image,
    .alert-email__image,
    .alert-phone__image {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        z-index: 1;
        transition: all .3s ease 0s;
        height: 20px;
        width: 20px;
        scale: 1;

        &.hide {
            scale: 0;
        }
    }
}

.form_input {
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    border-radius: 500px;
    padding: 25px 20px;
    width: 100%;
    font-size: 16px;
    color: var(--white);
    transition: all .3s ease 0s;
    height: 60px;

    &::placeholder {
        color: #FCFFF5;
        opacity: 0.7;
    }

    &:focus {
        outline: none;
        border-color: var(--white);
        transition: all .3s ease 0s;
    }

    &:focus-visible {
        outline: none;
        border-color: var(--white);
        transition: all .3s ease 0s;
    }
}

#phone {
    padding-left: 75px;
}

.iti {
    width: 100%;
}

.iti--inline-dropdown {
    .iti__dropdown-content {
        border-radius: 10px;
        background: var(--white);
        overflow: hidden;
        width: 330px !important;
        margin-top: 5px;
    }
}

.iti__country-container {
    padding: 0;
}

.iti__flag-container:hover {
    padding: 0;
}

.iti__arrow--up {
    border-top: 4px solid transparent !important;
    border-bottom: 4px solid var(--white);
    transition: all .3s ease 0s;
}

.iti__selected-country-primary {
    border-radius: 500px 0 0 500px;
    padding: 0 10px 0 20px;
    position: relative;
}

.iti__arrow {
    border-top: 4px solid var(--white);
    margin-left: 10px;
    transition: all .3s ease 0s;
}

.iti__search-input {
    outline: none;
    height: 30px;
    padding: 8px;
    border-bottom: 1px solid var(--blue);
    border-radius: 0;
    caret-color: var(--blue);
    box-shadow: 0 0px 10px var(--blue);
}

.iti__country-list {

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--white);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--blue);
    }
}

.form_title {
    margin-bottom: 40px;
}

@media (max-width: #{$sm-and-sm}) {
    .form_title {
        margin-bottom: 25px;
        font-size: 18px;
        text-align: center;
    }
}

.tel_label {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--white);
    margin-top: 15px;
    margin-bottom: 10px;

    @include xs {
        display: none;
    }
}

.check_wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: var(--white);
    margin-bottom: 40px;

    span{
        b{
            text-decoration: underline;
        }
    }

    @include sm{
        margin-top: 15px;
        margin-bottom: 25px;
    }
}


.checkbox {
    display: none;
}

.custom-checkbox {
    min-width: 24px;
    min-height: 24px;
    border-radius: 6px;
    border: 1px solid var(--white);
    margin-right: 10px;
    accent-color: var(--white);
    background-color: transparent;
    position: relative;
    transition: all .3s ease 0s;
}

.checkbox:checked+.custom-checkbox {
    background-color: var(--white);
    transition: all .3s ease 0s;
}

.checkbox:checked+.custom-checkbox:before {
    content: url(../svg/check-arrow.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    transition: all .3s ease 0s;
    height: 100%;
}


.form-btn {
    width: 100%;
    height: 60px;
}

@media (max-width: #{$sm-and-sm}) {
    .form-btn {
        padding: 10px;
    }
}

.error-message {
    position: absolute;
    bottom: -20px;
    left: 10px;
    @include adaptive-value("font-size", 14, 12, 1);
    color: var(--black2F);
}

.form-btn {
    &:disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}

.icon-alert {
    width: 20px;
    height: 20px;
    color: var(--red);
}

.hide {
    scale: 0;
    transition: all .3s ease 0s;
}
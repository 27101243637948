.blog {

    &_card {
        max-width: 588px;
        width: 100%;
        border-radius: 5px 30px;
        background: var(--white);
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);
        overflow: hidden;
        position: relative;
    }

    &_category-wrap {
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;
    }

    &_category {
        border-radius: 5px;
        background-color: var(--whiteF8);
        padding: 10px;
        width: max-content;


        span {
            color: var(--hover-blue);
            font-size: 13px;
            font-weight: 600;
        }
    }

    @media (max-width: #{$lg-and-sm}) {
        &_descr {
            font-size: 16px;
        }
    }

    @media (max-width: #{$md-and-sm}) {
        &_content {
            flex-direction: column;
        }

        &_text-wrap {
            max-width: 100%;
        }

        &_category-wrap {
            top: 10px;
            left: 10px;
        }
    }

    @media (max-width: #{$md-and-sm}) {

        &_category-wrap {
            top: 10px;
            left: 10px;
        }
    }
}

.hot {
    background-color: var(--yellow);

    span {
        color: var(--white);
    }
}

.blog_card {

    .card_text {
        padding: 35px 25px;
    }

    .card_title {
        color: var(--black2F);
        font-weight: 700;
        line-height: 144%;
        margin-bottom: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

    }

    // .card_bottom

    .card_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 25px;

    }

    // .card_info-wrap

    .card_info-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px 20px;
    }

    // .card_btn

    .card_btn {
        min-width: 47px;
        min-height: 47px;
        max-width: 47px;
        max-height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--blue);
        border-radius: 50%;
        transition: all .3s ease 0s;
    }

    @media (hover:hover) {
        .card_btn {
            &:hover {
                background-color: var(--hover-blue);
                transition: all .3s ease 0s;
            }
        }
    }

    @media (hover:none) {
        .card_btn {
            &:active {
                background-color: var(--hover-blue);
            }
        }
    }

    @media (max-width: #{$xs-and-sm}) {

        .card_info-wrap {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

        .card_text {
            padding: 25px 15px;
        }
    }
}

.big_card {

    .card_title {
        font-size: 18px;
    }
}

@media (max-width: #{$lg-and-sm}) {
    .big_card {
        .card_title {
            font-size: 14px;
            margin-bottom: 25px;
        }
    }
}

.small_card {
    display: flex;
    align-items: center;
    max-height: 167px;

    .blog_category-wrap {
        top: 10px;
        left: 10px;
    }

    .blog__image {
        width: 170px;
        height: 167px;
    }

    .card_text {
        max-width: 340px;
        width: 100%;
        padding: 25px;
        @include adaptive-value("padding-top", 25, 15, 1);
        @include adaptive-value("padding-bottom", 25, 15, 1);
        @include adaptive-value("padding-right", 25, 15, 1);
        @include adaptive-value("padding-left", 25, 15, 1);
    }

    .card_title {
        font-size: 14px;
    }

    .card_info-wrap {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
}

@media (max-width: #{$xs-and-sm}) {
    .small_card {
        flex-direction: column;
        max-height: 100%;
        height: auto;

        .blog__image {
            width: 100%;
        }
    }
}

.icon-calendar,
.icon-clock {
    color: var(--blue);
}

.icon-calendar {
    width: 18px;
    height: 18px;
}

.icon-clock {
    width: 20px;
    height: 20px;
}

.icon-arrow_right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 10px;
    color: var(--white);
}

.blog__image {
    display: block;
    width: 100%;
    height: 250px;
    position: relative;
    border-radius: 5px 30px;
    overflow: hidden;

    .blog__img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: #{$xs-and-sm}) {
    .blog__image {
        height: 200px;
    }
}


.info {

    // .info_body

    &_body {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    // .info_text

    &_text {
        color: var(--grey);
        font-size: 14px;
        white-space: nowrap;
    }
}
.breadcrumbs {
    @include adaptive-value("margin-bottom", 60, 50, 1);
    position: relative;
    z-index: 1;

    @include xs {
        display: none;
    }

    .icon-bc_icon {
        width: 6px;
        height: 10px;
        color: var(--blue);
    }

    &__list {
        display: flex;
        align-items: center;
        gap: 8px;
        // width: max-content;
        flex-wrap: wrap;
    }

    &__item {
        width: max-content;

        &:last-child {
            text-overflow: ellipsis;
            max-width: 315px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;

            .icon {
                display: none;

                @include xs {
                    display: block;
                }
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--grey);
        font-size: 14px;
        font-weight: 300;
        transition: all .3s;

        @include xs {
            flex-direction: row-reverse;
            justify-content: flex-end;

            .icon {
                rotate: 180deg;
            }
        }
    }
}
a.breadcrumbs__link:hover {
  color: var(--blue);
}
.breadcrumbs_mob {
    display: none;

    @include xs {
        display: block;
        margin-bottom: 50px;
    }
}
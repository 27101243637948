.hero {
  @include adaptive-value("padding-bottom", 175, 0, 1);

  @include xs {
    overflow: hidden;
  }

  .container {
    position: relative;
    z-index: 2;

    @include xs {
      padding: 0;
    }
  }


  &__wrap {
    position: relative;
    padding-top: 60px;
    background: #F8F8F8;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    border-radius: 5px 30px;
    background: var(--light-grey, #F8F8F8);

    @include xs {
      margin-bottom: 110px;
    }

    &::after,
    &::before {
      position: absolute;
      top: 0;
      z-index: 0;
    }

    &::after {
      content: url(../img/hero/ellipse_right.png);
      right: 0;

      @include md {
        display: none;
      }
    }

    &::before {
      content: url(../img/hero/ellipse_left.png);
      left: 0;
    }
  }

  // .hero__content

  &__content {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;
    // margin-bottom: 50px;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  // .hero__title

  &__title {
    margin-bottom: 40px;

    h1 {
      color: var(--black2F);
      text-align: center;
      @include adaptive-value("font-size", 40, 20, 1);
      font-weight: 700;
      line-height: calc(50 / 40);

      @include xs {
        text-align: start;
        font-size: 20px;
      }

      br {
        display: none;

        @include xs {
          display: block;
        }
      }

      .big_text, strong {
        @include xs {
          color: var(--blue);
          font-size: 120px;
          line-height: 120px;
        }
      }
      em {
        color: var(--blue);
        font-style: normal;
      }
    }

    @include xs {
      padding: 0 20px;
    }
  }

  // .hero__text

  &__text {
    max-width: 685px;
    width: 100%;
    margin: 0 auto;
    @include adaptive-value("margin-bottom", 60, 50, 1);

    p {
      color: var(--grey);
      text-align: center;
      @include adaptive-value("font-size", 20, 16, 1);
      font-weight: 400;
      line-height: calc(26 / 18);

      @include xs {
        text-align: start;
        padding: 0 20px;
      }
    }
  }

  // .hero__action-btn
  &__action-btn {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 30px;

    .blue_btn {
      text-decoration: underline;
      color: var(--blue);
      transition: all .3s ease 0s;

      &:hover {
        color: var(--hover-blue);
        transition: all .3s ease 0s;
      }
    }

    @include xs {
      padding: 0 20px;
    }
  }

  &__btn {
    color: var(--white);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 144.144%;

    @include xs {
      width: 100%;
    }
  }

  // .hero__action-descr

  &__action-descr {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    @include adaptive-value("margin-bottom", 50, 30, 1);

    @include xs {
      justify-content: flex-start;
      padding: 0 20px;
    }
  }

  // .hero__action-item

  &__action-item {
    display: flex;
    align-items: center;
    gap: 5px;

    .icon-check {
      min-width: 7px;
      min-height: 5px;
      max-width: 7px;
      max-height: 5px;
      color: var(--blue);
    }

    p {
      font-weight: 300;
      font-size: 14px;
      color: var(--grey);

      @include xs {
        text-align: start;
      }
    }

  }

  // .hero__video

  &__video {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px 30px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    top: 60px;

    @include xs {
      top: 0;
      width: calc(100% - 40px);
      margin: -110px auto 0 auto;
      transform: translate(0, 110px);
    }
  }

  .video_poster__image {
    display: inline-block;
    width: 100%;
    height: auto;

    .video_poster__img {
      width: 100%;
      height: auto;
    }
  }

  // .hero__icons


  .hero__image {
    width: 45px;
    height: auto;

    .hero__img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: #{$md-and-sm}) {
    &__icons {
      display: none;
    }
  }

  &__icons-wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 20px;
  }

  &__icons-mob {
    flex-shrink: 0;
    display: none;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    min-width: 100%;
    margin-bottom: 40px;
    animation: scroll 20s linear infinite;

    @include xs {
      display: flex;

    }
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(calc(-100% - 20px));
    }
  }

  // .hero__icon

  &__icon {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 5px 25px;
    background: var(--white);
    box-shadow: 0px 5.398px 16.87px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    z-index: 1;

    // .hero__icon_1

    &_1 {
      transform: rotate(10deg);
      top: 3%;
      left: 9%;

      @include xs {
        .hero__image {
          transform: rotate(-10deg);
        }
      }
    }

    // .hero__icon_2

    &_2 {
      transform: rotate(-5deg);
      top: 15%;
      left: 6%;

      @include xs {
        .hero__image {
          transform: rotate(5deg);
        }
      }
    }

    // .hero__icon_3

    &_3 {
      transform: rotate(10deg);
      top: 25%;
      left: 13%;

      @include xs {
        .hero__image {
          transform: rotate(-10deg);
        }
      }
    }

    // .hero__icon_4

    &_4 {
      transform: rotate(-15deg);
      top: 35.67%;
      left: 8.64%;

      @include xs {
        .hero__image {
          transform: rotate(15deg);
        }
      }
    }

    // .hero__icon_5

    &_5 {
      transform: rotate(-12deg);
      top: 2.64%;
      right: 9%;

      @include xs {
        .hero__image {
          transform: rotate(12deg);
        }
      }
    }

    // .hero__icon_6

    &_6 {
      transform: rotate(5deg);
      top: 14.64%;
      right: 6%;

      @include xs {
        .hero__image {
          transform: rotate(-5deg);
        }
      }
    }

    // .hero__icon_7

    &_7 {
      transform: rotate(-5deg);
      top: 25.64%;
      right: 15.64%;

      @include xs {
        .hero__image {
          transform: rotate(5deg);
        }
      }
    }

    // .hero__icon_8

    &_8 {
      transform: rotate(10deg);
      top: 35.64%;
      right: 8.64%;

      @include xs {
        .hero__image {
          transform: rotate(-10deg);
        }
      }
    }

    @media (max-width: #{$lg-and-sm}) {
      &_1 {
        top: 30%;
        left: 3%;
      }

      &_2 {
        top: 27%;
        left: 12%;
      }

      &_3 {
        top: 38%;
        left: 15%;
      }

      &_4 {
        top: 40%;
        left: 4%;
      }

      &_5 {
        top: 30%;
        right: 3%;
      }

      &_6 {
        top: 27%;
        right: 13%;
      }

      &_7 {
        top: 38%;
        right: 15%;
      }

      &_8 {
        top: 40%;
        right: 4%;
      }
    }

    @media (max-width: #{$xs-and-sm}) {
      position: static;
      transform: rotate(0);
      min-width: 80px;
      min-height: 80px;
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  width: 100%;
  height: 100%;
  padding-block: 10vh;

  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  background-color: rgb(0 0 0 / 50%);

  &._show {
    pointer-events: auto;
    visibility: visible;

    opacity: 1;
  }

  &__dialog {
    width: 100%;
    max-width: 800px;
    height: calc(100% - 20vh);
    margin: 0 auto;

    background-color: #fff;
  }

  // &__header {
  // }

  &__header-close-btn {
    display: block;

    width: fit-content;
    height: fit-content;
    margin-left: auto;
  }

  // &__body {
  // }
}

.modal_cookies {
  position: fixed;
  right: -100%;
  bottom: 35px;
  z-index: 999;
  max-width: 400px;
  width: 100%;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 8px 25px 0px #0000001A;
  background-color: var(--white);
  transition: all .3s ease 0s;

  &.show {
    right: 35px;
    transition: all .3s ease 0s;

    @include xs {
      max-width: 100%;
      width: calc(100% - 30px);
      right: 50%;
      transform: translateX(50%);
    }
  }

  .modal__header-title {
    font-size: 14px;
    line-height: calc(20 /14);
    margin-bottom: 20px;

    a {
      color: var(--blue);
      text-decoration: underline;
    }

    @include xs {
      text-align: center;
    }
  }

  .modal__btn-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media (max-width:480px) {
    .modal__btn-wrap {
      flex-wrap: wrap;
    }
  }

  button,
  a {
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    width: 100%;
  }
}
.advantages {
  @include adaptive-value("padding-top", 100, 60, 1);
  @include adaptive-value("padding-bottom", 100, 60, 1);

  // .advantages__row

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 40px;
    @include adaptive-value("column-gap", 60, 0, 1);

    @include xs {
      justify-content: flex-start;
    }


    .advantages__col {
      &:nth-child(4n + 1) {

        .icon,
        .advantages__title {
          color: var(--blue);
        }
      }

      &:nth-child(4n + 2) {

        .icon,
        .advantages__title {
          color: var(--yellow);
        }
      }

      &:nth-child(4n + 3) {

        .icon,
        .advantages__title {
          color: var(--green);
        }
      }

      &:nth-child(4n + 4) {

        .icon,
        .advantages__title {
          color: var(--black2F);
        }
      }
    }
  }

  // .advantages__col

  &__col {
    max-width: 340px;
    width: 100%;

    @include md {
      text-align: center;
    }

    @include xs {
      max-width: 100%;
    }
  }

  // .advantages__icon

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    min-height: 110px;
    max-width: 110px;
    max-height: 110px;
    border-radius: 5px 25px;
    background: var(--white);
    -webkit-box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;

    @include md {
      margin: 0 auto 20px auto;
    }

    @include xs {
      min-width: 80px;
      min-height: 80px;
      max-width: 80px;
      max-height: 80px;
      margin: 0 0 30px 0;
    }

    .icon {
      width: 48px;
      height: 48px;

      @include xs {
        width: 36px;
        height: 36px;
      }
    }
  }


  // .advantages__title

  &__title {
    margin-bottom: 20px;

    h5 {
      @include adaptive-value("font-size", 18, 20, 1);
      font-weight: 700;
      line-height: 144.444%;
    }

    @include xs {
      text-align: start;
    }
  }

  // .advantages__text

  &__text {
    p {
      color: var(--grey);
      font-size: 14px;
      font-weight: 400;
      line-height: 142.857%;

      @include xs {
        text-align: start;
      }
    }
  }
}

.pluses {
  @include adaptive-value("padding-top", 50, 60, 1);
  @include adaptive-value("padding-bottom", 100, 60, 1);

  .advantages__row {
    @include adaptive-value("column-gap", 80, 0, 1);
  }

  .advantages__col {
    max-width: 230px;
    width: 100%;

    @include xs {
      display: flex;
      align-items: center;
      gap: 20px;
      max-width: 100%
    }

    .advantages__icon {
      @include xs {
        margin: 0
      }
    }

    .advantages__title {
      h5 {
        @include adaptive-value("font-size", 22, 20, 1);
      }
    }

    .advantages__text {
      p {
        @include adaptive-value("font-size", 18, 16, 1);
      }
    }
  }
}

.advantages_dial, .autodialer-page {
  //@include adaptive-value("padding-bottom", 100, 60, 1);

  .advantages__row {
    @include adaptive-value("row-gap", 80, 40, 1);
  }

  .advantages__title-main {
    max-width: 770px;
    @include xs {
      max-width: 100%;
      text-align: start;
      br {
        display: none;
      }
    }
  }

  .advantages__text-wrap {
    max-width: 250px;
    width: 100%;

    @include xs {
      max-width: 100%;

      br {
        display: none;
      }
    }
  }

  .advantages__title {
    h5 {
      @include adaptive-value("font-size", 18, 16, 1);
    }
  }
}

.advantages_dial_ua {
  .advantages__text-wrap {
    max-width: 280px;
  }
}

.advantages_voice {
  .advantages__title-main {
    max-width: 555px;
  }

  .advantages__text-wrap {
    max-width: 280px;
  }
}

.advantages_ua {
  .service__bottom {
    padding: 47px 39px;

    @include xs {
      padding: 48px 15.5px;
    }
  }
}

.advantages_contact_center {
  .advantages__text-wrap {
    max-width: 255px;
    width: 100%;

    @include md {
      margin: 0 auto;
    }

    @include xs {
      max-width: 100%;
      margin: 0 auto 0 0;
    }
  }

  .advantages__title {
    h5 {
      @include adaptive-value("font-size", 18, 16, 1);
    }

    @include xs {
      max-width: 300px;
    }
  }

  .advantages__col {

    &:nth-child(1) {
      .advantages__icon {
        .icon {
          width: 70px;
          height: auto;

          @include xs {
            width: 45px;
          }
        }
      }
    }
  }
}
.robot .advantages__title-main {
  text-align: center;
}

.advantages_ip_pbx{
  .advantages__text{
    max-width: 310px;
  }
}
.contact {
    @include adaptive-value("padding-top", 50, 25, 1);
    @include adaptive-value("padding-bottom", 50, 25, 1);

    // .contact_content

    &_content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 25px;
        border-radius: 5px 30px;
        background-color: var(--whiteF8);
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);
        padding: 35px 25px;
    }

    // .contact_col

    &_col {
        width: 48%;
    }

    // .contact_title

    &_title {
        color: var(--black2F);
        @include adaptive-value("font-size", 28, 22, 1);
        font-weight: 700;
        margin-bottom: 25px;

        span {
            color: var(--blue);
        }
    }

    // .contact_text

    &_text {
        color: var(--grey);
        @include adaptive-value("font-size", 18, 16, 1);
        line-height: 144%;
        margin-bottom: 25px;
    }

    // .contact_social-wrap

    &_social-wrap {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    // .contact_social-link

    &_social-link {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--blue);
        transition: all .3s ease 0s;

        .icon {
            width: 16px;
            height: 16px;
            color: var(--white);
        }
    }

    @media (hover:hover) {
        &_social-link {
            &:hover {
                background-color: var(--hover-blue);
                transition: all .3s ease 0s;
            }
        }
    }

    @media (hover:none) {
        &_social-link {
            &:active {
                background-color: var(--hover-blue);
            }
        }
    }

    .contact-form {
        b {
            text-decoration: underline;
            font-weight: 600;
            color: var(--blue);
        }
    }

    // .contact_input-wrap

    &_input-wrap {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;

    }

    // .contact_input

    .input_body {
        width: 100%;
    }

    input {
        padding: 15px 20px;
        color: var(--black2F);
        font-size: 14px;
        max-width: 375px;
        width: 100%;
        border: none;
        background-color: var(--white);
        border-radius: 500px;
        outline: none;
        border: 1px solid transparent;
        transition: all .3s ease 0s;
        height: 40px;
        width: 100%;

        &:focus-visible {
            border-color: var(--blue);
            transition: all .3s ease 0s;
        }

        &:focus {
            border-color: var(--blue);
            transition: all .3s ease 0s;
        }

        &::placeholder {
            color: var(--grey);
        }
    }

    // .contact_form-btn

    .form-btn {
        max-width: 160px;
        width: 100%;
        height: 40px;
        font-size: 14px;
    }

    .check_wrap {
        margin: 0;
        font-size: 14px;
        color: var(--grey);

        .custom-checkbox {
            border-color: var(--blue)
        }

        .checkbox:checked+.custom-checkbox {
            background-color: var(--blue);
        }


        .checkbox:checked+.custom-checkbox:before {
            content: url(../svg/check-arrow-white.svg);
        }

        p,
        span {
            color: var(--grey);
            font-size: 14px;
        }
    }

    @media (max-width: #{$md-and-sm}) {
        &_content {
            flex-direction: column;
            text-align: start;
            align-items: center;
            gap: 50px;
        }

        &_col {
            width: 100%;
        }

        &_input-wrap {
            justify-content: center;
        }

        &_input {
            max-width: 100%;
        }
    }

    @media (max-width: #{$xs-and-sm}) {
        &_input-wrap {
            flex-direction: column;
            margin-bottom: 20px;
        }

        .form-btn {
            max-width: 100%;
        }
    }

}

.blog-page .content section:last-of-type {
  @media (max-width: #{$xs-and-sm}) {
    padding-bottom: 0;
  }
}

.blog-page .content section:last-of-type .container {
  @media (max-width: #{$xs-and-sm}) {
    padding: 0;
  }
}

.blog-page .content section:last-of-type .demo_content {
  @media (max-width: #{$xs-and-sm}) {
    border-radius: 0;
  }
}
@charset "UTF-8";
:root {
  --iti-hover-color: rgba(0, 0, 0, 0.05);
  --iti-border-color: #ccc;
  --iti-dialcode-color: #999;
  --iti-dropdown-bg: white;
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 12px;
  --iti-flag-width: 16px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-arrow-color: #555;
  --iti-path-flags-1x: url("../img/flags.webp");
  --iti-path-flags-2x: url("../img/flags@2x.webp");
  --iti-path-globe-1x: url("../img/globe.webp");
  --iti-path-globe-2x: url("../img/globe@2x.webp");
  --iti-flag-sprite-width: 3904px;
  --iti-flag-sprite-height: 12px;
  --iti-mobile-popup-margin: 30px;
}

.iti {
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti__a11y-text {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
}

.iti input.iti__tel-input,
.iti input.iti__tel-input[type=text],
.iti input.iti__tel-input[type=tel] {
  position: relative;
  z-index: 0;
  margin: 0 !important;
}

.iti__country-container {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: var(--iti-border-width);
}

.iti__selected-country {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border-radius: 0;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;
}

.iti__selected-country-primary {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);
}

.iti__arrow {
  margin-left: var(--iti-arrow-padding);
  width: 0;
  height: 0;
  border-left: var(--iti-triangle-border) solid transparent;
  border-right: var(--iti-triangle-border) solid transparent;
  border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
}

[dir=rtl] .iti__arrow {
  margin-right: var(--iti-arrow-padding);
  margin-left: 0;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}

.iti__dropdown-content {
  border-radius: 3px;
  background-color: var(--iti-dropdown-bg);
}

.iti--inline-dropdown .iti__dropdown-content {
  position: absolute;
  z-index: 2;
  margin-top: 3px;
  margin-left: calc(var(--iti-border-width) * -1);
  border: var(--iti-border-width) solid var(--iti-border-color);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.iti__search-input {
  width: 100%;
  border-width: 0;
  border-radius: 3px;
}

.iti__search-input + .iti__country-list {
  border-top: 1px solid var(--iti-border-color);
}

.iti__country-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti--inline-dropdown .iti__country-list {
  max-height: 185px;
}

.iti--flexible-dropdown-width .iti__country-list {
  white-space: nowrap;
}

@media (max-width: 500px) {
  .iti--flexible-dropdown-width .iti__country-list {
    white-space: normal;
  }
}
.iti__country {
  display: flex;
  align-items: center;
  padding: 8px var(--iti-spacer-horizontal);
  outline: none;
}

.iti__dial-code {
  color: var(--iti-dialcode-color);
}

.iti__country.iti__highlight {
  background-color: var(--iti-hover-color);
}

.iti__country-list .iti__flag, .iti__country-name {
  margin-right: var(--iti-spacer-horizontal);
}

[dir=rtl] .iti__country-list .iti__flag, [dir=rtl] .iti__country-name {
  margin-right: 0;
  margin-left: var(--iti-spacer-horizontal);
}

.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover, .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover button {
  cursor: pointer;
}

.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
  background-color: var(--iti-hover-color);
}

.iti .iti__selected-dial-code {
  margin-left: 4px;
}

[dir=rtl] .iti .iti__selected-dial-code {
  margin-left: 0;
  margin-right: 4px;
}

.iti--container {
  position: fixed;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: var(--iti-border-width);
}

.iti--container:hover {
  cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: var(--iti-mobile-popup-margin);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.iti--fullscreen-popup .iti__dropdown-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}

.iti--fullscreen-popup .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  --iti-flag-offset: 100px;
  height: var(--iti-flag-height);
  width: var(--iti-flag-width);
  border-radius: 1px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: var(--iti-path-flags-1x);
  background-repeat: no-repeat;
  background-position: var(--iti-flag-offset) 0;
  background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
}

.iti__ac {
  --iti-flag-offset: 0px;
}

.iti__ad {
  --iti-flag-offset: -16px;
}

.iti__ae {
  --iti-flag-offset: -32px;
}

.iti__af {
  --iti-flag-offset: -48px;
}

.iti__ag {
  --iti-flag-offset: -64px;
}

.iti__ai {
  --iti-flag-offset: -80px;
}

.iti__al {
  --iti-flag-offset: -96px;
}

.iti__am {
  --iti-flag-offset: -112px;
}

.iti__ao {
  --iti-flag-offset: -128px;
}

.iti__ar {
  --iti-flag-offset: -144px;
}

.iti__as {
  --iti-flag-offset: -160px;
}

.iti__at {
  --iti-flag-offset: -176px;
}

.iti__au {
  --iti-flag-offset: -192px;
}

.iti__aw {
  --iti-flag-offset: -208px;
}

.iti__ax {
  --iti-flag-offset: -224px;
}

.iti__az {
  --iti-flag-offset: -240px;
}

.iti__ba {
  --iti-flag-offset: -256px;
}

.iti__bb {
  --iti-flag-offset: -272px;
}

.iti__bd {
  --iti-flag-offset: -288px;
}

.iti__be {
  --iti-flag-offset: -304px;
}

.iti__bf {
  --iti-flag-offset: -320px;
}

.iti__bg {
  --iti-flag-offset: -336px;
}

.iti__bh {
  --iti-flag-offset: -352px;
}

.iti__bi {
  --iti-flag-offset: -368px;
}

.iti__bj {
  --iti-flag-offset: -384px;
}

.iti__bl {
  --iti-flag-offset: -400px;
}

.iti__bm {
  --iti-flag-offset: -416px;
}

.iti__bn {
  --iti-flag-offset: -432px;
}

.iti__bo {
  --iti-flag-offset: -448px;
}

.iti__bq {
  --iti-flag-offset: -464px;
}

.iti__br {
  --iti-flag-offset: -480px;
}

.iti__bs {
  --iti-flag-offset: -496px;
}

.iti__bt {
  --iti-flag-offset: -512px;
}

.iti__bw {
  --iti-flag-offset: -528px;
}

.iti__by {
  --iti-flag-offset: -544px;
}

.iti__bz {
  --iti-flag-offset: -560px;
}

.iti__ca {
  --iti-flag-offset: -576px;
}

.iti__cc {
  --iti-flag-offset: -592px;
}

.iti__cd {
  --iti-flag-offset: -608px;
}

.iti__cf {
  --iti-flag-offset: -624px;
}

.iti__cg {
  --iti-flag-offset: -640px;
}

.iti__ch {
  --iti-flag-offset: -656px;
}

.iti__ci {
  --iti-flag-offset: -672px;
}

.iti__ck {
  --iti-flag-offset: -688px;
}

.iti__cl {
  --iti-flag-offset: -704px;
}

.iti__cm {
  --iti-flag-offset: -720px;
}

.iti__cn {
  --iti-flag-offset: -736px;
}

.iti__co {
  --iti-flag-offset: -752px;
}

.iti__cr {
  --iti-flag-offset: -768px;
}

.iti__cu {
  --iti-flag-offset: -784px;
}

.iti__cv {
  --iti-flag-offset: -800px;
}

.iti__cw {
  --iti-flag-offset: -816px;
}

.iti__cx {
  --iti-flag-offset: -832px;
}

.iti__cy {
  --iti-flag-offset: -848px;
}

.iti__cz {
  --iti-flag-offset: -864px;
}

.iti__de {
  --iti-flag-offset: -880px;
}

.iti__dj {
  --iti-flag-offset: -896px;
}

.iti__dk {
  --iti-flag-offset: -912px;
}

.iti__dm {
  --iti-flag-offset: -928px;
}

.iti__do {
  --iti-flag-offset: -944px;
}

.iti__dz {
  --iti-flag-offset: -960px;
}

.iti__ec {
  --iti-flag-offset: -976px;
}

.iti__ee {
  --iti-flag-offset: -992px;
}

.iti__eg {
  --iti-flag-offset: -1008px;
}

.iti__eh {
  --iti-flag-offset: -1024px;
}

.iti__er {
  --iti-flag-offset: -1040px;
}

.iti__es {
  --iti-flag-offset: -1056px;
}

.iti__et {
  --iti-flag-offset: -1072px;
}

.iti__fi {
  --iti-flag-offset: -1088px;
}

.iti__fj {
  --iti-flag-offset: -1104px;
}

.iti__fk {
  --iti-flag-offset: -1120px;
}

.iti__fm {
  --iti-flag-offset: -1136px;
}

.iti__fo {
  --iti-flag-offset: -1152px;
}

.iti__fr {
  --iti-flag-offset: -1168px;
}

.iti__ga {
  --iti-flag-offset: -1184px;
}

.iti__gb {
  --iti-flag-offset: -1200px;
}

.iti__gd {
  --iti-flag-offset: -1216px;
}

.iti__ge {
  --iti-flag-offset: -1232px;
}

.iti__gf {
  --iti-flag-offset: -1248px;
}

.iti__gg {
  --iti-flag-offset: -1264px;
}

.iti__gh {
  --iti-flag-offset: -1280px;
}

.iti__gi {
  --iti-flag-offset: -1296px;
}

.iti__gl {
  --iti-flag-offset: -1312px;
}

.iti__gm {
  --iti-flag-offset: -1328px;
}

.iti__gn {
  --iti-flag-offset: -1344px;
}

.iti__gp {
  --iti-flag-offset: -1360px;
}

.iti__gq {
  --iti-flag-offset: -1376px;
}

.iti__gr {
  --iti-flag-offset: -1392px;
}

.iti__gt {
  --iti-flag-offset: -1408px;
}

.iti__gu {
  --iti-flag-offset: -1424px;
}

.iti__gw {
  --iti-flag-offset: -1440px;
}

.iti__gy {
  --iti-flag-offset: -1456px;
}

.iti__hk {
  --iti-flag-offset: -1472px;
}

.iti__hn {
  --iti-flag-offset: -1488px;
}

.iti__hr {
  --iti-flag-offset: -1504px;
}

.iti__ht {
  --iti-flag-offset: -1520px;
}

.iti__hu {
  --iti-flag-offset: -1536px;
}

.iti__id {
  --iti-flag-offset: -1552px;
}

.iti__ie {
  --iti-flag-offset: -1568px;
}

.iti__il {
  --iti-flag-offset: -1584px;
}

.iti__im {
  --iti-flag-offset: -1600px;
}

.iti__in {
  --iti-flag-offset: -1616px;
}

.iti__io {
  --iti-flag-offset: -1632px;
}

.iti__iq {
  --iti-flag-offset: -1648px;
}

.iti__ir {
  --iti-flag-offset: -1664px;
}

.iti__is {
  --iti-flag-offset: -1680px;
}

.iti__it {
  --iti-flag-offset: -1696px;
}

.iti__je {
  --iti-flag-offset: -1712px;
}

.iti__jm {
  --iti-flag-offset: -1728px;
}

.iti__jo {
  --iti-flag-offset: -1744px;
}

.iti__jp {
  --iti-flag-offset: -1760px;
}

.iti__ke {
  --iti-flag-offset: -1776px;
}

.iti__kg {
  --iti-flag-offset: -1792px;
}

.iti__kh {
  --iti-flag-offset: -1808px;
}

.iti__ki {
  --iti-flag-offset: -1824px;
}

.iti__km {
  --iti-flag-offset: -1840px;
}

.iti__kn {
  --iti-flag-offset: -1856px;
}

.iti__kp {
  --iti-flag-offset: -1872px;
}

.iti__kr {
  --iti-flag-offset: -1888px;
}

.iti__kw {
  --iti-flag-offset: -1904px;
}

.iti__ky {
  --iti-flag-offset: -1920px;
}

.iti__kz {
  --iti-flag-offset: -1936px;
}

.iti__la {
  --iti-flag-offset: -1952px;
}

.iti__lb {
  --iti-flag-offset: -1968px;
}

.iti__lc {
  --iti-flag-offset: -1984px;
}

.iti__li {
  --iti-flag-offset: -2000px;
}

.iti__lk {
  --iti-flag-offset: -2016px;
}

.iti__lr {
  --iti-flag-offset: -2032px;
}

.iti__ls {
  --iti-flag-offset: -2048px;
}

.iti__lt {
  --iti-flag-offset: -2064px;
}

.iti__lu {
  --iti-flag-offset: -2080px;
}

.iti__lv {
  --iti-flag-offset: -2096px;
}

.iti__ly {
  --iti-flag-offset: -2112px;
}

.iti__ma {
  --iti-flag-offset: -2128px;
}

.iti__mc {
  --iti-flag-offset: -2144px;
}

.iti__md {
  --iti-flag-offset: -2160px;
}

.iti__me {
  --iti-flag-offset: -2176px;
}

.iti__mf {
  --iti-flag-offset: -2192px;
}

.iti__mg {
  --iti-flag-offset: -2208px;
}

.iti__mh {
  --iti-flag-offset: -2224px;
}

.iti__mk {
  --iti-flag-offset: -2240px;
}

.iti__ml {
  --iti-flag-offset: -2256px;
}

.iti__mm {
  --iti-flag-offset: -2272px;
}

.iti__mn {
  --iti-flag-offset: -2288px;
}

.iti__mo {
  --iti-flag-offset: -2304px;
}

.iti__mp {
  --iti-flag-offset: -2320px;
}

.iti__mq {
  --iti-flag-offset: -2336px;
}

.iti__mr {
  --iti-flag-offset: -2352px;
}

.iti__ms {
  --iti-flag-offset: -2368px;
}

.iti__mt {
  --iti-flag-offset: -2384px;
}

.iti__mu {
  --iti-flag-offset: -2400px;
}

.iti__mv {
  --iti-flag-offset: -2416px;
}

.iti__mw {
  --iti-flag-offset: -2432px;
}

.iti__mx {
  --iti-flag-offset: -2448px;
}

.iti__my {
  --iti-flag-offset: -2464px;
}

.iti__mz {
  --iti-flag-offset: -2480px;
}

.iti__na {
  --iti-flag-offset: -2496px;
}

.iti__nc {
  --iti-flag-offset: -2512px;
}

.iti__ne {
  --iti-flag-offset: -2528px;
}

.iti__nf {
  --iti-flag-offset: -2544px;
}

.iti__ng {
  --iti-flag-offset: -2560px;
}

.iti__ni {
  --iti-flag-offset: -2576px;
}

.iti__nl {
  --iti-flag-offset: -2592px;
}

.iti__no {
  --iti-flag-offset: -2608px;
}

.iti__np {
  --iti-flag-offset: -2624px;
}

.iti__nr {
  --iti-flag-offset: -2640px;
}

.iti__nu {
  --iti-flag-offset: -2656px;
}

.iti__nz {
  --iti-flag-offset: -2672px;
}

.iti__om {
  --iti-flag-offset: -2688px;
}

.iti__pa {
  --iti-flag-offset: -2704px;
}

.iti__pe {
  --iti-flag-offset: -2720px;
}

.iti__pf {
  --iti-flag-offset: -2736px;
}

.iti__pg {
  --iti-flag-offset: -2752px;
}

.iti__ph {
  --iti-flag-offset: -2768px;
}

.iti__pk {
  --iti-flag-offset: -2784px;
}

.iti__pl {
  --iti-flag-offset: -2800px;
}

.iti__pm {
  --iti-flag-offset: -2816px;
}

.iti__pr {
  --iti-flag-offset: -2832px;
}

.iti__ps {
  --iti-flag-offset: -2848px;
}

.iti__pt {
  --iti-flag-offset: -2864px;
}

.iti__pw {
  --iti-flag-offset: -2880px;
}

.iti__py {
  --iti-flag-offset: -2896px;
}

.iti__qa {
  --iti-flag-offset: -2912px;
}

.iti__re {
  --iti-flag-offset: -2928px;
}

.iti__ro {
  --iti-flag-offset: -2944px;
}

.iti__rs {
  --iti-flag-offset: -2960px;
}

.iti__ru {
  --iti-flag-offset: -2976px;
}

.iti__rw {
  --iti-flag-offset: -2992px;
}

.iti__sa {
  --iti-flag-offset: -3008px;
}

.iti__sb {
  --iti-flag-offset: -3024px;
}

.iti__sc {
  --iti-flag-offset: -3040px;
}

.iti__sd {
  --iti-flag-offset: -3056px;
}

.iti__se {
  --iti-flag-offset: -3072px;
}

.iti__sg {
  --iti-flag-offset: -3088px;
}

.iti__sh {
  --iti-flag-offset: -3104px;
}

.iti__si {
  --iti-flag-offset: -3120px;
}

.iti__sj {
  --iti-flag-offset: -3136px;
}

.iti__sk {
  --iti-flag-offset: -3152px;
}

.iti__sl {
  --iti-flag-offset: -3168px;
}

.iti__sm {
  --iti-flag-offset: -3184px;
}

.iti__sn {
  --iti-flag-offset: -3200px;
}

.iti__so {
  --iti-flag-offset: -3216px;
}

.iti__sr {
  --iti-flag-offset: -3232px;
}

.iti__ss {
  --iti-flag-offset: -3248px;
}

.iti__st {
  --iti-flag-offset: -3264px;
}

.iti__sv {
  --iti-flag-offset: -3280px;
}

.iti__sx {
  --iti-flag-offset: -3296px;
}

.iti__sy {
  --iti-flag-offset: -3312px;
}

.iti__sz {
  --iti-flag-offset: -3328px;
}

.iti__tc {
  --iti-flag-offset: -3344px;
}

.iti__td {
  --iti-flag-offset: -3360px;
}

.iti__tg {
  --iti-flag-offset: -3376px;
}

.iti__th {
  --iti-flag-offset: -3392px;
}

.iti__tj {
  --iti-flag-offset: -3408px;
}

.iti__tk {
  --iti-flag-offset: -3424px;
}

.iti__tl {
  --iti-flag-offset: -3440px;
}

.iti__tm {
  --iti-flag-offset: -3456px;
}

.iti__tn {
  --iti-flag-offset: -3472px;
}

.iti__to {
  --iti-flag-offset: -3488px;
}

.iti__tr {
  --iti-flag-offset: -3504px;
}

.iti__tt {
  --iti-flag-offset: -3520px;
}

.iti__tv {
  --iti-flag-offset: -3536px;
}

.iti__tw {
  --iti-flag-offset: -3552px;
}

.iti__tz {
  --iti-flag-offset: -3568px;
}

.iti__ua {
  --iti-flag-offset: -3584px;
}

.iti__ug {
  --iti-flag-offset: -3600px;
}

.iti__us {
  --iti-flag-offset: -3616px;
}

.iti__uy {
  --iti-flag-offset: -3632px;
}

.iti__uz {
  --iti-flag-offset: -3648px;
}

.iti__va {
  --iti-flag-offset: -3664px;
}

.iti__vc {
  --iti-flag-offset: -3680px;
}

.iti__ve {
  --iti-flag-offset: -3696px;
}

.iti__vg {
  --iti-flag-offset: -3712px;
}

.iti__vi {
  --iti-flag-offset: -3728px;
}

.iti__vn {
  --iti-flag-offset: -3744px;
}

.iti__vu {
  --iti-flag-offset: -3760px;
}

.iti__wf {
  --iti-flag-offset: -3776px;
}

.iti__ws {
  --iti-flag-offset: -3792px;
}

.iti__xk {
  --iti-flag-offset: -3808px;
}

.iti__ye {
  --iti-flag-offset: -3824px;
}

.iti__yt {
  --iti-flag-offset: -3840px;
}

.iti__za {
  --iti-flag-offset: -3856px;
}

.iti__zm {
  --iti-flag-offset: -3872px;
}

.iti__zw {
  --iti-flag-offset: -3888px;
}

.iti__globe {
  background-image: var(--iti-path-globe-1x);
  background-size: contain;
  background-position: right;
  box-shadow: none;
  height: 19px;
}

@media (min-resolution: 2x) {
  .iti__flag {
    background-image: var(--iti-path-flags-2x);
  }
  .iti__globe {
    background-image: var(--iti-path-globe-2x);
  }
}
/**
 * Functions for ViewPort Calculating
============
  SCSS
  .selector {
    font-size: px(18);
    @include md
      font-size: pxm(14);
    @include xs
      font-size: pxs(12);
  }

  CSS RESULT
  .selector {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    .selector {
      font-size: 14px;
    }
  }
  @media (max-width: 320px) {
    .selector {
      font-size: 12px;
    }
  }
============
*/
/**
  function VA => use for calculate letter-spacing from photoshop
============
  SCSS
  .selector {
    letter-spacing: VA(120, 14);
  }

  CSS RESULT
  .selector {
    letter-spacing: 1.68px;
  }
============
  $unit => VA value in photoshop
  $font_size => how px font-size in photoshop ||| options 16
  $return px | em ||| options px
 */
/* Init */
/*  @include adaptive-value("padding-top", 15, 7, 1);
    @include adaptive-value("padding-bottom", 15, 7, 1);
    @include adaptive-value("padding-right", 15, 7, 1);
    @include adaptive-value("padding-left", 15, 7, 1);

    @include adaptive-value("margin-top", 15, 7, 1);
    @include adaptive-value("margin-bottom", 40 , 20, 1);
    @include adaptive-value("margin-right", 15, 7, 1);
    @include adaptive-value("margin-left", 15, 7, 1);

    @include adaptive-value("font-size", 115, 96, 1);
*/
/* "paddibg" - свойство которое нужно адаптировать */
/* 50 - максимальное значение свойства в px */
/* 20 - минимальное значение свойства в px */
/* 0 или 1 или 2 - ($type) условие, по которому будет выполняться адаптив */
/* Touch screens with fingers */
/* Mouse touch pad */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Montserrat-Bold.eot") format("eot");
  src: url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.svg") format("svg"), url("../fonts/Montserrat-Bold.ttf") format("ttf");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Montserrat-Regular.eot") format("eot");
  src: url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.svg") format("svg"), url("../fonts/Montserrat-Regular.ttf") format("ttf");
  font-display: swap;
}
:root {
  --white: #fff;
  --whiteF8: #F8F8F8;
  --whiteFC: #FCFFF5;
  --black: #000;
  --black05: #050B1A;
  --black2F: #2F2F31;
  --blueCE: #CEE3EC80;
  --blue: #007BB3;
  --hover-blue: #004565;
  --grey: #58595B;
  --grey70: #707173;
  --yellow: #E8B524;
  --green: #03C15C;
  --red: #AC1E1E;
  --font-montserrat: "Montserrat",
    sans-serif;
  --font-primary: var(--font-montserrat);
  --font-size: 14px;
  --bg-primary: var(--white);
  --text-color-primary: var(--black);
  --text-color-primary-hover: var(--black);
  --current-scrollbar-width: 0px;
  --header-height: 0px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-family: var(--font-primary);
  font-size: var(--font-size);
  font-weight: normal;
  line-height: 1.3;
  color: var(--text-color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  scroll-behavior: smooth;
}
html::-webkit-scrollbar {
  width: 10px;
}
html::-webkit-scrollbar-track {
  background-color: var(--white);
}
html::-webkit-scrollbar-thumb {
  background-color: var(--blue);
}

body {
  padding-right: var(--current-scrollbar-width);
  background-color: var(--bg-primary);
}
body.lock {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  padding: 0;
  cursor: pointer;
  background-color: initial;
  border: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

video {
  width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  font: inherit;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

fieldset {
  min-inline-size: min-content;
  padding: 0;
  margin: 0;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-image: none;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
}

.content {
  position: relative;
  z-index: 500;
  flex: 1 0 auto;
  overflow: hidden;
}

.container {
  max-width: 1180px;
  padding-right: 20px;
  padding-left: 20px;
  margin: 0 auto;
}

.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}

.important_none {
  display: none;
}

.swiper-button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.arrow_wrap-mob {
  display: none;
}
@media (max-width: 576px) {
  .arrow_wrap-mob {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-color: var(--blue);
  margin-top: 0;
  transition: all 0.3s ease 0s;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: var(--hover-blue);
  transition: all 0.3s ease 0s;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-button-prev .button__image {
  width: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next .button__image {
  width: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination {
  width: max-content !important;
  position: static;
}

.swiper-pagination-bullet {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}
.swiper-pagination-bullet:hover {
  background-color: var(--blue);
  transition: all 0.3s ease 0s;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 24px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
  background-color: var(--blue);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  overflow: hidden;
}

.section_bg_gray {
  background-color: var(--whiteF8);
}

.dial_tariff {
  background-color: var(--whiteF8);
}
@media (max-width: 576px) {
  .dial_tariff {
    padding-bottom: 60px;
  }
}

@media (max-width: 576px) {
  .swiper-pagination {
    display: none;
  }
}

.drop-shadow {
  display: none;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.drop-shadow.show {
  display: block;
}

.title_blue_text b, .title_blue_text strong {
  color: var(--blue);
}

.blog_card {
  max-width: 588px;
  width: 100%;
  border-radius: 5px 30px;
  background: var(--white);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}
.blog_category-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}
.blog_category {
  border-radius: 5px;
  background-color: var(--whiteF8);
  padding: 10px;
  width: max-content;
}
.blog_category span {
  color: var(--hover-blue);
  font-size: 13px;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .blog_descr {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .blog_content {
    flex-direction: column;
  }
  .blog_text-wrap {
    max-width: 100%;
  }
  .blog_category-wrap {
    top: 10px;
    left: 10px;
  }
}
@media (max-width: 992px) {
  .blog_category-wrap {
    top: 10px;
    left: 10px;
  }
}

.hot {
  background-color: var(--yellow);
}
.hot span {
  color: var(--white);
}

.blog_card .card_text {
  padding: 35px 25px;
}
.blog_card .card_title {
  color: var(--black2F);
  font-weight: 700;
  line-height: 144%;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog_card .card_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
.blog_card .card_info-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.blog_card .card_btn {
  min-width: 47px;
  min-height: 47px;
  max-width: 47px;
  max-height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .blog_card .card_btn:hover {
    background-color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .blog_card .card_btn:active {
    background-color: var(--hover-blue);
  }
}
@media (max-width: 576px) {
  .blog_card .card_info-wrap {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .blog_card .card_text {
    padding: 25px 15px;
  }
}

.big_card .card_title {
  font-size: 18px;
}

@media (max-width: 1200px) {
  .big_card .card_title {
    font-size: 14px;
    margin-bottom: 25px;
  }
}
.small_card {
  display: flex;
  align-items: center;
  max-height: 167px;
}
.small_card .blog_category-wrap {
  top: 10px;
  left: 10px;
}
.small_card .blog__image {
  width: 170px;
  height: 167px;
}
.small_card .card_text {
  max-width: 340px;
  width: 100%;
  padding: 25px;
  /* Только если меньше контейнера */
  padding-top: 25px;
  /* Только если меньше контейнера */
  padding-bottom: 25px;
  /* Только если меньше контейнера */
  padding-right: 25px;
  /* Только если меньше контейнера */
  padding-left: 25px;
}
@media (max-width: 1320px) {
  .small_card .card_text {
    padding-top: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .small_card .card_text {
    padding-bottom: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .small_card .card_text {
    padding-right: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .small_card .card_text {
    padding-left: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
.small_card .card_title {
  font-size: 14px;
}
.small_card .card_info-wrap {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

@media (max-width: 576px) {
  .small_card {
    flex-direction: column;
    max-height: 100%;
    height: auto;
  }
  .small_card .blog__image {
    width: 100%;
  }
}
.icon-calendar,
.icon-clock {
  color: var(--blue);
}

.icon-calendar {
  width: 18px;
  height: 18px;
}

.icon-clock {
  width: 20px;
  height: 20px;
}

.icon-arrow_right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 10px;
  color: var(--white);
}

.blog__image {
  display: block;
  width: 100%;
  height: 250px;
  position: relative;
  border-radius: 5px 30px;
  overflow: hidden;
}
.blog__image .blog__img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 576px) {
  .blog__image {
    height: 200px;
  }
}
.info_body {
  display: flex;
  align-items: center;
  gap: 5px;
}
.info_text {
  color: var(--grey);
  font-size: 14px;
  white-space: nowrap;
}

.success_modal-container {
  position: fixed;
  inset: 0;
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease 0s;
}
.success_modal-container.openRequest {
  opacity: 1;
  visibility: visible;
  scale: 1;
  transition: all 0.3s ease 0s;
}
.success_modal-container .success_modal {
  max-width: 600px;
  width: calc(100% - 40px);
  border-radius: 30px;
  /* Только если меньше контейнера */
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 30px;
  /* Только если меньше контейнера */
  padding-right: 40px;
  /* Только если меньше контейнера */
  padding-left: 40px;
  background-color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  scale: 1;
  transition: all 0.3s ease 0s;
  text-align: center;
  padding: 50px 20px;
}
@media (max-width: 1320px) {
  .success_modal-container .success_modal {
    padding-top: calc(50px + -20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .success_modal-container .success_modal {
    padding-bottom: calc(25px + 5 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .success_modal-container .success_modal {
    padding-right: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .success_modal-container .success_modal {
    padding-left: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
.success_modal-container .success_modal.successful_modal_eng .text-wrap {
  max-width: 440px;
}
.success_modal-container .success_modal .close-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 18px;
  right: 18px;
}
.success_modal-container .success_modal .close-btn::after, .success_modal-container .success_modal .close-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 100%;
  height: 1px;
  background-color: var(--black2F);
}
.success_modal-container .success_modal .close-btn::after {
  rotate: -45deg;
}
.success_modal-container .success_modal .close-btn::before {
  rotate: 45deg;
}
.success_modal-container .success_modal .icon {
  color: var(--green);
  width: 97px;
  height: 100px;
  /* Только если меньше контейнера */
  margin-bottom: 60px;
}
@media (max-width: 1320px) {
  .success_modal-container .success_modal .icon {
    margin-bottom: calc(30px + 30 * (100vw - 360px) / 960);
  }
}
.success_modal-container .success_modal .text-wrap {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
}
.success_modal-container .success_modal .text-wrap h3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--black2F);
  margin-bottom: 25px;
}
.success_modal-container .success_modal .text-wrap p {
  font-size: 14px;
  color: var(--grey);
  line-height: 1.4285714286;
  margin-bottom: 25px;
  font-weight: 600;
}
.success_modal-container .success_modal .text-wrap span {
  font-size: 14px;
  color: var(--grey);
}
.success_modal-container .success_modal .text-wrap a {
  color: var(--blue);
  text-decoration: underline;
  font-weight: 600;
}

.section-title {
  text-align: center;
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 40px;
  line-height: 125%;
  margin: 0 auto 50px auto;
  color: var(--black2F);
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .section-title {
    font-size: calc(28px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .section-title {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}

.primary-link {
  color: var(--blue);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.3s ease 0s;
}
.primary-link:hover {
  color: var(--hover-blue);
  transition: all 0.3s ease 0s;
}

.btn {
  display: inline-flex;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: var(--white);
  text-align: center;
  user-select: none;
  background-color: transparent;
  padding: 5px 30px;
  font-size: 14px;
  line-height: 1;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  border: 1px solid transparent;
  outline: 0;
  border-radius: 50px;
  transition: 0.2s ease;
  font-weight: 700;
  font-size: 18px;
  height: 60px;
  white-space: nowrap;
}
.btn_pimary {
  background-color: var(--blue);
  color: var(--white);
}
.btn_pimary:hover {
  background-color: var(--hover-blue);
}
.btn_outline {
  color: var(--blue);
  border: 1px solid var(--blue);
}
.btn_outline:hover {
  background-color: var(--blue);
  color: var(--white);
}
.btn_white {
  background-color: var(--white);
  color: var(--blue, #007BB3);
}
.btn_white:hover {
  color: var(--white);
  background-color: var(--blue);
  border: 1px solid #fff;
}

.play_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: 1;
  width: 104px;
  height: 104px;
  border-radius: 50%;
  background-color: var(--hover-blue);
  border: 2px solid var(--white);
  transition: all 0.3s ease 0s;
}
.play_btn .icon-play {
  width: 22px;
  height: 19px;
  color: var(--white);
}
.play_btn.hide_btn {
  animation: scaleDown 1s linear forwards;
}
@media (max-width: 768px) {
  .play_btn {
    width: 48px;
    height: 48px;
  }
  .play_btn .icon-play {
    width: 16px;
    height: 13px;
  }
}

@media (hover: hover) {
  .play_btn:hover {
    background-color: var(--blue);
    border-color: var(--blue);
    transition: all 0.3s ease 0s;
  }
}
@keyframes scaleDown {
  0% {
    scale: 1;
  }
  25% {
    scale: 1.3;
  }
  50% {
    scale: 1;
  }
  100% {
    scale: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
.burger-btn {
  display: none;
  width: 34px;
  height: 20px;
  position: relative;
  z-index: 2;
}
.burger-btn i {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 4px;
  background-color: var(--blue);
  border-radius: 50px;
  left: 0;
  transition: all 0.3s ease 0s;
}
.burger-btn i:nth-child(1) {
  top: 0;
}
.burger-btn i:nth-child(2) {
  top: 50%;
  width: 75%;
  right: 0;
  left: auto;
}
.burger-btn i:nth-child(3) {
  top: 100%;
}
.burger-btn.active i {
  transition: all 0.3s ease 0s;
}
.burger-btn.active i:nth-child(1) {
  rotate: 45deg;
  top: 50%;
}
.burger-btn.active i:nth-child(2) {
  opacity: 0;
}
.burger-btn.active i:nth-child(3) {
  rotate: -45deg;
  top: 50%;
}

@media (max-width: 1024px) {
  .burger-btn {
    display: block;
  }
}
.accordion .accordion__item {
  width: calc(50% - 20px);
  background-color: var(--white);
  border-radius: 5px 30px 5px 30px;
  overflow: hidden;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}
.accordion .accordion__item:hover {
  background-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.accordion .accordion__item:hover span {
  color: var(--white);
  transition: all 0.5s ease 0s;
}
.accordion .accordion__item:hover .open_status {
  background-color: var(--white);
  transition: all 0.3s ease 0s;
}
.accordion .accordion__item:hover .open_status::after, .accordion .accordion__item:hover .open_status::before {
  background-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.accordion .accordion__item.active {
  background-color: var(--blue);
}
.accordion .accordion__item.active .accordion__toggler {
  background-color: var(--blue);
}
.accordion .accordion__item.active span {
  color: var(--white);
  transition: all 0.3s ease 0s;
}
.accordion .accordion__item.active .item_text-wrap p {
  margin-bottom: 25px;
}
.accordion .accordion__item.active .open_status {
  background-color: var(--white);
  transition: all 0.3s ease 0s;
}
.accordion .accordion__item.active .open_status::after, .accordion .accordion__item.active .open_status::before {
  background-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.accordion .accordion__item.active .open_status::after {
  transform: translate(-50%, -50%) rotate(180deg);
  transition: all 0.3s ease 0s;
}
.accordion .accordion__content {
  display: none;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 5px;
  padding: 25px 25px 0 25px;
  text-align: left;
  transform: translate(0px, -25px);
}
.accordion .accordion__toggler {
  min-height: 105px;
  padding: 25px;
  transition: all 0.3s ease 0s;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  cursor: pointer;
}
@media (max-width: 576px) {
  .accordion .accordion__toggler {
    min-height: auto;
  }
}
@media (max-width: 1024px) {
  .accordion .accordion__item {
    width: 100%;
    height: auto;
  }
}
.accordion span {
  color: var(--blue);
  font-size: 18px;
  line-height: 144.4444444444%;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-align: start;
}
@media (max-width: 576px) {
  .accordion span {
    font-size: 16px;
  }
}
.accordion .open_status {
  position: relative;
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 50%;
  background-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.accordion .open_status::after, .accordion .open_status::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  width: 7.45px;
  height: 2px;
  transition: all 0.3s ease 0s;
}
.accordion .open_status::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.accordion p,
.accordion li,
.accordion b {
  font-size: 14px;
  line-height: 142.8571428571%;
  color: var(--white);
  height: auto;
}
.accordion b {
  display: inline-block;
}
.accordion ul,
.accordion ol {
  padding-left: 15px;
}
.accordion ul li {
  list-style: outside;
}
.accordion ul li b {
  margin: 0;
}
.accordion ol li {
  list-style: auto;
}
.accordion ol li b {
  margin: 0;
}
.accordion li {
  margin-top: 0;
  margin-bottom: 3px;
}

.breadcrumbs {
  /* Только если меньше контейнера */
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1320px) {
  .breadcrumbs {
    margin-bottom: calc(50px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .breadcrumbs {
    display: none;
  }
}
.breadcrumbs .icon-bc_icon {
  width: 6px;
  height: 10px;
  color: var(--blue);
}
.breadcrumbs__list {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.breadcrumbs__item {
  width: max-content;
}
.breadcrumbs__item:last-child {
  text-overflow: ellipsis;
  max-width: 315px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.breadcrumbs__item:last-child .icon {
  display: none;
}
@media (max-width: 576px) {
  .breadcrumbs__item:last-child .icon {
    display: block;
  }
}
.breadcrumbs__link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey);
  font-size: 14px;
  font-weight: 300;
  transition: all 0.3s;
}
@media (max-width: 576px) {
  .breadcrumbs__link {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .breadcrumbs__link .icon {
    rotate: 180deg;
  }
}

a.breadcrumbs__link:hover {
  color: var(--blue);
}

.breadcrumbs_mob {
  display: none;
}
@media (max-width: 576px) {
  .breadcrumbs_mob {
    display: block;
    margin-bottom: 50px;
  }
}

.tabs .tabs__head-row {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .tabs .tabs__head-row {
    column-gap: 25px;
  }
}
@media (max-width: 576px) {
  .tabs .tabs__head-row {
    white-space: nowrap;
    overflow: scroll;
    justify-content: space-between;
    scrollbar-width: none;
  }
  .tabs .tabs__head-row::-webkit-scrollbar {
    display: none;
  }
}
.tabs .tabs__head-col {
  width: 100%;
}
.tabs .tabs__btn {
  padding: 13px 5px;
  color: var(--grey);
  line-height: 144.4444444444%;
  border-bottom: 1px solid var(--grey);
  transition: all 0.3s ease 0s;
  opacity: 0.5;
  width: 100%;
}
.tabs .tabs__btn.active {
  color: var(--blue);
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
  opacity: 1;
}
.tabs .tabs__item {
  opacity: 0;
  display: none;
}
.tabs .tabs__item.active {
  opacity: 1;
  display: flex;
}

.not-found {
  max-width: 1400px;
  width: calc(100% - 20px);
  margin: 0 auto;
  background-color: var(--whiteF8);
  border-radius: 5px 30px;
  position: relative;
  overflow: hidden;
  padding: 60px 20px;
}
.not-found::before {
  content: "";
  position: absolute;
  left: 0;
  /* Только если меньше контейнера */
  top: -1100px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);
}
@media (max-width: 1320px) {
  .not-found::before {
    top: calc(300px + -1400 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .not-found::before {
    width: 638px;
    top: -100px;
    left: -50%;
    transform: translateX(-20%);
  }
}
.not-found_content {
  position: relative;
  z-index: 1;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Только если меньше контейнера */
  gap: 60px;
}
@media (max-width: 1320px) {
  .not-found_content {
    gap: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .not-found_content {
    flex-direction: column;
  }
}
.not-found .not-found__image {
  max-width: 500px;
  width: 100%;
}
.not-found .not-found__image img {
  width: 100%;
  height: 100%;
}
.not-found_text-wrap {
  text-align: start;
  max-width: 580px;
  width: 100%;
}
@media (max-width: 768px) {
  .not-found_text-wrap {
    text-align: center;
  }
}
.not-found__title {
  color: var(--blue);
  text-align: start;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .not-found__title {
    text-align: center;
  }
}
.not-found__title br {
  display: none;
}
@media (max-width: 576px) {
  .not-found__title br {
    display: block;
  }
}
.not-found__text {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
  line-height: 1.4444444444;
  /* Только если меньше контейнера */
  margin-bottom: 60px;
  max-width: 339px;
  width: 100%;
}
@media (max-width: 1320px) {
  .not-found__text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .not-found__text {
    margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .not-found__text {
    margin: 0 auto 40px auto;
  }
}
.not-found .not-found__link {
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .not-found .not-found__link {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .not-found .not-found__link {
    width: 100%;
  }
}

.not_found_ua .not-found__text {
  max-width: 445px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  padding-block: 10vh;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal._show {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}
.modal__dialog {
  width: 100%;
  max-width: 800px;
  height: calc(100% - 20vh);
  margin: 0 auto;
  background-color: #fff;
}
.modal__header-close-btn {
  display: block;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
}

.modal_cookies {
  position: fixed;
  right: -100%;
  bottom: 35px;
  z-index: 999;
  max-width: 400px;
  width: 100%;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: var(--white);
  transition: all 0.3s ease 0s;
}
.modal_cookies.show {
  right: 35px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 576px) {
  .modal_cookies.show {
    max-width: 100%;
    width: calc(100% - 30px);
    right: 50%;
    transform: translateX(50%);
  }
}
.modal_cookies .modal__header-title {
  font-size: 14px;
  line-height: 1.4285714286;
  margin-bottom: 20px;
}
.modal_cookies .modal__header-title a {
  color: var(--blue);
  text-decoration: underline;
}
@media (max-width: 576px) {
  .modal_cookies .modal__header-title {
    text-align: center;
  }
}
.modal_cookies .modal__btn-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 480px) {
  .modal_cookies .modal__btn-wrap {
    flex-wrap: wrap;
  }
}
.modal_cookies button,
.modal_cookies a {
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  width: 100%;
}

.dropdown__item {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition-timing-function: ease;
  transition-duration: 0.5s;
  transition-property: opacity, visibility;
}
.dropdown__item._active {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
}

.radio {
  position: relative;
  display: block;
}
.radio__input {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: pointer;
  border-radius: inherit;
}
.radio__custom {
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.checkbox {
  position: relative;
  display: block;
}
.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: pointer;
}
.checkbox__custom {
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.input_body {
  position: relative;
}
.input_body .alert-name__image,
.input_body .alert-email__image,
.input_body .alert-phone__image {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 0.3s ease 0s;
  height: 20px;
  width: 20px;
  scale: 1;
}
.input_body .alert-name__image.hide,
.input_body .alert-email__image.hide,
.input_body .alert-phone__image.hide {
  scale: 0;
}

.form_input {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  border-radius: 500px;
  padding: 25px 20px;
  width: 100%;
  font-size: 16px;
  color: var(--white);
  transition: all 0.3s ease 0s;
  height: 60px;
}
.form_input::placeholder {
  color: #FCFFF5;
  opacity: 0.7;
}
.form_input:focus {
  outline: none;
  border-color: var(--white);
  transition: all 0.3s ease 0s;
}
.form_input:focus-visible {
  outline: none;
  border-color: var(--white);
  transition: all 0.3s ease 0s;
}

#phone {
  padding-left: 75px;
}

.iti {
  width: 100%;
}

.iti--inline-dropdown .iti__dropdown-content {
  border-radius: 10px;
  background: var(--white);
  overflow: hidden;
  width: 330px !important;
  margin-top: 5px;
}

.iti__country-container {
  padding: 0;
}

.iti__flag-container:hover {
  padding: 0;
}

.iti__arrow--up {
  border-top: 4px solid transparent !important;
  border-bottom: 4px solid var(--white);
  transition: all 0.3s ease 0s;
}

.iti__selected-country-primary {
  border-radius: 500px 0 0 500px;
  padding: 0 10px 0 20px;
  position: relative;
}

.iti__arrow {
  border-top: 4px solid var(--white);
  margin-left: 10px;
  transition: all 0.3s ease 0s;
}

.iti__search-input {
  outline: none;
  height: 30px;
  padding: 8px;
  border-bottom: 1px solid var(--blue);
  border-radius: 0;
  caret-color: var(--blue);
  box-shadow: 0 0px 10px var(--blue);
}

.iti__country-list::-webkit-scrollbar {
  width: 10px;
}
.iti__country-list::-webkit-scrollbar-track {
  background-color: var(--white);
}
.iti__country-list::-webkit-scrollbar-thumb {
  background-color: var(--blue);
}

.form_title {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .form_title {
    margin-bottom: 25px;
    font-size: 18px;
    text-align: center;
  }
}
.tel_label {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: var(--white);
  margin-top: 15px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .tel_label {
    display: none;
  }
}

.check_wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: var(--white);
  margin-bottom: 40px;
}
.check_wrap span b {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .check_wrap {
    margin-top: 15px;
    margin-bottom: 25px;
  }
}

.checkbox {
  display: none;
}

.custom-checkbox {
  min-width: 24px;
  min-height: 24px;
  border-radius: 6px;
  border: 1px solid var(--white);
  margin-right: 10px;
  accent-color: var(--white);
  background-color: transparent;
  position: relative;
  transition: all 0.3s ease 0s;
}

.checkbox:checked + .custom-checkbox {
  background-color: var(--white);
  transition: all 0.3s ease 0s;
}

.checkbox:checked + .custom-checkbox:before {
  content: url(../svg/check-arrow.svg);
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  transition: all 0.3s ease 0s;
  height: 100%;
}

.form-btn {
  width: 100%;
  height: 60px;
}

@media (max-width: 768px) {
  .form-btn {
    padding: 10px;
  }
}
.error-message {
  position: absolute;
  bottom: -20px;
  left: 10px;
  /* Только если меньше контейнера */
  font-size: 14px;
  color: var(--black2F);
}
@media (max-width: 1320px) {
  .error-message {
    font-size: calc(12px + 2 * (100vw - 360px) / 960);
  }
}

.form-btn:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.icon-alert {
  width: 20px;
  height: 20px;
  color: var(--red);
}

.hide {
  scale: 0;
  transition: all 0.3s ease 0s;
}

.header {
  background: var(--white);
  z-index: 1000;
  position: relative;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}
.header__container {
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
}
.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 80px;
  /* Только если меньше контейнера */
  column-gap: 80px;
}
@media (max-width: 1320px) {
  .header__wrap {
    column-gap: calc(-80px + 160 * (100vw - 360px) / 960);
  }
}
.header__logo {
  position: relative;
  z-index: 3;
}
@media (max-width: 1024px) {
  .header__logo {
    width: 90px;
    height: auto;
  }
}
.header .logo__image {
  display: inline-block;
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .header .logo__image img {
    width: 100%;
    height: 100%;
  }
}
.header__nav-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 20px;
}
@media (max-width: 1024px) {
  .header__nav-wrap {
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 2;
    transition: all 0.3s ease 0s;
    max-width: 500px;
    width: 100%;
    height: 100dvh;
    background-color: var(--white);
    padding: 90px 0 35px 0;
    padding-top: 90px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    border-left: 1px solid var(--blue);
    box-shadow: 3px 0px 15px var(--blue);
  }
}
@media (max-width: 576px) {
  .header__nav-wrap {
    padding: 90px 0 35px 0;
  }
}
.header .activeMenu {
  right: 0;
  transition: all 0.3s ease 0s;
}
.header__navbar {
  flex-grow: 1;
}
@media (max-width: 1024px) {
  .header__navbar {
    overflow-y: auto;
    width: 100%;
    flex-grow: 0;
  }
}
.header__menu {
  display: flex;
  align-items: center;
  z-index: 3;
  column-gap: 25px;
}
.header__menu .link_wrap {
  display: flex;
  align-items: center;
  column-gap: 7px;
  color: var(--black2F);
  font-size: 13px;
  width: max-content;
  position: relative;
}
.header__menu .link_wrap::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
  background: var(--blue);
  transition: 0.3s;
  border-radius: 5px;
  transform: scale(0);
}
@media (hover: hover) {
  .header__menu .link_wrap:hover {
    color: var(--blue);
    transition: all 0.3s ease 0s;
  }
  .header__menu .link_wrap:hover::before {
    transform: scale(1);
    transition: 0.3s;
  }
}
@media (max-width: 1024px) {
  .header__menu .link_wrap {
    padding: 25px 15px;
    background-color: var(--white);
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
    justify-content: space-between;
  }
}
.header__menu .icon-arrow_drop {
  width: 6px;
  height: 4px;
  color: var(--blue);
  margin-top: 2px;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .header__menu .icon-arrow_drop {
    width: 9px;
    height: 6px;
  }
}
@media (max-width: 1024px) {
  .header__menu {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
    padding: 15px 20px 30px 20px;
  }
}
.header .lang_switcher {
  display: flex;
  align-items: center;
  background-color: var(--whiteF8);
  border-radius: 50px;
  padding: 3px;
}
.header .lang_switcher .lang_btn {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  color: var(--black2F);
  font-size: 14px;
  transition: all 0.3s ease 0s;
}
.header .lang_switcher .lang_btn.active_lang {
  background-color: var(--blue);
  color: var(--white);
  transition: all 0.3s ease 0s;
}
.header__buttons {
  display: flex;
  /* Только если меньше контейнера */
  gap: 40px;
  position: relative;
  z-index: 3;
}
@media (max-width: 1320px) {
  .header__buttons {
    gap: calc(-40px + 80 * (100vw - 360px) / 960);
  }
}
.header__buttons .btn {
  font-weight: 400;
  width: 100%;
  height: 40px;
  font-size: 14px;
}
.header__buttons .phone_link {
  display: flex;
  align-items: center;
  gap: 3px;
}
.header__buttons .phone_link .icon-phone_2 {
  width: 12px;
  height: 12px;
  color: var(--blue);
}
.header__buttons .phone_link span {
  color: var(--black2F);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}
.header .hide_switcher {
  display: none;
  position: relative;
  z-index: 5;
}
@media (max-width: 1024px) {
  .header .hidden_login {
    display: none;
  }
}
.header .login_mob {
  display: none;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  background-color: var(--white);
  z-index: 5;
}
@media (max-width: 1024px) {
  .header .login_mob {
    display: flex;
  }
}
@media (max-width: 1024px) {
  .header__buttons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20%;
    z-index: -1;
  }
  .header__buttons .phone_link {
    margin-bottom: 40px;
  }
  .header__buttons .phone_link .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .header__buttons .phone_link span {
    font-size: 20px;
  }
  .header .lang_switcher {
    display: none;
  }
  .header .hide_switcher {
    display: block;
  }
}
@media (max-height: 740px) {
  .header__buttons {
    bottom: 5%;
  }
}
.header .header_backdrop {
  display: none;
  position: fixed;
  top: 89px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
}
.header .header_backdrop.show {
  display: block;
}
.header .dropdown.open .dropdown_content {
  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s ease-in 0.1s, max-height 0.4s ease-in 0s;
}
.header .dropdown.open .drop_btn .icon-arrow_drop {
  transition: 0.3s;
  rotate: 180deg;
}
@media (max-width: 1024px) {
  .header .dropdown {
    width: 100%;
    background-color: var(--white);
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  }
  .header .dropdown.open .dropdown_content {
    height: 330px;
    transition: all 0.3s ease 0s;
    padding: 20px 15px;
  }
  .header .dropdown.open .link_wrap {
    color: var(--blue);
  }
}
.header .dropdown_content {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 60px;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  top: 89px;
  left: 0;
  background-color: var(--white);
  z-index: 3;
  padding-left: 60px;
  padding-right: 60px;
  padding: 40px 15px 40px 15px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
}
.header .dropdown_content .close_sub-menu {
  display: none;
}
@media (max-width: 1024px) {
  .header .dropdown_content {
    position: static;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;
    box-shadow: none;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    padding: 0;
    transition: all 0.3s ease 0s;
    background-color: var(--whiteF8);
    border-radius: 2px;
    max-width: 100%;
    width: calc(100% - 40px);
  }
}
.header .dropdown_row {
  display: flex;
  justify-content: center;
  gap: 60px;
}
@media (max-width: 1024px) {
  .header .dropdown_row {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
}
.header .column {
  transition: all 0.3s linear;
}
.header .column .column_title {
  color: var(--blue);
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 25px;
}
@media (max-width: 1024px) {
  .header .column .column_title {
    width: 100%;
    margin-bottom: 20px;
  }
}
.header .column .column_link-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.header .column .features .column_link {
  margin-bottom: 0;
}
.header .column .features .primary-link {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 5px;
}
.header .column .features .primary-link .icon-arrow_drop {
  width: 6px;
  height: 4px;
  rotate: -90deg;
}
@media (max-width: 1024px) {
  .header .column .features .primary-link {
    justify-content: center;
  }
}
.header .column .column_link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--black2F);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .header .column .column_link:hover {
    color: var(--blue);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .header .column .column_link:active {
    color: var(--blue);
    transition: all 0.3s ease 0s;
  }
}
.header .column .column_text {
  color: var(--grey70);
  font-size: 13px;
  font-weight: 400;
  line-height: 123%;
  max-width: 161px;
  width: 100%;
}
@media (max-width: 1024px) {
  .header .column .column_text {
    max-width: 100%;
  }
}
.header .column .primary-link:hover .icon-arrow_drop {
  color: var(--hover-blue);
  transform: translatey(5px);
}
.header .list_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
@media (max-width: 1024px) {
  .header .list_wrap {
    row-gap: 25px;
  }
  .header .column {
    width: 100%;
  }
}
.header .integrations_content {
  flex-direction: column;
  align-items: center;
}
.header .integrations_content .column_link-wrap {
  row-gap: 15px;
}
.header .integrations_content .column_link {
  margin-bottom: 0;
}
.header .integrations_content .column_link span {
  opacity: 0.8;
}
.header .integrations_content .column_text {
  max-width: 197px;
}
.header .integrations_content .integrations_link {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--blue);
  font-size: 14px;
  font-weight: 500;
}
.header .integrations_content .integrations_link .icon-arrow_drop {
  rotate: -90deg;
}
.header .integrations_content .integrations_link {
  color: var(--hover-blue);
  transition: all 0.3s ease 0s;
}
.header .integrations_content .integrations_link:hover .icon-arrow_drop {
  color: var(--hover-blue);
  transform: translatey(5px);
}
@media (max-width: 1024px) {
  .header .integrations_content .column_text {
    max-width: 100%;
  }
}
@media (max-width: 1024px) {
  .header .integrations_content .column_list,
  .header .integrations_content .list_item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .header .integrations_content .column_link-body {
    width: 130px;
  }
  .header .integrations_content .column_link-body span {
    white-space: nowrap;
  }
  .header .integrations_content .column_title {
    margin: 0;
  }
  .header .integrations_content .features_list {
    flex-direction: column;
  }
}
.header .resources_content .column_link {
  margin-bottom: 0;
}
.header .divider_col {
  padding-left: 60px;
  border-left: 1px solid var(--grey);
  min-height: 100%;
  max-width: 227px;
  width: 100%;
}
@media (max-width: 1024px) {
  .header .divider_col {
    padding-left: 10px;
    min-height: max-content;
    max-width: 100%;
    border: none;
    padding: 0;
  }
  .header .divider_col .column_title {
    border: none;
  }
}

.header_eng .header__buttons {
  gap: 15px;
}

.hero {
  /* Только если меньше контейнера */
  padding-bottom: 175px;
}
@media (max-width: 1320px) {
  .hero {
    padding-bottom: calc(0px + 175 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .hero {
    overflow: hidden;
  }
}
.hero .container {
  position: relative;
  z-index: 2;
}
@media (max-width: 576px) {
  .hero .container {
    padding: 0;
  }
}
.hero__wrap {
  position: relative;
  padding-top: 60px;
  background: #F8F8F8;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px 30px;
  background: var(--light-grey, #F8F8F8);
}
@media (max-width: 576px) {
  .hero__wrap {
    margin-bottom: 110px;
  }
}
.hero__wrap::after, .hero__wrap::before {
  position: absolute;
  top: 0;
  z-index: 0;
}
.hero__wrap::after {
  content: url(../img/hero/ellipse_right.png);
  right: 0;
}
@media (max-width: 992px) {
  .hero__wrap::after {
    display: none;
  }
}
.hero__wrap::before {
  content: url(../img/hero/ellipse_left.png);
  left: 0;
}
.hero__content {
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}
.hero__title {
  margin-bottom: 40px;
}
.hero__title h1 {
  color: var(--black2F);
  text-align: center;
  /* Только если меньше контейнера */
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
}
@media (max-width: 1320px) {
  .hero__title h1 {
    font-size: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .hero__title h1 {
    text-align: start;
    font-size: 20px;
  }
}
.hero__title h1 br {
  display: none;
}
@media (max-width: 576px) {
  .hero__title h1 br {
    display: block;
  }
}
@media (max-width: 576px) {
  .hero__title h1 .big_text, .hero__title h1 strong {
    color: var(--blue);
    font-size: 120px;
    line-height: 120px;
  }
}
.hero__title h1 em {
  color: var(--blue);
  font-style: normal;
}
@media (max-width: 576px) {
  .hero__title {
    padding: 0 20px;
  }
}
.hero__text {
  max-width: 685px;
  width: 100%;
  margin: 0 auto;
  /* Только если меньше контейнера */
  margin-bottom: 60px;
}
@media (max-width: 1320px) {
  .hero__text {
    margin-bottom: calc(50px + 10 * (100vw - 360px) / 960);
  }
}
.hero__text p {
  color: var(--grey);
  text-align: center;
  /* Только если меньше контейнера */
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4444444444;
}
@media (max-width: 1320px) {
  .hero__text p {
    font-size: calc(16px + 4 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .hero__text p {
    text-align: start;
    padding: 0 20px;
  }
}
.hero__action-btn {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px 30px;
}
.hero__action-btn .blue_btn {
  text-decoration: underline;
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
.hero__action-btn .blue_btn:hover {
  color: var(--hover-blue);
  transition: all 0.3s ease 0s;
}
@media (max-width: 576px) {
  .hero__action-btn {
    padding: 0 20px;
  }
}
.hero__btn {
  color: var(--white);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 144.144%;
}
@media (max-width: 576px) {
  .hero__btn {
    width: 100%;
  }
}
.hero__action-descr {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .hero__action-descr {
    margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .hero__action-descr {
    justify-content: flex-start;
    padding: 0 20px;
  }
}
.hero__action-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.hero__action-item .icon-check {
  min-width: 7px;
  min-height: 5px;
  max-width: 7px;
  max-height: 5px;
  color: var(--blue);
}
.hero__action-item p {
  font-weight: 300;
  font-size: 14px;
  color: var(--grey);
}
@media (max-width: 576px) {
  .hero__action-item p {
    text-align: start;
  }
}
.hero__video {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px 30px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  top: 60px;
}
@media (max-width: 576px) {
  .hero__video {
    top: 0;
    width: calc(100% - 40px);
    margin: -110px auto 0 auto;
    transform: translate(0, 110px);
  }
}
.hero .video_poster__image {
  display: inline-block;
  width: 100%;
  height: auto;
}
.hero .video_poster__image .video_poster__img {
  width: 100%;
  height: auto;
}
.hero .hero__image {
  width: 45px;
  height: auto;
}
.hero .hero__image .hero__img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .hero__icons {
    display: none;
  }
}
.hero__icons-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 20px;
}
.hero__icons-mob {
  flex-shrink: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  min-width: 100%;
  margin-bottom: 40px;
  animation: scroll 20s linear infinite;
}
@media (max-width: 576px) {
  .hero__icons-mob {
    display: flex;
  }
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}
.hero__icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 5px 25px;
  background: var(--white);
  box-shadow: 0px 5.398px 16.87px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
}
.hero__icon_1 {
  transform: rotate(10deg);
  top: 3%;
  left: 9%;
}
@media (max-width: 576px) {
  .hero__icon_1 .hero__image {
    transform: rotate(-10deg);
  }
}
.hero__icon_2 {
  transform: rotate(-5deg);
  top: 15%;
  left: 6%;
}
@media (max-width: 576px) {
  .hero__icon_2 .hero__image {
    transform: rotate(5deg);
  }
}
.hero__icon_3 {
  transform: rotate(10deg);
  top: 25%;
  left: 13%;
}
@media (max-width: 576px) {
  .hero__icon_3 .hero__image {
    transform: rotate(-10deg);
  }
}
.hero__icon_4 {
  transform: rotate(-15deg);
  top: 35.67%;
  left: 8.64%;
}
@media (max-width: 576px) {
  .hero__icon_4 .hero__image {
    transform: rotate(15deg);
  }
}
.hero__icon_5 {
  transform: rotate(-12deg);
  top: 2.64%;
  right: 9%;
}
@media (max-width: 576px) {
  .hero__icon_5 .hero__image {
    transform: rotate(12deg);
  }
}
.hero__icon_6 {
  transform: rotate(5deg);
  top: 14.64%;
  right: 6%;
}
@media (max-width: 576px) {
  .hero__icon_6 .hero__image {
    transform: rotate(-5deg);
  }
}
.hero__icon_7 {
  transform: rotate(-5deg);
  top: 25.64%;
  right: 15.64%;
}
@media (max-width: 576px) {
  .hero__icon_7 .hero__image {
    transform: rotate(5deg);
  }
}
.hero__icon_8 {
  transform: rotate(10deg);
  top: 35.64%;
  right: 8.64%;
}
@media (max-width: 576px) {
  .hero__icon_8 .hero__image {
    transform: rotate(-10deg);
  }
}
@media (max-width: 1200px) {
  .hero__icon_1 {
    top: 30%;
    left: 3%;
  }
  .hero__icon_2 {
    top: 27%;
    left: 12%;
  }
  .hero__icon_3 {
    top: 38%;
    left: 15%;
  }
  .hero__icon_4 {
    top: 40%;
    left: 4%;
  }
  .hero__icon_5 {
    top: 30%;
    right: 3%;
  }
  .hero__icon_6 {
    top: 27%;
    right: 13%;
  }
  .hero__icon_7 {
    top: 38%;
    right: 15%;
  }
  .hero__icon_8 {
    top: 40%;
    right: 4%;
  }
}
@media (max-width: 576px) {
  .hero__icon {
    position: static;
    transform: rotate(0);
    min-width: 80px;
    min-height: 80px;
  }
}

.pluses {
  /* Только если меньше контейнера */
  padding-top: 50px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .pluses {
    padding-top: calc(60px + -10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .pluses {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.pluses__wrap {
  display: flex;
  justify-content: center;
  gap: 80px;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .pluses__wrap {
    gap: 30px;
  }
}
.pluses__col {
  max-width: 235px;
}
.pluses__col:nth-child(1) .icon {
  color: var(--blue);
}
.pluses__col:nth-child(2) .icon {
  color: var(--yellow);
}
.pluses__col:nth-child(3) .icon {
  color: var(--green);
}
@media (max-width: 576px) {
  .pluses__col {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.pluses__icon {
  max-width: 110px;
  width: 100%;
  height: 110px;
  margin-bottom: 40px;
  border-radius: 5px 25px;
  background: var(--white);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .pluses__icon {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    margin: 0;
  }
}
.pluses__icon .icon {
  width: 43px;
  height: 43px;
}
.pluses__descr-title {
  margin-bottom: 20px;
}
.pluses__descr-title h2 {
  /* Только если меньше контейнера */
  font-size: 22px;
  font-weight: 700;
}
@media (max-width: 1320px) {
  .pluses__descr-title h2 {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.pluses__descr-title_blue h2 {
  color: var(--blue);
}
.pluses__descr-title_yellow h2 {
  color: var(--yellow);
}
.pluses__descr-title_green h2 {
  color: var(--green);
}
.pluses__descr-text {
  padding-right: 31px;
}
.pluses__descr-text p {
  color: var(--grey);
  /* Только если меньше контейнера */
  font-size: 18px;
  font-weight: 400;
  line-height: 144.444%;
}
@media (max-width: 1320px) {
  .pluses__descr-text p {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .pluses__descr-text {
    padding-right: 0;
  }
}

.product {
  padding: 100px 0;
  background-color: var(--whiteF8);
}
@media (max-width: 768px) {
  .product {
    padding: 50px 0;
  }
}
.product .container {
  padding: 0;
}
.product_title {
  max-width: 700px;
  width: 100%;
  padding: 0 20px;
  line-height: 128%;
}
@media (max-width: 576px) {
  .product_title {
    text-align: start;
  }
}
.product .swiper-slide {
  width: 365px;
}
@media (max-width: 768px) {
  .product .swiper-slide {
    width: auto;
  }
}
.product .product_swiper {
  padding: 0 20px 20px 20px;
}
.product .swiper-button-wrap {
  display: none;
}
@media (max-width: 768px) {
  .product .swiper-button-wrap {
    display: flex;
  }
}
@media (max-width: 576px) {
  .product .swiper-button-wrap {
    gap: 30px;
  }
}
.product_cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 768px) {
  .product_cards-wrapper {
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0;
  }
}
.product_card {
  background-color: var(--white);
  width: 365px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px 30px 5px 30px;
  overflow: hidden;
  height: 480px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .product_card {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .product_card {
    min-height: 455px;
  }
}
.product ._modify {
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 35px 15px;
}
.product ._modify .product_card-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0;
  flex: 1;
  width: calc(100% - 30px);
  height: max-content;
  justify-content: center;
}
.product ._modify .card_title {
  font-size: 28px;
  line-height: 128.5714285714%;
  color: var(--white);
  margin: 0;
}
.product ._modify .card_descr {
  font-size: 18px;
  line-height: 144.4444444444%;
  color: var(--white);
}
.product ._modify .product_link {
  color: var(--blue);
  font-size: 18px;
  line-height: 144.4444444444%;
  font-weight: 700;
  background-color: var(--white);
  height: 60px;
  width: 100%;
}
@media (hover: hover) {
  .product ._modify .product_link:hover {
    background-color: var(--blue);
    color: var(--white);
    border-color: var(--white);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .product ._modify .product_link:active {
    background-color: var(--blue);
    color: var(--white);
    border-color: var(--white);
  }
}
.product_card-img {
  width: 100%;
  height: 240px;
}
.product_card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 430px) {
  .product_card-img {
    height: auto;
  }
}
.product_card-text {
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 50%;
  flex: 1;
}
@media (max-width: 430px) {
  .product_card-text {
    height: auto;
    gap: 20px;
  }
}
.product .card_title {
  font-size: 22px;
  font-weight: 700;
  line-height: 127.2727272727%;
  color: var(--blue);
  margin-bottom: 20px;
}
@media (max-width: 430px) {
  .product .card_title {
    margin-bottom: 0;
  }
}
.product .card_descr {
  font-size: 14px;
  line-height: 142.8571428571%;
  color: var(--grey);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product_link {
  text-align: center;
  font-size: 13px;
  line-height: 123.0769230769%;
  color: var(--blue);
  border: 1px solid var(--blue);
  padding: 15px 20px;
  margin: 0 auto;
  height: 40px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  font-weight: 400;
  max-width: min-content;
}

.product-en .product_title {
  max-width: 550px;
}

.benefit {
  padding: 100px 0;
}
@media (max-width: 576px) {
  .benefit .benefit_tabs {
    display: none;
  }
}
.benefit .benefit_tabs .tabs__head-col {
  width: max-content;
}
.benefit .benefit_tabs .tabs__btn {
  font-size: 18px;
}
.benefit .benefit_tabs .benefit_card-title {
  min-height: 55px;
}
.benefit_ip-pbx .benefit_tabs .benefit_card-title {
  min-height: auto;
}
.benefit_ip-pbx .benefit_accordion .benefit_card-title {
  font-size: 18px;
  min-height: 40px;
}
.benefit .benefit_accordion {
  display: none;
}
@media (max-width: 576px) {
  .benefit .benefit_accordion {
    display: block;
  }
}
.benefit .benefit_accordion .accordion__item.active .accordion__toggler {
  color: var(--white);
}
.benefit .benefit_accordion .accordion__item:not(:last-child) {
  margin-bottom: 10px;
}
.benefit .benefit_accordion .accordion__toggler {
  font-size: 16px;
  font-weight: 700;
  color: var(--blue);
}
.benefit .benefit_card-wrap {
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(240px, 270px));
  justify-content: center;
  gap: 20px;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  counter-reset: num;
}
@media (max-width: 1170px) {
  .benefit .benefit_card-wrap {
    grid-template: 1fr/repeat(auto-fit, minmax(330px, 48%));
  }
}
@media (max-width: 720px) {
  .benefit .benefit_card-wrap {
    grid-template: 1fr/1fr;
  }
}
.benefit .benefit_card {
  transition: all 0.3s ease 0s;
  background-color: var(--whiteF8);
  border-radius: 5px 30px 5px 30px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  padding: 25px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  position: relative;
}
.benefit .benefit_card.familiar {
  row-gap: 0;
}
@media (max-width: 768px) {
  .benefit .benefit_card {
    row-gap: 20px;
  }
}
.benefit .benefit_card:nth-child(4n+1) .benefit_card-number {
  background-color: var(--blue);
}
.benefit .benefit_card:nth-child(4n+2) .benefit_card-number {
  background-color: var(--green);
}
.benefit .benefit_card:nth-child(4n+3) .benefit_card-number {
  background-color: var(--yellow);
}
.benefit .benefit_card:nth-child(4n+4) .benefit_card-number {
  background-color: var(--black2F);
}
.benefit .benefit_card-number {
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  line-height: 155.5555555556%;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  counter-increment: num;
}
.benefit .benefit_card-number::before {
  content: "0" counter(num);
}
.benefit .benefit_card-title {
  /* Только если меньше контейнера */
  font-size: 18px;
  line-height: 155.5555555556%;
  font-weight: 700;
  color: var(--black2F);
}
@media (max-width: 1320px) {
  .benefit .benefit_card-title {
    font-size: calc(14px + 4 * (100vw - 360px) / 960);
  }
}
.benefit .benefit_card-text {
  font-size: 14px;
  line-height: 142.8571428571%;
  color: var(--grey);
}
.benefit .benefit_tab_pane {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.benefit .benefit_tab_pane .benefit_card {
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.benefit .active-pane {
  display: block;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease 0s;
}
.benefit .active-pane .benefit_card {
  opacity: 1;
  transition: all 1s ease 0s;
}
.benefit .active-pane .benefit_card:nth-child(1) {
  transition-delay: 0.1s;
}
.benefit .active-pane .benefit_card:nth-child(2) {
  transition-delay: 0.4s;
}
.benefit .active-pane .benefit_card:nth-child(3) {
  transition-delay: 0.8s;
}
.benefit .active-pane .benefit_card:nth-child(4) {
  transition-delay: 1.2s;
}

@media (max-width: 768px) {
  .benefit {
    padding: 50px 0;
  }
}
.works {
  background-color: var(--whiteF8);
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .works {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .works {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.works__row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
@media (max-width: 1200px) {
  .works__row {
    flex-direction: column;
    gap: 50px;
  }
}
.works__circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 273px;
  height: 273px;
  border-radius: 50%;
  background-color: var(--blue);
  position: relative;
  z-index: 10;
}
@media (max-width: 992px) {
  .works__circle {
    flex-direction: column;
    background-color: transparent;
    width: auto;
    height: auto;
  }
}
.works__circle-title {
  /* Только если меньше контейнера */
  font-size: 22px;
  color: var(--white);
}
@media (max-width: 1320px) {
  .works__circle-title {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .works__circle-title {
    margin: 0 auto 20px auto;
  }
}
.works__left, .works__right {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .works__left, .works__right {
    border-radius: 5px 30px;
    background-color: var(--blue);
    padding: 25px 15px;
  }
}
.works__left {
  position: relative;
  right: -20px;
}
.works__left .icon-long_arrow {
  left: -30px;
}
@media (max-width: 1200px) {
  .works__left .icon-long_arrow {
    display: none;
  }
}
@media (max-width: 992px) {
  .works__left .icon-long_arrow {
    display: block;
    left: 50%;
    transform: translateY(50%);
    bottom: -40px;
    rotate: 90deg;
    z-index: 2;
  }
}
@media (max-width: 1200px) {
  .works__left {
    right: 0;
  }
}
.works .tip_wrap {
  position: absolute;
  top: -13%;
  right: -8%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.works .tip_wrap .icon-tip_arrow {
  width: 24px;
  height: 15px;
  color: var(--grey);
}
.works .tip_wrap .tip_text {
  display: inline-block;
  font-size: 13px;
  color: var(--grey);
  max-width: 150px;
}
@media (max-width: 1200px) {
  .works .tip_wrap {
    top: -15%;
    right: -55%;
  }
}
@media (max-width: 992px) {
  .works .tip_wrap {
    display: none;
  }
}
.works__icon-body-left {
  position: absolute;
}
@media (max-width: 992px) {
  .works__icon-body-left {
    position: static;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
}
@media (max-width: 576px) {
  .works__icon-body-left {
    row-gap: 10px;
  }
}
.works__icon-body-left .works__icon-wrap:nth-child(1) {
  transform: rotate(15deg) translateY(-155px) rotate(-15deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(1) .icon {
  color: var(--blue);
}
@media (max-width: 992px) {
  .works__icon-body-left .works__icon-wrap:nth-child(1) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--blue);
  }
}
.works__icon-body-left .works__icon-wrap:nth-child(2) {
  transform: rotate(48deg) translateY(-120px) rotate(-48deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(2) .icon {
  color: var(--yellow);
}
@media (max-width: 992px) {
  .works__icon-body-left .works__icon-wrap:nth-child(2) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--yellow);
  }
}
.works__icon-body-left .works__icon-wrap:nth-child(3) {
  transform: rotate(94deg) translateY(-89px) rotate(-94deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(3) .icon {
  color: var(--green);
}
@media (max-width: 992px) {
  .works__icon-body-left .works__icon-wrap:nth-child(3) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--green);
  }
}
.works__icon-body-left .works__icon-wrap:nth-child(4) {
  transform: rotate(153deg) translateY(-85px) rotate(-153deg);
}
.works__icon-body-left .works__icon-wrap:nth-child(4) .icon {
  color: var(--black2F);
}
@media (max-width: 992px) {
  .works__icon-body-left .works__icon-wrap:nth-child(4) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--black2F);
  }
}
@media (max-width: 1200px) {
  .works__icon-body-left .works__icon-wrap:nth-child(1) {
    transform: rotate(15deg) translateY(-155px) rotate(-15deg);
  }
  .works__icon-body-left .works__icon-wrap:nth-child(2) {
    transform: rotate(48deg) translateY(-120px) rotate(-48deg);
  }
  .works__icon-body-left .works__icon-wrap:nth-child(3) {
    transform: rotate(94deg) translateY(-89px) rotate(-94deg);
  }
  .works__icon-body-left .works__icon-wrap:nth-child(4) {
    transform: rotate(153deg) translateY(-85px) rotate(-153deg);
  }
}
@media (max-width: 992px) {
  .works__icon-body-left .works__icon-wrap:nth-child(1), .works__icon-body-left .works__icon-wrap:nth-child(2), .works__icon-body-left .works__icon-wrap:nth-child(3), .works__icon-body-left .works__icon-wrap:nth-child(4) {
    transform: none;
  }
}
.works__right {
  flex-direction: row-reverse;
  position: relative;
  left: -20px;
}
.works__right .icon-long_arrow {
  rotate: 180deg;
  right: -30px;
}
@media (max-width: 1200px) {
  .works__right .icon-long_arrow {
    display: none;
  }
}
@media (max-width: 992px) {
  .works__right .icon-long_arrow {
    display: block;
    left: 50%;
    transform: translateY(-50%);
    top: -40px;
    rotate: -90deg;
    z-index: 1;
  }
}
@media (max-width: 1200px) {
  .works__right {
    left: 0;
  }
}
.works__icon-body-right {
  position: absolute;
}
@media (max-width: 992px) {
  .works__icon-body-right {
    position: static;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
}
@media (max-width: 576px) {
  .works__icon-body-right {
    row-gap: 10px;
  }
}
.works__icon-body-right .works__icon-wrap:nth-child(1) {
  transform: rotate(349deg) translateY(-180px) rotate(-349deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(1) .icon {
  color: var(--black2F);
}
@media (max-width: 992px) {
  .works__icon-body-right .works__icon-wrap:nth-child(1) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--black2F);
  }
}
.works__icon-body-right .works__icon-wrap:nth-child(2) {
  transform: rotate(-38deg) translateY(-190px) rotate(38deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(2) .icon {
  color: var(--blue);
}
@media (max-width: 992px) {
  .works__icon-body-right .works__icon-wrap:nth-child(2) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--blue);
  }
}
.works__icon-body-right .works__icon-wrap:nth-child(3) {
  transform: rotate(-65deg) translateY(-190px) rotate(65deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(3) .icon {
  color: var(--yellow);
}
@media (max-width: 992px) {
  .works__icon-body-right .works__icon-wrap:nth-child(3) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--yellow);
  }
}
.works__icon-body-right .works__icon-wrap:nth-child(4) {
  transform: rotate(-92deg) translateY(-172px) rotate(93deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(4) .icon {
  color: var(--green);
}
@media (max-width: 992px) {
  .works__icon-body-right .works__icon-wrap:nth-child(4) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--green);
  }
}
.works__icon-body-right .works__icon-wrap:nth-child(5) {
  transform: rotate(-122deg) translateY(-140px) rotate(123deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(5) .icon {
  color: var(--black2F);
}
@media (max-width: 992px) {
  .works__icon-body-right .works__icon-wrap:nth-child(5) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--black2F);
  }
}
.works__icon-body-right .works__icon-wrap:nth-child(6) {
  transform: rotate(-160deg) translateY(-100px) rotate(160deg);
}
.works__icon-body-right .works__icon-wrap:nth-child(6) .icon {
  color: var(--blue);
}
@media (max-width: 992px) {
  .works__icon-body-right .works__icon-wrap:nth-child(6) .icon_body {
    padding-right: 15px;
    border-right: 1px solid var(--blue);
  }
}
@media (max-width: 1200px) {
  .works__icon-body-right .works__icon-wrap:nth-child(1) {
    transform: rotate(349deg) translateY(-180px) rotate(-349deg);
  }
  .works__icon-body-right .works__icon-wrap:nth-child(2) {
    transform: rotate(-38deg) translateY(-190px) rotate(38deg);
  }
  .works__icon-body-right .works__icon-wrap:nth-child(3) {
    transform: rotate(-65deg) translateY(-190px) rotate(65deg);
  }
  .works__icon-body-right .works__icon-wrap:nth-child(4) {
    transform: rotate(-92deg) translateY(-172px) rotate(92deg);
  }
  .works__icon-body-right .works__icon-wrap:nth-child(5) {
    transform: rotate(-122deg) translateY(-140px) rotate(122deg);
  }
  .works__icon-body-right .works__icon-wrap:nth-child(6) {
    transform: rotate(-160deg) translateY(-100px) rotate(160deg);
  }
}
@media (max-width: 992px) {
  .works__icon-body-right .works__icon-wrap:nth-child(1), .works__icon-body-right .works__icon-wrap:nth-child(2), .works__icon-body-right .works__icon-wrap:nth-child(3), .works__icon-body-right .works__icon-wrap:nth-child(4), .works__icon-body-right .works__icon-wrap:nth-child(5), .works__icon-body-right .works__icon-wrap:nth-child(6) {
    transform: none;
  }
}
.works .works__icon-wrap {
  background-color: var(--white);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  transform-origin: center;
}
.works .works__icon-wrap .tooltip {
  transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  position: absolute;
  top: -80%;
  left: 50%;
  z-index: 3;
  background-color: var(--white);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 24px;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transform: translate(-50%, -100%);
}
.works .works__icon-wrap .tooltip .icon-tooltip_arrow {
  width: 25px;
  height: 19px;
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: var(--white);
  z-index: -1;
}
@media (max-width: 992px) {
  .works .works__icon-wrap .tooltip .icon-tooltip_arrow {
    display: none;
  }
}
@media (max-width: 992px) {
  .works .works__icon-wrap .tooltip {
    position: static;
    opacity: 1;
    visibility: visible;
    height: auto;
    transform: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }
}
.works .works__icon-wrap .tooltip_text {
  white-space: nowrap;
  font-size: 14px;
  color: var(--black2F);
}
.works .works__icon-wrap .tooltip_text br {
  display: none;
}
@media (max-width: 768px) {
  .works .works__icon-wrap .tooltip_text {
    white-space: normal;
  }
  .works .works__icon-wrap .tooltip_text br {
    display: block;
  }
}
.works .works__icon-wrap .icon {
  width: 32px;
  height: 32px;
}
@media (min-width: 992px) {
  .works .works__icon-wrap:hover .tooltip {
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    opacity: 1;
    visibility: visible;
    height: auto;
    transform: translate(-50%, 0);
  }
}
@media (max-width: 992px) {
  .works .works__icon-wrap {
    position: static;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    background-color: var(--white);
    border-radius: 5px 25px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    width: auto;
    height: auto;
    padding: 20px;
  }
}
.works .icon-long_arrow {
  color: var(--blue);
  width: 130px;
  position: relative;
}
@media (max-width: 1200px) {
  .works .icon-long_arrow {
    position: absolute;
  }
}
.works__center {
  min-width: 370px;
}
@media (max-width: 1200px) {
  .works__center {
    min-width: 100%;
    text-align: center;
  }
}
.works .works-logo__image {
  display: inline-block;
  margin-bottom: 18px;
  width: 366px;
  height: auto;
}
@media (max-width: 1200px) {
  .works .works-logo__image {
    max-width: 366px;
    width: 100%;
    margin: 0 auto 25px auto;
  }
}
.works .works-logo__image .works-logo__img {
  width: 100%;
  height: auto;
}
.works__center-text {
  /* Только если меньше контейнера */
  font-size: 22px;
  color: var(--grey);
}
@media (max-width: 1320px) {
  .works__center-text {
    font-size: calc(16px + 6 * (100vw - 360px) / 960);
  }
}

.free {
  background: var(--blue);
  padding-top: 60px;
  padding-bottom: 60px;
}
.free__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px 50px;
}
@media (max-width: 992px) {
  .free__wrap {
    flex-direction: column;
  }
}
.free .applications_icon__image {
  display: inline-block;
  max-width: 450px;
  width: 100%;
  height: auto;
}
.free .applications_icon__image .applications_icon__img {
  width: 100%;
  height: auto;
}
@media (max-width: 992px) {
  .free .applications_icon__image {
    padding-left: 0;
  }
}
.free__content {
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media (max-width: 992px) {
  .free__content {
    padding-right: 0;
    align-items: center;
    gap: 40px;
  }
}
@media (max-width: 576px) {
  .free__content {
    align-items: normal;
  }
}
.free__title h4 {
  color: var(--white);
  font-size: 28px;
  /* Только если меньше контейнера */
  font-size: 28px;
  font-weight: 400;
  line-height: 128.571429%;
}
@media (max-width: 1320px) {
  .free__title h4 {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .free__title {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .free__title {
    text-align: start;
  }
}
.free__text {
  max-width: 346px;
}
.free__text p {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  line-height: 142.857%;
}
@media (max-width: 992px) {
  .free__text {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .free__text {
    text-align: start;
  }
}
@media (max-width: 576px) {
  .free .btn {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .demo-v2 .free__wrap {
    flex-direction: column-reverse;
  }
}
.demo-v2 .free__content {
  padding: 0;
  max-width: 425px;
  width: 100%;
}
.demo-v2 .free__title h4 {
  font-weight: 400;
}
.demo-v2 .free__text p {
  max-width: 280px;
  width: 100%;
}
.demo-v2 .orders__image {
  max-width: 450px;
  width: 100%;
  height: auto;
}
.demo-v2 .orders__image img {
  width: 100%;
  height: 100%;
}
.demo-v2 .free__link .btn {
  max-width: 315px;
  width: 100%;
}

.applications {
  padding: 100px 0;
  text-align: center;
}
.applications .applications_text-wrap {
  text-align: center;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .applications .applications_text-wrap {
    margin-bottom: 30px;
  }
}
.applications .applications_title {
  /* Только если меньше контейнера */
  margin-bottom: 30px;
}
@media (max-width: 1320px) {
  .applications .applications_title {
    margin-bottom: calc(25px + 5 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .applications .applications_title {
    text-align: start;
  }
}
.applications .applications_text {
  color: var(--grey);
  line-height: 144.4444444444%;
  /* Только если меньше контейнера */
  font-size: 18px;
  max-width: 532px;
  width: 100%;
  margin: 0 auto;
  font-weight: 400;
}
@media (max-width: 1320px) {
  .applications .applications_text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .applications .applications_text {
    text-align: start;
  }
}
.applications .applications_icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto 50px auto;
}
@media (max-width: 576px) {
  .applications .applications_icon-wrap .display_none {
    display: none;
  }
}
.applications .applications_icon__image {
  width: 75px;
  height: auto;
}
.applications .applications_icon__image .applications_icon__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .applications .applications_icon {
    height: 45px;
  }
}
.applications .applications_link {
  font-weight: 400;
}
@media (max-width: 576px) {
  .applications .applications_link {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .applications {
    padding: 50px 0;
  }
}
@media (max-width: 460px) {
  .voice-applications .applications_icon__image:last-child {
    display: none;
  }
}

.advantages {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .advantages {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .advantages {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.advantages__row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 40px;
  /* Только если меньше контейнера */
  column-gap: 60px;
}
@media (max-width: 1320px) {
  .advantages__row {
    column-gap: calc(0px + 60 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .advantages__row {
    justify-content: flex-start;
  }
}
.advantages__row .advantages__col:nth-child(4n+1) .icon,
.advantages__row .advantages__col:nth-child(4n+1) .advantages__title {
  color: var(--blue);
}
.advantages__row .advantages__col:nth-child(4n+2) .icon,
.advantages__row .advantages__col:nth-child(4n+2) .advantages__title {
  color: var(--yellow);
}
.advantages__row .advantages__col:nth-child(4n+3) .icon,
.advantages__row .advantages__col:nth-child(4n+3) .advantages__title {
  color: var(--green);
}
.advantages__row .advantages__col:nth-child(4n+4) .icon,
.advantages__row .advantages__col:nth-child(4n+4) .advantages__title {
  color: var(--black2F);
}
.advantages__col {
  max-width: 340px;
  width: 100%;
}
@media (max-width: 992px) {
  .advantages__col {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .advantages__col {
    max-width: 100%;
  }
}
.advantages__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  min-height: 110px;
  max-width: 110px;
  max-height: 110px;
  border-radius: 5px 25px;
  background: var(--white);
  -webkit-box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .advantages__icon {
    margin: 0 auto 20px auto;
  }
}
@media (max-width: 576px) {
  .advantages__icon {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    margin: 0 0 30px 0;
  }
}
.advantages__icon .icon {
  width: 48px;
  height: 48px;
}
@media (max-width: 576px) {
  .advantages__icon .icon {
    width: 36px;
    height: 36px;
  }
}
.advantages__title {
  margin-bottom: 20px;
}
.advantages__title h5 {
  /* Только если меньше контейнера */
  font-size: 18px;
  font-weight: 700;
  line-height: 144.444%;
}
@media (max-width: 1320px) {
  .advantages__title h5 {
    font-size: calc(20px + -2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .advantages__title {
    text-align: start;
  }
}
.advantages__text p {
  color: var(--grey);
  font-size: 14px;
  font-weight: 400;
  line-height: 142.857%;
}
@media (max-width: 576px) {
  .advantages__text p {
    text-align: start;
  }
}

.pluses {
  /* Только если меньше контейнера */
  padding-top: 50px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .pluses {
    padding-top: calc(60px + -10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .pluses {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.pluses .advantages__row {
  /* Только если меньше контейнера */
  column-gap: 80px;
}
@media (max-width: 1320px) {
  .pluses .advantages__row {
    column-gap: calc(0px + 80 * (100vw - 360px) / 960);
  }
}
.pluses .advantages__col {
  max-width: 230px;
  width: 100%;
}
@media (max-width: 576px) {
  .pluses .advantages__col {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .pluses .advantages__col .advantages__icon {
    margin: 0;
  }
}
.pluses .advantages__col .advantages__title h5 {
  /* Только если меньше контейнера */
  font-size: 22px;
}
@media (max-width: 1320px) {
  .pluses .advantages__col .advantages__title h5 {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.pluses .advantages__col .advantages__text p {
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .pluses .advantages__col .advantages__text p {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}

.advantages_dial .advantages__row, .autodialer-page .advantages__row {
  /* Только если меньше контейнера */
  row-gap: 80px;
}
@media (max-width: 1320px) {
  .advantages_dial .advantages__row, .autodialer-page .advantages__row {
    row-gap: calc(40px + 40 * (100vw - 360px) / 960);
  }
}
.advantages_dial .advantages__title-main, .autodialer-page .advantages__title-main {
  max-width: 770px;
}
@media (max-width: 576px) {
  .advantages_dial .advantages__title-main, .autodialer-page .advantages__title-main {
    max-width: 100%;
    text-align: start;
  }
  .advantages_dial .advantages__title-main br, .autodialer-page .advantages__title-main br {
    display: none;
  }
}
.advantages_dial .advantages__text-wrap, .autodialer-page .advantages__text-wrap {
  max-width: 250px;
  width: 100%;
}
@media (max-width: 576px) {
  .advantages_dial .advantages__text-wrap, .autodialer-page .advantages__text-wrap {
    max-width: 100%;
  }
  .advantages_dial .advantages__text-wrap br, .autodialer-page .advantages__text-wrap br {
    display: none;
  }
}
.advantages_dial .advantages__title h5, .autodialer-page .advantages__title h5 {
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .advantages_dial .advantages__title h5, .autodialer-page .advantages__title h5 {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}

.advantages_dial_ua .advantages__text-wrap {
  max-width: 280px;
}

.advantages_voice .advantages__title-main {
  max-width: 555px;
}
.advantages_voice .advantages__text-wrap {
  max-width: 280px;
}

.advantages_ua .service__bottom {
  padding: 47px 39px;
}
@media (max-width: 576px) {
  .advantages_ua .service__bottom {
    padding: 48px 15.5px;
  }
}

.advantages_contact_center .advantages__text-wrap {
  max-width: 255px;
  width: 100%;
}
@media (max-width: 992px) {
  .advantages_contact_center .advantages__text-wrap {
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .advantages_contact_center .advantages__text-wrap {
    max-width: 100%;
    margin: 0 auto 0 0;
  }
}
.advantages_contact_center .advantages__title h5 {
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .advantages_contact_center .advantages__title h5 {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .advantages_contact_center .advantages__title {
    max-width: 300px;
  }
}
.advantages_contact_center .advantages__col:nth-child(1) .advantages__icon .icon {
  width: 70px;
  height: auto;
}
@media (max-width: 576px) {
  .advantages_contact_center .advantages__col:nth-child(1) .advantages__icon .icon {
    width: 45px;
  }
}

.robot .advantages__title-main {
  text-align: center;
}

.advantages_ip_pbx .advantages__text {
  max-width: 310px;
}

.service {
  background-color: var(--whiteF8);
  /* Только если меньше контейнера */
  padding-top: 50px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .service {
    padding-top: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .service {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.service__title h3 {
  color: var(--white);
  font-size: 22px;
  font-weight: 700;
  line-height: 127.273%;
}
.service__text p {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  line-height: 142.857%;
}
.service__btn {
  margin-top: 20px;
}
.service__btn .btn {
  color: var(--hover-blue);
}
.service__row {
  display: flex;
  gap: 20px;
}
@media (max-width: 992px) {
  .service__row {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .service__row {
    gap: 10px;
  }
}
.service__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.33%;
  padding-left: 60px;
  padding-right: 60px;
  background: var(--white);
  border-radius: 5px 30px;
  background: var(--white);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 992px) {
  .service__left {
    width: 38%;
    min-height: 330px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 576px) {
  .service__left {
    align-items: flex-start;
    width: 100%;
    min-height: auto;
    padding: 30px 25px;
  }
}
.service__num {
  color: var(--blue);
  font-size: 70px;
  font-weight: 700;
  line-height: 107.143%;
  white-space: nowrap;
  text-align: center;
}
.service__descr {
  color: var(--black2F);
  font-size: 14px;
  font-weight: 600;
  line-height: 142.857%;
  max-width: 160px;
  width: 100%;
}
@media (max-width: 992px) {
  .service__descr {
    max-width: 100%;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .service__descr {
    text-align: start;
  }
}
.service__middle {
  width: 33.33%;
}
@media (max-width: 992px) {
  .service__middle {
    width: 58%;
  }
}
@media (max-width: 576px) {
  .service__middle {
    width: 100%;
  }
}
.service__right {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 992px) {
  .service__right {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .service__right {
    gap: 10px;
  }
}
.service__bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 34px 50px;
  border-radius: 5px 30px;
  background: var(--deep-blue, #004565);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 576px) {
  .service__bottom {
    padding: 34px;
  }
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
}
@media (max-width: 992px) {
  .middle {
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .middle {
    gap: 10px;
  }
}
.middle__col {
  display: flex;
  align-items: center;
  gap: 15px;
  background: var(--blue);
  padding: 42px 40px;
  border-radius: 5px 30px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 992px) {
  .middle__col {
    padding: 25px;
  }
}
@media (max-width: 576px) {
  .middle__col {
    flex-direction: column;
    align-items: flex-start;
  }
}
.middle__col-descr {
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  line-height: 142.857%;
}
.middle__col-num {
  color: var(--white);
  font-size: 50px;
  font-weight: 700;
  line-height: 110%;
}

.right {
  display: flex;
  gap: 20px;
}
@media (max-width: 992px) {
  .right {
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .right {
    gap: 10px;
  }
}
.right__col {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 22px 30px;
  border-radius: 5px 30px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1200px) {
  .right__col {
    padding: 30px 20px;
  }
}
@media (max-width: 992px) {
  .right__col {
    width: 48%;
  }
}
.right .icon-infinity {
  width: 47px;
  height: 35px;
  color: var(--blue);
}
@media (max-width: 576px) {
  .right .icon-infinity {
    margin-bottom: 15px;
  }
}
.right__col-descr {
  color: var(--black2F);
  font-size: 14px;
  font-weight: 600;
  line-height: 142.857%;
  display: inline-block;
}
@media (max-width: 375px) {
  .right__col {
    width: 100%;
  }
}
.right__col-num {
  color: var(--blue);
  font-size: 50px;
  font-weight: 700;
  line-height: 110%;
}

.reviews_second {
  padding: 90px 0;
}
.reviews_second .container {
  padding: 0;
}
.reviews_second .reviews_title {
  margin: 0 0 auto 0;
}
@media (max-width: 1024px) {
  .reviews_second .reviews_title {
    margin-bottom: 30px;
  }
}
.reviews_second .reviews_second_content {
  display: grid;
  grid-template: 1fr/1fr 1fr;
  gap: 50px;
}
@media (max-width: 1200px) {
  .reviews_second .reviews_second_content {
    gap: 25px;
  }
}
@media (max-width: 1024px) {
  .reviews_second .reviews_second_content {
    grid-template: 1fr/1fr;
    gap: 40px;
  }
}
.reviews_second .reviews_second-content_wrap {
  max-width: 530px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .reviews_second .reviews_second-content_wrap {
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.reviews_second .reviews_title {
  text-align: start;
}
@media (max-width: 1024px) {
  .reviews_second .reviews_title {
    text-align: center;
  }
}
.reviews_second .reviews_second-text {
  color: var(--grey);
  font-size: 18px;
  line-height: 144.4444444444%;
  margin-bottom: 25px;
  max-width: 460px;
  width: 100%;
}
.reviews_second .reviews_items-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 1024px) {
  .reviews_second .reviews_items-wrap {
    justify-content: flex-start;
  }
}
@media (max-width: 576px) {
  .reviews_second .reviews_items-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.reviews_second .reviews_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  background-color: var(--white);
  border-radius: 5px 30px;
  filter: drop-shadow(0 8px 10px rgba(0, 0, 0, 0.1));
  padding: 25px 18px;
  height: 160px;
}
@media (max-width: 1024px) {
  .reviews_second .reviews_item {
    padding: 25px 15px;
  }
}
.reviews_second .current-score {
  color: var(--black2F);
  font-weight: 700;
  font-size: 20px;
}
.reviews_second .total-score {
  color: var(--grey);
}
.reviews_second .reviews_second_swiper {
  max-width: 600px;
  width: 100%;
  max-height: max-content;
  padding: 0 20px;
}
.reviews_second .reviews_second_swiper .swiper-wrapper {
  height: 440px;
  margin-bottom: 20px;
}
.reviews_second .reviews_second_swiper .swiper-slide {
  min-height: 100%;
  height: auto;
}
@media (max-width: 1024px) {
  .reviews_second .reviews_second_swiper {
    max-width: 100%;
    padding: 15px;
  }
}
.reviews_second .reviews_slide {
  background-color: var(--white);
  border-radius: 5px 30px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 440px;
  height: 100%;
  padding: 40px;
}
@media (max-width: 1024px) {
  .reviews_second .reviews_slide {
    padding: 25px;
  }
}
.reviews_second .reviews_slide-img {
  height: 160px;
  width: 100%;
  background-color: var(--whiteF8);
  border-radius: 5px 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.reviews_second .reviews_slide-img .__image {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.reviews_second .reviews_slide-img .__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.reviews_second .reviews_slide-text {
  height: 200px;
  overflow: auto;
}
.reviews_second .reviews_slide-text::-webkit-scrollbar {
  display: none;
}

.slide_text {
  font-size: 14px;
  line-height: 142.8571428571%;
  color: var(--grey);
}

.reviews_second-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 1024px) {
  .reviews_second-nav {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .reviews_second {
    padding: 40px 0;
  }
}
.reviews {
  /* Только если меньше контейнера */
  padding-top: 70px;
  /* Только если меньше контейнера */
  padding-bottom: 70px;
}
@media (max-width: 1320px) {
  .reviews {
    padding-top: calc(60px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .reviews {
    padding-bottom: calc(60px + 10 * (100vw - 360px) / 960);
  }
}
.reviews .container {
  padding: 0;
}
@media (max-width: 576px) {
  .reviews .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.reviews .reviews_head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 50px;
  padding: 0 15px;
}
@media (max-width: 768px) {
  .reviews .reviews_head {
    margin-bottom: 25px;
  }
}
@media (max-width: 576px) {
  .reviews .reviews_head {
    display: contents;
  }
}
.reviews .reviews_title {
  max-width: 606px;
  width: 100%;
  text-align: start;
  margin: 0;
}
@media (max-width: 576px) {
  .reviews .reviews_title {
    order: 1;
    padding: 0 20px;
  }
}
.reviews .arrow_wrap {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 576px) {
  .reviews .arrow_wrap {
    order: 3;
    gap: 30px;
  }
}
.reviews .reviews_swiper {
  padding: 15px 15px 0 15px;
}
@media (max-width: 576px) {
  .reviews .reviews_swiper {
    width: 100%;
    order: 2;
  }
}
.reviews .swiper-wrapper {
  margin-bottom: 25px;
}
.reviews .swiper-slide {
  min-height: 100%;
  height: auto;
}
.reviews .reviews_slide {
  background-color: var(--white);
  border-radius: 5px 30px 5px 30px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
}
@media (max-width: 992px) {
  .reviews .reviews_slide {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  }
}
.reviews .reviews_slide-img {
  height: 220px;
  width: 100%;
  background-color: var(--whiteF8);
  border-radius: 5px 30px 5px 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.reviews .reviews_slide-img .__image {
  max-width: 100%;
}
.reviews .reviews_slide-img .__image img {
  object-fit: contain;
}
.reviews .reviews_slide-text {
  padding: 25px;
}
.reviews .reviews_slide-text.expanded .toggle-button {
  display: inline-block;
}
@media (max-width: 768px) {
  .reviews .reviews_slide-text {
    padding: 25px 15px;
  }
}
.reviews .slide_text {
  font-size: 14px;
  line-height: 142.8571428571%;
  color: var(--grey);
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 240px;
  transition: max-height 0.3s ease;
  font-weight: 400;
}
.reviews .slide_text.expanded {
  -webkit-line-clamp: unset;
  max-height: unset;
}
.reviews .reviews_swiper-btn {
  color: var(--blue);
  font-size: 14px;
  margin-top: 20px;
  text-decoration: underline;
  font-weight: 700;
}
.reviews .swiper-pagination {
  margin: 0 auto;
}
@media (max-width: 576px) {
  .reviews .swiper-pagination {
    display: none;
  }
}

.proposal {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
  background-color: var(--blue);
  text-align: center;
}
@media (max-width: 1320px) {
  .proposal {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .proposal {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .proposal {
    padding: 60px 0;
  }
}
.proposal .proposal_content {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}
.proposal .proposal_title {
  color: var(--white);
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 28px;
  line-height: 128.5714285714%;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .proposal .proposal_title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .proposal .proposal_title {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .proposal .proposal_title {
    text-align: start;
    max-width: 305px;
  }
}
.proposal .proposal_btn {
  width: 316px;
  padding: 23.5px 0;
}
.proposal .proposal_popup_content {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  max-width: 945px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .proposal .proposal_popup_content {
    display: flex;
    flex-direction: column-reverse;
  }
}
.proposal .popup_form-wrap {
  text-align: start;
  max-width: 465px;
  width: 100%;
}
@media (max-width: 1024px) {
  .proposal .popup_form-wrap {
    max-width: 650px;
    margin: 0 auto;
  }
}
.proposal .popup_form-wrap form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.proposal .input-wrap {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
}
.proposal .form_input {
  margin: 0;
}
.proposal .error-message {
  color: var(--white);
}
.proposal .manager {
  max-width: 440px;
  width: 100%;
  border-radius: 5px 30px;
  overflow: hidden;
  border: 1px solid var(--white);
  height: 100%;
}
.proposal .info_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 85px;
  padding: 25px;
}
@media (max-width: 576px) {
  .proposal .info_wrap {
    text-align: start;
  }
}
.proposal .manager__image {
  display: inline-block;
  border-radius: 5px 30px;
  width: 100%;
  max-height: 425px;
  height: 100%;
}
.proposal .manager__img {
  width: 100%;
  height: 100%;
}
.proposal .name {
  color: var(--white);
  /* Только если меньше контейнера */
  font-size: 22px;
  font-weight: 700;
  line-height: 127.2727272727%;
}
@media (max-width: 1320px) {
  .proposal .name {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.proposal .role {
  color: var(--white);
  font-size: 14px;
}
.proposal_popup-container {
  position: fixed;
  visibility: hidden;
  inset: 0;
  z-index: 4;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease 0s;
}
.proposal .showProposalContainer {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease 0s;
}
.proposal .iti__selected-dial-code {
  color: var(--white);
}
.proposal .close_popUp {
  display: none;
}

.proposal_modal {
  padding: 60px 0;
}
.proposal_modal .modal_proposal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  translate: -50% -50%;
  max-width: 1200px;
  width: calc(100% - 40px);
  scale: 0;
  opacity: 0;
  padding: 100px 40px;
  background-color: var(--blue);
  outline: none;
  border: none;
  border-radius: 8px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 768px) {
  .proposal_modal .modal_proposal {
    padding: 50px 15px 25px 15px;
  }
}
@media (max-width: 1024px) {
  .proposal_modal .modal_proposal {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
  .proposal_modal .modal_proposal::-webkit-scrollbar {
    display: none;
  }
}
.proposal_modal .openProposalPopUp {
  scale: 1;
  opacity: 1;
  transition: all 0.3s ease 0s;
}
.proposal_modal .close_popUp {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 15px;
  right: 15px;
}
.proposal_modal .close_popUp::before, .proposal_modal .close_popUp::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 100%;
  height: 1px;
  background-color: var(--white);
}
.proposal_modal .close_popUp::before {
  rotate: 45deg;
}
.proposal_modal .close_popUp::after {
  rotate: -45deg;
}
@media (max-width: 576px) {
  .proposal_modal .proposal_title {
    text-align: center;
    margin: 0 auto 40px auto;
  }
}
@media (max-width: 576px) {
  .proposal_modal .form_title,
  .proposal_modal .tel_label {
    text-align: start;
  }
}

.results {
  padding: 100px 0;
  background-color: var(--whiteF8);
  text-align: center;
}
@media (max-width: 768px) {
  .results .container {
    padding: 0;
  }
}
.results .results_title {
  margin-bottom: 30px;
  padding: 0 20px;
}
.results .results_descr {
  font-size: 18px;
  line-height: 144.4444444444%;
  margin-bottom: 50px;
  padding: 0 20px;
  color: var(--grey);
}
@media (max-width: 768px) {
  .results .results_descr {
    margin-bottom: 30px;
  }
}
.results .result_swiper {
  padding: 0 10px;
}
.results .swiper-wrapper {
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .results .swiper-wrapper {
    margin-bottom: 30px;
  }
}
.results .result_slide {
  border-radius: 5px 30px 5px 30px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: var(--white);
  min-height: 475px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.results .slide__image {
  width: 100%;
  height: 240px;
  border-radius: 5px 30px 5px 30px;
  overflow: hidden;
  display: inline-block;
}
.results .slide__image .slide__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .results .slide_img {
    min-height: 200px;
  }
}
.results .slide_title-wrap {
  flex: 1;
  margin-bottom: 25px;
  padding: 25px 25px 0 25px;
  width: 100%;
}
.results .slide_title {
  font-size: 18px;
  line-height: 145%;
  font-weight: 700;
  text-align: start;
  color: var(--blue);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 768px) {
  .results .slide_title {
    font-size: 16px;
  }
}
.results .btn {
  font-size: 14px;
  height: 40px;
  width: max-content;
  padding: 15px 20px;
  margin-bottom: 25px;
}
.results .swiper_nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

@media (max-width: 768px) {
  .results {
    padding: 50px 0;
  }
}
.faq {
  padding: 100px 0;
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
  background-color: var(--whiteF8);
  text-align: center;
}
@media (max-width: 1320px) {
  .faq {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .faq {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.faq .faq_title {
  max-width: 726px;
  width: 100%;
  margin: 0 auto 50px auto;
}
@media (max-width: 576px) {
  .faq .faq_title {
    text-align: start;
  }
  .faq .faq_title br {
    display: none;
  }
}
@media (max-width: 768px) {
  .faq .faq_title {
    margin-bottom: 30px;
  }
}
.faq .faq_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .faq .faq_wrap {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .faq .faq_wrap {
    gap: 10px;
  }
}
@media (max-width: 576px) {
  .faq .accordion__toggler {
    padding: 20px 15px;
  }
}
.faq .accordion__content {
  padding: 5px 25px 0 25px;
}
@media (max-width: 576px) {
  .faq .accordion__content {
    padding: 20px 15px 0 15px;
  }
}
@media (max-width: 576px) {
  .faq .item_title br {
    display: none;
  }
}
.faq .item_descr {
  font-size: 14px;
  color: var(--white);
  line-height: 142.8571428571%;
}
.faq .question_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .faq .question_wrap {
    flex-direction: column;
  }
}
.faq .question_text {
  /* Только если меньше контейнера */
  font-size: 22px;
  font-weight: 500;
  color: var(--grey);
}
@media (max-width: 1320px) {
  .faq .question_text {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.faq .question_link {
  color: var(--blue);
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}
.faq .question_link:hover {
  color: var(--hover-blue);
  transition: all 0.3s ease 0s;
}

.faq_home .item_title {
  max-width: 430px;
}

.faq_contact_center_ua .accordion__item:last-child b {
  text-decoration: underline;
}

.additional-product {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .additional-product {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .additional-product {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.additional-product_title {
  max-width: 600px;
  width: 100%;
}
.additional-product_card-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.additional-product .product_card {
  min-height: 395px;
  height: auto;
  flex: 0 1 365px;
}
@media (max-width: 576px) {
  .additional-product .product_card {
    min-height: auto;
  }
}
.additional-product .product_card-img {
  background-color: var(--blueCE);
  border-radius: 5px 30px;
  padding: 20px 36px;
}
.additional-product .product_card-img .additional__image {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.additional-product .product_card-img .additional__image img {
  width: 100%;
  height: 100%;
}
.additional-product .product_card-text {
  gap: 20px;
}
.additional-product .product_card-title {
  /* Только если меньше контейнера */
  font-size: 22px;
  color: var(--blue);
  font-weight: 700;
}
@media (max-width: 1320px) {
  .additional-product .product_card-title {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.additional-product .product_card-descr {
  font-size: 14px;
  color: var(--grey);
  line-height: 1.4285714286;
}

.additional-benefit {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .additional-benefit {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .additional-benefit {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.additional-benefit_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.additional-benefit .accordion {
  max-width: 545px;
  width: 100%;
}
.additional-benefit .accordion__item {
  width: 100%;
}
.additional-benefit .accordion__item:not(:last-child) {
  margin-bottom: 20px;
}
.additional-benefit .accordion__toggler {
  min-height: auto;
}
.additional-benefit .additional__image {
  max-width: 545px;
  width: 100%;
  display: inline-block;
}
@media (max-width: 992px) {
  .additional-benefit .additional__image {
    display: none;
  }
}
.additional-benefit .additional__image img {
  width: 100%;
  height: 100%;
}
.additional-benefit .additional_mob__image {
  margin: 0 auto;
  max-width: 330px;
  width: auto;
  min-height: 180px;
  max-height: 250px;
  border-radius: 5px 25px;
  margin-top: 25px;
  display: none;
  position: relative;
}
@media (max-width: 992px) {
  .additional-benefit .additional_mob__image {
    display: block;
  }
}
@media (max-width: 576px) {
  .additional-benefit .additional_mob__image {
    max-height: 180px;
  }
}
.additional-benefit .additional_mob__image img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.additional-get {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .additional-get {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .additional-get {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.additional-get_title {
  /* Только если меньше контейнера */
  margin-bottom: 30px;
}
@media (max-width: 1320px) {
  .additional-get_title {
    margin-bottom: calc(25px + 5 * (100vw - 360px) / 960);
  }
}
.additional-get_descr {
  text-align: center;
  font-size: 18px;
  color: var(--grey);
  /* Только если меньше контейнера */
  margin-bottom: 100px;
}
@media (max-width: 1320px) {
  .additional-get_descr {
    margin-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.additional-get_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
@media (max-width: 992px) {
  .additional-get_content {
    flex-direction: column;
    gap: 30px;
  }
}
.additional-get_text-wrap {
  max-width: 500px;
  width: 100%;
}
.additional-get .text-head {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .additional-get .text-head {
    flex-direction: column;
    align-items: flex-start;
  }
}
.additional-get .text_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  min-height: 110px;
  max-width: 110px;
  max-height: 110px;
  border-radius: 5px 25px;
  background: var(--white);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .additional-get .text_icon {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  }
}
.additional-get .text_icon .icon {
  width: 48px;
  height: 48px;
  color: var(--blue);
}
@media (max-width: 768px) {
  .additional-get .text_icon .icon {
    width: 36px;
    height: 36px;
  }
}
.additional-get .text_title {
  /* Только если меньше контейнера */
  font-size: 22px;
  font-weight: 700;
}
@media (max-width: 1320px) {
  .additional-get .text_title {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.additional-get .descr {
  font-size: 14px;
  color: var(--grey);
  line-height: 1.4285714286;
}
.additional-get .additional__image {
  max-width: 560px;
  width: 100%;
  display: inline-block;
}
.additional-get .additional__image img {
  width: 100%;
  height: 100%;
}

.marquee-section {
  /* Только если меньше контейнера */
  padding-top: 80px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
}
@media (max-width: 1320px) {
  .marquee-section {
    padding-top: calc(60px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .marquee-section {
    padding-bottom: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.marquee-section_title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  /* Только если меньше контейнера */
  margin-bottom: 40px;
  color: var(--grey);
  text-transform: uppercase;
}
@media (max-width: 1320px) {
  .marquee-section_title {
    margin-bottom: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
.marquee-section .marquee {
  overflow: hidden;
  display: flex;
  /* Только если меньше контейнера */
  gap: 60px;
}
@media (max-width: 1320px) {
  .marquee-section .marquee {
    gap: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.marquee-section .marquee.right-direction {
  justify-content: flex-end;
}
.marquee-section .marquee-row {
  display: flex;
  align-items: center;
  /* Только если меньше контейнера */
  gap: 60px;
  /* Только если меньше контейнера */
  height: 100px;
}
@media (max-width: 1320px) {
  .marquee-section .marquee-row {
    gap: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .marquee-section .marquee-row {
    height: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.marquee-section .marquee__image {
  display: inline-block;
}
.marquee-section .marquee__image img {
  object-fit: contain;
}

.blog {
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
  background-color: var(--whiteF8);
  max-width: 1400px;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-radius: 5px 30px;
}
@media (max-width: 1320px) {
  .blog {
    padding-bottom: calc(10px + 50 * (100vw - 360px) / 960);
  }
}
.blog .container {
  padding: 0 10px;
}
.blog_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.blog_text-wrap {
  max-width: 460px;
  width: 100%;
}
.blog_title {
  color: var(--blue);
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 40px;
}
.blog_descr {
  color: var(--grey);
  font-size: 18px;
  font-weight: 400;
  line-height: 144%;
}
@media (max-width: 1200px) {
  .blog_descr {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .blog_content {
    flex-direction: column;
  }
  .blog_text-wrap {
    max-width: 100%;
  }
}

.articles {
  /* Только если меньше контейнера */
  padding-top: 50px;
  /* Только если меньше контейнера */
  padding-bottom: 50px;
}
@media (max-width: 1320px) {
  .articles {
    padding-top: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles {
    padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
.articles .container {
  padding: 0;
}
.articles_header {
  position: relative;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .articles_header {
    margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
.articles_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 0 15px;
}
@media (max-width: 658px) {
  .articles_nav {
    padding: 0;
  }
}
.articles_button-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
  width: 100%;
}
.articles_button-wrap::-webkit-scrollbar {
  display: none;
}
.articles_button-wrap.terms_nav {
  flex-wrap: wrap;
}
@media (max-width: 658px) {
  .articles_button-wrap {
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .articles_button-wrap.terms_nav .articles_button:first-child {
    margin-left: 0;
  }
  .articles_button-wrap.terms_nav .articles_button:last-child {
    margin-right: 0;
  }
}
.articles_button {
  color: var(--blue);
  text-align: center;
  font-size: 13px;
  min-width: 110px;
  height: 33px;
  padding: 0 15px;
  border-radius: 50px;
  background: rgba(206, 227, 236, 0.5);
  transition: all 0.3s ease 0s;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .articles_button {
    min-width: auto;
    width: calc(50% - 5px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.articles_button:hover {
  background-color: var(--hover-blue);
  color: var(--white);
}
.articles_button.active_btn {
  background-color: var(--blue);
  color: var(--white);
  transition: all 0.3s ease 0s;
}
@media (max-width: 658px) {
  .articles_button:last-child {
    margin-right: 15px;
  }
}
.articles_search-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 50px;
  border: 2px solid var(--blue);
  background: var(--white);
  padding: 10px 15px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 658px) {
  .articles_search-btn {
    width: 100%;
    justify-content: center;
  }
}
.articles_search-btn.activeSearchBtn {
  background-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.articles_search-btn.activeSearchBtn .articles_search-text,
.articles_search-btn.activeSearchBtn .icon-search {
  color: var(--white);
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .articles_search-btn:hover {
    background-color: var(--blue);
    transition: all 0.3s ease 0s;
  }
  .articles_search-btn:hover .articles_search-text,
  .articles_search-btn:hover .icon-search {
    color: var(--white);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .articles_search-btn:active {
    background-color: var(--blue);
  }
  .articles_search-btn:active .articles_search-text,
  .articles_search-btn:active .icon-search {
    color: var(--white);
  }
}
.articles_search-text {
  color: var(--blue);
  text-align: center;
  font-size: 13px;
}
.articles_search-input-wrap {
  position: absolute;
  z-index: 2;
  right: 50%;
  transform: translateX(50%);
  /* Только если меньше контейнера */
  bottom: -160px;
  border-radius: 30px;
  background-color: var(--white);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
  /* Только если меньше контейнера */
  padding-top: 40px;
  /* Только если меньше контейнера */
  padding-bottom: 40px;
  /* Только если меньше контейнера */
  padding-right: 40px;
  /* Только если меньше контейнера */
  padding-left: 40px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  pointer-events: none;
}
@media (max-width: 1320px) {
  .articles_search-input-wrap {
    bottom: calc(-80px + -80 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles_search-input-wrap {
    padding-top: calc(10px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles_search-input-wrap {
    padding-bottom: calc(10px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles_search-input-wrap {
    padding-right: calc(10px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles_search-input-wrap {
    padding-left: calc(10px + 30 * (100vw - 360px) / 960);
  }
}
.articles_search-input-wrap.openInput {
  width: calc(100% - 30px);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: all 0.3s ease 0s;
}
.articles_search-input-body {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: var(--whiteF8);
  backdrop-filter: blur(7.5px);
  /* Только если меньше контейнера */
  padding-top: 20px;
  /* Только если меньше контейнера */
  padding-bottom: 20px;
  /* Только если меньше контейнера */
  padding-right: 20px;
  /* Только если меньше контейнера */
  padding-left: 20px;
  border-radius: 500px;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1320px) {
  .articles_search-input-body {
    padding-top: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles_search-input-body {
    padding-bottom: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles_search-input-body {
    padding-right: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .articles_search-input-body {
    padding-left: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
.articles_search-input-body:focus-within {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
@media (max-width: 658px) {
  .articles_search-wrap {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.articles_search-input {
  width: 100%;
  border: none;
  background-color: transparent;
  color: var(--black2F);
  font-size: 14px;
  outline: none;
}
.articles_search-input::placeholder {
  color: var(--grey);
}
.articles_search-input::-webkit-search-cancel-button {
  cursor: pointer;
}
.articles_content {
  padding: 0 15px;
}
.articles_content-category-name {
  color: var(--black2F);
  /* Только если меньше контейнера */
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 30px;
  text-align: start;
}
@media (max-width: 1320px) {
  .articles_content-category-name {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .articles_content-category-name {
    font-size: 28px;
  }
}
.articles_search-result {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  /* Только если меньше контейнера */
  font-size: 22px;
}
@media (max-width: 1320px) {
  .articles_search-result {
    font-size: calc(18px + 4 * (100vw - 360px) / 960);
  }
}
.articles_search-result span {
  color: var(--black2F);
}
.articles_search-feild {
  display: flex;
  align-items: center;
  gap: 15px;
}
.articles_search-feild strong {
  font-weight: 700;
}
.articles_search-feild .clear_btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  margin-left: 10px;
  transition: all 0.3s ease 0s;
}
.articles_search-feild .clear_btn::after, .articles_search-feild .clear_btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 2px;
  background-color: var(--grey);
  transition: all 0.3s ease 0s;
}
.articles_search-feild .clear_btn::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.articles_search-feild .clear_btn::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
@media (hover: hover) {
  .articles_search-feild .clear_btn:hover {
    background-color: var(--blue);
    transition: all 0.3s ease 0s;
  }
  .articles_search-feild .clear_btn:hover::after, .articles_search-feild .clear_btn:hover::before {
    background-color: var(--white);
    transition: all 0.3s ease 0s;
  }
}
.articles .highlight {
  color: var(--blue);
}
.articles_content-wrap {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.articles_content-wrap .big_card {
  min-height: 100%;
}
.articles_content-wrap .big_card .blog__image {
  height: 332px;
  /* Только если меньше контейнера */
  height: 332px;
}
@media (max-width: 1320px) {
  .articles_content-wrap .big_card .blog__image {
    height: calc(230px + 102 * (100vw - 360px) / 960);
  }
}
.articles_all-category {
  display: flex;
  flex-direction: column;
  /* Только если меньше контейнера */
  row-gap: 100px;
}
@media (max-width: 1320px) {
  .articles_all-category {
    row-gap: calc(40px + 60 * (100vw - 360px) / 960);
  }
}
.articles_selected-category {
  text-align: center;
}
.articles .btn {
  font-weight: 400;
}
.articles_content-selected {
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(330px, 1fr));
  gap: 30px 20px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .articles_content-selected {
    margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
.articles_content-selected .category_card {
  display: flex;
  flex-direction: column;
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}
.articles_content-selected .category_card.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: all 0.3s ease 0s;
}
.articles_content-selected .blog__image {
  height: 200px;
}
.articles_content-selected .card_title {
  /* Только если меньше контейнера */
  font-size: 18px;
  margin-bottom: 15px;
  text-align: start;
  padding: 35px 25px 0 25px;
  flex: 1 0 auto;
}
@media (max-width: 1320px) {
  .articles_content-selected .card_title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.articles_content-selected .card_bottom {
  padding: 0 25px 25px 25px;
}
.articles_content-small-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 512px;
  width: 100%;
}
@media (max-width: 992px) {
  .articles_nav {
    flex-direction: column;
  }
  .articles_content-wrap {
    flex-direction: column;
  }
  .articles_content-wrap .big_card {
    max-width: 100%;
  }
  .articles_content-small-cards {
    max-width: 100%;
  }
  .articles_content-small-cards .blog__image {
    min-width: 170px;
  }
  .articles_content-small-cards .card_text {
    max-width: 100%;
  }
  .articles_content-small-cards .blog_card {
    max-width: 100%;
  }
}

.icon-search {
  width: 20px;
  height: 20px;
  color: var(--blue);
  transition: all 0.3s ease 0s;
}

.contact {
  /* Только если меньше контейнера */
  padding-top: 50px;
  /* Только если меньше контейнера */
  padding-bottom: 50px;
}
@media (max-width: 1320px) {
  .contact {
    padding-top: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact {
    padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
.contact_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  border-radius: 5px 30px;
  background-color: var(--whiteF8);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 35px 25px;
}
.contact_col {
  width: 48%;
}
.contact_title {
  color: var(--black2F);
  /* Только если меньше контейнера */
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 25px;
}
@media (max-width: 1320px) {
  .contact_title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
.contact_title span {
  color: var(--blue);
}
.contact_text {
  color: var(--grey);
  /* Только если меньше контейнера */
  font-size: 18px;
  line-height: 144%;
  margin-bottom: 25px;
}
@media (max-width: 1320px) {
  .contact_text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.contact_social-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}
.contact_social-link {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.contact_social-link .icon {
  width: 16px;
  height: 16px;
  color: var(--white);
}
@media (hover: hover) {
  .contact_social-link:hover {
    background-color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .contact_social-link:active {
    background-color: var(--hover-blue);
  }
}
.contact .contact-form b {
  text-decoration: underline;
  font-weight: 600;
  color: var(--blue);
}
.contact_input-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.contact .input_body {
  width: 100%;
}
.contact input {
  padding: 15px 20px;
  color: var(--black2F);
  font-size: 14px;
  max-width: 375px;
  width: 100%;
  border: none;
  background-color: var(--white);
  border-radius: 500px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s;
  height: 40px;
  width: 100%;
}
.contact input:focus-visible {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.contact input:focus {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.contact input::placeholder {
  color: var(--grey);
}
.contact .form-btn {
  max-width: 160px;
  width: 100%;
  height: 40px;
  font-size: 14px;
}
.contact .check_wrap {
  margin: 0;
  font-size: 14px;
  color: var(--grey);
}
.contact .check_wrap .custom-checkbox {
  border-color: var(--blue);
}
.contact .check_wrap .checkbox:checked + .custom-checkbox {
  background-color: var(--blue);
}
.contact .check_wrap .checkbox:checked + .custom-checkbox:before {
  content: url(../svg/check-arrow-white.svg);
}
.contact .check_wrap p,
.contact .check_wrap span {
  color: var(--grey);
  font-size: 14px;
}
@media (max-width: 992px) {
  .contact_content {
    flex-direction: column;
    text-align: start;
    align-items: center;
    gap: 50px;
  }
  .contact_col {
    width: 100%;
  }
  .contact_input-wrap {
    justify-content: center;
  }
  .contact_input {
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .contact_input-wrap {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .contact .form-btn {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .blog-page .content section:last-of-type {
    padding-bottom: 0;
  }
}

@media (max-width: 576px) {
  .blog-page .content section:last-of-type .container {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .blog-page .content section:last-of-type .demo_content {
    border-radius: 0;
  }
}

.demo {
  /* Только если меньше контейнера */
  padding-top: 20px;
  /* Только если меньше контейнера */
  padding-bottom: 50px;
}
@media (max-width: 1320px) {
  .demo {
    padding-top: calc(25px + -5 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .demo {
    padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
.demo_content {
  /* Только если меньше контейнера */
  padding-top: 60px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
  /* Только если меньше контейнера */
  padding-right: 40px;
  /* Только если меньше контейнера */
  padding-left: 40px;
  background-color: var(--blue);
  border-radius: 5px 30px;
}
@media (max-width: 1320px) {
  .demo_content {
    padding-top: calc(20px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .demo_content {
    padding-bottom: calc(20px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .demo_content {
    padding-right: calc(15px + 25 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .demo_content {
    padding-left: calc(15px + 25 * (100vw - 360px) / 960);
  }
}
.demo_content-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .demo_content-body {
    flex-direction: column-reverse;
  }
}
.demo_text-wrap {
  max-width: 402px;
  width: 100%;
}
@media (max-width: 768px) {
  .demo_text-wrap {
    text-align: center;
    max-width: 100%;
  }
}
.demo_title {
  color: var(--white);
  /* Только если меньше контейнера */
  font-size: 28px;
  font-weight: 400;
  line-height: 128%;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .demo_title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .demo_title {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .demo_title br {
    display: none;
  }
}
@media (max-width: 576px) {
  .demo_title {
    text-align: start;
    max-width: 297px;
    width: 100%;
  }
}
.demo_text {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .demo_text {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .demo_text {
    text-align: start;
  }
}
.demo .btn {
  width: 315px;
}
@media (max-width: 576px) {
  .demo .btn {
    width: 100%;
  }
}
.demo .demo__image {
  max-width: 500px;
  width: 100%;
  height: auto;
}
.demo .demo__image .demo__img {
  width: 100%;
  height: auto;
}

.article {
  /* Только если меньше контейнера */
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 1320px) {
  .article {
    padding-top: calc(20px + 10 * (100vw - 360px) / 960);
  }
}
.article_content-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
}
.article_content {
  max-width: 740px;
  width: 100%;
}
.article_content h1 {
  /* Только если меньше контейнера */
  font-size: 28px;
  font-weight: 700;
  line-height: 128.5714285714%;
  text-align: left;
  /* Только если меньше контейнера */
  margin-bottom: 40px;
}
@media (max-width: 1320px) {
  .article_content h1 {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article_content h1 {
    margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
  }
}
.article_content p {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
  margin-bottom: 30px;
}
@media (max-width: 1320px) {
  .article_content p {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article_content h2 {
  /* Только если меньше контейнера */
  font-size: 22px;
  margin-bottom: 30px;
  color: var(--black2F);
}
@media (max-width: 1320px) {
  .article_content h2 {
    font-size: calc(18px + 4 * (100vw - 360px) / 960);
  }
}
.article_content ul,
.article_content ol {
  display: flex;
  flex-direction: column;
  /* Только если меньше контейнера */
  gap: 20px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .article_content ul,
  .article_content ol {
    gap: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article_content ul,
  .article_content ol {
    margin-bottom: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
.article_content ul li,
.article_content ol li {
  position: relative;
  display: flex;
  align-items: flex-start;
  /* Только если меньше контейнера */
  gap: 20px;
  /* Только если меньше контейнера */
  padding-left: 40px;
}
@media (max-width: 1320px) {
  .article_content ul li,
  .article_content ol li {
    gap: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article_content ul li,
  .article_content ol li {
    padding-left: calc(30px + 10 * (100vw - 360px) / 960);
  }
}
.article_content ul li a,
.article_content ol li a {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--blue);
  font-weight: 500;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1320px) {
  .article_content ul li a,
  .article_content ol li a {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article_content ul li p,
.article_content ol li p {
  margin: 0;
}
@media (hover: hover) {
  .article_content ul li a:hover,
  .article_content ol li a:hover {
    color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: hover) {
  .article_content ul li a:active,
  .article_content ol li a:active {
    color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
.article_content ul li {
  position: relative;
}
.article_content ul li::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 16px;
  background-image: url(/img/article/arrow_3.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.article_content ol {
  counter-reset: zeroNum;
}
.article_content ol li {
  counter-increment: zeroNum;
  padding-left: 0;
}
.article_content ol li::before {
  content: "0" counter(zeroNum);
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--blue);
  font-weight: 500;
}
@media (max-width: 1320px) {
  .article_content ol li::before {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article_content table {
  border-radius: 5px 30px;
  overflow: hidden;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .article_content table {
    margin-bottom: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
.article_content table thead th {
  background-color: var(--blue);
  text-align: start;
  padding: 23px 10px 23px 25px;
  color: var(--white);
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .article_content table thead th {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article_content table thead th:nth-child(1), .article_content table thead th:nth-child(2) {
  border-right: 1px solid rgba(223, 223, 223, 0.15);
}
.article_content table tbody tr {
  border-bottom: 1px solid rgba(88, 89, 91, 0.15);
}
.article_content table tbody td {
  padding: 14px 25px;
  font-size: 14px;
  color: var(--grey);
  vertical-align: top;
}
.article_content table tbody td:first-child {
  white-space: nowrap;
  font-weight: 600;
  color: var(--black2F);
  border-right: 1px solid rgba(88, 89, 91, 0.15);
}
.article_content table tbody td:nth-child(2) {
  border-right: 1px solid rgba(88, 89, 91, 0.15);
}
.article_caterory {
  color: var(--blue);
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
  background-color: var(--blueCE);
  border-radius: 50px;
  padding: 12px 15px;
  display: inline-block;
}
@media (max-width: 768px) {
  .article_caterory {
    padding: 10px;
    font-size: 12px;
  }
}
.article_info-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.article_info-body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.article_info-body span {
  font-size: 14px;
  color: var(--grey);
}
.article_info-body b {
  font-weight: 700;
  color: var(--black2F);
}
.article_info-body .time,
.article_info-body .publish {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 576px) {
  .article_info-body .publish b {
    display: none;
  }
}
.article_info-body .publish .icon-calendar {
  display: none;
}
@media (max-width: 576px) {
  .article_info-body .publish .icon-calendar {
    display: block;
  }
}
.article_social-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 576px) {
  .article_social-wrap {
    align-items: flex-start;
    flex-direction: column;
  }
}
.article_social-body {
  display: flex;
  align-items: center;
  gap: 8px;
}
.article_social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--blue);
}
.article_social-link .icon {
  width: 16px;
  height: 16px;
  color: var(--white);
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .article_social-link:hover {
    background-color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .article_social-link:active {
    background-color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
.article .link-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .article .link-wrap {
    margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
.article .link-wrap a {
  /* Только если меньше контейнера */
  font-size: 18px;
  font-weight: 500;
  color: var(--blue);
}
@media (max-width: 1320px) {
  .article .link-wrap a {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article .article_image {
  display: inline-block;
  width: 100%;
  height: auto;
  /* Только если меньше контейнера */
  margin-bottom: 40px;
  border-radius: 5px 30px;
  overflow: hidden;
}
@media (max-width: 1320px) {
  .article .article_image {
    margin-bottom: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
.article .article_image .article__image {
  margin-bottom: 0;
  width: 100%;
  height: 100%;
}
.article .article_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article .info_banner {
  border-radius: 5px 30px;
  background-color: var(--blue);
  padding-top: 20px;
  padding-bottom: 20px;
  /* Только если меньше контейнера */
  padding-right: 40px;
  /* Только если меньше контейнера */
  padding-left: 40px;
  display: flex;
  align-items: center;
  /* Только если меньше контейнера */
  gap: 30px;
}
@media (max-width: 1320px) {
  .article .info_banner {
    padding-right: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article .info_banner {
    padding-left: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article .info_banner {
    gap: calc(20px + 10 * (100vw - 360px) / 960);
  }
}
.article .info_banner .banner_img {
  max-width: 110px;
  height: auto;
}
.article .info_banner .banner_img .banner__image {
  width: 100%;
  height: 100%;
}
.article .info_banner .banner_img .banner__image img {
  width: 100%;
  height: 100%;
}
.article .info_banner .banner_text-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 25px;
}
@media (max-width: 576px) {
  .article .info_banner .banner_text-wrap {
    align-items: center;
    gap: 20px;
  }
}
.article .info_banner h3 {
  /* Только если меньше контейнера */
  font-size: 22px;
  color: var(--white);
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .article .info_banner h3 {
    font-size: calc(18px + 4 * (100vw - 360px) / 960);
  }
}
.article .info_banner p {
  font-size: 14px;
  color: var(--white);
  max-width: 450px;
  width: 100%;
  margin: 0;
}
.article .info_banner p br {
  display: none;
}
@media (max-width: 576px) {
  .article .info_banner p {
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
  }
  .article .info_banner p br {
    display: block;
  }
}
.article .info_banner .arrow_wrap {
  background-color: var(--white);
}
.article .info_banner .icon-arrow_top {
  color: var(--blue);
}
@media (hover: hover) {
  .article .info_banner .arrow_wrap:hover {
    background-color: var(--blue);
    transition: all 0.3s ease 0s;
    border-color: var(--white);
  }
  .article .info_banner .arrow_wrap:hover .icon-arrow_top {
    color: var(--white);
  }
}
@media (hover: none) {
  .article .info_banner .arrow_wrap:active {
    background-color: var(--blue);
    border-color: var(--white);
  }
  .article .info_banner .arrow_wrap:active .icon-arrow_top {
    color: var(--white);
  }
}
.article blockquote {
  border-radius: 5px 30px;
  overflow: hidden;
  background-color: var(--white);
  /* Только если меньше контейнера */
  padding-top: 25px;
  /* Только если меньше контейнера */
  padding-bottom: 25px;
  /* Только если меньше контейнера */
  padding-right: 25px;
  /* Только если меньше контейнера */
  padding-left: 25px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  margin: 50px 0;
}
@media (max-width: 1320px) {
  .article blockquote {
    padding-top: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article blockquote {
    padding-bottom: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article blockquote {
    padding-right: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article blockquote {
    padding-left: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
.article blockquote .read_banner-text_wrap {
  padding-left: 0;
  border-left: none;
}
.article blockquote .read_banner-text_wrap.showDivider {
  padding-left: 20px;
  border-left: 1px solid var(--blue);
}
.article blockquote span {
  font-weight: 700;
  color: var(--black2F);
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}
.article blockquote p {
  margin: 0;
}
.article blockquote a {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--blue);
  transition: all 0.3s ease 0s;
  position: relative;
}
@media (max-width: 1320px) {
  .article blockquote a {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article blockquote a::after {
  content: url(../svg/arrow-white.svg);
  background-color: var(--blue);
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (hover: hover) {
  .article blockquote a:hover {
    color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .article blockquote a:active {
    color: var(--hover-blue);
  }
}
.article .user_wrap {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 50px 0;
}
.article .user_wrap .user__image {
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
}
.article .user_wrap .user__image .user__img {
  width: 100%;
  height: 100%;
}
.article .user_wrap .without_icon {
  display: none;
}
.article .user_wrap .user_info-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.article .user_wrap .user_name {
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--blue);
}
@media (max-width: 1320px) {
  .article .user_wrap .user_name {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article .user_wrap .user_position {
  font-size: 14px;
  color: var(--grey);
}
.article .grade_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  background-color: var(--whiteF8);
  border-radius: 5px 30px;
  padding: 25px;
}
.article .grade_banner .rated {
  background-color: var(--white);
  border-radius: 5px 30px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 40px;
  /* Только если меньше контейнера */
  gap: 40px;
  max-width: 407px;
  width: 100%;
}
@media (max-width: 1320px) {
  .article .grade_banner .rated {
    gap: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
.article .grade_banner .rated b {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--black2F);
  font-weight: 700;
  display: inline-block;
  margin-bottom: 15px;
}
@media (max-width: 1320px) {
  .article .grade_banner .rated b {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article .grade_banner .stars__image {
  display: inline-block;
  max-width: 207px;
  height: auto;
}
.article .grade_banner .stars__image img {
  width: 100%;
  height: 100%;
}
.article .grade_banner .rating,
.article .grade_banner .rating_num,
.article .grade_banner .votes_num {
  display: flex;
  align-items: center;
}
.article .grade_banner .rating {
  gap: 10px;
  margin-bottom: 15px;
}
.article .grade_banner .rating_num {
  display: flex;
  align-items: center;
  gap: 2px;
}
.article .grade_banner .rating_num span {
  font-size: 14px;
  color: var(--grey);
}
.article .grade_banner .rating_num .curent_rating {
  font-size: 28px;
  font-weight: 700;
  color: var(--black2F);
}
.article .grade_banner .rating_title {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--black2F);
  opacity: 0.7;
}
@media (max-width: 1320px) {
  .article .grade_banner .rating_title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article .grade_banner .article_social-wrap {
  flex-direction: column;
  align-items: flex-start;
  row-gap: 15px;
}
.article .grade_banner .votes_num {
  gap: 3px;
}
.article .grade_banner .votes_num span {
  white-space: nowrap;
  font-size: 14px;
  color: var(--grey);
}
.article .banner {
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .article .banner {
    margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
.article .arrow_wrap {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s;
}
.article .icon-arrow_top {
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height: 12px;
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .article .arrow_wrap:hover {
    background-color: var(--blue);
    transition: all 0.3s ease 0s;
    border-color: var(--white);
  }
  .article .arrow_wrap:hover .icon-arrow_top {
    color: var(--white);
  }
}
@media (hover: none) {
  .article .arrow_wrap:active {
    background-color: var(--blue);
    border-color: var(--white);
  }
  .article .arrow_wrap:active .icon-arrow_top {
    color: var(--white);
  }
}
@media (max-width: 1200px) {
  .article_content-wrap {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .article .grade_banner {
    flex-direction: column;
    align-items: flex-start;
  }
  .article .grade_banner .rated {
    max-width: 100%;
  }
  .article .grade_banner .stars_wrap {
    max-width: 230px;
  }
  .article .table-wrap {
    overflow-x: auto;
    margin-bottom: 50px;
  }
  .article .table-wrap::-webkit-scrollbar {
    height: 6px;
    width: 100%;
    border-radius: 5px;
  }
  .article .table-wrap::-webkit-scrollbar-track {
    background-color: rgba(88, 89, 91, 0.1);
  }
  .article .table-wrap::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--blue);
  }
  .article table {
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .article .info_banner {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  .article .banner_text-wrap,
  .article .read_banner {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .article .read_banner,
  .article .grade_banner {
    padding: 15px;
  }
  .article .grade_banner .rated {
    align-items: center;
    flex-direction: column;
    padding: 20px 15px;
  }
  .article .grade_banner .stars_wrap {
    text-align: center;
  }
  .article .read_banner-text_wrap {
    padding-left: 0;
    border: none;
  }
}

.help_cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 340px;
  width: 100%;
}
.help_cards .surbscribe {
  max-width: 320px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px 30px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  padding: 25px;
  text-align: center;
}
.help_cards .surbscribe .input_body {
  margin-bottom: 30px;
}
.help_cards .surbscribe_card-title {
  /* Только если меньше контейнера */
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .help_cards .surbscribe_card-title {
    font-size: calc(18px + 4 * (100vw - 360px) / 960);
  }
}
.help_cards .surbscribe_card-text {
  font-size: 14px;
  color: var(--grey);
  /* Только если меньше контейнера */
  margin-bottom: 30px;
}
@media (max-width: 1320px) {
  .help_cards .surbscribe_card-text {
    margin-bottom: calc(20px + 10 * (100vw - 360px) / 960);
  }
}
.help_cards .surbscribe_card-input {
  width: 100%;
  border-radius: 500px;
  padding: 15px 20px;
  border: none;
  background-color: rgba(47, 47, 49, 0.0509803922);
  outline: none;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s;
  font-size: 14px;
  color: var(--black2F);
}
.help_cards .surbscribe_card-input:focus {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.help_cards .surbscribe_card-input:focus-visible {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.help_cards .surbscribe .check_wrap {
  margin: 20px 0;
}
.help_cards .surbscribe .check_wrap p {
  color: var(--grey);
  text-align: start;
}
.help_cards .surbscribe .check_wrap a {
  text-decoration: underline;
  font-weight: 600;
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
.help_cards .surbscribe .check_wrap a:hover {
  color: var(--hover-blue);
}
.help_cards .surbscribe .custom-checkbox {
  border-color: var(--blue);
}
.help_cards .surbscribe .checkbox:checked + .custom-checkbox {
  background-color: var(--blue);
}
.help_cards .surbscribe .checkbox:checked + .custom-checkbox:before {
  content: url(../svg/check_arrow-white.svg);
}
.help_cards .surbscribe .form-btn {
  height: 40px;
  font-size: 14px;
}
.help_cards .call_swiper {
  max-width: 340px;
  width: 100%;
  padding: 0 10px;
}
.help_cards .call_swiper .swiper-pagination {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0 auto;
}
.help_cards .call {
  width: 100%;
  background-color: var(--white);
  border-radius: 5px 30px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
}
.help_cards .call .call__image {
  width: 100%;
  max-height: 180px;
}
.help_cards .call .call__image img {
  width: 100%;
  height: 100%;
}
.help_cards .call_text-wrap {
  padding: 20px;
}
.help_cards .call_title {
  /* Только если меньше контейнера */
  font-size: 28px;
  color: var(--blue);
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .help_cards .call_title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
.help_cards .call_sale-price {
  color: var(--grey);
  font-size: 14px;
  text-decoration: line-through;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.5;
}
.help_cards .call_text {
  font-size: 14px;
  margin-bottom: 20px;
  color: var(--black2F);
  font-weight: 600;
}
.help_cards .call_text span {
  color: var(--blue);
}
.help_cards .call .btn {
  font-size: 14px;
  font-weight: 400;
  padding: 15px 20px;
  height: 40px;
  width: max-content;
}
@media (max-width: 1200px) {
  .help_cards .surbscribe {
    max-width: 740px;
  }
  .help_cards .call_swiper {
    max-width: 740px;
  }
}

@media (max-width: 1200px) {
  .help_cards {
    max-width: 100%;
  }
}
.article-nav {
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .article-nav {
    padding-bottom: calc(40px + 60 * (100vw - 360px) / 960);
  }
}
.article-nav_categories {
  /* Только если меньше контейнера */
  margin-bottom: 100px;
}
@media (max-width: 1320px) {
  .article-nav_categories {
    margin-bottom: calc(40px + 60 * (100vw - 360px) / 960);
  }
}
.article-nav_categories-title {
  /* Только если меньше контейнера */
  font-size: 28px;
  color: var(--black2F);
  /* Только если меньше контейнера */
  margin-bottom: 30px;
}
@media (max-width: 1320px) {
  .article-nav_categories-title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article-nav_categories-title {
    margin-bottom: calc(20px + 10 * (100vw - 360px) / 960);
  }
}
.article-nav .articles-button-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.article-nav .articles_button {
  margin: 0;
  min-width: 90px;
}
@media (max-width: 576px) {
  .article-nav .articles_button {
    padding: 10px;
  }
}
.article-nav .articles-move {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
}
.article-nav .articles-move.no_prev {
  justify-content: end;
}
.article-nav .left_col .articles-move-link {
  justify-content: flex-start;
}
.article-nav .right_col .articles-move-link {
  justify-content: flex-end;
}
@media (max-width: 576px) {
  .article-nav .right_col .articles-move-link {
    text-align: end;
  }
}
.article-nav .right_col .icon-arrow_2 {
  rotate: 180deg;
}
.article-nav .articles-move-link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--blue);
  margin-bottom: 15px;
}
@media (max-width: 1320px) {
  .article-nav .articles-move-link {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.article-nav .articles-move-link .icon-arrow_2 {
  width: 19px;
  height: 16px;
  color: var(--blue);
}
@media (hover: hover) {
  .article-nav .articles-move-link:hover {
    color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
  .article-nav .articles-move-link:hover .icon-arrow_2 {
    color: var(--hover-blue);
  }
}
@media (hover: none) {
  .article-nav .articles-move-link:active {
    background-color: var(--hover-blue);
  }
  .article-nav .articles-move-link:active .icon-arrow_2 {
    color: var(--hover-blue);
  }
}
.article-nav .articles-move-title-wrap {
  /* Только если меньше контейнера */
  padding-top: 25px;
  /* Только если меньше контейнера */
  padding-bottom: 25px;
  /* Только если меньше контейнера */
  padding-right: 25px;
  /* Только если меньше контейнера */
  padding-left: 25px;
  border-radius: 5px 30px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  max-width: 365px;
}
@media (max-width: 1320px) {
  .article-nav .articles-move-title-wrap {
    padding-top: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article-nav .articles-move-title-wrap {
    padding-bottom: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article-nav .articles-move-title-wrap {
    padding-right: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .article-nav .articles-move-title-wrap {
    padding-left: calc(15px + 10 * (100vw - 360px) / 960);
  }
}
.article-nav .articles-move-title {
  /* Только если меньше контейнера */
  font-size: 18px;
  font-weight: 700;
  line-height: 144.4444444444%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1320px) {
  .article-nav .articles-move-title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .article-nav .articles-move-title-wrap {
    display: none;
  }
}

.article-similar .container {
  padding: 0;
}
.article-similar_title {
  /* Только если меньше контейнера */
  font-size: 28px;
  color: var(--black2F);
  font-weight: 700;
  margin-bottom: 30px;
  padding: 0 20px;
}
@media (max-width: 1320px) {
  .article-similar_title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
.article-similar .info_body {
  background-color: var(--white);
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
}
.article-similar .blog_card {
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}
.article-similar .card_text {
  padding: 25px;
}
.article-similar .card_title {
  color: var(--blue);
  -webkit-line-clamp: 5;
  margin: 0;
}
@media (max-width: 576px) {
  .article-similar .blog__image {
    height: 220px;
  }
}
.article-similar .article_swiper {
  padding: 0 20px 20px 20px;
  /* Только если меньше контейнера */
  padding-bottom: 20px;
}
@media (max-width: 1320px) {
  .article-similar .article_swiper {
    padding-bottom: calc(25px + -5 * (100vw - 360px) / 960);
  }
}
.article-similar .swiper-button-wrap {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .article-similar .swiper-button-wrap {
    display: flex;
  }
  .article-similar .swiper-button-wrap .swiper-button-prev,
  .article-similar .swiper-button-wrap .swiper-button-next {
    display: flex;
  }
}
.article-similar .btn {
  display: none;
}
@media (max-width: 768px) {
  .article-similar .btn {
    display: block;
    height: 40px;
    width: max-content;
    padding: 10px 20px;
    margin: 30px auto 0 auto;
    font-size: 14px;
  }
}

.dial-main {
  background-color: var(--whiteF8);
  max-width: 1400px;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-radius: 5px 30px;
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1320px) {
  .dial-main {
    padding-bottom: calc(10px + 50 * (100vw - 360px) / 960);
  }
}
.dial-main::before {
  content: "";
  position: absolute;
  left: 0;
  /* Только если меньше контейнера */
  top: -1100px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);
}
@media (max-width: 1320px) {
  .dial-main::before {
    top: calc(300px + -1400 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .dial-main::before {
    width: 638px;
    top: -100px;
    left: -50%;
    transform: translateX(-20%);
  }
}
.dial-main .container {
  padding: 0 10px;
  position: relative;
  z-index: 2;
}
.dial-main_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 576px) {
  .dial-main_content {
    gap: 50px;
  }
}
.dial-main_text-block {
  max-width: 495px;
  width: 100%;
}
.dial-main_title {
  /* Только если меньше контейнера */
  font-size: 40px;
  color: var(--blue);
  font-weight: 700;
  /* Только если меньше контейнера */
  margin-bottom: 40px;
}
@media (max-width: 1320px) {
  .dial-main_title {
    font-size: calc(28px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .dial-main_title {
    margin-bottom: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
.dial-main_text {
  /* Только если меньше контейнера */
  font-size: 18px;
  line-height: 144%;
  color: var(--grey);
  /* Только если меньше контейнера */
  margin-bottom: 60px;
}
@media (max-width: 1320px) {
  .dial-main_text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .dial-main_text {
    margin-bottom: calc(30px + 30 * (100vw - 360px) / 960);
  }
}
.dial-main_video-block {
  max-width: 600px;
  width: 100%;
}
.dial-main_video {
  border-radius: 5px 30px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}
.dial-main_video.disabled {
  pointer-events: none;
  filter: grayscale(0.5);
}
.dial-main_video .dial_video {
  width: 100%;
  height: auto;
}
@media (max-width: 992px) {
  .dial-main_content {
    flex-direction: column;
  }
  .dial-main_text-block {
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .dial-main .btn {
    width: 100%;
  }
}

.familiar {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .familiar {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .familiar {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.familiar_no_titles .familiar_card-text {
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .familiar_no_titles .familiar_card-text {
    font-size: calc(14px + 4 * (100vw - 360px) / 960);
  }
}
.familiar_no_titles .familiar_card-num {
  margin: 0;
}
.familiar_small_format .familiar_card-wrap {
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(270px, 1fr));
  gap: 15px 20px;
}
.familiar_small_format .familiar_card {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .familiar_small_format .familiar_card.number_center {
    flex-direction: row;
  }
}
.familiar_small_format .familiar_card.number_center .card_title-wrap {
  gap: 25px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .familiar_small_format .familiar_card.number_center .card_title-wrap {
    gap: 15px;
    margin-bottom: 0;
    margin-left: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 768px) {
  .familiar_small_format .familiar_card.number_center .familiar_card-num {
    margin: auto 0;
  }
}
@media (max-width: 768px) {
  .familiar_small_format .familiar_card {
    padding: 25px 20px;
  }
}
.familiar_small_format .card-text-wrap {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .familiar_small_format .card-text-wrap {
    gap: 15px;
  }
}
.familiar_small_format .familiar_card-num {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
}
.familiar_is_slider {
  /* Только если меньше контейнера */
  padding-bottom: 50px;
}
@media (max-width: 1320px) {
  .familiar_is_slider {
    padding-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
.familiar_is_slider .card-text-wrap {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .familiar_is_slider .card-text-wrap {
    gap: 15px;
  }
}
.familiar_is_slider .familiar_title {
  max-width: 730px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1024px) {
  .familiar_is_slider .familiar_card-wrap {
    display: block;
  }
}
.familiar_is_slider .familiar_card {
  min-height: 175px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .familiar_is_slider .familiar_card {
    padding: 25px 25px 30px 25px;
  }
}
.familiar_is_slider .familiar_card:not(:last-child) {
  margin-bottom: 10px;
}
.familiar_is_slider .familiar_card-text {
  font-size: 14px;
}
.familiar_is_slider .swiper {
  padding: 0 15px 15px 15px;
}
.familiar_is_slider .swiper-button-wrap {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .familiar_is_slider .swiper-button-wrap {
    display: flex;
  }
}
@media (max-width: 1024px) {
  .familiar_is_slider .swiper-button-prev {
    display: flex;
  }
}
@media (max-width: 1024px) {
  .familiar_is_slider .swiper-button-next {
    display: flex;
  }
}
.familiar_title {
  text-align: center;
  /* Только если меньше контейнера */
  font-size: 40px;
  font-weight: 700;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .familiar_title {
    font-size: calc(30px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .familiar_title {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
.familiar_card-wrap {
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(300px, 366px));
  justify-content: center;
  gap: 20px;
  counter-reset: num;
}
@media (max-width: 576px) {
  .familiar_card-wrap {
    gap: 10px;
  }
}
.familiar_card {
  padding: 25px;
  border-radius: 5px 30px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}
.familiar_card:nth-child(4n+1) .familiar_card-num {
  background-color: var(--blue);
}
.familiar_card:nth-child(4n+2) .familiar_card-num {
  background-color: var(--green);
}
.familiar_card:nth-child(4n+3) .familiar_card-num {
  background-color: var(--yellow);
}
.familiar_card:nth-child(4n+4) .familiar_card-num {
  background-color: var(--black2F);
}
.familiar .card_title-wrap {
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .familiar .card_title-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
}
.familiar_card-num {
  counter-increment: num;
  margin-bottom: 20px;
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 18px;
  margin-bottom: 25px;
}
@media (max-width: 1320px) {
  .familiar_card-num {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .familiar_card-num {
    margin-bottom: 0;
  }
}
.familiar_card-num::before {
  content: "0" counter(num);
}
.familiar_card-title {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--black2F);
}
@media (max-width: 1320px) {
  .familiar_card-title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.familiar_card-title br {
  display: none;
}
@media (max-width: 576px) {
  .familiar_card-title {
    margin: 0;
  }
  .familiar_card-title br {
    display: block;
  }
}
.familiar_card-text {
  font-size: 14px;
  color: var(--grey);
  line-height: 142%;
}

.voice_familiar {
  padding-bottom: 0;
}
.voice_familiar .familiar_card-wrap {
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .voice_familiar .familiar_card-wrap {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}

.try-dial {
  /* Только если меньше контейнера */
  padding-top: 0px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .try-dial {
    padding-top: calc(60px + -60 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .try-dial {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.try-dial_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* Только если меньше контейнера */
  row-gap: 50px;
}
@media (max-width: 1320px) {
  .try-dial_content {
    row-gap: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
.try-dial_text-block {
  max-width: 675px;
  text-align: center;
}
.try-dial_title {
  /* Только если меньше контейнера */
  font-size: 40px;
  /* Только если меньше контейнера */
  margin-bottom: 30px;
}
@media (max-width: 1320px) {
  .try-dial_title {
    font-size: calc(28px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .try-dial_title {
    margin-bottom: calc(20px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .try-dial_title {
    text-align: start;
  }
}
.try-dial_text {
  width: 100%;
  margin: 0 auto;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
}
@media (max-width: 1320px) {
  .try-dial_text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .try-dial_text {
    text-align: start;
  }
}
.try-dial .try-dial-deck__image {
  display: inline-block;
  max-width: 1140px;
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .try-dial .try-dial-deck__image {
    display: none;
  }
}
.try-dial .try-dial-deck__image img {
  height: 100%;
  width: 100%;
}
.try-dial .try-dial-mob__image {
  display: none;
  max-width: 350px;
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .try-dial .try-dial-mob__image {
    display: block;
  }
}
.try-dial .try-dial-mob__image img {
  height: 100%;
  width: 100%;
}
@media (max-width: 992px) {
  .try-dial_content {
    flex-direction: column;
    text-align: center;
  }
  .try-dial_text-block {
    max-width: 100%;
  }
}

.try-dial-en .try-dial_text-block {
  max-width: 675px;
}
.try-dial-en .try-dial_text {
  max-width: 100%;
}

.dial-benefit {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .dial-benefit {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .dial-benefit {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .dial-benefit .tabs {
    display: none;
  }
}
@media (max-width: 992px) {
  .dial-benefit .tabs__head {
    display: none;
  }
}
.dial-benefit .tabs__head-col {
  width: max-content;
}
.dial-benefit .tabs__item {
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.dial-benefit .dial_accrordion {
  max-width: 560px;
  width: 100%;
}
.dial-benefit .dial_accrordion .accordion__content {
  padding-top: 5px;
}
.dial-benefit .accordion__item {
  width: 100%;
}
.dial-benefit .accordion__item:not(:last-child) {
  margin-bottom: 20px;
}
.dial-benefit .dial__image {
  max-width: 540px;
  width: 100%;
  height: auto;
}
.dial-benefit .dial__image img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .dial-benefit .dial_accrordion {
    max-width: 100%;
  }
  .dial-benefit .dial__image {
    display: none;
  }
}
.dial-benefit .dial_accrordion-mob {
  display: none;
}
@media (max-width: 576px) {
  .dial-benefit .dial_accrordion-mob {
    display: block;
  }
}
.dial-benefit .dial_accrordion-mob .accordion__content {
  padding: 25px 15px 0 15px;
}
.dial-benefit .dial_accrordion-mob .accordion__toggler {
  padding: 25px 15px;
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item {
  background-color: var(--white);
  border-radius: 5px 30px;
  padding: 25px 20px 30px 20px;
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item:not(:last-child) {
  margin-bottom: 15px;
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item span {
  color: var(--black2F);
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item p {
  margin: 20px 0;
  color: var(--grey);
}
.dial-benefit .dial_accrordion-mob .accrordion-mob_item img {
  width: 100%;
  height: auto;
}

.dial_choise {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
  background-color: var(--white);
}
@media (max-width: 1320px) {
  .dial_choise {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .dial_choise {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.dial_choise_title {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .dial_choise_title {
    margin-bottom: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
.dial_choise_card-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* Только если меньше контейнера */
  gap: 60px;
  flex-wrap: wrap;
}
@media (max-width: 1320px) {
  .dial_choise_card-wrap {
    gap: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.dial_choise_card {
  max-width: 340px;
  width: 100%;
}
.dial_choise_card:nth-child(4n+1) .icon {
  color: var(--blue);
}
.dial_choise_card:nth-child(4n+1) .card_title {
  color: var(--blue);
}
.dial_choise_card:nth-child(4n+2) .icon {
  color: var(--yellow);
}
.dial_choise_card:nth-child(4n+2) .card_title {
  color: var(--yellow);
}
.dial_choise_card:nth-child(4n+3) .icon {
  color: var(--green);
}
.dial_choise_card:nth-child(4n+3) .card_title {
  color: var(--green);
}
.dial_choise_card:nth-child(4n+4) .icon {
  color: var(--black2F);
}
.dial_choise_card:nth-child(4n+4) .card_title {
  color: var(--black2F);
}
.dial_choise .card_list li {
  font-size: 14px;
  color: var(--grey);
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease 0s;
  margin-bottom: 20px;
}
.dial_choise .card_list li.hide {
  opacity: 0;
  height: 0;
  visibility: hidden;
  margin-bottom: 0;
  transition: all 0.3s ease 0s;
}
.dial_choise .card_list li.hide:not(:last-child) {
  margin-bottom: 0;
}
.dial_choise .card_list li::before {
  content: "";
  width: 20px;
  height: 20px;
  mask: url(../icon/icons/icons.svg#check_2) no-repeat center/cover;
  background-color: var(--black2F);
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}
.dial_choise .card_list.yellow li::before {
  background-color: var(--yellow);
}
.dial_choise .card_list.blue li::before {
  background-color: var(--blue);
}
.dial_choise .card_list.green li::before {
  background-color: var(--green);
}
.dial_choise .card_list.black li::before {
  background-color: var(--black2F);
}
.dial_choise .card_item {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease 0s;
  margin-bottom: 20px;
}
.dial_choise .card_item.hide {
  opacity: 0;
  height: 0;
  visibility: hidden;
  margin-bottom: 0;
  transition: all 0.3s ease 0s;
}
.dial_choise .card_item.hide:not(:last-child) {
  margin-bottom: 0;
}
.dial_choise .card_item span {
  font-size: 14px;
  color: var(--grey);
}
.dial_choise .card_item .icon {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}
.dial_choise .card_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Только если меньше контейнера */
  width: 110px;
  /* Только если меньше контейнера */
  height: 110px;
  border-radius: 5px 30px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  /* Только если меньше контейнера */
  margin-bottom: 40px;
}
@media (max-width: 1320px) {
  .dial_choise .card_icon {
    width: calc(80px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .dial_choise .card_icon {
    height: calc(80px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .dial_choise .card_icon {
    margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
  }
}
.dial_choise .card_icon .icon {
  width: 48px;
  height: 48px;
  /* Только если меньше контейнера */
  width: 48px;
  /* Только если меньше контейнера */
  height: 48px;
}
@media (max-width: 1320px) {
  .dial_choise .card_icon .icon {
    width: calc(36px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .dial_choise .card_icon .icon {
    height: calc(36px + 12 * (100vw - 360px) / 960);
  }
}
.dial_choise .card_text-wrap {
  max-width: 285px;
  width: 100%;
}
@media (max-width: 576px) {
  .dial_choise .card_text-wrap {
    max-width: 100%;
  }
}
.dial_choise .card_title {
  /* Только если меньше контейнера */
  font-size: 18px;
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .dial_choise .card_title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .dial_choise .card_title br {
    display: none;
  }
}
.dial_choise .card_text {
  font-size: 14px;
  color: var(--grey);
}
.dial_choise .show_btn {
  font-size: 14px;
  font-weight: 700;
  color: var(--blue);
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}
.dial_choise .show_btn.hidden {
  display: none;
}
@media (hover: hover) {
  .dial_choise .show_btn:hover {
    color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .dial_choise .show_btn:active {
    color: var(--hover-blue);
  }
}

.dial_choise-en .dial_choise_title {
  max-width: 770px;
}

.dial_choise .dial_choise_title {
  text-align: center;
}

.voice-robot .container {
  padding: 0 10px;
  position: relative;
  z-index: 2;
}
.voice-robot_wrap {
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
  max-width: 1400px;
  width: calc(100% - 20px);
  margin: 0 auto;
  background-color: var(--whiteF8);
  border-radius: 5px 30px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1320px) {
  .voice-robot_wrap {
    padding-bottom: calc(10px + 50 * (100vw - 360px) / 960);
  }
}
.voice-robot_wrap::before {
  content: "";
  position: absolute;
  left: 0;
  /* Только если меньше контейнера */
  top: -1100px;
  z-index: 1;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);
}
@media (max-width: 1320px) {
  .voice-robot_wrap::before {
    top: calc(300px + -1400 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .voice-robot_wrap::before {
    width: 638px;
    top: -100px;
    left: -50%;
    transform: translateX(-20%);
  }
}
.voice-robot_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px 30px;
}
@media (max-width: 992px) {
  .voice-robot_content {
    flex-direction: column;
  }
}
.voice-robot_text-wrap {
  max-width: 492px;
  width: 100%;
}
@media (max-width: 992px) {
  .voice-robot_text-wrap {
    max-width: 100%;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .voice-robot_text-wrap {
    text-align: start;
  }
}
.voice-robot_title {
  text-align: start;
  /* Только если меньше контейнера */
   margin-bottom: 40px;
}
@media (max-width: 1320px) {
  .voice-robot_title {
     margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .voice-robot_title {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .voice-robot_title {
    text-align: start;
  }
}
.voice-robot_descr {
  /* Только если меньше контейнера */
  font-size: 18px;
  line-height: 1.4444444444;
  max-width: 380px;
  width: 100%;
  color: var(--grey);
  margin-bottom: 60px;
}
@media (max-width: 1320px) {
  .voice-robot_descr {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .voice-robot_descr {
    margin: 0 auto 50px auto;
  }
}
@media (max-width: 576px) {
  .voice-robot_descr {
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .voice-robot .btn {
    width: 100%;
  }
}
.voice-robot_video-wrap {
  position: relative;
  border-radius: 5px 30px;
  overflow: hidden;
  max-width: 588px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
}
.voice-robot_reviews {
  /* Только если меньше контейнера */
  margin-top: 30px;
}
@media (max-width: 1320px) {
  .voice-robot_reviews {
    margin-top: calc(50px + -20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .voice-robot_reviews {
    text-align: center;
  }
}
.voice-robot .reviews_text {
  color: var(--grey);
  font-weight: 300;
  font-size: 14px;
}
.voice-robot .reviews_logo-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}
@media (max-width: 992px) {
  .voice-robot .reviews_logo-wrap {
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .voice-robot .reviews_logo-wrap {
    gap: 5px;
  }
}
.voice-robot .logo-img_wrap {
  max-width: 128px;
  width: 100%;
  height: auto;
  background-color: var(--white);
  border-radius: 5px 10px;
  padding: 5px 7px;
}
.voice-robot .logo-img_wrap .logo__img {
  display: block;
  width: 100%;
  height: auto;
}

.voice-page-en .proposal {
  padding: 60px 0;
}

.voice-familiar {
  /* Только если меньше контейнера */
  padding-top: 0px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .voice-familiar {
    padding-top: calc(0px + 0 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-familiar {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.voice-familiar_board {
  border-radius: 5px 30px;
  background-color: var(--blue);
  /* Только если меньше контейнера */
  padding-top: 60px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
  /* Только если меньше контейнера */
  padding-right: 60px;
  /* Только если меньше контейнера */
  padding-left: 60px;
  text-align: center;
}
@media (max-width: 1320px) {
  .voice-familiar_board {
    padding-top: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-familiar_board {
    padding-bottom: calc(20px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-familiar_board {
    padding-right: calc(20px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-familiar_board {
    padding-left: calc(20px + 40 * (100vw - 360px) / 960);
  }
}
.voice-familiar_board-title {
  /* Только если меньше контейнера */
  font-size: 28px;
  color: var(--white);
  max-width: 590px;
  width: 100%;
  margin: 0 auto 30px auto;
  line-height: 145%;
}
@media (max-width: 1320px) {
  .voice-familiar_board-title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .voice-familiar_board-title {
    text-align: start;
  }
}
.voice-familiar_board-text {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--white);
  margin: 0 auto 50px auto;
  line-height: 150%;
}
@media (max-width: 1320px) {
  .voice-familiar_board-text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .voice-familiar_board-text {
    text-align: start;
  }
}
.voice-familiar_board-columns {
  display: grid;
  grid-template: 1fr/230px 1px 230px 1px 230px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .voice-familiar_board-columns {
    grid-template: 1fr/1fr;
  }
}
.voice-familiar_board-column {
  text-align: start;
  width: 100%;
}
.voice-familiar_board-column .icon {
  /* Только если меньше контейнера */
  margin-bottom: 40px;
  /* Только если меньше контейнера */
  width: 48px;
  /* Только если меньше контейнера */
  height: 48px;
  color: var(--white);
}
@media (max-width: 1320px) {
  .voice-familiar_board-column .icon {
    margin-bottom: calc(30px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-familiar_board-column .icon {
    width: calc(36px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-familiar_board-column .icon {
    height: calc(36px + 12 * (100vw - 360px) / 960);
  }
}
.voice-familiar .column-title {
  /* Только если меньше контейнера */
  font-size: 22px;
  color: var(--white);
  margin-bottom: 20px;
  line-height: 150%;
}
@media (max-width: 1320px) {
  .voice-familiar .column-title {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.voice-familiar .column-text {
  color: var(--white);
  font-size: 14px;
  line-height: 142%;
}
.voice-familiar .divider {
  width: 1px;
  height: 100%;
  background-color: var(--white);
  opacity: 0.25;
}
@media (max-width: 992px) {
  .voice-familiar .divider {
    width: 100%;
    height: 1px;
  }
}
@media (max-width: 576px) {
  .voice-familiar .btn {
    width: 100%;
  }
}

.voice-works {
  background-color: var(--whiteF8);
  /* Только если меньше контейнера */
  padding-top: 72px;
  /* Только если меньше контейнера */
  padding-bottom: 72px;
}
@media (max-width: 1320px) {
  .voice-works {
    padding-top: calc(60px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-works {
    padding-bottom: calc(60px + 12 * (100vw - 360px) / 960);
  }
}
.voice-works_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .voice-works_content {
    flex-direction: column;
  }
}
.voice-works_left-side {
  max-width: 400px;
  min-width: 330px;
  width: 100%;
}
@media (max-width: 992px) {
  .voice-works_left-side {
    display: none;
  }
}
.voice-works_left-side .voice-works_card:nth-child(1) .icon-wrap {
  border-right: 1px solid var(--blue);
}
.voice-works_left-side .voice-works_card:nth-child(1) .icon {
  color: var(--blue);
}
.voice-works_left-side .voice-works_card:nth-child(2) .icon-wrap {
  border-right: 1px solid var(--yellow);
}
.voice-works_left-side .voice-works_card:nth-child(2) .icon {
  color: var(--yellow);
}
.voice-works_card {
  background-color: var(--white);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px 25px;
  padding: 20px;
  display: grid;
  grid-template: 1fr/56px 1fr;
  align-items: flex-start;
  gap: 20px;
  min-height: 150px;
  max-width: 400px;
}
.voice-works_card:not(:last-child) {
  margin-bottom: 30px;
}
.voice-works_card .icon {
  width: 36px;
  height: 36px;
}
.voice-works_center-side {
  position: relative;
  max-width: 340px;
  width: 100%;
  text-align: center;
}
@media (max-width: 992px) {
  .voice-works_center-side {
    max-width: 100%;
  }
}
.voice-works .robot__image {
  display: inline-block;
  /* Только если меньше контейнера */
  width: 216px;
  height: auto;
  position: relative;
  z-index: 1;
  box-shadow: 0 10.7px 26.75px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
@media (max-width: 1320px) {
  .voice-works .robot__image {
    width: calc(160px + 56 * (100vw - 360px) / 960);
  }
}
.voice-works .robot__image img {
  width: 100%;
  height: 100%;
}
.voice-works .line {
  position: absolute;
}
@media (max-width: 992px) {
  .voice-works .line {
    display: none;
  }
}
.voice-works .top_left {
  top: 30px;
  right: 0;
}
.voice-works .top_right {
  top: 30px;
  left: 0;
}
.voice-works .bottom_left {
  bottom: 30px;
  left: 0;
}
.voice-works .bottom_right {
  bottom: 30px;
  right: 0;
}
.voice-works .lines {
  display: none;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .voice-works .lines {
    display: block;
    width: 75%;
  }
}
.voice-works_right-side {
  max-width: 400px;
  min-width: 330px;
  width: 100%;
}
@media (max-width: 992px) {
  .voice-works_right-side {
    display: none;
  }
}
.voice-works_right-side .voice-works_card:nth-child(1) .icon-wrap {
  border-right: 1px solid var(--green);
}
.voice-works_right-side .voice-works_card:nth-child(1) .icon {
  color: var(--green);
}
.voice-works_right-side .voice-works_card:nth-child(2) .icon-wrap {
  border-right: 1px solid var(--black2F);
}
.voice-works_right-side .voice-works_card:nth-child(2) .icon {
  color: var(--black2F);
}
.voice-works .icon-wrap {
  height: 100%;
  padding-right: 20px;
}
.voice-works .card_title {
  font-weight: 700;
  color: var(--black2F);
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.4285714286;
}
.voice-works .card_text {
  color: var(--grey);
  font-size: 14px;
  line-height: 1.4285714286;
}
.voice-works .voice_tabs {
  border-radius: 25px;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  display: none;
}
@media (max-width: 992px) {
  .voice-works .voice_tabs {
    display: block;
  }
}
.voice-works .voice_tabs .tabs__head-row {
  gap: 0;
  margin: 0;
  padding-bottom: 2px;
  position: relative;
  background-color: var(--whiteF8);
}
.voice-works .voice_tabs .tabs__head-row::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--grey);
  opacity: 0.25;
}
.voice-works .voice_tabs .tabs__head-row::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 52px;
  height: 2px;
  background-color: var(--after-color, var(--blue));
  transition: left 0.3s ease, background-color 0.3s ease;
}
.voice-works .voice_tabs .tabs__head-col:nth-child(1) .tabs__btn.active {
  color: var(--blue);
}
.voice-works .voice_tabs .tabs__head-col:nth-child(2) .tabs__btn.active {
  color: var(--yellow);
}
.voice-works .voice_tabs .tabs__head-col:nth-child(3) .tabs__btn.active {
  color: var(--green);
}
.voice-works .voice_tabs .tabs__head-col:nth-child(4) .tabs__btn.active {
  color: var(--black2F);
}
.voice-works .voice_tabs .tabs__btn {
  padding: 11px 26px;
  border: none;
  background-color: var(--whiteF8);
}
.voice-works .voice_tabs .tabs__btn.active {
  background-color: var(--white);
}
.voice-works .voice_tabs .tabs__btn.active .icon {
  opacity: 1;
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(1) {
  color: var(--blue);
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(2) {
  color: var(--yellow);
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(3) {
  color: var(--green);
}
.voice-works .voice_tabs .tabs__btn.active:nth-child(4) {
  color: var(--black2F);
}
.voice-works .voice_tabs .icon {
  width: 28px;
  height: 28px;
  opacity: 0.15;
}
.voice-works .voice_tabs .tabs__body {
  padding: 25px;
}

.voice-use {
  /* Только если меньше контейнера */
  padding-top: 0px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .voice-use {
    padding-top: calc(0px + 0 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-use {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.voice-use_example {
  background-color: var(--blue);
  border-radius: 5px 30px;
  /* Только если меньше контейнера */
  padding-top: 70px;
  /* Только если меньше контейнера */
  padding-bottom: 70px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
@media (max-width: 1320px) {
  .voice-use_example {
    padding-top: calc(40px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .voice-use_example {
    padding-bottom: calc(40px + 30 * (100vw - 360px) / 960);
  }
}
.voice-use .example_body {
  max-width: 736px;
  width: 100%;
  margin: 0 auto;
}
.voice-use .example_title {
  /* Только если меньше контейнера */
  font-size: 28px;
  color: var(--white);
  font-weight: 700;
  margin-bottom: 60px;
  text-align: center;
}
@media (max-width: 1320px) {
  .voice-use .example_title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .voice-use .example_title {
    max-width: 280px;
    width: 100%;
    margin: 0 auto 50px auto;
  }
}
.voice-use .example_item-wrap {
  counter-reset: num;
}
.voice-use .example_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Только если меньше контейнера */
  gap: 30px;
}
@media (max-width: 1320px) {
  .voice-use .example_item {
    gap: calc(25px + 5 * (100vw - 360px) / 960);
  }
}
.voice-use .example_item:not(:last-child) {
  /* Только если меньше контейнера */
  margin-bottom: 40px;
}
@media (max-width: 1320px) {
  .voice-use .example_item:not(:last-child) {
    margin-bottom: calc(50px + -10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .voice-use .example_item {
    flex-direction: column;
    align-items: flex-start;
  }
}
.voice-use .item_title {
  max-width: 275px;
  width: 100%;
  counter-increment: num;
  font-weight: 500;
  color: var(--white);
  /* Только если меньше контейнера */
  font-size: 18px;
  line-height: 1.4444444444;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1320px) {
  .voice-use .item_title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.voice-use .item_title::before {
  content: "0" counter(num);
  font-weight: 400;
  opacity: 0.7;
  display: block;
}
@media (max-width: 576px) {
  .voice-use .item_title {
    flex-direction: column;
    align-items: flex-start;
  }
}
.voice-use .record_wrap {
  max-width: 390px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 768px) {
  .voice-use .record_wrap {
    max-width: 100%;
  }
}
.voice-use .record_track {
  max-width: 289px;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  position: relative;
}
@media (max-width: 768px) {
  .voice-use .record_track {
    max-width: 100%;
  }
}
.voice-use .record_progress {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.voice-use .record_progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 11px;
  height: 11px;
  background-color: var(--thumb-color, #fff);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.voice-use .record_progress::-moz-range-thumb {
  width: 11px;
  height: 11px;
  background-color: var(--thumb-color, #fff);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.voice-use .record_progress::-ms-thumb {
  width: 11px;
  height: 11px;
  background-color: var(--thumb-color, #fff);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.voice-use .record_current-time {
  font-weight: 500;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--white);
  transition: all 0.3s ease 0s;
  min-width: 33px;
  max-width: 33px;
  height: auto;
  display: inline-block;
  text-align: center;
}
@media (max-width: 1320px) {
  .voice-use .record_current-time {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.voice-use .record-play_btn {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .voice-use .record-play_btn:hover {
    background-color: var(--blue);
    border-color: var(--white);
    transition: all 0.3s ease 0s;
  }
  .voice-use .record-play_btn:hover .icon {
    color: var(--white);
    transition: all 0.3s ease 0s;
  }
}
.voice-use .icon {
  color: var(--blue);
  width: 7px;
  height: 8px;
  transition: all 0.3s ease 0s;
}

.contact-center_title {
  color: var(--black2F);
}

.contact-center-try {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
  background-color: var(--whiteF8);
}
@media (max-width: 1320px) {
  .contact-center-try {
    padding-top: calc(50px + 50 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact-center-try {
    padding-bottom: calc(50px + 50 * (100vw - 360px) / 960);
  }
}
.contact-center-try_title {
  max-width: 700px;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .contact-center-try_title {
    text-align: start;
  }
}
.contact-center-try_text {
  max-width: 532px;
  width: 100%;
  margin: 0 auto 50px auto;
  text-align: center;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
  line-height: 1.5;
}
@media (max-width: 1320px) {
  .contact-center-try_text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .contact-center-try_text {
    margin-bottom: 40px;
    text-align: start;
  }
}
.contact-center-try_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 44px;
}
@media (max-width: 768px) {
  .contact-center-try_content {
    flex-direction: column;
    gap: 10px;
  }
}
.contact-center-try_left-side, .contact-center-try_right-side {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media (max-width: 768px) {
  .contact-center-try_left-side, .contact-center-try_right-side {
    gap: 10px;
  }
}
@media (max-width: 576px) {
  .contact-center-try_left-side, .contact-center-try_right-side {
    width: 100%;
  }
}
.contact-center-try_left-side {
  align-items: flex-start;
}
.contact-center-try_left-side .contact-center-try_card:nth-child(1) {
  max-width: 326px;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-center-try_left-side .contact-center-try_card:nth-child(1) {
    max-width: 380px;
  }
}
.contact-center-try_left-side .contact-center-try_card:nth-child(1) .icon-wrap {
  border-color: var(--blue);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(1) .icon {
  color: var(--blue);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(2) {
  max-width: 175px;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-center-try_left-side .contact-center-try_card:nth-child(2) {
    max-width: 380px;
  }
}
.contact-center-try_left-side .contact-center-try_card:nth-child(2) .icon-wrap {
  border-color: var(--yellow);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(2) .icon {
  color: var(--yellow);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(3) {
  max-width: 326px;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-center-try_left-side .contact-center-try_card:nth-child(3) {
    max-width: 380px;
  }
}
.contact-center-try_left-side .contact-center-try_card:nth-child(3) .icon-wrap {
  border-color: var(--green);
}
.contact-center-try_left-side .contact-center-try_card:nth-child(3) .icon {
  color: var(--green);
}
.contact-center-try_right-side {
  align-items: flex-end;
}
.contact-center-try_right-side .contact-center-try_card:nth-child(1) {
  max-width: 326px;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-center-try_right-side .contact-center-try_card:nth-child(1) {
    max-width: 380px;
  }
}
.contact-center-try_right-side .contact-center-try_card:nth-child(1) .icon-wrap {
  border-color: var(--blue);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(1) .icon {
  color: var(--blue);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(2) {
  max-width: 175px;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-center-try_right-side .contact-center-try_card:nth-child(2) {
    max-width: 380px;
  }
}
.contact-center-try_right-side .contact-center-try_card:nth-child(2) .icon-wrap {
  border-color: var(--green);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(2) .icon {
  color: var(--green);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(3) {
  max-width: 326px;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-center-try_right-side .contact-center-try_card:nth-child(3) {
    max-width: 380px;
  }
}
.contact-center-try_right-side .contact-center-try_card:nth-child(3) .icon-wrap {
  border-color: var(--yellow);
}
.contact-center-try_right-side .contact-center-try_card:nth-child(3) .icon {
  color: var(--yellow);
}
.contact-center-try_card {
  position: relative;
}
.contact-center-try .card_content {
  position: relative;
  z-index: 1;
  background-color: var(--white);
  border-radius: 5px 25px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 20px 35px 20px 22px;
  position: relative;
  width: 100%;
}
@media (max-width: 576px) {
  .contact-center-try .card_content {
    padding: 15px;
    gap: 15px;
  }
}
.contact-center-try .card_content .icon {
  width: 36px;
  height: 36px;
}
@media (max-width: 992px) {
  .contact-center-try_center-side {
    display: none;
  }
}
.contact-center-try_center-side .logo__image {
  max-width: 330px;
  min-width: 250px;
  height: auto;
  display: inline-block;
}
.contact-center-try_center-side .logo__image img {
  width: 100%;
  height: 100%;
}
.contact-center-try .icon-wrap {
  padding-right: 22px;
  border-right: 1px solid transparent;
}
@media (max-width: 576px) {
  .contact-center-try .icon-wrap {
    padding-right: 15px;
  }
}
.contact-center-try .card_title {
  max-width: 180px;
  color: var(--black2F);
  font-size: 14px;
  font-weight: 400;
}
.contact-center-try .line {
  position: absolute;
}
@media (max-width: 992px) {
  .contact-center-try .line {
    display: none;
  }
}
.contact-center-try .top_right {
  /* Только если меньше контейнера */
  right: -80px;
  top: 50%;
}
@media (max-width: 1320px) {
  .contact-center-try .top_right {
    right: calc(-40px + -40 * (100vw - 360px) / 960);
  }
}
.contact-center-try .top_left {
  /* Только если меньше контейнера */
  left: -80px;
  top: 50%;
}
@media (max-width: 1320px) {
  .contact-center-try .top_left {
    left: calc(-40px + -40 * (100vw - 360px) / 960);
  }
}
.contact-center-try .left {
  top: 50%;
  transform: translateY(-50%);
  /* Только если меньше контейнера */
  right: -170px;
}
@media (max-width: 1320px) {
  .contact-center-try .left {
    right: calc(-80px + -90 * (100vw - 360px) / 960);
  }
}
.contact-center-try .bottom_right {
  /* Только если меньше контейнера */
  left: -80px;
  bottom: 50%;
}
@media (max-width: 1320px) {
  .contact-center-try .bottom_right {
    left: calc(-40px + -40 * (100vw - 360px) / 960);
  }
}
.contact-center-try .bottom_left {
  /* Только если меньше контейнера */
  right: -80px;
  bottom: 50%;
}
@media (max-width: 1320px) {
  .contact-center-try .bottom_left {
    right: calc(-40px + -40 * (100vw - 360px) / 960);
  }
}
.contact-center-try .right {
  top: 50%;
  transform: translateY(-50%);
  /* Только если меньше контейнера */
  left: -170px;
}
@media (max-width: 1320px) {
  .contact-center-try .right {
    left: calc(-80px + -90 * (100vw - 360px) / 960);
  }
}

.contact-center-choose {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .contact-center-choose {
    padding-top: calc(50px + 50 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact-center-choose {
    padding-bottom: calc(50px + 50 * (100vw - 360px) / 960);
  }
}
.contact-center-choose_content {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  counter-reset: num;
}
.contact-center-choose .icon {
  width: 20px;
  height: 20px;
}
.contact-center-choose .col_head {
  margin-bottom: 20px;
  display: grid;
  grid-template: 1fr/auto 88px 170px;
  align-items: flex-start;
  width: 100%;
  /* Только если меньше контейнера */
  gap: 20px;
}
@media (max-width: 1320px) {
  .contact-center-choose .col_head {
    gap: calc(8px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .contact-center-choose .col_head {
    grid-template: 1fr/auto 75px 170px;
  }
}
@media (max-width: 576px) {
  .contact-center-choose .col_head {
    grid-template: 1fr/auto 50px 75px;
  }
}
.contact-center-choose .col_head span {
  text-align: center;
  /* Только если меньше контейнера */
  font-size: 22px;
}
@media (max-width: 1320px) {
  .contact-center-choose .col_head span {
    font-size: calc(16px + 6 * (100vw - 360px) / 960);
  }
}
.contact-center-choose .col_head span:nth-child(2) {
  color: var(--blue);
  font-weight: 500;
}
.contact-center-choose .col_head span:nth-child(3) {
  color: var(--grey);
}
.contact-center-choose .col_item {
  display: grid;
  grid-template: 1fr/75px auto 88px 170px;
  align-items: center;
  width: 100%;
  /* Только если меньше контейнера */
  gap: 20px;
  counter-increment: num;
}
@media (max-width: 1320px) {
  .contact-center-choose .col_item {
    gap: calc(8px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .contact-center-choose .col_item {
    grid-template: 1fr/50px auto 75px 170px;
  }
}
@media (max-width: 576px) {
  .contact-center-choose .col_item {
    grid-template: 1fr/25px auto 50px 75px;
    align-items: flex-start;
  }
}
.contact-center-choose .col_item:nth-child(2)::before {
  padding-top: 28px;
}
@media (max-width: 768px) {
  .contact-center-choose .col_item:nth-child(2)::before {
    padding-top: 15px;
  }
}
.contact-center-choose .col_item:nth-child(-n+10)::before {
  content: "0" counter(num);
}
.contact-center-choose .col_item:nth-child(n+11)::before {
  content: counter(num);
}
.contact-center-choose .col_item:last-child::before {
  padding-bottom: 28px;
}
@media (max-width: 576px) {
  .contact-center-choose .col_item:last-child::before {
    padding-bottom: 15px;
  }
}
.contact-center-choose .col_item::before {
  color: var(--grey);
  /* Только если меньше контейнера */
  font-size: 18px;
  opacity: 0.5;
  padding: 17px 0;
}
@media (max-width: 1320px) {
  .contact-center-choose .col_item::before {
    font-size: calc(14px + 4 * (100vw - 360px) / 960);
  }
}
@media (max-width: 400px) {
  .contact-center-choose .col_item::before {
    padding-top: 15px;
  }
}
.contact-center-choose .col_item:nth-child(2) .icon-check_wrap {
  padding-top: 28px;
  border-radius: 30px 30px 0 0;
}
@media (max-width: 576px) {
  .contact-center-choose .col_item:nth-child(2) .icon-check_wrap {
    padding-top: 15px;
  }
}
.contact-center-choose .col_item:nth-child(2) .icon-cross_wrap,
.contact-center-choose .col_item:nth-child(2) p {
  padding-top: 28px;
}
@media (max-width: 576px) {
  .contact-center-choose .col_item:nth-child(2) .icon-cross_wrap,
  .contact-center-choose .col_item:nth-child(2) p {
    padding-top: 15px;
  }
}
.contact-center-choose .col_item.last-child .icon-check_wrap {
  padding-bottom: 28px;
  border-radius: 0 0 30px 30px;
}
@media (max-width: 576px) {
  .contact-center-choose .col_item.last-child .icon-check_wrap {
    padding-bottom: 15px;
  }
}
.contact-center-choose .col_item.last-child .icon-cross_wrap,
.contact-center-choose .col_item.last-child p {
  padding-bottom: 28px;
}
@media (max-width: 576px) {
  .contact-center-choose .col_item.last-child .icon-cross_wrap,
  .contact-center-choose .col_item.last-child p {
    padding-bottom: 15px;
  }
}
.contact-center-choose .col_item p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 245px;
  width: 100%;
  padding: 17px 0;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
}
@media (max-width: 1320px) {
  .contact-center-choose .col_item p {
    font-size: calc(14px + 4 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .contact-center-choose .col_item p {
    padding: 14px 0;
  }
}
@media (max-width: 400px) {
  .contact-center-choose .col_item p {
    max-width: 120px;
  }
}
.contact-center-choose .col_item .icon-check_wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  padding: 17px 0;
}
@media (max-width: 576px) {
  .contact-center-choose .col_item .icon-check_wrap {
    padding: 14px 0;
  }
}
.contact-center-choose .col_item .icon-check_wrap .icon {
  color: var(--white);
}
.contact-center-choose .col_item .icon-cross_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0;
  height: 100%;
}
@media (max-width: 576px) {
  .contact-center-choose .col_item .icon-cross_wrap {
    padding: 14px 0;
  }
}

.trial {
  padding: 60px 0;
  background-color: var(--blue);
}
.trial_without-additional-content .trial_content {
  max-width: 700px;
}
.trial_without-additional-content .trial_text-block {
  max-width: 100%;
  width: 100%;
  text-align: center;
}
.trial_without-additional-content .trial_text-block-title {
  font-weight: 700;
}
.trial_without-additional-content .trial__image {
  display: none;
}
.trial_modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: calc(100% - 40px);
  border-radius: 5px;
}
.trial_modal.open {
  display: block;
}
@media (max-width: 768px) {
  .trial_modal .trial_content {
    flex-direction: column;
  }
}
.trial_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .trial_content {
    flex-direction: column-reverse;
  }
}
.trial_text-block {
  max-width: 346px;
  width: 100%;
}
@media (max-width: 992px) {
  .trial_text-block {
    max-width: 100%;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .trial_text-block {
    text-align: start;
  }
}
.trial_text-block-title {
  font-weight: 400;
  /* Только если меньше контейнера */
  font-size: 28px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
  color: var(--white);
  line-height: 145%;
}
@media (max-width: 1320px) {
  .trial_text-block-title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .trial_text-block-title {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
.trial_text-block-descr {
  font-weight: 700;
  font-size: 14px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
  color: var(--white);
  line-height: 142%;
}
@media (max-width: 1320px) {
  .trial_text-block-descr {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
.trial .trial__image {
  max-width: 480px;
  width: 100%;
  height: auto;
}
.trial .trial__image img {
  width: 100%;
  height: 100%;
}
@media (max-width: 576px) {
  .trial .btn {
    width: 100%;
  }
}

.tariff_content {
  background-color: var(--blue);
  border-radius: 5px 30px;
  /* Только если меньше контейнера */
  padding-top: 60px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
  padding-left: 18px;
  padding-right: 18px;
}
@media (max-width: 1320px) {
  .tariff_content {
    padding-top: calc(20px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .tariff_content {
    padding-bottom: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.tariff_body {
  max-width: 875px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 992px) {
  .tariff_body {
    flex-direction: column-reverse;
  }
}
.tariff_text-block {
  max-width: 425px;
  width: 100%;
}
@media (max-width: 992px) {
  .tariff_text-block {
    max-width: 100%;
    text-align: center;
  }
}
.tariff_text-block-title {
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 28px;
  margin-bottom: 30px;
  color: var(--white);
}
@media (max-width: 1320px) {
  .tariff_text-block-title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
.tariff_text-block-descr {
  font-size: 14px;
  font-weight: 700;
  color: var(--white);
  /* Только если меньше контейнера */
  margin-bottom: 75px;
  line-height: 142%;
  max-width: 342px;
  width: 100%;
}
@media (max-width: 1320px) {
  .tariff_text-block-descr {
    margin-bottom: calc(30px + 45 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .tariff_text-block-descr {
    max-width: 260px;
    width: 100%;
    margin: 0 auto 30px auto;
  }
}
.tariff .tariff__image {
  max-width: 460px;
  height: auto;
}
.tariff .tariff__image img {
  width: 100%;
  height: 100%;
}
@media (max-width: 576px) {
  .tariff .btn {
    width: 100%;
  }
}

.clients-result {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .clients-result {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .clients-result {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.clients-result .container {
  text-align: center;
}
.clients-result_content {
  display: grid;
  grid-template: 1fr/303px 1fr;
  justify-content: center;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  gap: 20px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .clients-result_content {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .clients-result_content {
    grid-template: 1fr/250px 1fr;
  }
}
@media (max-width: 768px) {
  .clients-result_content {
    grid-template: 1fr/1fr;
  }
}
.clients-result .clients-result__image {
  width: 315px;
  border-radius: 5px 30px;
  overflow: hidden;
  height: 100%;
}
@media (max-width: 768px) {
  .clients-result .clients-result__image {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
  }
}
.clients-result .clients-result__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.clients-result_numbers-wrap {
  width: 100%;
  border-radius: 5px 30px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: var(--whiteF8);
  /* Только если меньше контейнера */
  padding-top: 50px;
  /* Только если меньше контейнера */
  padding-bottom: 50px;
  /* Только если меньше контейнера */
  padding-right: 50px;
  /* Только если меньше контейнера */
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  /* Только если меньше контейнера */
  row-gap: 45px;
}
@media (max-width: 1320px) {
  .clients-result_numbers-wrap {
    padding-top: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .clients-result_numbers-wrap {
    padding-bottom: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .clients-result_numbers-wrap {
    padding-right: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .clients-result_numbers-wrap {
    padding-left: calc(25px + 25 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .clients-result_numbers-wrap {
    row-gap: calc(40px + 5 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .clients-result_numbers-wrap {
    align-items: flex-start;
  }
}
.clients-result_number-top, .clients-result_number-bottom {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* Только если меньше контейнера */
  gap: 30px;
}
@media (max-width: 1320px) {
  .clients-result_number-top, .clients-result_number-bottom {
    gap: calc(40px + -10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .clients-result_number-top, .clients-result_number-bottom {
    flex-direction: column;
  }
}
.clients-result_number-top {
  align-items: center;
}
@media (max-width: 576px) {
  .clients-result_number-top {
    align-items: flex-start;
  }
}
.clients-result_number-top .clients-result_number:nth-child(1) {
  max-width: 300px;
  width: 100%;
}
.clients-result_number-top .clients-result_number:nth-child(1) .percent {
  color: var(--blue);
}
.clients-result_number-top .clients-result_number:nth-child(2) {
  max-width: 207px;
  width: 100%;
}
.clients-result_number-top .clients-result_number:nth-child(2) .percent {
  color: var(--yellow);
}
.clients-result_number-bottom {
  align-items: flex-end;
}
@media (max-width: 576px) {
  .clients-result_number-bottom {
    align-items: center;
  }
}
.clients-result_number-bottom .clients-result_number:first-child {
  max-width: 300px;
  width: 100%;
}
.clients-result_number-bottom .clients-result_number:first-child .percent {
  color: var(--green);
}
.clients-result_number-bottom .clients-result_number:last-child {
  max-width: 207px;
  width: 100%;
}
.clients-result_number {
  text-align: start;
  width: 50%;
}
@media (max-width: 576px) {
  .clients-result_number {
    width: 100%;
  }
}
.clients-result_number:nth-child(3) .percent {
  color: var(--green);
}
.clients-result .percent {
  /* Только если меньше контейнера */
  font-size: 70px;
  font-weight: 600;
  /* Только если меньше контейнера */
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .clients-result .percent {
    font-size: calc(50px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .clients-result .percent {
    margin-bottom: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
.clients-result .number_text {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
  font-weight: 400;
  max-width: 270px;
  width: 100%;
}
@media (max-width: 1320px) {
  .clients-result .number_text {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.clients-result .number_link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  font-weight: 600;
  font-size: 18px;
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
@media (max-width: 576px) {
  .clients-result .number_link {
    margin-top: 10px;
    justify-content: center;
  }
}
.clients-result .number_link span {
  white-space: nowrap;
}
.clients-result .number_link .icon {
  rotate: 180deg;
  min-width: 13px;
  min-height: 11px;
  max-width: 13px;
  max-height: 11px;
  color: var(--blue);
}
@media (hover: hover) {
  .clients-result .number_link:hover {
    color: var(--hover-blue);
    transition: all 0.3s ease 0s;
  }
  .clients-result .number_link:hover .icon {
    color: var(--hover-blue);
  }
}
@media (hover: none) {
  .clients-result .number_link:active {
    color: var(--hover-blue);
  }
  .clients-result .number_link:active .icon {
    color: var(--hover-blue);
  }
}
.clients-result .btn {
  font-weight: 400;
}

.contacts {
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 50px;
  max-width: 1400px;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-radius: 5px 30px 0 0;
  background: linear-gradient(175.44deg, #F8F8F8 3.63%, rgba(248, 248, 248, 0) 85.79%);
  position: relative;
  overflow: hidden;
}
@media (max-width: 1320px) {
  .contacts {
    padding-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
.contacts::before {
  content: "";
  position: absolute;
  left: 0;
  /* Только если меньше контейнера */
  top: -1100px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);
}
@media (max-width: 1320px) {
  .contacts::before {
    top: calc(300px + -1400 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .contacts::before {
    width: 638px;
    top: -100px;
    left: -50%;
    transform: translateX(-20%);
  }
}
.contacts_content {
  position: relative;
  z-index: 1;
}
.contacts_title {
  text-align: center;
  color: var(--blue);
}
.contacts_card-wrap {
  max-width: 900px;
  width: 100%;
  margin: 0 auto 50px auto;
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  justify-items: center;
}
@media (max-width: 768px) {
  .contacts_card-wrap {
    grid-template: 1fr/repeat(auto-fit, minmax(300px, 1fr));
  }
}
.contacts_card {
  background-color: var(--white);
  border-radius: 5px 30px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
  padding: 25px;
  max-width: 440px;
  width: 100%;
}
.contacts_card-title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}
.contacts_card-title .icon {
  width: 24px;
  height: 24px;
  color: var(--blue);
}
.contacts_card-title span {
  font-size: 18px;
  font-weight: 700;
  color: var(--black2F);
}
.contacts_card-text p,
.contacts_card-text a {
  /* Только если меньше контейнера */
  font-size: 22px;
  line-height: 1.2727272727;
}
@media (max-width: 1320px) {
  .contacts_card-text p,
  .contacts_card-text a {
    font-size: calc(18px + 4 * (100vw - 360px) / 960);
  }
}
.contacts_card-text a {
  color: var(--blue);
}
.contacts_card-text p {
  color: var(--grey);
}
.contacts_card-tip {
  margin-top: 20px;
  font-size: 12px;
  color: var(--grey);
  line-height: 1.4285714286;
}
.contacts .contacts-social_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
@media (max-width: 992px) {
  .contacts .contacts-social_wrap {
    flex-direction: column;
  }
}
.contacts .contacts-social_media, .contacts .contacts-social_messengers {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 576px) {
  .contacts .contacts-social_media, .contacts .contacts-social_messengers {
    flex-direction: column;
  }
}
.contacts .media_title {
  font-size: 18px;
}
.contacts .media_link-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}
.contacts .media_link {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}
.contacts .media_link img {
  width: 24px;
  height: 24px;
}
.contacts_offices {
  /* Только если меньше контейнера */
  margin-bottom: 60px;
}
@media (max-width: 1320px) {
  .contacts_offices {
    margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.contacts .offices-title {
  /* Только если меньше контейнера */
  font-size: 28px;
  /* Только если меньше контейнера */
  margin-bottom: 60px;
  color: var(--black2F);
  text-align: center;
}
@media (max-width: 1320px) {
  .contacts .offices-title {
    font-size: calc(20px + 8 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contacts .offices-title {
    margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.contacts .offices_col-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  flex-wrap: wrap;
}
.contacts .offices_col b {
  /* Только если меньше контейнера */
  font-size: 18px;
  margin-bottom: 20px;
  display: inline-block;
  color: var(--black2F);
}
@media (max-width: 1320px) {
  .contacts .offices_col b {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.contacts .offices_number {
  display: flex;
  align-items: center;
  gap: 5px;
}
.contacts .offices_number:not(:last-child) {
  margin-bottom: 20px;
}
.contacts .offices_number .icon {
  width: 24px;
  height: 24px;
  color: var(--blue);
}
.contacts .offices_number a {
  font-size: 18px;
  color: var(--blue);
}

.contacts_ua .contacts_card-wrap {
  /* Только если меньше контейнера */
  margin-bottom: 60px;
}
@media (max-width: 1320px) {
  .contacts_ua .contacts_card-wrap {
    margin-bottom: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.contacts_ua .contacts-social_wrap {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  /* Только если меньше контейнера */
  padding-top: 60px;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
}
@media (max-width: 1320px) {
  .contacts_ua .contacts-social_wrap {
    padding-top: calc(40px + 20 * (100vw - 360px) / 960);
  }
}

.contacts_ua-v2 .contacts_card-wrap {
  max-width: 980px;
  grid-template: 1fr/repeat(auto-fit, minmax(300px, 1fr));
}
.contacts_ua-v2 .contacts-social_wrap {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  /* Только если меньше контейнера */
  padding-top: 60px;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
}
@media (max-width: 1320px) {
  .contacts_ua-v2 .contacts-social_wrap {
    padding-top: calc(40px + 20 * (100vw - 360px) / 960);
  }
}
.contacts_ua-v2 .contacts_number {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  /* Только если меньше контейнера */
  margin-bottom: 60px;
}
@media (max-width: 1320px) {
  .contacts_ua-v2 .contacts_number {
    margin-bottom: calc(30px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .contacts_ua-v2 .contacts_number {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}
.contacts_ua-v2 .number_col {
  width: 29%;
}
@media (max-width: 992px) {
  .contacts_ua-v2 .number_col {
    width: 48%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .contacts_ua-v2 .number_col {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .contacts_ua-v2 .number_col {
    text-align: start;
  }
}
.contacts_ua-v2 .number_title {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 25px;
  text-align: start;
}
@media (max-width: 992px) {
  .contacts_ua-v2 .number_title {
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .contacts_ua-v2 .number_title {
    justify-content: flex-start;
  }
}
.contacts_ua-v2 .number_title .icon {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  color: var(--blue);
}
.contacts_ua-v2 .number_title span {
  font-size: 18px;
  font-weight: 700;
}
.contacts_ua-v2 .number_body {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 992px) {
  .contacts_ua-v2 .number_body {
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .contacts_ua-v2 .number_body {
    justify-content: flex-start;
  }
}
.contacts_ua-v2 .number_body span {
  font-weight: 500;
  color: var(--black2F);
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .contacts_ua-v2 .number_body span {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.contacts_ua-v2 .numbers_wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contacts_ua-v2 .numbers_wrap a {
  display: block;
  width: max-content;
  font-size: 18px;
  color: var(--blue);
}
.contacts_ua-v2 .numbers_wrap p {
  color: var(--grey);
  font-size: 12px;
  line-height: 1.5;
}

.pricing {
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .pricing {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.pricing .container {
  position: relative;
}
.pricing_content {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(180deg, #F8F8F8 3.63%, rgba(248, 248, 248, 0) 85.79%);
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 60px;
  /* Только если меньше контейнера */
  margin-bottom: 0px;
  position: relative;
  overflow: hidden;
  border-radius: 5px 30px;
}
@media (max-width: 1320px) {
  .pricing_content {
    padding-bottom: calc(30px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .pricing_content {
    margin-bottom: calc(60px + -60 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .pricing_content {
    background-color: var(--whiteF8);
  }
}
.pricing_content::before {
  content: "";
  position: absolute;
  left: 0;
  /* Только если меньше контейнера */
  top: -1100px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);
}
@media (max-width: 1320px) {
  .pricing_content::before {
    top: calc(300px + -1400 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .pricing_content::before {
    width: 638px;
    top: -100px;
    left: -50%;
    transform: translateX(-20%);
  }
}
.pricing_text-wrap {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.pricing_title {
  max-width: 520px;
  width: 100%;
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  .pricing_title {
    text-align: start;
  }
}
.pricing_descr {
  /* Только если меньше контейнера */
  font-size: 18px;
  line-height: 1.4444444444;
  color: var(--grey);
}
@media (max-width: 1320px) {
  .pricing_descr {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .pricing_descr {
    text-align: start;
  }
}
.pricing .tabs__head {
  max-width: 735px;
  width: 100%;
  margin: 0 auto;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .pricing .tabs__head {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
.pricing .tabs__head-row {
  overflow: visible;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 0;
}
@media (max-width: 576px) {
  .pricing .tabs__head-row {
    justify-content: flex-start;
  }
}
.pricing .tabs__head-col {
  width: max-content;
}
.pricing .tabs__btn {
  /* Только если меньше контейнера */
  font-size: 18px;
  width: max-content;
}
@media (max-width: 1320px) {
  .pricing .tabs__btn {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.pricing .plan {
  width: 100%;
}
.pricing .plan_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
  /* Только если меньше контейнера */
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .pricing .plan_head {
    margin-bottom: calc(30px + -10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .pricing .plan_head {
    flex-direction: column;
    align-items: flex-start;
  }
}
.pricing .plan_head b {
  font-size: 14px;
  color: var(--black2F);
}
.pricing .plan_currency, .pricing .plan_payment {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 576px) {
  .pricing .plan_currency, .pricing .plan_payment {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }
}
.pricing .plan .currency-dropdown {
  position: relative;
}
@media (max-width: 576px) {
  .pricing .plan .currency-dropdown {
    width: 100%;
  }
}
.pricing .plan .currency-dropdown .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100px;
  padding: 15px;
  background-color: var(--whiteF8);
  border-radius: 50px;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s;
}
@media (max-width: 576px) {
  .pricing .plan .currency-dropdown .dropdown-button {
    width: 100%;
  }
}
.pricing .plan .currency-dropdown .dropdown-button.active {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.pricing .plan .currency-dropdown .dropdown-button.active .icon {
  rotate: 180deg;
  transition: all 0.3s ease 0s;
}
.pricing .plan .currency-dropdown .dropdown-button span {
  font-size: 14px;
  font-weight: 600;
  color: var(--grey);
}
.pricing .plan .currency-dropdown .dropdown-button .icon {
  width: 6px;
  height: 4px;
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .pricing .plan .currency-dropdown .dropdown-button:hover {
    border-color: var(--blue);
    transition: all 0.3s ease 0s;
  }
}
.pricing .plan .currency-dropdown .dropdown-list {
  background-color: var(--white);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 10px;
  padding: 0;
  position: absolute;
  z-index: 1;
  margin-top: 5px;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.pricing .plan .currency-dropdown .dropdown-list.open {
  padding: 5px 0;
  transition: all 0.3s ease 0s;
  height: auto;
  opacity: 1;
  visibility: visible;
}
.pricing .plan .currency-dropdown .dropdown-list.open .dropdown-item {
  height: auto;
  opacity: 1;
  font-size: 14px;
  transition: all 0.3s ease 0s;
}
.pricing .plan .currency-dropdown .dropdown-item {
  font-size: 0;
  height: 0;
  opacity: 0;
  font-weight: 600;
  color: var(--grey);
  cursor: pointer;
  padding: 10px 15px;
  transition: all 0.3s ease 0s;
}
.pricing .plan .currency-dropdown .dropdown-item.current-item {
  color: var(--blue);
}
@media (hover: hover) {
  .pricing .plan .currency-dropdown .dropdown-item:hover {
    color: var(--blue);
    transition: all 0.3s ease 0s;
    background-color: var(--whiteF8);
  }
}
.pricing .plan .payment_toggler-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
}
.pricing .plan .payment_toggler-wrap .plan_text {
  font-weight: 600;
  font-size: 14px;
  color: var(--grey);
  transition: all 0.3s ease 0s;
}
.pricing .plan .payment_toggler-wrap .plan_text i {
  font-size: 13px;
}
.pricing .plan .payment_toggler-wrap .annual {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.pricing .plan .payment_toggler-wrap .current_plan {
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
.pricing .plan .payment_toggler-wrap .payment_toggler {
  width: 40px;
  height: 10px;
  border-radius: 50px;
  background-color: var(--blueCE);
  position: relative;
}
.pricing .plan .payment_toggler-wrap .payment_toggler.toggle::before {
  right: 20px;
  transition: all 0.3s ease 0s;
}
.pricing .plan .payment_toggler-wrap .payment_toggler::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--blue);
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.pricing .plan .body_tip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(232, 181, 36, 0.1490196078);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .pricing .plan .body_tip {
    flex-direction: column;
    text-align: center;
  }
}
.pricing .plan .body_tip .icon {
  width: 30px;
  height: 30px;
  color: var(--yellow);
}
.pricing .plan .body_tip p {
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--black2F);
}
@media (max-width: 1320px) {
  .pricing .plan .body_tip p {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.pricing .plan_cards-wrap {
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(330px, 1fr));
  row-gap: 30px;
  /* Только если меньше контейнера */
  column-gap: 30px;
  /* Только если меньше контейнера */
  margin-bottom: 100px;
}
@media (max-width: 1320px) {
  .pricing .plan_cards-wrap {
    column-gap: calc(0px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .pricing .plan_cards-wrap {
    margin-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .pricing .plan_cards-wrap {
    grid-template: 1fr/1fr;
  }
}
.pricing .plan_card {
  background-color: var(--white);
  border-radius: 5px 30px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  padding: 35px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pricing .plan_card:nth-child(1) {
  --card-color: #007BB3 ;
}
.pricing .plan_card:nth-child(2) {
  --card-color: #E8B524 ;
}
.pricing .plan_card:nth-child(3) {
  --card-color: #03C15C ;
}
.pricing .plan_card .card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 25px;
}
.pricing .plan_card .card_head .icon {
  width: 35px;
  height: 35px;
  color: var(--card-color);
  opacity: 0.5;
}
.pricing .plan_card .card_head .popular {
  font-size: 14px;
  color: var(--card-color);
  border: 1px solid var(--card-color);
  border-radius: 50px;
  padding: 7px 15px;
  height: 35px;
}
.pricing .plan_card .card_title {
  /* Только если меньше контейнера */
  font-size: 22px;
  color: var(--black05);
  margin-bottom: 25px;
}
@media (max-width: 1320px) {
  .pricing .plan_card .card_title {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.pricing .plan_card .card_descr {
  font-size: 14px;
  color: var(--grey);
  line-height: 1.4285714286;
  margin-bottom: 25px;
}
.pricing .plan_card .card_price-wrap {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(88, 89, 91, 0.3019607843);
}
.pricing .plan_card .price-wrap {
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;
  transition: all 0.3s ease 0s;
}
.pricing .plan_card .price-wrap.toggle-price {
  display: none;
  opacity: 0;
}
.pricing .plan_card .price-wrap.current-price-wrap {
  opacity: 1;
  display: flex;
  transition: all 0.3s ease 0s;
}
.pricing .plan_card .current-price span {
  font-size: 40px;
  color: var(--card-color);
  font-weight: 700;
}
.pricing .plan_card .current-price span::before {
  content: var(--currency-symbol);
  margin-right: -10px;
}
.pricing .plan_card .current-price small {
  color: var(--card-color);
  font-weight: 700;
  font-size: 28px;
  margin-right: 10px;
}
@media (max-width: 576px) {
  .pricing .plan_card .current-price small {
    font-size: 32px;
  }
}
.pricing .plan_card .old-price {
  position: relative;
  margin-bottom: 5px;
}
.pricing .plan_card .old-price span {
  font-size: 28px;
  color: var(--grey);
  opacity: 0.5;
}
.pricing .plan_card .old-price span::before {
  content: var(--currency-symbol);
  margin-right: -10px;
}
.pricing .plan_card .old-price::before {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--card-color);
  rotate: -20deg;
}
.pricing .plan_card .price-descr,
.pricing .plan_card .save-descr {
  font-size: 13px;
  color: var(--grey);
  font-style: italic;
  transition: all 0.3s;
  opacity: 1;
}
.pricing .plan_card .price-descr.save-descr_hide,
.pricing .plan_card .save-descr.save-descr_hide {
  opacity: 0;
  margin-top: 0;
}
.pricing .plan_card .price-descr span,
.pricing .plan_card .save-descr span {
  font-weight: 700;
}
.pricing .plan_card .price-descr span::before,
.pricing .plan_card .save-descr span::before {
  content: var(--currency-symbol);
  margin-right: -2px;
}
.pricing .plan_card .save-descr {
  margin-top: 10px;
}
.pricing .plan_card .card_list-wrap {
  flex: 1;
}
.pricing .plan_card .card_list-wrap span {
  font-weight: 600;
  font-size: 14px;
  color: var(--grey);
  margin-bottom: 20px;
  display: inline-block;
}
.pricing .plan_card .card_list {
  display: flex;
  flex-direction: column;
  /* Только если меньше контейнера */
  row-gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .pricing .plan_card .card_list {
    row-gap: calc(15px + 5 * (100vw - 360px) / 960);
  }
}
.pricing .plan_card .list_item {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-size: 14px;
  color: var(--grey);
}
.pricing .plan_card .list_item b {
  font-weight: 600;
}
.pricing .plan_card .list_item .icon-check_2 {
  color: var(--card-color);
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}
.pricing .plan_card .list_item .icon-help {
  color: var(--blue);
  opacity: 0.3;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}
.pricing .plan_card .tooltip {
  margin-left: auto;
  position: relative;
  cursor: pointer;
}
.pricing .plan_card .tooltip:hover .tooltip_content {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}
.pricing .plan_card .tooltip:hover .icon {
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
.pricing .plan_card .tooltip_content {
  position: absolute;
  z-index: 3;
  top: -80px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  width: max-content;
  border-radius: 10px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: var(--white);
  font-size: 14px;
  color: var(--grey);
  line-height: 1.4285714286;
  padding: 15px;
  transition: all 0.3s ease 0s;
  font-weight: 400;
}
@media (max-width: 768px) {
  .pricing .plan_card .tooltip_content.second_content {
    left: auto;
    right: 0;
  }
}
.pricing .plan_card .tooltip_content b {
  font-weight: 600;
}
.pricing .plan_card .show_btn {
  color: var(--blue);
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
  margin: 20px auto 40px auto;
  /* Только если меньше контейнера */
  margin-bottom: 40px;
  font-weight: 700;
  display: block;
}
@media (max-width: 1320px) {
  .pricing .plan_card .show_btn {
    margin-bottom: calc(25px + 15 * (100vw - 360px) / 960);
  }
}
.pricing .plan_card .btn_pimary {
  width: 100%;
  margin-bottom: 25px;
  background-color: var(--card-color);
}
.pricing .plan_card .buy_btn {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: max-content;
  color: var(--card-color);
  opacity: 0.5;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .pricing .plan_card .buy_btn:hover {
    opacity: 1;
    transition: all 0.3s ease 0s;
  }
}
.pricing .plan_card .card_tip {
  font-size: 13px;
  color: var(--grey);
  line-height: 1.3846153846;
  margin-top: 32px;
}
.pricing .plan_card .card_tip a {
  color: var(--blue);
  font-weight: 600;
}
.pricing .plan_subtitle {
  font-weight: 700;
  font-size: 22px;
}
.pricing .contact-center_body .contact-center_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 992px) {
  .pricing .contact-center_body .contact-center_wrap {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
.pricing .contact-center_body .plan_card {
  min-height: max-content;
  max-width: 480px;
  width: 100%;
}
.pricing .contact-center_body .accordion-wrap {
  display: flex;
  flex-direction: column;
  /* Только если меньше контейнера */
  gap: 40px;
  max-width: 630px;
  width: 100%;
  padding-top: 35px;
}
@media (max-width: 1320px) {
  .pricing .contact-center_body .accordion-wrap {
    gap: calc(30px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .pricing .contact-center_body .accordion-wrap {
    padding: 0;
  }
}
.pricing .contact-center_body .accordion__item {
  border-radius: 0px;
  box-shadow: none;
  overflow: visible;
  width: 100%;
  background-color: transparent;
}
.pricing .contact-center_body .accordion__item:not(:last-child) {
  /* Только если меньше контейнера */
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .pricing .contact-center_body .accordion__item:not(:last-child) {
    margin-bottom: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
.pricing .contact-center_body .accordion__toggler {
  border-radius: 5px 30px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  min-height: 70px;
}
.pricing .contact-center_body .accordion__toggler:hover {
  background-color: var(--blue);
}
.pricing .contact-center_body .accordion__content {
  padding: 0;
  margin-top: 45px;
  counter-reset: num;
}
.pricing .contact-center_body .accordion__content .item_wrap:first-child {
  border-bottom: 1px solid rgba(88, 89, 91, 0.2);
  padding-bottom: 20px;
}
.pricing .contact-center_body .accordion__content .item_wrap:not(:last-child) {
  border-bottom: 1px solid rgba(88, 89, 91, 0.2);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.pricing .contact-center_body .accordion__content .item_title {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--black2F);
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  counter-increment: num;
}
@media (max-width: 1320px) {
  .pricing .contact-center_body .accordion__content .item_title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.pricing .contact-center_body .accordion__content .item_title::before {
  content: "0" counter(num);
  font-size: 18px;
  color: var(--grey);
  opacity: 0.5;
}
.pricing .contact-center_body .accordion__content .item_text {
  font-size: 14px;
  color: var(--grey);
}

.addons {
  /* Только если меньше контейнера */
  padding-top: 100px;
}
@media (max-width: 1320px) {
  .addons {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.addons_content {
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(330px, 1fr));
  gap: 20px;
  counter-reset: num;
}
@media (max-width: 576px) {
  .addons_content {
    grid-template: 1fr/1fr;
  }
}
.addons_card {
  display: flex;
  flex-direction: column;
  background-color: var(--whiteF8);
  border-radius: 5px 30px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  padding: 25px;
}
.addons_card:nth-child(4n+1) .card_number {
  background-color: var(--blue);
}
.addons_card:nth-child(4n+2) .card_number {
  background-color: var(--yellow);
}
.addons_card:nth-child(4n+3) .card_number {
  background-color: var(--green);
}
.addons_card:nth-child(4n+4) .card_number {
  background-color: var(--black2F);
}
.addons .card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .addons .card_head {
    gap: 25px;
  }
}
.addons .card_number {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  overflow: hidden;
  counter-increment: num;
  display: grid;
  place-items: center;
}
.addons .card_number::before {
  content: "0" counter(num);
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
}
.addons .card_toogler-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.addons .card_toggler-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.addons .card_toggler-item.active span {
  color: var(--blue);
}
.addons .card_toggler-item span {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  color: var(--grey);
}
.addons .card_toggler-item .icon {
  width: 17px;
  height: 17px;
  color: #C6DFEA;
  transition: all 0.3s ease 0s;
}
.addons .card_toggler-item .tooltip {
  position: relative;
  cursor: pointer;
}
.addons .card_toggler-item .tooltip:hover .tooltip_content {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}
.addons .card_toggler-item .tooltip:hover .icon {
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
.addons .card_toggler-item .tooltip_content {
  position: absolute;
  z-index: 3;
  top: -80px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: max-content;
  border-radius: 10px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: var(--white);
  font-size: 14px;
  color: var(--grey);
  line-height: 1.4285714286;
  padding: 15px;
  transition: all 0.3s ease 0s;
  font-weight: 400;
}
@media (max-width: 768px) {
  .addons .card_toggler-item .tooltip_content.second_content {
    left: auto;
    right: 0;
  }
}
.addons .card_toggler-item .tooltip_content b {
  font-weight: 600;
}
.addons .card_toggler {
  width: 40px;
  height: 10px;
  border-radius: 50px;
  background-color: var(--blueCE);
  position: relative;
}
.addons .card_toggler span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--blue);
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.addons .card_toggler span.active {
  left: 20px;
}
.addons .card_title-wrap {
  margin-bottom: 25px;
}
.addons .card_title-wrap i {
  font-size: 13px;
}
.addons .card_title {
  font-weight: 700;
  /* Только если меньше контейнера */
  font-size: 22px;
  margin-bottom: 10px;
  color: var(--black2F);
}
@media (max-width: 1320px) {
  .addons .card_title {
    font-size: calc(20px + 2 * (100vw - 360px) / 960);
  }
}
.addons .card_text {
  color: var(--grey);
  font-size: 14px;
  line-height: 1.4285714286;
  margin-bottom: 25px;
  flex: 1 0 auto;
}
.addons .card_price {
  /* Только если меньше контейнера */
  font-size: 40px;
  color: var(--blue);
  margin-bottom: 25px;
  font-weight: 700;
  width: max-content;
}
@media (max-width: 1320px) {
  .addons .card_price {
    font-size: calc(28px + 12 * (100vw - 360px) / 960);
  }
}
.addons .card_price span {
  display: flex;
  align-items: center;
}
.addons .card_price span::before {
  content: var(--currency-symbol);
}
.addons .btn {
  font-size: 14px;
  width: 100%;
  padding: 15px;
  height: 40px;
}

.pricing-feature {
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
  background-color: var(--whiteF8);
}
@media (max-width: 1320px) {
  .pricing-feature {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .pricing-feature {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.pricing-feature_head-row {
  display: grid;
  grid-template: 1fr/auto auto 23% 23% 23%;
  margin-bottom: 25px;
}
.pricing-feature_head-row.top_row {
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .pricing-feature_head-row.top_row {
    margin-bottom: 25px;
    grid-template: 1fr/auto auto auto;
    gap: 15px;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
  }
  .pricing-feature_head-row.top_row b {
    min-height: 80px;
  }
  .pricing-feature_head-row.top_row b:nth-child(1) {
    display: none;
  }
  .pricing-feature_head-row.top_row b:nth-child(2), .pricing-feature_head-row.top_row b:nth-child(3), .pricing-feature_head-row.top_row b:nth-child(4) {
    display: grid;
    place-items: center;
    border-radius: 10px;
    background-color: var(--white);
    padding: 15px 10px;
    height: 100%;
  }
  .pricing-feature_head-row.top_row b:nth-child(2) {
    color: var(--blue);
  }
  .pricing-feature_head-row.top_row b:nth-child(3) {
    color: var(--yellow);
  }
  .pricing-feature_head-row.top_row b:nth-child(4) {
    color: var(--green);
  }
}
@media (max-width: 992px) {
  .pricing-feature_head-row.bottom_row {
    grid-template: 1fr/auto auto auto;
    gap: 15px;
  }
  .pricing-feature_head-row.bottom_row .row-title:nth-child(1) {
    grid-column: 1/4;
  }
  .pricing-feature_head-row.bottom_row .row-title:nth-child(2), .pricing-feature_head-row.bottom_row .row-title:nth-child(3), .pricing-feature_head-row.bottom_row .row-title:nth-child(4) {
    border-radius: 10px;
    background-color: var(--white);
    padding: 10px 0;
    min-width: 100px;
  }
  .pricing-feature_head-row.bottom_row .tooltip_content {
    right: auto;
    left: 0;
  }
}
@media (max-width: 360px) {
  .pricing-feature_head-row.bottom_row .row-title:nth-child(2), .pricing-feature_head-row.bottom_row .row-title:nth-child(3), .pricing-feature_head-row.bottom_row .row-title:nth-child(4) {
    min-width: auto;
  }
}
.pricing-feature_head-row b {
  max-width: 220px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  /* Только если меньше контейнера */
  font-size: 22px;
}
@media (max-width: 1320px) {
  .pricing-feature_head-row b {
    font-size: calc(14px + 8 * (100vw - 360px) / 960);
  }
}
.pricing-feature_head-row b:nth-child(1) {
  text-align: start;
  max-width: 100%;
  grid-column: 1/3;
}
@media (max-width: 992px) {
  .pricing-feature_head-row b {
    max-width: 100%;
  }
}
.pricing-feature_head-row .row-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 250px;
  width: 100%;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
  /* Только если меньше контейнера */
  font-size: 18px;
}
@media (max-width: 1320px) {
  .pricing-feature_head-row .row-title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.pricing-feature_head-row .row-title:nth-child(1) {
  justify-content: flex-start;
  max-width: 100%;
  grid-column: 1/3;
}
.pricing-feature_head-row .row-title:nth-child(3) {
  max-width: 200px;
}
@media (max-width: 992px) {
  .pricing-feature_head-row .row-title:nth-child(3) {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .pricing-feature_head-row .row-title {
    max-width: 100%;
  }
}
.pricing-feature .accordion__item {
  box-shadow: none;
  overflow: visible;
  width: 100%;
  background-color: transparent;
}
.pricing-feature .accordion__item:not(:last-child) {
  /* Только если меньше контейнера */
  margin-bottom: 20px;
}
@media (max-width: 1320px) {
  .pricing-feature .accordion__item:not(:last-child) {
    margin-bottom: calc(10px + 10 * (100vw - 360px) / 960);
  }
}
.pricing-feature .accordion__item.active {
  background-color: transparent;
}
.pricing-feature .accordion__item:hover {
  background-color: transparent;
}
.pricing-feature .accordion__item:hover .accordion__toggler {
  background-color: var(--blue);
}
.pricing-feature .accordion__toggler {
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: var(--white);
  padding: 25px;
  border-radius: 5px 30px 5px 30px;
  min-height: max-content;
}
.pricing-feature .accordion__content {
  padding: 45px 0 0 0;
}
.pricing-feature .list_tip {
  max-width: 230px;
  width: 100%;
  margin-left: auto;
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--grey);
}
.pricing-feature_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  counter-reset: num;
  position: relative;
}
.pricing-feature_content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 100%;
  height: 1px;
  background-color: rgba(88, 89, 91, 0.2);
}
.pricing-feature_content::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 100%;
  height: 1px;
  background-color: rgba(88, 89, 91, 0.2);
}
.pricing-feature .icon {
  width: 20px;
  height: 20px;
}
.pricing-feature .col_item {
  display: grid;
  grid-template: 1fr/65px 275px auto auto auto;
  align-items: center;
  width: 100%;
  /* Только если меньше контейнера */
  gap: 20px;
  counter-increment: num;
  position: relative;
}
@media (max-width: 1320px) {
  .pricing-feature .col_item {
    gap: calc(8px + 12 * (100vw - 360px) / 960);
  }
}
@media (max-width: 768px) {
  .pricing-feature .col_item {
    grid-template: 1fr/50px auto 75px 75px 75px;
  }
}
@media (max-width: 576px) {
  .pricing-feature .col_item {
    grid-template: 1fr/25px auto 50px 75px;
    align-items: flex-start;
  }
}
.pricing-feature .col_item:first-child::before {
  padding-top: 28px;
}
@media (max-width: 768px) {
  .pricing-feature .col_item:first-child::before {
    padding-top: 15px;
  }
}
.pricing-feature .col_item:first-child .icon-check_wrap {
  padding-top: 28px;
  border-radius: 30px 30px 0 0;
}
@media (max-width: 576px) {
  .pricing-feature .col_item:first-child .icon-check_wrap {
    padding-top: 15px;
  }
}
.pricing-feature .col_item:first-child .icon-cross_wrap,
.pricing-feature .col_item:first-child .title-wrap {
  padding-top: 28px;
}
@media (max-width: 576px) {
  .pricing-feature .col_item:first-child .icon-cross_wrap,
  .pricing-feature .col_item:first-child .title-wrap {
    padding-top: 15px;
  }
}
.pricing-feature .col_item:nth-child(-n+10)::before {
  content: "0" counter(num);
}
.pricing-feature .col_item:nth-child(n+10)::before {
  content: counter(num);
}
.pricing-feature .col_item:last-child::before {
  padding-bottom: 28px;
}
@media (max-width: 576px) {
  .pricing-feature .col_item:last-child::before {
    padding-bottom: 15px;
  }
}
.pricing-feature .col_item:last-child .icon-cross_wrap,
.pricing-feature .col_item:last-child .title-wrap {
  padding-bottom: 28px;
}
@media (max-width: 576px) {
  .pricing-feature .col_item:last-child .icon-cross_wrap,
  .pricing-feature .col_item:last-child .title-wrap {
    padding-bottom: 15px;
  }
}
.pricing-feature .col_item::before {
  color: var(--grey);
  /* Только если меньше контейнера */
  font-size: 18px;
  opacity: 0.5;
  padding: 17px 0;
}
@media (max-width: 1320px) {
  .pricing-feature .col_item::before {
    font-size: calc(14px + 4 * (100vw - 360px) / 960);
  }
}
@media (max-width: 400px) {
  .pricing-feature .col_item::before {
    padding-top: 15px;
  }
}
.pricing-feature .col_item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(88, 89, 91, 0.2);
}
.pricing-feature .col_item .title-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 275px;
  width: 100%;
  padding: 17px 0;
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
}
@media (max-width: 1320px) {
  .pricing-feature .col_item .title-wrap {
    font-size: calc(14px + 4 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .pricing-feature .col_item .title-wrap {
    padding: 14px 0;
  }
}
.pricing-feature .col_item .title-wrap p {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--grey);
}
@media (max-width: 1320px) {
  .pricing-feature .col_item .title-wrap p {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.pricing-feature .col_item .icon-check_wrap,
.pricing-feature .col_item .icon-cross_wrap {
  max-width: 88px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0;
  position: relative;
  z-index: 1;
}
.pricing-feature .col_item .icon-check_wrap.bordered,
.pricing-feature .col_item .icon-cross_wrap.bordered {
  padding-bottom: 28px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
@media (max-width: 576px) {
  .pricing-feature .col_item .icon-check_wrap.bordered,
  .pricing-feature .col_item .icon-cross_wrap.bordered {
    padding-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .pricing-feature .col_item .icon-check_wrap,
  .pricing-feature .col_item .icon-cross_wrap {
    padding: 14px 0;
  }
}
.pricing-feature .col_item .icon-check_wrap {
  background-color: var(--blue);
}
.pricing-feature .col_item .icon-check_wrap .icon {
  color: var(--white);
}
.pricing-feature .col_item .icon-cross_wrap .icon {
  color: var(--black2F);
}
@media (max-width: 650px) {
  .pricing-feature .list_tip {
    display: none;
  }
  .pricing-feature_content::before, .pricing-feature_content::after {
    display: none;
  }
  .pricing-feature .col_item {
    grid-template: auto 1fr/1fr 1fr 1fr;
    row-gap: 15px;
    margin-bottom: 25px;
  }
  .pricing-feature .col_item::before, .pricing-feature .col_item::after {
    display: none;
  }
  .pricing-feature .col_item:first-child .title-wrap, .pricing-feature .col_item:last-child .title-wrap {
    padding: 0;
  }
  .pricing-feature .col_item .title-wrap {
    grid-column: -1/1;
    padding: 0;
    max-width: 100%;
  }
  .pricing-feature .col_item:first-child .icon-check_wrap {
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px;
  }
  .pricing-feature .col_item:first-child .icon-check_wrap.blue .icon {
    color: var(--blue);
  }
  .pricing-feature .col_item .icon-check_wrap {
    max-width: 100%;
    height: auto;
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px;
  }
  .pricing-feature .col_item .icon-check_wrap.bordered {
    border-radius: 10px;
    padding: 10px;
  }
  .pricing-feature .col_item .icon-check_wrap.blue .icon {
    color: var(--blue);
  }
  .pricing-feature .col_item .icon-check_wrap.yellow .icon {
    color: var(--yellow);
  }
  .pricing-feature .col_item .icon-check_wrap.green .icon {
    color: var(--green);
  }
  .pricing-feature .bottom_row {
    grid-template: auto 1fr/auto auto auto;
    gap: 15px 10px;
  }
  .pricing-feature .bottom_row span {
    font-weight: 500;
  }
  .pricing-feature .bottom_row span:nth-child(1) {
    grid-column: -1/1;
  }
}
.pricing-feature .tooltip {
  position: relative;
  cursor: pointer;
  margin-bottom: 2px;
}
.pricing-feature .tooltip:hover .tooltip_content {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}
.pricing-feature .tooltip:hover .icon {
  color: var(--blue);
  transition: all 0.3s ease 0s;
}
.pricing-feature .tooltip .icon {
  width: 17px;
  height: 17px;
  color: #CEE3EC;
  transition: all 0.3s ease 0s;
}
.pricing-feature .tooltip_content {
  position: absolute;
  z-index: 3;
  top: -100%;
  left: 25px;
  opacity: 0;
  visibility: hidden;
  max-width: 165px;
  width: max-content;
  border-radius: 10px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: var(--white);
  font-size: 14px;
  color: var(--grey);
  line-height: 1.4285714286;
  padding: 15px;
  transition: all 0.3s ease 0s;
  font-weight: 400;
}
@media (max-width: 576px) {
  .pricing-feature .tooltip_content {
    top: -65px;
    left: auto;
    right: 0px;
  }
}
.pricing-feature .tooltip_content b {
  font-weight: 600;
}

.orders {
  background-color: var(--blue);
  /* Только если меньше контейнера */
  padding-top: 100px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .orders {
    padding-top: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .orders {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.orders__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 976px;
  width: 100%;
  margin: 0 auto;
  gap: 50px;
}
@media (max-width: 1024px) {
  .orders__wrap {
    flex-direction: column;
    gap: 40px;
  }
}
.orders__text p {
  color: var(--white);
  /* Только если меньше контейнера */
  font-size: 28px;
  line-height: 128.571%;
}
@media (max-width: 1320px) {
  .orders__text p {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
.orders__text p span {
  font-weight: 400;
}
.orders .orders__image {
  display: inline-block;
  margin-top: 68px;
  width: 100%;
}
@media (max-width: 768px) {
  .orders .orders__image {
    max-width: 420px;
  }
}
.orders .orders__image .orders__img {
  width: 100%;
  height: auto;
}
.orders .orders__form,
.orders .orders__content {
  max-width: 465px;
  width: 100%;
}
@media (max-width: 1024px) {
  .orders .orders__content {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .orders .orders__image {
    margin: 0;
  }
}
.orders .input-wrap {
  position: relative;
  margin-bottom: 25px;
}
.orders__form-text p {
  color: var(--white);
  font-weight: 500;
  /* Только если меньше контейнера */
  font-size: 18px;
  line-height: 144.4444444444%;
  margin-bottom: 40px;
}
@media (max-width: 1320px) {
  .orders__form-text p {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.orders .iti__selected-dial-code {
  color: var(--white);
}

.orders_pricing .orders__text {
  max-width: 430px;
  width: 100%;
}
.orders_pricing .form-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 15px;
}
.orders_pricing .input-wrap:nth-child(1) {
  width: 48%;
  order: 1;
}
@media (max-width: 768px) {
  .orders_pricing .input-wrap:nth-child(1) {
    width: 100%;
  }
}
.orders_pricing .input-wrap:nth-child(2) {
  width: 100%;
  order: 3;
}
.orders_pricing .input-wrap:nth-child(3) {
  width: 48%;
  order: 2;
}
@media (max-width: 768px) {
  .orders_pricing .input-wrap:nth-child(3) {
    width: 100%;
  }
}
.orders_pricing .input-wrap:nth-child(4) {
  width: 100%;
  order: 4;
}
.orders_pricing .textarea {
  height: 120px;
  border-radius: 30px;
  margin-bottom: 15px;
}
.orders_pricing .check_wrap b {
  text-decoration: none;
}

.contact-us-modal {
  max-width: 600px;
  width: calc(100% - 40px);
  height: max-content;
  border-radius: 30px;
  background-color: var(--white);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  scale: 1;
  transition: all 0.3s ease 0s;
}
@media (max-width: 576px) {
  .contact-us-modal {
    display: flex;
    flex-direction: column;
  }
}
.contact-us-modal.openContactForm {
  opacity: 1;
  visibility: visible;
  scale: 1;
  transition: all 0.3s ease 0s;
}
.contact-us-modal.form_modal_pricing .contact-us-modal_title, .contact-us-modal.form_modal_request .contact-us-modal_title {
  max-width: 375px;
}
.contact-us-modal.form_modal_pricing .check_wrap, .contact-us-modal.form_modal_request .check_wrap {
  margin: 0;
}
.contact-us-modal.form_modal_pricing .check_wrap .custom-checkbox, .contact-us-modal.form_modal_request .check_wrap .custom-checkbox {
  background-color: var(--whiteF8);
}
.contact-us-modal.form_modal_pricing .check_wrap p,
.contact-us-modal.form_modal_pricing .check_wrap span, .contact-us-modal.form_modal_request .check_wrap p,
.contact-us-modal.form_modal_request .check_wrap span {
  color: var(--black2F);
}
.contact-us-modal.form_modal_pricing .check_wrap p ins,
.contact-us-modal.form_modal_pricing .check_wrap span ins, .contact-us-modal.form_modal_request .check_wrap p ins,
.contact-us-modal.form_modal_request .check_wrap span ins {
  font-weight: 600;
  color: var(--blue);
}
.contact-us-modal.form_modal_request .contact-us-modal_title {
  max-width: 425px;
}
.contact-us-modal .contact-us-modal-body {
  display: flex;
  flex-direction: column;
  /* Только если меньше контейнера */
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 30px;
  /* Только если меньше контейнера */
  padding-right: 40px;
  /* Только если меньше контейнера */
  padding-left: 40px;
  height: 100%;
}
@media (max-width: 1320px) {
  .contact-us-modal .contact-us-modal-body {
    padding-top: calc(50px + -20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact-us-modal .contact-us-modal-body {
    padding-bottom: calc(25px + 5 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact-us-modal .contact-us-modal-body {
    padding-right: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact-us-modal .contact-us-modal-body {
    padding-left: calc(20px + 20 * (100vw - 360px) / 960);
  }
}
.contact-us-modal .close-form-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 18px;
  right: 18px;
  z-index: 9;
}
.contact-us-modal .close-form-btn::after, .contact-us-modal .close-form-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 100%;
  height: 1px;
  background-color: var(--black2F);
}
.contact-us-modal .close-form-btn::after {
  rotate: -45deg;
}
.contact-us-modal .close-form-btn::before {
  rotate: 45deg;
}
.contact-us-modal_title {
  /* Только если меньше контейнера */
  font-size: 18px;
  color: var(--black2F);
  font-weight: 700;
  text-align: center;
  max-width: 332px;
  width: 100%;
  margin: 0 auto 25px auto;
}
@media (max-width: 1320px) {
  .contact-us-modal_title {
    font-size: calc(16px + 2 * (100vw - 360px) / 960);
  }
}
.contact-us-modal_text {
  max-width: 342px;
  width: 100%;
  margin: 0 auto 25px auto;
  font-weight: 600;
  font-size: 14px;
  color: var(--grey);
  text-align: center;
}
.contact-us-modal_form {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 35px;
  column-gap: 15px;
}
@media (max-width: 768px) {
  .contact-us-modal_form {
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
}
.contact-us-modal .input_body {
  position: relative;
  width: 100%;
}
.contact-us-modal .input_body::before {
  content: "*";
  position: absolute;
  top: 3px;
  left: 0;
  width: 6px;
  height: 6px;
  color: var(--red);
}
.contact-us-modal_input {
  border: 1px solid transparent;
  background-color: var(--whiteF8);
  backdrop-filter: blur(15px);
  border-radius: 500px;
  padding: 25px 20px;
  width: 100%;
  outline: none;
  transition: all 0.3s ease 0s;
  font-size: 14px;
  color: var(--black);
}
.contact-us-modal_input:focus {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.contact-us-modal_input:focus-visible {
  border-color: var(--blue);
  transition: all 0.3s ease 0s;
}
.contact-us-modal_input::placeholder {
  color: var(--grey);
}
.contact-us-modal .short_input {
  max-width: 252px;
  width: 100%;
}
.contact-us-modal .iti-body .iti__arrow {
  border-top: 4px solid var(--grey);
}
.contact-us-modal .iti__selected-dial-code {
  color: var(--grey);
}
.contact-us-modal .form-btn {
  margin: 0 auto;
  width: max-content;
  font-weight: 400;
  width: 153px;
}
@media (max-width: 576px) {
  .contact-us-modal .form-btn {
    width: 100%;
    height: 40px;
    margin-top: auto;
    margin-bottom: 25px;
  }
}
.contact-us-modal .textarea {
  min-height: 120px;
  height: 120px;
  max-height: 220px;
  border-radius: 30px;
}
@media (max-width: 576px) {
  .contact-us-modal .textarea {
    flex: 1;
  }
}
@media (max-width: 768px) {
  .contact-us-modal .short_input {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .contact-us-modal {
    width: 100%;
    height: 100vh;
    overflow: auto;
    border-radius: 0;
  }
}
.contact-form-container {
  position: fixed;
  inset: 0;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease 0s;
}
.contact-form-container.showContactFormContainer {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}

.contact_page_form {
  padding-top: 30px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
  position: static;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  max-width: 100%;
  max-width: 1400px;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-radius: 0 0 5px 30px;
}
@media (max-width: 1320px) {
  .contact_page_form {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.contact_page_form .contact-us-modal-body {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--white);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px 30px;
  /* Только если меньше контейнера */
  padding-top: 50px;
  /* Только если меньше контейнера */
  padding-bottom: 50px;
  /* Только если меньше контейнера */
  padding-right: 50px;
  /* Только если меньше контейнера */
  padding-left: 50px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1320px) {
  .contact_page_form .contact-us-modal-body {
    padding-top: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact_page_form .contact-us-modal-body {
    padding-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact_page_form .contact-us-modal-body {
    padding-right: calc(20px + 30 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .contact_page_form .contact-us-modal-body {
    padding-left: calc(20px + 30 * (100vw - 360px) / 960);
  }
}
.contact_page_form .close-form-btn {
  display: none;
}
.contact_page_form .contact-us-modal_title {
  /* Только если меньше контейнера */
  font-size: 28px;
  margin-bottom: 40px;
  max-width: 100%;
}
@media (max-width: 1320px) {
  .contact_page_form .contact-us-modal_title {
    font-size: calc(22px + 6 * (100vw - 360px) / 960);
  }
}
.contact_page_form .contact-us-modal_form {
  justify-content: space-between;
}
.contact_page_form .input_body {
  width: 32%;
}
@media (max-width: 992px) {
  .contact_page_form .input_body:nth-child(1), .contact_page_form .input_body:nth-child(2) {
    width: 48.5%;
  }
  .contact_page_form .input_body:nth-child(3) {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .contact_page_form .input_body:nth-child(1), .contact_page_form .input_body:nth-child(2) {
    width: 100%;
  }
}
.contact_page_form .short_input {
  max-width: 100%;
}
.contact_page_form .textarea {
  height: 150px;
}
.contact_page_form .form-btn {
  width: 200px;
  margin: 0 auto;
}

.succesfull_request {
  position: absolute;
  inset: 0;
  background-color: var(--white);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  scale: 0;
}
.succesfull_request.openRequest {
  opacity: 1;
  visibility: visible;
  scale: 1;
  transition: all 0.3s ease 0s;
}
.succesfull_request .request_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 496px;
  width: calc(100% - 40px);
  height: 100%;
  margin: 0 auto;
  text-align: center;
}
.succesfull_request .request_content img {
  width: 100px;
  height: 100px;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .succesfull_request .request_content img {
    margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
.succesfull_request .request_content h2 {
  margin-bottom: 30px;
}
.succesfull_request .request_content p {
  font-size: 18px;
  line-height: 1.4444444444;
  color: var(--grey);
}

.features-main {
  background: linear-gradient(175.44deg, #F8F8F8 3.63%, rgba(248, 248, 248, 0) 85.79%);
  padding: 30px 0;
  /* Только если меньше контейнера */
  padding-bottom: 30px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px 30px 0 0;
}
@media (max-width: 1320px) {
  .features-main {
    padding-bottom: calc(0px + 30 * (100vw - 360px) / 960);
  }
}
.features-main_title {
  margin: 0;
}
@media (max-width: 576px) {
  .features-main_title {
    text-align: start;
  }
}

.features-body {
  /* Только если меньше контейнера */
  padding-top: 20px;
  /* Только если меньше контейнера */
  padding-bottom: 100px;
}
@media (max-width: 1320px) {
  .features-body {
    padding-top: calc(30px + -10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .features-body {
    padding-bottom: calc(60px + 40 * (100vw - 360px) / 960);
  }
}
.features-body_content {
  text-align: center;
}
.features-body_wrap:not(:last-child) {
  margin-bottom: 120px;
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+1) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+1) h3 {
  color: var(--blue);
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+2) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+2) h3 {
  color: var(--green);
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+3) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+3) h3 {
  color: var(--yellow);
}
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+4) .icon,
.features-body_wrap:nth-child(3n+1) .features-body_card:nth-child(4n+4) h3 {
  color: var(--black2F);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+1) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+1) h3 {
  color: var(--green);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+2) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+2) h3 {
  color: var(--yellow);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+3) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+3) h3 {
  color: var(--black2F);
}
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+4) .icon,
.features-body_wrap:nth-child(3n+2) .features-body_card:nth-child(4n+4) h3 {
  color: var(--blue);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+1) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+1) h3 {
  color: var(--black2F);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+2) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+2) h3 {
  color: var(--blue);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+3) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+3) h3 {
  color: var(--green);
}
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+4) .icon,
.features-body_wrap:nth-child(3n+3) .features-body_card:nth-child(4n+4) h3 {
  color: var(--yellow);
}
.features-body_wrap .btn {
  font-weight: 400;
}
.features-body_subtitle {
  /* Только если меньше контейнера */
  font-size: 28px;
  color: var(--black2F);
  font-weight: 700;
  /* Только если меньше контейнера */
  margin-bottom: 50px;
}
@media (max-width: 1320px) {
  .features-body_subtitle {
    font-size: calc(20px + 8 * (100vw - 360px) / 960);
  }
}
@media (max-width: 1320px) {
  .features-body_subtitle {
    margin-bottom: calc(30px + 20 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .features-body_subtitle {
    text-align: start;
  }
}
.features-body_card-wrap {
  /* Только если меньше контейнера */
  margin-bottom: 50px;
  display: grid;
  grid-template: 1fr/repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
}
@media (max-width: 1320px) {
  .features-body_card-wrap {
    margin-bottom: calc(40px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 576px) {
  .features-body_card-wrap {
    grid-template: auto/1fr;
  }
}
.features-body_card {
  padding: 25px 25px 42px 25px;
  /* Только если меньше контейнера */
  padding-bottom: 42px;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px 30px;
  text-align: start;
  min-height: 292px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1320px) {
  .features-body_card {
    padding-bottom: calc(35px + 7 * (100vw - 360px) / 960);
  }
}
.features-body_card.hide {
  display: none;
  opacity: 0;
}
.features-body_card.active {
  display: block;
  opacity: 1;
}
@media (max-width: 576px) {
  .features-body_card {
    min-height: auto;
  }
}
.features-body_card p {
  /* Только если меньше контейнера */
  font-size: 14px;
  line-height: 1.4285714286;
  color: var(--grey);
  flex: 1 0 auto;
  margin-bottom: 25px;
}
@media (max-width: 1320px) {
  .features-body_card p {
    font-size: calc(16px + -2 * (100vw - 360px) / 960);
  }
}
.features-body_card a {
  font-size: 14px;
  text-decoration: underline;
  color: var(--blue);
  font-weight: 700;
}
.features-body .card_head {
  display: flex;
  flex-direction: column;
  gap: 25px 15px;
  margin-bottom: 25px;
}
.features-body .card_head .icon {
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
}
@media (max-width: 576px) {
  .features-body .card_head .icon {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
  }
}
.features-body .card_head h3 {
  font-size: 18px;
  font-weight: 700;
}

.footer {
  background-color: var(--hover-blue);
  padding: 60px 0;
}
.footer .container {
  max-width: 1360px;
}
.footer .footer_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .footer .footer_top {
    flex-direction: column;
  }
}
@media (max-width: 1200px) {
  .footer .footer_top {
    margin-bottom: 60px;
  }
}
.footer .logo_wrap {
  max-width: 295px;
  width: 100%;
}
@media (max-width: 1024px) {
  .footer .logo_wrap {
    max-width: 100%;
    margin: 0 auto;
  }
}
.footer .footer__image {
  display: inline-block;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .footer .footer__image {
    max-width: 200px;
    height: auto;
  }
  .footer .footer__image .footer__img {
    width: 100%;
    height: auto;
  }
}
.footer .footer_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 142.8571428571%;
  color: var(--white);
}
.footer .col_wrap {
  max-width: 875px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
}
@media (max-width: 576px) {
  .footer .col_wrap {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .footer .col_wrap {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 40px;
  }
}
@media (max-width: 576px) {
  .footer .footer_list {
    height: 0;
    visibility: hidden;
    opacity: 0;
    text-align: start;
  }
  .footer .footer_list.open {
    height: auto;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease 0s;
  }
}
.footer .footer_col {
  max-width: 140px;
  width: 100%;
}
@media (max-width: 1024px) {
  .footer .footer_col {
    min-width: 140px;
  }
}
@media (max-width: 768px) {
  .footer .footer_col {
    max-width: 40%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .footer .footer_col {
    min-width: 100%;
  }
}
.footer .footer_title {
  font-weight: 700;
  font-size: 18px;
  color: var(--white);
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .footer .footer_title {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    text-align: start;
    margin: 0;
    width: max-content;
    position: relative;
  }
  .footer .footer_title::after {
    content: url(../svg/footer_arrow.svg);
    width: 10px;
    height: 8px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: -15px;
    transition: all 0.3s ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center;
  }
  .footer .footer_title.active {
    margin-bottom: 20px;
  }
  .footer .footer_title.active::after {
    transform: rotateX(180deg);
    transition: all 0.3s ease 0s;
  }
}
.footer .list_item:not(:last-child) {
  margin-bottom: 15px;
}
.footer .item_link {
  color: var(--white);
  opacity: 0.8;
  font-size: 14px;
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .footer .item_link:hover {
    opacity: 1;
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .footer .item_link:active {
    opacity: 1;
  }
}
.footer .footer_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Только если меньше контейнера */
  gap: 30px;
}
@media (max-width: 1320px) {
  .footer .footer_bottom {
    gap: calc(20px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .footer .footer_bottom {
    justify-content: center;
  }
}
.footer .contact_wrap {
  display: flex;
  align-items: center;
  gap: 30px;
  /* Только если меньше контейнера */
  gap: 30px;
}
@media (max-width: 1320px) {
  .footer .contact_wrap {
    gap: calc(20px + 10 * (100vw - 360px) / 960);
  }
}
@media (max-width: 992px) {
  .footer .contact_wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer .contact_item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}
.footer .contact_item .email {
  color: var(--white);
  font-size: 14px;
}
.footer .contact_item .email_link {
  display: flex;
  align-items: center;
  gap: 8px;
}
.footer .contact_item .icon {
  width: 18px;
  height: 18px;
  color: var(--white);
}
@media (max-width: 992px) {
  .footer .contact_item {
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .footer .contact_item {
    width: 100%;
  }
}
.footer .item_link-text,
.footer .follow {
  font-weight: 800;
  font-size: 18px;
  color: var(--white);
}
.footer .underline {
  text-decoration: underline;
}
.footer .contact_item-link {
  transition: all 0.3s ease 0s;
  display: flex;
  align-items: center;
  gap: 8px;
}
.footer .contact_item-link .icon {
  transition: all 0.3s ease 0s;
}
.footer .contact_item-link .icon-phone_2 {
  width: 15px;
  height: 15px;
}
@media (hover: hover) {
  .footer .contact_item-link:hover .item_link-text {
    transition: all 0.3s ease 0s;
    color: var(--blue);
  }
  .footer .contact_item-link:hover .icon {
    color: var(--blue);
  }
}
@media (hover: none) {
  .footer .contact_item-link:active {
    color: var(-blue);
  }
  .footer .contact_item-link:active .icon {
    color: var(--blue);
  }
}
.footer .social_links_wrap,
.footer .links_wrap {
  display: flex;
  align-items: center;
}
.footer .social_links_wrap {
  gap: 40px;
}
@media (max-width: 768px) {
  .footer .social_links_wrap {
    flex-direction: column;
    gap: 20px;
  }
}
.footer .follow {
  text-decoration: none;
}
.footer .links_wrap {
  gap: 12px;
}
@media (max-width: 576px) {
  .footer .links_wrap {
    gap: 40px;
  }
}
.footer .link,
.footer .link__image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .link {
  width: 44px;
  height: 44px;
  background-color: var(--white);
  border-radius: 50%;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s;
}
.footer .link .icon {
  width: 16px;
  height: 16px;
  color: var(--hover-blue);
  transition: all 0.3s ease 0s;
}
@media (hover: hover) {
  .footer .link:hover {
    transition: all 0.3s ease 0s;
    background-color: var(--hover-blue);
    border-color: var(--white);
  }
  .footer .link:hover .icon {
    color: var(--white);
    transition: all 0.3s ease 0s;
  }
}
@media (hover: none) {
  .footer .link:active {
    background-color: var(--hover-blue);
    border-color: var(--white);
  }
  .footer .link:active .icon {
    color: var(--white);
  }
}
.footer_media-links {
  padding: 50px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .footer {
    padding: 35px 0;
  }
}
@media (max-width: 576px) {
  .footer_ua .links_wrap {
    gap: 12px;
  }
}
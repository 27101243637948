:root {
  //   === Colors
  --white: #fff;
  --whiteF8: #F8F8F8;
  --whiteFC: #FCFFF5;
  --black: #000;
  --black05: #050B1A;
  --black2F: #2F2F31;
  --blueCE: #CEE3EC80;
  --blue: #007BB3;
  --hover-blue: #004565;
  --grey: #58595B;
  --grey70: #707173;
  --yellow: #E8B524;
  --green: #03C15C;
  --red: #AC1E1E;

  //  === Fonts
  --font-montserrat: "Montserrat",
    sans-serif;

  //  === Topography
  --font-primary: var(--font-montserrat);
  --font-size: 14px;

  // === Theme settings
  --bg-primary: var(--white);
  --text-color-primary: var(--black);
  --text-color-primary-hover: var(--black);

  //  === Js variables
  --current-scrollbar-width: 0px;
  --header-height: 0px;
}
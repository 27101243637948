.article {
    @include adaptive-value("padding-top", 30, 20, 1);
    padding-bottom: 30px;

    &_content-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40px;
    }

    // .article_content

    &_content {
        max-width: 740px;
        width: 100%;

        h1 {
            @include adaptive-value("font-size", 28, 22, 1);
            font-weight: 700;
            line-height: calc(36 / 28) * 100%;
            text-align: left;
            @include adaptive-value("margin-bottom", 40, 30, 1);
        }

        // .article_text

        p {
            @include adaptive-value("font-size", 18, 16, 1);
            color: var(--grey);
            margin-bottom: 30px
        }

        // .article_subtitle

        h2 {
            @include adaptive-value("font-size", 22, 18, 1);
            margin-bottom: 30px;
            color: var(--black2F);

        }

        // .article_link-list

        ul,
        ol {
            display: flex;
            flex-direction: column;
            @include adaptive-value("gap", 20, 10, 1);
            @include adaptive-value("margin-bottom", 50, 25, 1);

            li {
                position: relative;
                display: flex;
                align-items: flex-start;
                @include adaptive-value("gap", 20, 10, 1);
                @include adaptive-value("padding-left", 40, 30, 1);

                a {
                    @include adaptive-value("font-size", 18, 16, 1);
                    color: var(--blue);
                    font-weight: 500;
                    transition: all .3s ease 0s
                }

                p {
                    margin: 0;
                }
            }

            @media (hover: hover) {
                li {
                    a {
                        &:hover {
                            color: var(--hover-blue);
                            transition: all .3s ease 0s
                        }
                    }
                }
            }

            @media (hover: hover) {
                li {
                    a {
                        &:active {
                            color: var(--hover-blue);
                            transition: all .3s ease 0s
                        }
                    }
                }
            }
        }

        ul {

            li {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 0;
                    width: 20px;
                    height: 16px;
                    background-image: url(/img/article/arrow_3.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }

        ol {
            counter-reset: zeroNum;

            li {
                counter-increment: zeroNum;
                padding-left: 0;

                &::before {
                    content: "0"counter(zeroNum);
                    @include adaptive-value("font-size", 18, 16, 1);
                    color: var(--blue);
                    font-weight: 500;
                }
            }
        }

        table {
            border-radius: 5px 30px;
            overflow: hidden;
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
            border-collapse: collapse;
            @include adaptive-value("margin-bottom", 50, 25, 1);

            thead {
                th {
                    background-color: var(--blue);
                    text-align: start;
                    padding: 23px 10px 23px 25px;
                    color: var(--white);
                    font-weight: 700;
                    @include adaptive-value("font-size", 18, 16, 1);

                    &:nth-child(1),
                    &:nth-child(2) {
                        border-right: 1px solid rgba(223, 223, 223, 0.15);
                    }

                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid rgba(88, 89, 91, 0.15)
                }

                td {
                    padding: 14px 25px;
                    font-size: 14px;
                    color: var(--grey);
                    vertical-align: top;

                    &:first-child {
                        white-space: nowrap;
                        font-weight: 600;
                        color: var(--black2F);
                        border-right: 1px solid rgba(88, 89, 91, 0.15);
                    }

                    &:nth-child(2) {
                        border-right: 1px solid rgba(88, 89, 91, 0.15);
                    }
                }
            }
        }
    }

    // .article_caterory

    &_caterory {
        color: var(--blue);
        font-size: 13px;
        text-align: center;
        margin-bottom: 20px;
        background-color: var(--blueCE);
        border-radius: 50px;
        padding: 12px 15px;
        display: inline-block;

        @include sm {
            padding: 10px;
            font-size: 12px;
        }
    }

    // .article_info-wrap

    &_info-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    // .article_info-body

    &_info-body {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        span {
            font-size: 14px;
            color: var(--grey);
        }

        b {
            font-weight: 700;
            color: var(--black2F);
        }

        .time,
        .publish {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .publish {

            b {
                @include xs {
                    display: none;
                }
            }

            .icon-calendar {
                display: none;

                @include xs {
                    display: block;
                }
            }
        }
    }

    // .article_social-wrap

    &_social-wrap {
        display: flex;
        align-items: center;
        gap: 15px;

        @include xs {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    // .article_social-body

    &_social-body {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    // .article_social-link

    &_social-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: var(--blue);

        .icon {
            width: 16px;
            height: 16px;
            color: var(--white);
            transition: all .3s ease 0s;
        }

    }

    @media (hover:hover) {
        &_social-link {
            &:hover {
                background-color: var(--hover-blue);
                transition: all .3s ease 0s;
            }
        }
    }

    @media (hover:none) {
        &_social-link {
            &:active {
                background-color: var(--hover-blue);
                transition: all .3s ease 0s;
            }
        }
    }

    .link-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        @include adaptive-value("margin-bottom", 50, 30, 1);

        a {
            @include adaptive-value("font-size", 18, 16, 1);
            font-weight: 500;
            color: var(--blue);
        }
    }

    .article_image {
        display: inline-block;
        width: 100%;
        height: auto;
        @include adaptive-value("margin-bottom", 40, 20, 1);
        border-radius: 5px 30px;
        overflow: hidden;

        .article__image {
            margin-bottom: 0;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .info_banner {
        border-radius: 5px 30px;
        background-color: var(--blue);
        padding-top: 20px;
        padding-bottom: 20px;
        @include adaptive-value("padding-right", 40, 20, 1);
        @include adaptive-value("padding-left", 40, 20, 1);
        display: flex;
        align-items: center;
        @include adaptive-value("gap", 30, 20, 1);

        .banner_img {
            max-width: 110px;
            height: auto;

            .banner__image {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .banner_text-wrap {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 25px;

            @include xs {
                align-items: center;
                gap: 20px
            }
        }

        h3 {
            @include adaptive-value("font-size", 22, 18, 1);
            color: var(--white);
            font-weight: 700;
            margin-bottom: 20px
        }

        p {
            font-size: 14px;
            color: var(--white);
            max-width: 450px;
            width: 100%;
            margin: 0;

            br {
                display: none;
            }

            @include xs {
                max-width: 280px;
                width: 100%;
                margin: 0 auto;

                br {
                    display: block;
                }
            }
        }

        .arrow_wrap {
            background-color: var(--white);
        }

        .icon-arrow_top {
            color: var(--blue);
        }

        @media (hover:hover) {
            .arrow_wrap {
                &:hover {
                    background-color: var(--blue);
                    transition: all .3s ease 0s;
                    border-color: var(--white);

                    .icon-arrow_top {
                        color: var(--white);
                    }
                }
            }
        }

        @media (hover:none) {
            .arrow_wrap {
                &:active {
                    background-color: var(--blue);
                    border-color: var(--white);

                    .icon-arrow_top {
                        color: var(--white);
                    }
                }
            }
        }
    }

    blockquote {
        border-radius: 5px 30px;
        overflow: hidden;
        background-color: var(--white);
        @include adaptive-value("padding-top", 25, 15, 1);
        @include adaptive-value("padding-bottom", 25, 15, 1);
        @include adaptive-value("padding-right", 25, 15, 1);
        @include adaptive-value("padding-left", 25, 15, 1);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        margin: 50px 0;

        .read_banner-text_wrap {
            padding-left: 0;
            border-left: none;

            &.showDivider {
                padding-left: 20px;
                border-left: 1px solid var(--blue);
            }
        }


        span {
            font-weight: 700;
            color: var(--black2F);
            font-size: 18px;
            margin-bottom: 5px;
            display: block;
        }

        p {
            margin: 0;
        }

        a {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 20px;
            @include adaptive-value("font-size", 18, 16, 1);
            color: var(--blue);
            transition: all .3s ease 0s;
            position: relative;

            &::after {
                content: url(../svg/arrow-white.svg);
                background-color: var(--blue);
                min-width: 40px;
                min-height: 40px;
                max-width: 40px;
                max-height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        @media (hover: hover) {
            a {
                &:hover {
                    color: var(--hover-blue);
                    transition: all .3s ease 0s
                }
            }
        }

        @media (hover: none) {
            a {
                &:active {
                    color: var(--hover-blue);
                }
            }
        }
    }

    .user_wrap {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 50px 0;

        .user__image {
            display: inline-block;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            overflow: hidden;

            .user__img {
                width: 100%;
                height: 100%;
            }
        }

        .without_icon {
            display: none
        }

        .user_info-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
        }

        .user_name {
            font-weight: 700;
            @include adaptive-value("font-size", 18, 16, 1);
            color: var(--blue);
        }

        .user_position {
            font-size: 14px;
            color: var(--grey);
        }
    }

    .grade_banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 25px;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        background-color: var(--whiteF8);
        border-radius: 5px 30px;
        padding: 25px;

        .rated {
            background-color: var(--white);
            border-radius: 5px 30px;
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 40px;
            @include adaptive-value("gap", 40, 20, 1);
            max-width: 407px;
            width: 100%;

            b {
                @include adaptive-value("font-size", 18, 16, 1);
                color: var(--black2F);
                font-weight: 700;
                display: inline-block;
                margin-bottom: 15px;
            }
        }

        .stars__image {
            display: inline-block;
            max-width: 207px;
            height: auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .rating,
        .rating_num,
        .votes_num {
            display: flex;
            align-items: center;
        }

        .rating {
            gap: 10px;
            margin-bottom: 15px;
        }

        .rating_num {
            display: flex;
            align-items: center;
            gap: 2px;

            span {
                font-size: 14px;
                color: var(--grey);
            }

            .curent_rating {
                font-size: 28px;
                font-weight: 700;
                color: var(--black2F);
            }
        }

        .rating_title {
            @include adaptive-value("font-size", 18, 16, 1);
            color: var(--black2F);
            opacity: 0.7;
        }

        .article_social-wrap {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 15px;
        }

        .votes_num {
            gap: 3px;

            span {
                white-space: nowrap;
                font-size: 14px;
                color: var(--grey);
            }
        }
    }

    .banner {
        @include adaptive-value("margin-bottom", 50, 30, 1);
    }

    .arrow_wrap {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
        background-color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        transition: all .3s ease 0s;
    }

    .icon-arrow_top {
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
        color: var(--blue);
        transition: all .3s ease 0s;
    }

    @media (hover:hover) {
        .arrow_wrap {
            &:hover {
                background-color: var(--blue);
                transition: all .3s ease 0s;
                border-color: var(--white);

                .icon-arrow_top {
                    color: var(--white);
                }
            }
        }
    }

    @media (hover:none) {
        .arrow_wrap {
            &:active {
                background-color: var(--blue);
                border-color: var(--white);

                .icon-arrow_top {
                    color: var(--white);
                }
            }
        }
    }

    @media (max-width: #{$lg-and-sm}) {
        &_content-wrap {
            flex-direction: column;
            align-items: center;
        }
    }

    @media (max-width: #{$sm-and-sm}) {
        .grade_banner {
            flex-direction: column;
            align-items: flex-start;

            .rated {
                max-width: 100%;
            }

            .stars_wrap {
                max-width: 230px;
            }
        }

        .table-wrap {
            overflow-x: auto;
            margin-bottom: 50px;

            &::-webkit-scrollbar {
                height: 6px;
                width: 100%;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(88, 89, 91, 0.1);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: var(--blue)
            }
        }

        table {
            margin-bottom: 10px;
        }
    }

    @media (max-width: #{$xs-and-sm}) {
        .info_banner {
            flex-direction: column;
            align-items: center;
            padding: 15px;
        }

        .banner_text-wrap,
        .read_banner {
            align-items: center;
            flex-direction: column;
            text-align: center;
        }

        .read_banner,
        .grade_banner {
            padding: 15px;
        }

        .grade_banner {
            .rated {
                align-items: center;
                flex-direction: column;
                padding: 20px 15px;
            }

            .stars_wrap {
                text-align: center;
            }

        }

        .read_banner-text_wrap {
            padding-left: 0;
            border: none;
        }
    }
}

.help_cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 340px;
    width: 100%;

    .surbscribe {
        max-width: 320px;
        width: 100%;
        background-color: var(--white);
        border-radius: 5px 30px;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        padding: 25px;
        text-align: center;

        .input_body {
            margin-bottom: 30px
        }

        // .surbscribe_card-title

        &_card-title {
            @include adaptive-value("font-size", 22, 18, 1);
            font-weight: 700;
            margin-bottom: 20px;
        }

        // .surbscribe_card-text

        &_card-text {
            font-size: 14px;
            color: var(--grey);
            @include adaptive-value("margin-bottom", 30, 20, 1);
        }

        // .surbscribe_card-input

        &_card-input {
            width: 100%;
            border-radius: 500px;
            padding: 15px 20px;
            border: none;
            background-color: #2f2f310d;
            outline: none;
            border: 1px solid transparent;
            transition: all .3s ease 0s;
            font-size: 14px;
            color: var(--black2F);

            &:focus {
                border-color: var(--blue);
                transition: all .3s ease 0s;
            }

            &:focus-visible {
                border-color: var(--blue);
                transition: all .3s ease 0s;
            }
        }

        // .surbscribe_card-check-wrap

        .check_wrap {
            margin: 20px 0;

            p {
                color: var(--grey);
                text-align: start;
            }

            a {
                text-decoration: underline;
                font-weight: 600;
                color: var(--blue);
                transition: all .3s ease 0s;
                &:hover {
                  color: var(--hover-blue);
                }
            }
        }

        .custom-checkbox {
            border-color: var(--blue);
        }

        .checkbox:checked+.custom-checkbox {
            background-color: var(--blue);
        }


        .checkbox:checked+.custom-checkbox:before {
            content: url(../svg/check_arrow-white.svg);
        }

        // .surbscribe_card-form-btn

        .form-btn {
            height: 40px;
            font-size: 14px;
        }

    }

    .call_swiper {
        max-width: 340px;
        width: 100%;
        padding: 0 10px;

        .swiper-pagination {
            position: static;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px auto 0 auto;
        }
    }

    .call {
        width: 100%;
        background-color: var(--white);
        border-radius: 5px 30px;
        box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

        overflow: hidden;
        text-align: center;

        .call__image {
            width: 100%;
            max-height: 180px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &_text-wrap {
            padding: 20px;
        }

        &_title {
            @include adaptive-value("font-size", 28, 22, 1);
            color: var(--blue);
            margin-bottom: 20px;
        }

        &_sale-price {
            color: var(--grey);
            font-size: 14px;
            text-decoration: line-through;
            display: inline-block;
            margin-bottom: 10px;
            opacity: 0.5;
        }

        &_text {
            font-size: 14px;
            margin-bottom: 20px;
            color: var(--black2F);
            font-weight: 600;

            span{
                color: var(--blue);
            }
        }

        .btn {
            font-size: 14px;
            font-weight: 400;
            padding: 15px 20px;
            height: 40px;
            width: max-content
        }
    }

    @media (max-width: #{$lg-and-sm}) {
        .surbscribe {
            max-width: 740px;
        }

        .call_swiper {
            max-width: 740px;
        }
    }
}

@media (max-width: #{$lg-and-sm}) {
    .help_cards {
        max-width: 100%;
    }
}
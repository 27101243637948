.voice-use {
    @include adaptive-value("padding-top", 0, 0, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);

    &_example {
        background-color: var(--blue);
        border-radius: 5px 30px;
        @include adaptive-value("padding-top", 70, 40, 1);
        @include adaptive-value("padding-bottom", 70, 40, 1);
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 auto;
    }

    .example {

        // .example_body

        &_body {
            max-width: 736px;
            width: 100%;
            margin: 0 auto;
        }

        &_title {
            @include adaptive-value("font-size", 28, 22, 1);
            color: var(--white);
            font-weight: 700;
            margin-bottom: 60px;
            text-align: center;

            @include xs {
                max-width: 280px;
                width: 100%;
                margin: 0 auto 50px auto;
            }
        }

        &_item-wrap {
            counter-reset: num;
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include adaptive-value("gap", 30, 25, 1);

            &:not(:last-child) {
                @include adaptive-value("margin-bottom", 40, 50, 1);
            }

            @include sm {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .item {

        &_title {
            max-width: 275px;
            width: 100%;
            counter-increment: num;
            font-weight: 500;
            color: var(--white);
            @include adaptive-value("font-size", 18, 16, 1);
            line-height: calc(26 / 18);
            display: flex;
            align-items: center;
            gap: 20px;

            &::before {
                content: "0"counter(num);
                font-weight: 400;
                opacity: 0.7;
                display: block;
            }

            @include xs {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .record {

        // .record_wrap

        &_wrap {
            max-width: 390px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 15px;

            @include sm {
                max-width: 100%;
            }
        }

        // .record_track

        &_track {
            max-width: 289px;
            width: 100%;
            height: 3px;
            border-radius: 50px;
            position: relative;

            @include sm {
                max-width: 100%;
            }
        }

        &_progress {
            width: 100%;
            height: 100%;

            border-radius: 50px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            -webkit-appearance: none;
            appearance: none;
            outline: none;
            background: rgba(255, 255, 255, 0.3);
            cursor: pointer;
            transition: all .3s ease 0s;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 11px;
                height: 11px;
                background-color: var(--thumb-color, #fff);
                border-radius: 50%;
                cursor: pointer;
                transition: all .3s ease 0s;
            }

            &::-moz-range-thumb {
                width: 11px;
                height: 11px;
                background-color: var(--thumb-color, #fff);
                border-radius: 50%;
                cursor: pointer;
                transition: all .3s ease 0s;
            }

            &::-ms-thumb {
                width: 11px;
                height: 11px;
                background-color: var(--thumb-color, #fff);
                border-radius: 50%;
                cursor: pointer;
                transition: all .3s ease 0s;
            }
        }

        // .record_current-time

        &_current-time {
            font-weight: 500;
            @include adaptive-value("font-size", 18, 16, 1);
            color: var(--white);
            transition: all .3s ease 0s;
            min-width: 33px;
            max-width: 33px;
            height: auto;
            display: inline-block;
            text-align: center;
        }
    }

    .record-play {

        &_btn {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
            border-radius: 50%;
            background-color: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;
            transition: all .3s ease 0s;
        }

        @media (hover:hover) {
            &_btn {
                &:hover {
                    background-color: var(--blue);
                    border-color: var(--white);
                    transition: all .3s ease 0s;

                    .icon {
                        color: var(--white);
                        transition: all .3s ease 0s;
                    }
                }

            }
        }
    }

    .icon {
        color: var(--blue);
        width: 7px;
        height: 8px;
        transition: all .3s ease 0s;
    }
}
.dial-benefit {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);

    .tabs {
        @include xs {
            display: none;
        }
    }

    .tabs__head {
        @include md {
            display: none;
        }
    }

    .tabs__head-col {
        width: max-content;
    }

    .tabs__item {
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }

    .dial_accrordion {
        max-width: 560px;
        width: 100%;
        .accordion__content {
          padding-top: 5px;
        }
    }

    .accordion__item {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .dial__image {
        max-width: 540px;
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width: #{$md-and-sm}) {
        .dial_accrordion {
            max-width: 100%;
        }

        .dial__image {
            display: none;
        }
    }

    .dial_accrordion-mob {
        display: none;

        @include xs {
            display: block;
        }

        .accordion__content {
            padding: 25px 15px 0 15px;
        }

        .accordion__toggler {
            padding: 25px 15px;
        }

        .accrordion-mob_item {
            background-color: var(--white);
            border-radius: 5px 30px;
            padding: 25px 20px 30px 20px;

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            span {
                color: var(--black2F);
            }

            p {
                margin: 20px 0;
                color: var(--grey);
            }

            img {
                width: 100%;
                height: auto;
            }
        }

    }
}
.burger-btn {
    display: none;
    width: 34px;
    height: 20px;
    position: relative;
    z-index: 2;

    i {
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 4px;
        background-color: var(--blue);
        border-radius: 50px;
        left: 0;
        transition: all .3s ease 0s;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 50%;
            width: 75%;
            right: 0;
            left: auto;
        }

        &:nth-child(3) {
            top: 100%;
        }
    }

    &.active {
        i {
            transition: all .3s ease 0s;

            &:nth-child(1) {
                rotate: 45deg;
                top: 50%;

            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                rotate: -45deg;
                top: 50%;
            }
        }
    }
}

@media (max-width: 1024px) {
    .burger-btn {
        display: block;
    }
}
.orders {
  background-color: var(--blue);
  @include adaptive-value("padding-top", 100, 60, 1);
  @include adaptive-value("padding-bottom", 100, 60, 1);

  // .orders__wrap

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 976px;
    width: 100%;
    margin: 0 auto;
    gap: 50px;
  }

  @media (max-width: 1024px) {
    &__wrap {
      flex-direction: column;
      gap: 40px;
    }
  }

  // .orders__text

  &__text {

    p {
      color: var(--white);
      @include adaptive-value("font-size", 28, 22, 1);
      line-height: 128.571%;

      span {
        font-weight: 400;
      }
    }
  }

  // .orders__img

  .orders__image {
    display: inline-block;
    margin-top: 68px;
    width: 100%;

    @include sm {
      max-width: 420px;
    }

    .orders__img {
      width: 100%;
      height: auto;
    }
  }

  .orders__form,
  .orders__content {
    max-width: 465px;
    width: 100%;
  }

  @media (max-width: 1024px) {
    .orders__content {
      display: flex;
      flex-direction: column-reverse;
      gap: 40px;
    }

    .orders__image {
      margin: 0;
    }
  }


  .input-wrap {
    position: relative;
    margin-bottom: 25px;
  }

  // .orders__form-text

  &__form-text {
    p {
      color: var(--white);
      font-weight: 500;
      @include adaptive-value("font-size", 18, 16, 1);
      line-height: calc(26 / 18) * 100%;
      margin-bottom: 40px;
    }
  }

  .iti__selected-dial-code {
    color: var(--white);
  }
}

.orders_pricing {

  .orders__text{
    max-width: 430px;
    width: 100%;
  }

  .form-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 15px;
  }

  .input-wrap {
    &:nth-child(1) {
      width: 48%;
      order: 1;

      @include sm {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 100%;
      order: 3;
    }

    &:nth-child(3) {
      width: 48%;
      order: 2;

      @include sm {
        width: 100%;
      }
    }

    &:nth-child(4) {
      width: 100%;
      order: 4;
    }
  }

  .textarea {
    height: 120px;
    border-radius: 30px;
    margin-bottom: 15px;
  }

  .check_wrap {
    b {
      text-decoration: none;
    }
  }
}
.footer {
  background-color: var(--hover-blue);
  padding: 60px 0;

  .container {
    max-width: px(1360);
  }

  .footer_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 35px;
    margin-bottom: 100px;
  }

  @media (max-width: 1024px) {
    .footer_top {
      flex-direction: column;
    }
  }


  @media (max-width: #{$lg-and-sm}) {
    .footer_top {
      margin-bottom: 60px;
    }
  }

  .logo_wrap {
    max-width: 295px;
    width: 100%;
  }

  @media (max-width: 1024px) {
    .logo_wrap {
      max-width: 100%;
      margin: 0 auto;
      //text-align: center;
    }
  }

  .footer__image {
    display: inline-block;
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    .footer__image {
      max-width: 200px;
      height: auto;

      .footer__img {
        width: 100%;
        height: auto;
      }
    }
  }

  .footer_text {
    font-weight: 600;
    font-size: 14px;
    line-height: calc(20 / 14) * 100%;
    color: var(--white);
  }

  .col_wrap {
    max-width: 875px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 25px;

    @include xs {
      flex-direction: column;
    }
  }

  @media (max-width: 1024px) {
    .col_wrap {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 40px;
    }
  }

  .footer_list {
    @include xs {
      height: 0;
      visibility: hidden;
      opacity: 0;
      text-align: start;

      &.open {
        height: auto;
        visibility: visible;
        opacity: 1;
        transition: all .3s ease 0s;
      }
    }
  }

  .footer_col {
    max-width: 140px;
    width: 100%;
  }

  @media (max-width: 1024px) {
    .footer_col {
      min-width: 140px;
    }
  }

  @media (max-width: #{$sm-and-sm}) {
    .footer_col {
      max-width: 40%;
      text-align: center;
    }
  }

  @media (max-width: 480px) {
    .footer_col {
      min-width: 100%;
    }
  }


  .footer_title {
    font-weight: 700;
    font-size: 18px;
    color: var(--white);
    margin-bottom: 25px;

    @include xs {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-start;
      text-align: start;
      margin: 0;
      width: max-content;
      position: relative;

      &::after {
        content: url(../svg/footer_arrow.svg);
        width: 10px;
        height: 8px;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        right: -15px;
        transition: all .3s ease 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: center;
      }

      &.active {
        margin-bottom: 20px;

        &::after {
          transform: rotateX(180deg);
          transition: all .3s ease 0s;
        }
      }
    }
  }

  .list_item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .item_link {
    color: var(--white);
    opacity: 0.8;
    font-size: 14px;
    transition: all .3s ease 0s;
  }

  @media (hover:hover) {
    .item_link {
      &:hover {
        opacity: 1;
        transition: all .3s ease 0s;
      }
    }
  }

  @media (hover:none) {
    .item_link {
      &:active {
        opacity: 1;
      }
    }
  }

  .footer_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include adaptive-value("gap", 30, 20, 1);
  }

  @media (max-width: #{$md-and-sm}) {
    .footer_bottom {
      justify-content: center;
    }
  }

  .contact_wrap {
    display: flex;
    align-items: center;
    gap: 30px;
    @include adaptive-value("gap", 30, 20, 1);
  }

  @media (max-width: #{$md-and-sm}) {
    .contact_wrap {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .contact_item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;

    .email {
      color: var(--white);
      font-size: 14px;
    }

    .email_link {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .icon {
      width: 18px;
      height: 18px;
      color: var(--white);
    }

    @include md {
      justify-content: center;
    }

    @include xs {
      width: 100%;
    }
  }


  .item_link-text,
  .follow {
    font-weight: 800;
    font-size: 18px;
    color: var(--white);
  }

  .underline {
    text-decoration: underline;
  }

  .contact_item-link {
    transition: all .3s ease 0s;
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
      transition: all .3s ease 0s;
    }

    .icon-phone_2 {
      width: 15px;
      height: 15px;
    }
  }

  @media (hover:hover) {
    .contact_item-link {
      &:hover {
        .item_link-text {
          transition: all .3s ease 0s;
          color: var(--blue);
        }

        .icon {
          color: var(--blue);
        }
      }
    }
  }

  @media (hover:none) {
    .contact_item-link {
      &:active {
        color: var(-blue);

        .icon {
          color: var(--blue);
        }
      }
    }
  }

  .social_links_wrap,
  .links_wrap {
    display: flex;
    align-items: center;
  }

  .social_links_wrap {
    gap: 40px;
  }

  @media (max-width: #{$sm-and-sm}) {
    .social_links_wrap {
      flex-direction: column;
      gap: 20px;
    }
  }

  .follow {
    text-decoration: none;
  }

  .links_wrap {
    gap: 12px;

    @include xs {
      gap: 40px;
    }
  }

  .link,
  .link__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link {
    width: 44px;
    height: 44px;
    background-color: var(--white);
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all .3s ease 0s;

    .icon {
      width: 16px;
      height: 16px;
      color: var(--hover-blue);
      transition: all .3s ease 0s;
    }
  }

  @media (hover:hover) {
    .link {
      &:hover {
        transition: all .3s ease 0s;
        background-color: var(--hover-blue);
        border-color: var(--white);

        .icon {
          color: var(--white);
          transition: all .3s ease 0s;
        }
      }
    }
  }

  @media (hover:none) {
    .link {
      &:active {
        background-color: var(--hover-blue);
        border-color: var(--white);

        .icon {
          color: var(--white);
        }
      }
    }
  }

  &_media-links {
    padding: 50px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
  }
}

@media (max-width: #{$sm-and-sm}) {
  .footer {
    padding: 35px 0;
  }
}

.footer_ua {
  .links_wrap {

    @include xs {
      gap: 12px;
    }
  }
}
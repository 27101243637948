.features-main {
    background: linear-gradient(175.44deg, #F8F8F8 3.63%, rgba(248, 248, 248, 0) 85.79%);
    padding: 30px 0;
    @include adaptive-value("padding-bottom", 30, 0, 1);
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px 30px 0 0;

    &_title {
        margin: 0;

        @include xs {
            text-align: start;
        }
    }
}

.features-body {
    @include adaptive-value("padding-top", 20, 30, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);

    &_content {
        text-align: center;
    }

    &_wrap {
        &:not(:last-child) {
            margin-bottom: 120px;
        }

        &:nth-child(3n + 1) {
            .features-body_card {
                &:nth-child(4n + 1) {

                    .icon,
                    h3 {
                        color: var(--blue)
                    }
                }

                &:nth-child(4n + 2) {

                    .icon,
                    h3 {
                        color: var(--green)
                    }
                }

                &:nth-child(4n + 3) {

                    .icon,
                    h3 {
                        color: var(--yellow)
                    }
                }

                &:nth-child(4n + 4) {

                    .icon,
                    h3 {
                        color: var(--black2F)
                    }
                }
            }
        }

        &:nth-child(3n + 2) {
            .features-body_card {
                &:nth-child(4n + 1) {

                    .icon,
                    h3 {
                        color: var(--green)
                    }
                }

                &:nth-child(4n + 2) {

                    .icon,
                    h3 {
                        color: var(--yellow)
                    }
                }

                &:nth-child(4n + 3) {

                    .icon,
                    h3 {
                        color: var(--black2F)
                    }
                }

                &:nth-child(4n + 4) {

                    .icon,
                    h3 {
                        color: var(--blue)
                    }
                }
            }
        }

        &:nth-child(3n + 3) {
            .features-body_card {
                &:nth-child(4n + 1) {

                    .icon,
                    h3 {
                        color: var(--black2F)
                    }
                }

                &:nth-child(4n + 2) {

                    .icon,
                    h3 {
                        color: var(--blue)
                    }
                }

                &:nth-child(4n + 3) {

                    .icon,
                    h3 {
                        color: var(--green)
                    }
                }

                &:nth-child(4n + 4) {

                    .icon,
                    h3 {
                        color: var(--yellow)
                    }
                }
            }
        }

        .btn {
            font-weight: 400;
        }
    }

    &_subtitle {
        @include adaptive-value("font-size", 28, 20, 1);
        color: var(--black2F);
        font-weight: 700;
        @include adaptive-value("margin-bottom", 50, 30, 1);

        @include xs {
            text-align: start;
        }
    }


    &_card-wrap {
        @include adaptive-value("margin-bottom", 50, 40, 1);
        display: grid;
        grid-template: 1fr / repeat(auto-fit, minmax(320px, 1fr));
        gap: 20px;

        @include xs {
            grid-template: auto / 1fr;
        }
    }


    &_card {
        padding: 25px 25px 42px 25px;
        @include adaptive-value("padding-bottom", 42, 35, 1);
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px 30px;
        text-align: start;
        min-height: 292px;
        display: flex;
        flex-direction: column;

        &.hide {
            display: none;
            opacity: 0;
        }

        &.active {
            display: block;
            opacity: 1;
        }

        @include xs {
            min-height: auto;
        }

        p {
            @include adaptive-value("font-size", 14, 16, 1);
            line-height: calc(20 / 14);
            color: var(--grey);
            flex: 1 0 auto;
            margin-bottom: 25px;
        }

        a {
            font-size: 14px;
            text-decoration: underline;
            color: var(--blue);
            font-weight: 700;
        }
    }


    .card {

        &_head {
            display: flex;
            flex-direction: column;
            gap: 25px 15px;
            margin-bottom: 25px;

            .icon {
                min-width: 48px;
                min-height: 48px;
                max-width: 48px;
                max-height: 48px;

                @include xs {
                    min-width: 36px;
                    min-height: 36px;
                    max-width: 36px;
                    max-height: 36px;
                }

            }

            h3 {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
}
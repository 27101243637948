.service {
    background-color: var(--whiteF8);
    @include adaptive-value("padding-top", 50, 30, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);
    // .serivce__title

    &__title {
        h3 {
            color: var(--white);
            font-size: 22px;
            font-weight: 700;
            line-height: 127.273%;
        }
    }

    &__text {
        p {
            color: var(--white);
            font-size: 14px;
            font-weight: 400;
            line-height: 142.857%;
        }
    }

    &__btn {
        margin-top: 20px;

        .btn {
            color: var(--hover-blue);
        }
    }


    &__row {
        display: flex;
        gap: 20px;

        @include md {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include xs {
            gap: 10px;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 33.33%;
        padding-left: 60px;
        padding-right: 60px;
        background: var(--white);
        border-radius: 5px 30px;
        background: var(--white);
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);
        // min-height: 400px;

        @include md {
            width: 38%;
            min-height: 330px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @include xs {
            align-items: flex-start;
            width: 100%;
            min-height: auto;
            padding: 30px 25px;
        }
    }

    &__num {
        color: var(--blue);
        font-size: 70px;
        font-weight: 700;
        line-height: 107.143%;
        white-space: nowrap;
        text-align: center;
    }

    &__descr {
        color: var(--black2F);
        font-size: 14px;
        font-weight: 600;
        line-height: 142.857%;
        max-width: 160px;
        width: 100%;

        @include md {
            max-width: 100%;
            text-align: center;
        }

        @include xs {
            text-align: start;
        }
    }

    &__middle {
        width: 33.33%;


        @include md {
            width: 58%;
        }

        @include xs {
            width: 100%;
        }
    }

    &__right {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include md {
            width: 100%;
        }

        @include xs {
            gap: 10px;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        padding: 34px 50px;
        border-radius: 5px 30px;
        background: var(--deep-blue, #004565);
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);

        @include xs {
            padding: 34px;
        }
    }
}

.middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;

    @include md {
        justify-content: space-between;
    }

    @include xs {
        gap: 10px;
    }

    // .middle__col

    &__col {
        display: flex;
        align-items: center;
        gap: 15px;
        background: var(--blue);
        padding: 42px 40px;
        border-radius: 5px 30px;
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);

        @include md {
            padding: 25px;
        }

        @include xs {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__col-descr {
        color: var(--white);
        font-size: 14px;
        font-weight: 600;
        line-height: 142.857%;
    }

    // .middle__col-num

    &__col-num {
        color: var(--white);
        font-size: 50px;
        font-weight: 700;
        line-height: 110%;
    }
}

.right {
    display: flex;
    gap: 20px;

    @include md {
        justify-content: space-between;
    }

    @include xs {
        gap: 10px;
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 22px 30px;
        border-radius: 5px 30px;
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);

        @include lg {
            padding: 30px 20px;
        }

        @include md {
            width: 48%;
        }
    }

    .icon-infinity {
        width: 47px;
        height: 35px;
        color: var(--blue);

        @include xs {
            margin-bottom: 15px;
        }
    }

    &__col-descr {
        color: var(--black2F);
        font-size: 14px;
        font-weight: 600;
        line-height: 142.857%;
        display: inline-block;
    }


    @media (max-width: 375px) {
        &__col {
            width: 100%;
        }
    }

    &__col-num {
        color: var(--blue);
        font-size: 50px;
        font-weight: 700;
        line-height: 110%;
    }
}
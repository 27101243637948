.primary-link {
    color: var(--blue);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    transition: all .3s ease 0s;

    &:hover {
        color: var(--hover-blue);
        transition: all .3s ease 0s;
    }
}
.contact-us-modal {
    max-width: 600px;
    width: calc(100% - 40px);
    height: max-content;
    // height: calc(100% - 40px);
    border-radius: 30px;
    background-color: var(--white);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    scale: 1;
    transition: all 0.3s ease 0s;

    @include xs {
        display: flex;
        flex-direction: column;
    }

    &.openContactForm {
        opacity: 1;
        visibility: visible;
        scale: 1;
        transition: all 0.3s ease 0s;
    }

    &.form_modal_pricing,
    &.form_modal_request {
        .contact-us-modal_title {
            max-width: 375px;
        }

        .check_wrap {
            margin: 0;

            .custom-checkbox {
                background-color: var(--whiteF8);
            }

            p,
            span {
                color: var(--black2F);

                ins {
                    font-weight: 600;
                    color: var(--blue);
                }
            }
        }
    }

    &.form_modal_request {
        .contact-us-modal_title {
            max-width: 425px;
        }
    }

    .contact-us-modal-body {
        display: flex;
        flex-direction: column;
        @include adaptive-value("padding-top", 30, 50, 1);
        @include adaptive-value("padding-bottom", 30, 25, 1);
        @include adaptive-value("padding-right", 40, 20, 1);
        @include adaptive-value("padding-left", 40, 20, 1);
        height: 100%;
    }

    .close-form-btn {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 18px;
        right: 18px;
        z-index: 9;

        &::after,
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            width: 100%;
            height: 1px;
            background-color: var(--black2F);
        }

        &::after {
            rotate: -45deg;
        }

        &::before {
            rotate: 45deg;
        }
    }

    // .contact-us-modal_title

    &_title {
        @include adaptive-value("font-size", 18, 16, 1);
        color: var(--black2F);
        font-weight: 700;
        text-align: center;
        max-width: 332px;
        width: 100%;
        margin: 0 auto 25px auto;
    }

    &_text {
        max-width: 342px;
        width: 100%;
        margin: 0 auto 25px auto;
        font-weight: 600;
        font-size: 14px;
        color: var(--grey);
        text-align: center;
    }

    // .contact-us-modal_form

    &_form {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 35px;
        column-gap: 15px;

        @include sm {
            flex-direction: column;
            justify-content: flex-start;
            flex: 1;
        }
    }

    .input_body {
        position: relative;
        width: 100%;

        &::before {
            content: '*';
            position: absolute;
            top: 3px;
            left: 0;
            width: 6px;
            height: 6px;
            color: var(--red);
        }
    }

    // .contact-us-modal_input

    &_input {
        border: 1px solid transparent;
        background-color: var(--whiteF8);
        backdrop-filter: blur(15px);
        border-radius: 500px;
        padding: 25px 20px;
        width: 100%;
        outline: none;
        transition: all .3s ease 0s;
        font-size: 14px;
        color: var(--black);

        &:focus {
            border-color: var(--blue);
            transition: all .3s ease 0s;
        }

        &:focus-visible {
            border-color: var(--blue);
            transition: all .3s ease 0s;
        }

        &::placeholder {
            color: var(--grey);
        }
    }

    .short_input {
        max-width: 252px;
        width: 100%;
    }

    .iti-body {
        .iti__arrow {
            border-top: 4px solid var(--grey);
        }
    }

    .iti__selected-dial-code {
        color: var(--grey);
    }

    // .contact-us-modal_btn

    .form-btn {
        margin: 0 auto;
        width: max-content;
        font-weight: 400;
        width: 153px;

        @include xs {
            width: 100%;
            height: 40px;
            margin-top: auto;
            margin-bottom: 25px;
        }
    }

    .textarea {
        min-height: 120px;
        height: 120px;
        max-height: 220px;
        border-radius: 30px;

        @include xs {
            flex: 1;
        }
    }

    @media (max-width: #{$sm-and-sm}) {
        .short_input {
            max-width: 100%;
        }
    }
}

@media (max-width:480px) {
    .contact-us-modal {
        width: 100%;
        height: 100vh;
        overflow: auto;
        border-radius: 0;
    }
}

.contact-form-container {
    position: fixed;
    inset: 0;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease 0s;

    &.showContactFormContainer {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease 0s;
    }
}

.contact_page_form {
    padding-top: 30px;
    @include adaptive-value("padding-bottom", 100, 60, 1);
    position: static;
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    max-width: 100%;
    max-width: 1400px;
    width: calc(100% - 20px);
    margin: 0 auto;
    border-radius: 0 0 5px 30px;

    .contact-us-modal-body {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        background-color: var(--white);
        box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
        border-radius: 5px 30px;
        @include adaptive-value("padding-top", 50, 30, 1);
        @include adaptive-value("padding-bottom", 50, 30, 1);
        @include adaptive-value("padding-right", 50, 20, 1);
        @include adaptive-value("padding-left", 50, 20, 1);
        position: relative;
        overflow: hidden;
    }

    .close-form-btn {
        display: none;
    }

    .contact-us-modal_title {
        @include adaptive-value("font-size", 28, 22, 1);
        margin-bottom: 40px;
        max-width: 100%;
    }

    .contact-us-modal_form {
        justify-content: space-between;
    }

    .input_body {
        width: 32%;

        @include md {

            &:nth-child(1),
            &:nth-child(2) {
                width: 48.5%;
            }

            &:nth-child(3) {
                width: 100%;
            }
        }

        @include sm {

            &:nth-child(1),
            &:nth-child(2) {
                width: 100%;
            }

        }
    }

    .short_input {
        max-width: 100%;
    }

    .textarea {
        height: 150px;
    }

    .form-btn {
        width: 200px;
        margin: 0 auto;
    }
}

.succesfull_request {
    position: absolute;
    inset: 0;
    background-color: var(--white);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    scale: 0;

    &.openRequest {
        opacity: 1;
        visibility: visible;
        scale: 1;
        transition: all .3s ease 0s;
    }

    .request_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 496px;
        width: calc(100% - 40px);
        height: 100%;
        margin: 0 auto;
        text-align: center;

        img {
            width: 100px;
            height: 100px;
            @include adaptive-value("margin-bottom", 50, 30, 1);
        }

        h2 {
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
            line-height: calc(26 / 18);
            color: var(--grey);
        }
    }
}
.demo {
    @include adaptive-value("padding-top", 20, 25, 1);
    @include adaptive-value("padding-bottom", 50, 25, 1);
    // .demo_content

    &_content {

        @include adaptive-value("padding-top", 60, 20, 1);
        @include adaptive-value("padding-bottom", 60, 20, 1);
        @include adaptive-value("padding-right", 40, 15, 1);
        @include adaptive-value("padding-left", 40, 15, 1);
        background-color: var(--blue);
        border-radius: 5px 30px;
    }

    &_content-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        max-width: 900px;
        width: 100%;
        margin: 0 auto;

        @include sm {
            flex-direction: column-reverse;
        }
    }

    // .demo_text-wrap

    &_text-wrap {
        max-width: 402px;
        width: 100%;

        @include sm {
            text-align: center;
            max-width: 100%;
        }
    }

    // .demo_title

    &_title {
        color: var(--white);
        @include adaptive-value("font-size", 28, 22, 1);
        font-weight: 400;
        line-height: 128%;
        @include adaptive-value("margin-bottom", 50, 40, 1);

        @include md {
            br {
                display: none;
            }
        }

        @include xs {
            text-align: start;
            max-width: 297px;
            width: 100%;
        }
    }

    // .demo_text

    &_text {
        color: var(--white);
        font-size: 14px;
        font-weight: 700;
        @include adaptive-value("margin-bottom", 50, 40, 1);

        @include xs {
            text-align: start;
        }
    }

    .btn {
        width: 315px;

        @include xs {
            width: 100%;
        }
    }

    .demo__image {
        max-width: 500px;
        width: 100%;
        height: auto;

        .demo__img {
            width: 100%;
            height: auto;
        }
    }
}
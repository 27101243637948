.results {
    padding: 100px 0;
    background-color: var(--whiteF8);
    text-align: center;

    .container {
        @include sm {
            padding: 0;
        }
    }

    .results_title {
        margin-bottom: 30px;
        padding: 0 20px;
    }

    .results_descr {
        font-size: 18px;
        line-height: calc(26 / 18) * 100%;
        margin-bottom: 50px;
        padding: 0 20px;
        color: var(--grey);
    }

    @media (max-width: #{$sm-and-sm}) {
        .results_descr {
            margin-bottom: 30px;
        }
    }

    .result_swiper {
        padding: 0 10px;
    }

    .swiper-wrapper {
        margin-bottom: 50px;
    }

    @media (max-width: #{$sm-and-sm}) {
        .swiper-wrapper {
            margin-bottom: 30px;
        }
    }


    .result_slide {
        border-radius: 5px 30px 5px 30px;
        box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: var(--white);
        min-height: 475px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .slide__image {
        width: 100%;
        height: 240px;
        border-radius: 5px 30px 5px 30px;
        overflow: hidden;
        display: inline-block;


        .slide__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    @media (max-width: #{$sm-and-sm}) {
        .slide_img {
            min-height: 200px;
        }
    }

    .slide_title-wrap {
        flex: 1;
        margin-bottom: 25px;
        padding: 25px 25px 0 25px;
        width: 100%;
    }

    .slide_title {
        font-size: 18px;
        line-height: 145%;
        font-weight: 700;
        text-align: start;
        color: var(--blue);
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    @media (max-width: #{$sm-and-sm}) {
        .slide_title {
            font-size: 16px;
        }
    }


    .btn {
        font-size: 14px;
        height: 40px;
        width: max-content;
        padding: 15px 20px;
        margin-bottom: 25px;
    }

    .swiper_nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
}

@media (max-width: #{$sm-and-sm}) {
    .results {
        padding: 50px 0;
    }
}
.additional-product {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);

    &_title {
        max-width: 600px;
        width: 100%;
    }


    &_card-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .product {

        &_card {
            min-height: 395px;
            height: auto;
            flex: 0 1 365px;

            @include xs {
                min-height: auto;
            }
        }


        &_card-img {
            background-color: var(--blueCE);
            border-radius: 5px 30px;
            padding: 20px 36px;

            .additional__image {
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 5px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &_card-text {
            gap: 20px;
        }

        &_card-title {
            @include adaptive-value("font-size", 22, 20, 1);
            color: var(--blue);
            font-weight: 700;
        }

        &_card-descr {
            font-size: 14px;
            color: var(--grey);
            line-height: calc(20 / 14);
        }
    }
}

.additional-benefit {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);

    &_content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }

    .accordion {
        max-width: 545px;
        width: 100%;
    }

    .accordion__item {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .accordion__toggler {
        min-height: auto;
    }

    .additional__image {
        max-width: 545px;
        width: 100%;
        display: inline-block;

        @include md {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .additional_mob__image {
        margin: 0 auto;
        max-width: 330px;
        width: auto;
        min-height: 180px;
        max-height: 250px;
        border-radius: 5px 25px;
        margin-top: 25px;
        display: none;
        position: relative;

        @include md {
            display: block;
        }

        @include xs {
            max-height: 180px;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;

        }
    }
}

.additional-get {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);

    &_title {
        @include adaptive-value("margin-bottom", 30, 25, 1);
    }

    &_descr {
        text-align: center;
        font-size: 18px;
        color: var(--grey);
        @include adaptive-value("margin-bottom", 100, 60, 1);
    }

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;

        @include md {
            flex-direction: column;
            gap: 30px;
        }
    }

    &_text-wrap {
        max-width: 500px;
        width: 100%;
    }

    .text-head {
        display: flex;
        align-items: center;
        gap: 25px;
        margin-bottom: 25px;

        @include xs {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .text_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 110px;
        min-height: 110px;
        max-width: 110px;
        max-height: 110px;
        border-radius: 5px 25px;
        background: var(--white);
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);

        @include sm {
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
        }

        .icon {
            width: 48px;
            height: 48px;
            color: var(--blue);

            @include sm {
                width: 36px;
                height: 36px;
            }
        }
    }

    .text_title {
        @include adaptive-value("font-size", 22, 20, 1);
        font-weight: 700;
    }

    .descr {
        font-size: 14px;
        color: var(--grey);
        line-height: calc(20 / 14);
    }

    .additional__image {
        max-width: 560px;
        width: 100%;
        display: inline-block;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.marquee-section {
    @include adaptive-value("padding-top", 80, 60, 1);
    @include adaptive-value("padding-bottom", 60, 40, 1);

    &_title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        @include adaptive-value("margin-bottom", 40, 20, 1);
        color: var(--grey);
        text-transform: uppercase;
    }

    .marquee {
        overflow: hidden;
        display: flex;
        @include adaptive-value("gap", 60, 40, 1);

        &.right-direction {
            justify-content: flex-end;
        }
    }

    .marquee-row {
        display: flex;
        align-items: center;
        @include adaptive-value("gap", 60, 40, 1);
        @include adaptive-value("height", 100, 60, 1);
    }

    .marquee__image {
        display: inline-block;

        img {
            object-fit: contain;
        }
    }
}
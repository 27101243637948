.product {
  padding: 100px 0;
  background-color: var(--whiteF8);

  @include sm {
    padding: 50px 0;
  }

  .container {
    padding: 0;
  }

  &_title {
    max-width: 700px;
    width: 100%;
    padding: 0 20px;
    line-height: 128%;

    @include xs {
      text-align: start;
    }
  }

  .swiper-slide{
    width: 365px;
    @include sm {
      width: auto;
    }
  }

  .product_swiper {
    padding: 0 20px 20px 20px;
  }

  .swiper-button-wrap {
    display: none;

    @include sm {
      display: flex;
    }

    @include xs {
      gap: 30px;
    }
  }

  &_cards-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    @include sm {
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 0;
    }
  }

  &_card {
    background-color: var(--white);
    width: 365px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.10);
    border-radius: 5px 30px 5px 30px;
    overflow: hidden;
    height: 480px;
    display: flex;
    flex-direction: column;

    @include sm {
      width: 100%;
    }

    @include xs {
      min-height: 455px;
    }
  }

  ._modify {
    background-color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 35px 15px;


    .product_card-text {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 0;
      flex: 1;
      // max-width: 255px;
      width: calc(100% - 30px);
      height: max-content;
      justify-content: center;
    }

    .card_title {
      font-size: 28px;
      line-height: calc((36 /28) * 100%);
      color: var(--white);
      margin: 0;
    }

    .card_descr {
      font-size: 18px;
      line-height: calc((26 /18) * 100%);
      color: var(--white);
    }

    .product_link {
      color: var(--blue);
      font-size: 18px;
      line-height: calc((26 /18) * 100%);
      font-weight: 700;
      background-color: var(--white);
      height: 60px;
      width: 100%;
    }

    @media (hover:hover) {
      .product_link:hover {
        background-color: var(--blue);
        color: var(--white);
        border-color: var(--white);
        transition: all .3s ease 0s;
      }
    }

    @media (hover:none) {
      .product_link:active {
        background-color: var(--blue);
        color: var(--white);
        border-color: var(--white);
      }
    }
  }

  &_card-img {
    width: 100%;
    height: 240px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width:430px) {
    &_card-img {
      height: auto;
    }
  }

  &_card-text {
    display: flex;
    flex-direction: column;
    padding: 25px;
    height: 50%;
    flex: 1;
  }


  @media (max-width:430px) {
    &_card-text {
      height: auto;
      gap: 20px;
    }
  }

  .card_title {
    font-size: 22px;
    font-weight: 700;
    line-height: calc((28 /22) * 100%);
    color: var(--blue);
    margin-bottom: 20px;
  }

  @media (max-width:430px) {
    .card_title {
      margin-bottom: 0;
    }
  }

  .card_descr {
    font-size: 14px;
    line-height: calc((20 / 14) * 100%);
    color: var(--grey);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &_link {
    text-align: center;
    font-size: 13px;
    line-height: calc((16 / 13) * 100%);
    color: var(--blue);
    border: 1px solid var(--blue);
    padding: 15px 20px;
    margin: 0 auto;
    height: 40px;
    border-radius: 50px;
    transition: all .3s ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    font-weight: 400;
    max-width: min-content;
  }
}

.product-en {
  .product_title {
    max-width: 550px;
  }
}
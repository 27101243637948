.dial_choise {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);
    background-color: var(--white);

    // .dial_choise_title

    &_title {
        max-width: 770px;
        width: 100%;
        margin: 0 auto;
        @include adaptive-value("margin-bottom", 50, 25, 1);
    }

    // .dial_choise_card-wrap

    &_card-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @include adaptive-value("gap", 60, 40, 1);
        flex-wrap: wrap;
    }



    &_card {
        max-width: 340px;
        width: 100%;

        &:nth-child(4n + 1) {
            .icon {
                color: var(--blue);
            }

            .card_title {
                color: var(--blue);
            }
        }

        &:nth-child(4n + 2) {
            .icon {
                color: var(--yellow);
            }

            .card_title {
                color: var(--yellow);
            }
        }

        &:nth-child(4n + 3) {
            .icon {
                color: var(--green);
            }

            .card_title {
                color: var(--green);
            }
        }

        &:nth-child(4n + 4) {
            .icon {
                color: var(--black2F);
            }

            .card_title {
                color: var(--black2F);
            }
        }
    }

    .card {
        &_list {
            li {
                font-size: 14px;
                color: var(--grey);
                display: flex;
                align-items: center;
                gap: 10px;
                transition: all .3s ease 0s;
                margin-bottom: 20px;

                &.hide {
                    opacity: 0;
                    height: 0;
                    visibility: hidden;
                    margin-bottom: 0;
                    transition: all .3s ease 0s;

                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }

                &::before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    mask: url(../icon/icons/icons.svg#check_2) no-repeat center / cover;
                    background-color: var(--black2F);
                    min-width: 20px;
                    min-height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                }
            }

            &.yellow li::before {
                background-color: var(--yellow);
            }

            &.blue li::before {
                background-color: var(--blue);
            }

            &.green li::before {
                background-color: var(--green);
            }

            &.black li::before {
                background-color: var(--black2F);
            }
        }

        &_item {
            display: flex;
            align-items: center;
            gap: 10px;
            transition: all .3s ease 0s;
            margin-bottom: 20px;

            &.hide {
                opacity: 0;
                height: 0;
                visibility: hidden;
                margin-bottom: 0;
                transition: all .3s ease 0s;

                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }

            span {
                font-size: 14px;
                color: var(--grey);
            }

            .icon {
                min-width: 20px;
                min-height: 20px;
                max-width: 20px;
                max-height: 20px;
            }
        }

        &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            @include adaptive-value("width", 110, 80, 1);
            @include adaptive-value("height", 110, 80, 1);
            border-radius: 5px 30px;
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
            @include adaptive-value("margin-bottom", 40, 30, 1);

            .icon {
                width: 48px;
                height: 48px;

                @include adaptive-value("width", 48, 36, 1);
                @include adaptive-value("height", 48, 36, 1);
            }
        }

        // .card_text-wrap

        &_text-wrap {
            max-width: 285px;
            width: 100%;

            @include xs {
                max-width: 100%
            }
        }

        // .card_title

        &_title {
            @include adaptive-value("font-size", 18, 16, 1);
            margin-bottom: 20px;

            @include xs {
                br {
                    display: none;
                }
            }
        }

        // .card_text

        &_text {
            font-size: 14px;
            color: var(--grey);
        }
    }

    .show_btn {
        font-size: 14px;
        font-weight: 700;
        color: var(--blue);
        text-decoration: underline;
        transition: all .3s ease 0s;

        &.hidden {
            display: none;
        }
    }

    @media (hover:hover) {
        .show_btn {

            &:hover {
                color: var(--hover-blue);
                transition: all .3s ease 0s;
            }
        }

    }

    @media (hover:none) {
        .show_btn {

            &:active {
                color: var(--hover-blue);
            }
        }

    }
}

.dial_choise-en {
    .dial_choise_title {
        max-width: 770px;
    }
}

.dial_choise {


    .dial_choise_title {
        text-align: center;
    }
}
.contact-center {
    &_title {
        color: var(--black2F);
    }
}

.contact-center-try {
    @include adaptive-value("padding-top", 100, 50, 1);
    @include adaptive-value("padding-bottom", 100, 50, 1);
    background-color: var(--whiteF8);

    &_title {
        max-width: 700px;
        margin-bottom: 30px;

        @include xs {
            text-align: start
        }
    }

    &_text {
        max-width: 532px;
        width: 100%;
        margin: 0 auto 50px auto;
        text-align: center;
        @include adaptive-value("font-size", 18, 16, 1);
        color: var(--grey);
        line-height: calc(24 / 16);

        @include xs {
            margin-bottom: 40px;
            text-align: start;
        }
    }

    // .contact-center-try_content

    &_content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 44px;

        @include sm {
            flex-direction: column;
            gap: 10px;
        }
    }

    // .contact-center-try_left-side

    &_left-side,
    &_right-side {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include sm {
            gap: 10px;
        }

        @include xs {
            width: 100%;
        }
    }

    &_left-side {
        align-items: flex-start;

        .contact-center-try_card {

            &:nth-child(1) {
                max-width: 326px;
                width: 100%;


                @include sm {
                    max-width: 380px;
                }

                .icon-wrap {
                    border-color: var(--blue)
                }

                .icon {
                    color: var(--blue);
                }
            }

            &:nth-child(2) {
                max-width: 175px;
                width: 100%;


                @include sm {
                    max-width: 380px;
                }

                .icon-wrap {
                    border-color: var(--yellow)
                }

                .icon {
                    color: var(--yellow);
                }
            }

            &:nth-child(3) {
                max-width: 326px;
                width: 100%;


                @include sm {
                    max-width: 380px;
                }

                .icon-wrap {
                    border-color: var(--green)
                }

                .icon {
                    color: var(--green);
                }
            }
        }
    }

    &_right-side {
        align-items: flex-end;

        .contact-center-try_card {

            &:nth-child(1) {
                max-width: 326px;
                width: 100%;


                @include sm {
                    max-width: 380px;
                }

                .icon-wrap {
                    border-color: var(--blue)
                }

                .icon {
                    color: var(--blue);
                }
            }

            &:nth-child(2) {
                max-width: 175px;
                width: 100%;


                @include sm {
                    max-width: 380px;
                }

                .icon-wrap {
                    border-color: var(--green)
                }

                .icon {
                    color: var(--green);
                }
            }

            &:nth-child(3) {
                max-width: 326px;
                width: 100%;


                @include sm {
                    max-width: 380px;
                }

                .icon-wrap {
                    border-color: var(--yellow)
                }

                .icon {
                    color: var(--yellow);
                }
            }
        }
    }

    &_card {
        position: relative;
    }

    .card_content {
        position: relative;
        z-index: 1;
        background-color: var(--white);
        border-radius: 5px 25px;
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        align-items: center;
        gap: 25px;
        padding: 20px 35px 20px 22px;
        position: relative;
        width: 100%;

        @include xs {
            padding: 15px;
            gap: 15px;
        }

        .icon {
            width: 36px;
            height: 36px;
        }
    }

    &_center-side {

        @include md {
            display: none;
        }

        .logo__image {
            max-width: 330px;
            min-width: 250px;
            height: auto;
            display: inline-block;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .icon-wrap {
        padding-right: 22px;
        border-right: 1px solid transparent;

        @include xs {
            padding-right: 15px;
        }
    }

    .card {

        &_title {
            max-width: 180px;
            color: var(--black2F);
            font-size: 14px;
            font-weight: 400;
        }
    }

    .line {
        position: absolute;

        @include md {
            display: none;
        }
    }

    .top {


        &_right {
            @include adaptive-value("right", -80, -40, 1);
            top: 50%;
        }

        // .top_left

        &_left {
            @include adaptive-value("left", -80, -40, 1);
            top: 50%;
        }
    }

    .left {
        top: 50%;
        transform: translateY(-50%);
        @include adaptive-value("right", -170, -80, 1);
    }

    .bottom {


        &_right {
            @include adaptive-value("left", -80, -40, 1);
            bottom: 50%;
        }

        // .top_left

        &_left {
            @include adaptive-value("right", -80, -40, 1);
            bottom: 50%;
        }
    }

    .right {
        top: 50%;
        transform: translateY(-50%);
        @include adaptive-value("left", -170, -80, 1);
    }
}

.contact-center-choose {
    @include adaptive-value("padding-top", 100, 50, 1);
    @include adaptive-value("padding-bottom", 100, 50, 1);

    &_content {
        max-width: 760px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        counter-reset: num;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .col_head {
        margin-bottom: 20px;
        display: grid;
        grid-template: 1fr / auto 88px 170px;
        align-items: flex-start;
        width: 100%;
        @include adaptive-value("gap", 20, 8, 1);


        @include sm {
            grid-template: 1fr / auto 75px 170px;
        }

        @include xs {
            grid-template: 1fr / auto 50px 75px;
        }


        span {
            text-align: center;
            @include adaptive-value("font-size", 22, 16, 1);

            &:nth-child(2) {
                color: var(--blue);
                font-weight: 500;
            }

            &:nth-child(3) {
                color: var(--grey);
            }
        }
    }

    .col_item {
        display: grid;
        grid-template: 1fr / 75px auto 88px 170px;
        align-items: center;
        width: 100%;
        @include adaptive-value("gap", 20, 8, 1);
        counter-increment: num;

        @include sm {
            grid-template: 1fr / 50px auto 75px 170px;
        }

        @include xs {
            grid-template: 1fr / 25px auto 50px 75px;
            align-items: flex-start;
        }

        &:nth-child(2) {
            &::before {
                padding-top: 28px;

                @include sm {
                    padding-top: 15px;
                }
            }
        }

        &:nth-child(-n+10) {
            &::before {
                content: "0"counter(num);
            }
        }

        &:nth-child(n+11) {
            &::before {
                content: counter(num);
            }
        }

        &:last-child {
            &::before {
                padding-bottom: 28px;

                @include xs {
                    padding-bottom: 15px;
                }
            }
        }

        &::before {
            color: var(--grey);
            @include adaptive-value("font-size", 18, 14, 1);
            opacity: 0.5;
            padding: 17px 0;
        }

        @media (max-width:400px) {
            &::before {
                padding-top: 15px;
            }
        }

        &:nth-child(2) {
            .icon-check_wrap {
                padding-top: 28px;
                border-radius: 30px 30px 0 0;

                @include xs {
                    padding-top: 15px;
                }
            }

            .icon-cross_wrap,
            p {
                padding-top: 28px;

                @include xs {
                    padding-top: 15px;
                }
            }
        }

        &.last-child {
            .icon-check_wrap {
                padding-bottom: 28px;
                border-radius: 0 0 30px 30px;

                @include xs {
                    padding-bottom: 15px;
                }
            }

            .icon-cross_wrap,
            p {
                padding-bottom: 28px;

                @include xs {
                    padding-bottom: 15px;
                }
            }
        }

        p {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            max-width: 245px;
            width: 100%;
            padding: 17px 0;
            @include adaptive-value("font-size", 18, 14, 1);
            color: var(--grey);

            @include xs {
                padding: 14px 0;
            }
        }

        @media (max-width:400px) {
            p {
                max-width: 120px;
            }
        }

        .icon-check_wrap {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--blue);
            padding: 17px 0;

            @include xs {
                padding: 14px 0;
            }

            .icon {
                color: var(--white);
            }
        }

        .icon-cross_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 17px 0;
            height: 100%;

            @include xs {
                padding: 14px 0;
            }
        }
    }
}
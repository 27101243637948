.not-found {
    max-width: 1400px;
    width: calc(100% - 20px);
    margin: 0 auto;
    background-color: var(--whiteF8);
    border-radius: 5px 30px;
    position: relative;
    overflow: hidden;
    padding: 60px 20px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        @include adaptive-value("top", -1100, 300, 1);
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);

        @include sm {
            width: 638px;
            top: -100px;
            left: -50%;
            transform: translateX(-20%);
        }
    }

    // .not-found_content

    &_content {
        position: relative;
        z-index: 1;
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include adaptive-value("gap", 60, 40, 1);

        @include sm {
            flex-direction: column; 
        }
    }

    .not-found__image {
        max-width: 500px;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    // .not-found_text-wrap

    &_text-wrap {
        text-align: start;
        max-width: 580px;
        width: 100%;

        @include sm {
            text-align: center;
        }
    }

    // .not-found__title

    &__title {
        color: var(--blue);
        text-align: start;
        margin-bottom: 40px;

        @include sm {
            text-align: center;
        }

        br{
            display: none;

            @include xs {
                display: block;
            }
        }
    }

    // .not-found__text

    &__text {
        @include adaptive-value("font-size", 18, 16, 1);
        color: var(--grey);
        line-height: calc(26 / 18);
        @include adaptive-value("margin-bottom", 60, 40, 1);
        max-width: 339px;
        width: 100%;

        @include sm {
            margin: 0 auto 40px auto;
        }
    }

    .not-found__link {
        @include adaptive-value("font-size", 18, 16, 1);
        
        @include xs {
            width: 100%;
        }
    }
}

.not_found_ua{

    .not-found__text{
        max-width: 445px;
    }
}
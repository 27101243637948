.familiar {
  @include adaptive-value("padding-top", 100, 60, 1);
  @include adaptive-value("padding-bottom", 100, 60, 1);

  &_no_titles {
    .familiar_card-text {
      @include adaptive-value("font-size", 18, 14, 1);
    }

    .familiar_card-num {
      margin: 0;
    }
  }

  &_small_format {

    .familiar_card-wrap {
      display: grid;
      grid-template: 1fr / repeat(auto-fit, minmax(270px, 1fr));
      gap: 15px 20px;

    }

    .familiar_card {
      display: flex;
      flex-direction: column;
      &.number_center {
        @include sm {
          flex-direction: row;
        }
        .card_title-wrap {
          gap: 25px;
          display: flex;
          flex-direction: column;
          @include sm {
            gap: 15px;
            margin-bottom: 0;
            margin-left: 20px;
            flex-direction: column;
            align-items: flex-start;
          }
        }
        .familiar_card-num {
          @include sm {
            margin: auto 0;
          }
        }
      }
      @include sm {
        padding: 25px 20px;
      }
    }

    .card-text-wrap {
      display: flex;
      flex-direction: column;

      @include sm {
        gap: 15px;
      }
    }

    .familiar_card-num {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
    }
  }

  &_is_slider {
    @include adaptive-value("padding-bottom", 50, 40, 1);

    .card-text-wrap {
      display: flex;
      flex-direction: column;

      @include sm {
        gap: 15px;
      }
    }

    .familiar {
      &_title {
        max-width: 730px;
        margin-right: auto;
        margin-left: auto;
      }


      // .familiar_card-wrap
      &_card-wrap {

        @media (max-width:1024px) {
          display: block;
        }
      }

      // .familiar_card

      &_card {
        min-height: 175px;
        box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        @include sm {
          padding: 25px 25px 30px 25px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      &_card-text {
        font-size: 14px;
      }
    }

    .swiper {
      padding: 0 15px 15px 15px;
    }

    .swiper-button-wrap {
      display: none;
      align-items: center;
      justify-content: center;
      gap: 30px;
      margin-top: 20px;

      @media (max-width:1024px) {
        display: flex;
      }
    }

    .swiper-button-prev {
      @media (max-width:1024px) {
        display: flex;
      }
    }

    .swiper-button-next {
      @media (max-width:1024px) {
        display: flex;
      }
    }
  }

  // .familiar_title

  &_title {
    text-align: center;
    @include adaptive-value("font-size", 40, 30, 1);
    font-weight: 700;
    @include adaptive-value("margin-bottom", 50, 40, 1);
  }

  // .familiar_card-wrap

  &_card-wrap {
    display: grid;
    grid-template: 1fr / repeat(auto-fit, minmax(300px, 366px));
    justify-content: center;
    gap: 20px;
    counter-reset: num;

    @include xs {
      gap: 10px;
    }
  }

  // .familiar_card

  &_card {
    padding: 25px;
    border-radius: 5px 30px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);

    &:nth-child(4n + 1) {
      .familiar_card-num {
        background-color: var(--blue);
      }
    }

    &:nth-child(4n + 2) {
      .familiar_card-num {
        background-color: var(--green);
      }
    }

    &:nth-child(4n + 3) {
      .familiar_card-num {
        background-color: var(--yellow);
      }
    }

    &:nth-child(4n + 4) {
      .familiar_card-num {
        background-color: var(--black2F);
      }
    }
  }

  .card_title-wrap {
    margin-bottom: 25px;

    @include xs {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;
    }
  }

  // .familiar_card-num

  &_card-num {
    counter-increment: num;
    margin-bottom: 20px;
    display: inline-block;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: 700;
    @include adaptive-value("font-size", 18, 16, 1);
    margin-bottom: 25px;

    @include xs {
      margin-bottom: 0;
    }

    &::before {
      content: "0"counter(num);
    }
  }

  &_card-title {
    @include adaptive-value("font-size", 18, 16, 1);
    color: var(--black2F);

    br {
      display: none;
    }


    @include xs {
      margin: 0;

      br {
        display: block;
      }
    }
  }

  // .familiar_card-text

  &_card-text {
    font-size: 14px;
    color: var(--grey);
    line-height: 142%;
  }
}

.voice_familiar {
  padding-bottom: 0;
  .familiar_card-wrap {
    @include adaptive-value("margin-bottom", 50, 40, 1);
  }
}
.pluses {
  @include adaptive-value("padding-top", 50, 60, 1);
  @include adaptive-value("padding-bottom", 100, 60, 1);
  // .pluses__wrap

  &__wrap {
    display: flex;
    justify-content: center;
    gap: 80px;
    flex-wrap: wrap;

    @include sm {
      gap: 30px;
    }
  }

  // .pluses__col

  &__col {
    max-width: 235px;

    &:nth-child(1) {
      .icon {
        color: var(--blue);
      }
    }

    &:nth-child(2) {
      .icon {
        color: var(--yellow);
      }
    }

    &:nth-child(3) {
      .icon {
        color: var(--green);
      }
    }

    @include xs {
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  // .pluses__icon

  &__icon {
    max-width: 110px;
    width: 100%;
    height: 110px;
    margin-bottom: 40px;
    border-radius: 5px 25px;
    background: var(--white);
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;

    @include xs {
      min-width: 80px;
      min-height: 80px;
      max-width: 80px;
      max-height: 80px;
      margin: 0;
    }

    .icon {
      width: 43px;
      height: 43px;
    }
  }

  // .pluses__descr-title

  &__descr-title {
    margin-bottom: 20px;

    h2 {
      @include adaptive-value("font-size", 22, 20, 1);
      font-weight: 700;
    }

    &_blue {
      h2 {
        color: var(--blue);
      }
    }

    &_yellow {
      h2 {
        color: var(--yellow);
      }
    }

    &_green {
      h2 {
        color: var(--green);
      }
    }
  }

  // .pluses__descr-text

  &__descr-text {
    padding-right: 31px;

    p {
      color: var(--grey);
      @include adaptive-value("font-size", 18, 16, 1);
      font-weight: 400;
      line-height: 144.444%;
    }

    @include xs {
      padding-right: 0;
    }
  }
}
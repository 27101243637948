.clients-result {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);
    // .clients-result_title

    .container {
        text-align: center;
    }

    // .clients-result_content

    &_content {
        display: grid;
        grid-template: 1fr / 303px 1fr;
        justify-content: center;
        max-width: 960px;
        width: 100%;
        margin: 0 auto;
        gap: 20px;
        @include adaptive-value("margin-bottom", 50, 40, 1);

        @include md {
            grid-template: 1fr / 250px 1fr;
        }

        @include sm {
            grid-template: 1fr / 1fr;
        }
    }

    .clients-result__image {
        width: 315px;
        border-radius: 5px 30px;
        overflow: hidden;
        height: 100%;

        @include sm {
            max-width: 320px;
            width: 100%;
            margin: 0 auto;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .clients-result_numbers-wrap

    &_numbers-wrap {
        width: 100%;
        border-radius: 5px 30px;
        box-shadow: 0px 8px 25px 0px #0000001A;
        background-color: var(--whiteF8);
        @include adaptive-value("padding-top", 50, 25, 1);
        @include adaptive-value("padding-bottom", 50, 25, 1);
        @include adaptive-value("padding-right", 50, 25, 1);
        @include adaptive-value("padding-left", 50, 25, 1);
        display: flex;
        flex-direction: column;
        @include adaptive-value("row-gap", 45, 40, 1);

        @include xs {
            align-items: flex-start;
        }
    }

    &_number-top,
    &_number-bottom {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        @include adaptive-value("gap", 30, 40, 1);

        @include xs {
            flex-direction: column;
        }
    }

    &_number-top {
        align-items: center;


        @include xs {
            align-items: flex-start;
        }

        .clients-result_number {
            &:nth-child(1) {
                max-width: 300px;
                width: 100%;

                .percent {
                    color: var(--blue);
                }
            }

            &:nth-child(2) {
                max-width: 207px;
                width: 100%;

                .percent {
                    color: var(--yellow);
                }
            }
        }
    }

    &_number-bottom {
        align-items: flex-end;

        @include xs {
            align-items: center;
        }

        .clients-result_number {
            &:first-child {
                max-width: 300px;
                width: 100%;

                .percent {
                    color: var(--green);
                }
            }

            &:last-child {
                max-width: 207px;
                width: 100%;
            }
        }
    }

    // .clients-result_number

    &_number {
        text-align: start;
        width: 50%;

        @include xs {
            width: 100%;
        }

        &:nth-child(3) {
            .percent {
                color: var(--green);
            }
        }
    }


    .percent {
        @include adaptive-value("font-size", 70, 50, 1);
        font-weight: 600;
        @include adaptive-value("margin-bottom", 20, 10, 1);
    }

    .number {

        // .number_text

        &_text {
            @include adaptive-value("font-size", 18, 16, 1);
            color: var(--grey);
            font-weight: 400;
            max-width: 270px;
            width: 100%;
        }

        // .number_link

        &_link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            font-weight: 600;
            font-size: 18px;
            color: var(--blue);
            transition: all .3s ease 0s;

            @include xs {
                margin-top: 10px;
                justify-content: center;
            }

            span {
                white-space: nowrap;
            }

            .icon {
                rotate: 180deg;
                min-width: 13px;
                min-height: 11px;
                max-width: 13px;
                max-height: 11px;
                color: var(--blue);
            }
        }

        @media (hover: hover) {
            &_link {
                &:hover {
                    color: var(--hover-blue);
                    transition: all .3s ease 0s;

                    .icon {
                        color: var(--hover-blue)
                    }
                }
            }

            ;
        }

        @media (hover: none) {
            &_link {
                &:active {
                    color: var(--hover-blue);

                    .icon {
                        color: var(--hover-blue)
                    }
                }
            }
        }
    }

    .btn {
        font-weight: 400;
    }
}
.success_modal-container {
    position: fixed;
    inset: 0;
    z-index: 15;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease 0s;

    &.openRequest {
        opacity: 1;
        visibility: visible;
        scale: 1;
        transition: all 0.3s ease 0s;
    }

    .success_modal {
        max-width: 600px;
        width: calc(100% - 40px);
        border-radius: 30px;
        @include adaptive-value("padding-top", 30, 50, 1);
        @include adaptive-value("padding-bottom", 30, 25, 1);
        @include adaptive-value("padding-right", 40, 20, 1);
        @include adaptive-value("padding-left", 40, 20, 1);
        background-color: var(--white);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        scale: 1;
        transition: all 0.3s ease 0s;
        text-align: center;
        padding: 50px 20px;

        &.successful_modal_eng {
            .text-wrap {
                max-width: 440px;
            }
        }

        .close-btn {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 18px;
            right: 18px;

            &::after,
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                width: 100%;
                height: 1px;
                background-color: var(--black2F);
            }

            &::after {
                rotate: -45deg;
            }

            &::before {
                rotate: 45deg;
            }
        }

        .icon {
            color: var(--green);
            width: 97px;
            height: 100px;
            @include adaptive-value("margin-bottom", 60, 30, 1);
        }

        .text-wrap {
            max-width: 420px;
            width: 100%;
            margin: 0 auto;

            h3 {
                font-weight: 700;
                font-size: 18px;
                color: var(--black2F);
                margin-bottom: 25px;
            }

            p {
                font-size: 14px;
                color: var(--grey);
                line-height: calc(20 / 14);
                margin-bottom: 25px;
                font-weight: 600;
            }

            span {
                font-size: 14px;
                color: var(--grey);
            }

            a {
                color: var(--blue);
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }
}
.contacts {
    padding-top: 30px;
    @include adaptive-value("padding-bottom", 50, 30, 1);
    max-width: 1400px;
    width: calc(100% - 20px);
    margin: 0 auto;
    border-radius: 5px 30px 0 0;
    background: linear-gradient(175.44deg, #F8F8F8 3.63%, rgba(248, 248, 248, 0) 85.79%);
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        @include adaptive-value("top", -1100, 300, 1);
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);

        @include sm {
            width: 638px;
            top: -100px;
            left: -50%;
            transform: translateX(-20%);
        }
    }

    // .contacts_content

    &_content {
        position: relative;
        z-index: 1;
    }

    // .contacts_title

    &_title {
        text-align: center;
        color: var(--blue);
    }

    // .contacts_card-wrap

    &_card-wrap {
        max-width: 900px;
        width: 100%;
        margin: 0 auto 50px auto;
        display: grid;
        grid-template: 1fr / repeat(auto-fit, minmax(400px, 1fr));
        gap: 20px;
        justify-items: center;

        @include sm {
            grid-template: 1fr / repeat(auto-fit, minmax(300px, 1fr));
        }
    }

    // .contacts_card

    &_card {
        background-color: var(--white);
        border-radius: 5px 30px;
        box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
        padding: 25px;
        max-width: 440px;
        width: 100%;
    }

    // .contacts_card-title

    &_card-title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 25px;

        .icon {
            width: 24px;
            height: 24px;
            color: var(--blue);
        }

        span {
            font-size: 18px;
            font-weight: 700;
            color: var(--black2F)
        }
    }

    // .contacts_card-text

    &_card-text {

        p,
        a {
            @include adaptive-value("font-size", 22, 18, 1);

            line-height: calc(28 / 22);
        }

        a {
            color: var(--blue);
        }

        p {
            color: var(--grey);
        }
    }

    &_card-tip {
        margin-top: 20px;
        font-size: 12px;
        color: var(--grey);
        line-height: calc(20 / 14);
    }

    .contacts-social {

        &_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 60px;

            @include md {
                flex-direction: column;
            }
        }

        &_media,
        &_messengers {
            display: flex;
            align-items: center;
            gap: 20px;

            @include xs {
                flex-direction: column;
            }
        }
    }


    .media {

        &_title {
            font-size: 18px;
        }

        &_link-wrap {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        &_link {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);

            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    &_offices {
        @include adaptive-value("margin-bottom", 60, 40, 1);
    }

    .offices-title {
        @include adaptive-value("font-size", 28, 20, 1);
        @include adaptive-value("margin-bottom", 60, 40, 1);
        color: var(--black2F);
        text-align: center
    }

    .offices {

        // .offices_col-wrap

        &_col-wrap {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 50px;
            flex-wrap: wrap
        }

        // .offices_col

        &_col {
            b {
                @include adaptive-value("font-size", 18, 16, 1);
                margin-bottom: 20px;
                display: inline-block;
                color: var(--black2F);
            }
        }


        // .offices_number

        &_number {
            display: flex;
            align-items: center;
            gap: 5px;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .icon {
                width: 24px;
                height: 24px;
                color: var(--blue)
            }

            a {
                font-size: 18px;
                color: var(--blue)
            }
        }
    }

}

.contacts_ua {
    .contacts_card-wrap {
        @include adaptive-value("margin-bottom", 60, 40, 1);
    }

    .contacts-social_wrap {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        @include adaptive-value("padding-top", 60, 40, 1);
        border-top: 1px solid rgba(128, 128, 128, 0.2);
    }
}

.contacts_ua-v2 {
    .contacts_card-wrap {
        max-width: 980px;
        grid-template: 1fr / repeat(auto-fit, minmax(300px, 1fr));
    }

    .contacts-social_wrap {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        @include adaptive-value("padding-top", 60, 40, 1);
        border-top: 1px solid rgba(128, 128, 128, 0.2);
    }

    .contacts_number {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        max-width: 980px;
        width: 100%;
        margin: 0 auto;
        @include adaptive-value("margin-bottom", 60, 30, 1);

        @include md {
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px;
        }
    }

    .number {

        &_col {
            width: 29%;

            @include md {
                width: 48%;
                text-align: center;
            }

            @include sm {
                width: 100%;
            }

            @include xs {
                text-align: start;
            }
        }

        // .number_title

        &_title {
            display: flex;
            align-items: flex-start;
            gap: 5px;
            margin-bottom: 25px;
            text-align: start;

            @include md {
                justify-content: center;
            }

            @include xs {
                justify-content: flex-start;
            }

            .icon {
                min-width: 24px;
                min-height: 24px;
                max-width: 24px;
                max-height: 24px;
                color: var(--blue)
            }

            span {
                font-size: 18px;
                font-weight: 700;
            }
        }

        // .number_body

        &_body {
            display: flex;
            align-items: center;
            gap: 10px;

            @include md {
                justify-content: center;
            }

            @include xs {
                justify-content: flex-start;
            }

            span {
                font-weight: 500;
                color: var(--black2F);
                @include adaptive-value("font-size", 18, 16, 1);
            }
        }
    }

    .numbers {

        // .numbers_wrap

        &_wrap {
            display: flex;
            flex-direction: column;
            gap: 15px;

            a {
                display: block;
                width: max-content;
                font-size: 18px;
                color: var(--blue);
            }

            p {
                color: var(--grey);
                font-size: 12px;
                line-height: calc(18 / 12);
            }
        }
    }

}
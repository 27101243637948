.btn {
  display: inline-flex;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: var(--white);
  text-align: center;
  user-select: none;
  background-color: transparent;
  padding: 5px 30px;
  font-size: 14px;
  line-height: 1;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  border: 1px solid transparent;
  outline: 0;
  border-radius: 50px;
  transition: .2s ease;
  font-weight: 700;
  font-size: 18px;
  height: 60px;
  white-space: nowrap;

  &_pimary {
    background-color: var(--blue);
    color: var(--white);

    &:hover {
      background-color: var(--hover-blue);
    }
  }

  &_outline {
    color: var(--blue);
    border: 1px solid var(--blue);

    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }

  &_white {
    background-color: var(--white);
    color: var(--blue, #007BB3);

    &:hover {
      color: var(--white);
      background-color: var(--blue);
      border: 1px solid #fff;
    }
  }
}



.play_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: 1;
  width: 104px;
  height: 104px;
  border-radius: 50%;
  background-color: var(--hover-blue);
  border: 2px solid var(--white);
  transition: all .3s ease 0s;

  .icon-play {
    width: 22px;
    height: 19px;
    color: var(--white);
  }

  &.hide_btn {
    animation: scaleDown 1s linear forwards;
  }

  @include sm {
    width: 48px;
    height: 48px;

    .icon-play {
      width: 16px;
      height: 13px;
    }
  }
}

@media (hover:hover) {
  .play_btn {
    &:hover {
      background-color: var(--blue);
      border-color: var(--blue);
      transition: all .3s ease 0s;
    }
  }
}

@keyframes scaleDown {
  0% {
    scale: 1;
  }

  25% {
    scale: 1.3;
  }

  50% {
    scale: 1;
  }

  100% {
    scale: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
.free {
  background: var(--blue);
  padding-top: 60px;
  padding-bottom: 60px;

  // .free__wrap

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px 50px;
  }

  @media (max-width: #{$md-and-sm}) {
    &__wrap {
      flex-direction: column;
    }
  }

  // .free__img

  .applications_icon__image {
    display: inline-block;
    max-width: 450px;
    width: 100%;
    height: auto;

    .applications_icon__img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: #{$md-and-sm}) {
    .applications_icon__image {
      padding-left: 0;
    }
  }


  // .free__content

  &__content {
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @include md {
      padding-right: 0;
      align-items: center;
      gap: 40px;
    }

    @include xs {
      align-items: normal;
    }
  }

  // .free__title

  &__title {
    h4 {
      color: var(--white);
      font-size: 28px;
      @include adaptive-value("font-size", 28, 22, 1);
      font-weight: 400;
      line-height: 128.571429%;
    }

    @include md {
      text-align: center;
    }

    @include xs {
      text-align: start;
    }
  }

  // .free__text

  &__text {
    max-width: 346px;

    p {
      color: var(--white);
      font-size: 14px;
      font-weight: 700;
      line-height: 142.857%;
    }

    @include md {
      text-align: center;
    }

    @include xs {
      text-align: start;
    }
  }

  .btn {
    @include xs {
      width: 100%;
    }
  }
}

.demo-v2{

  .free__wrap{
    @include md {
      flex-direction: column-reverse;
    }
  }

  .free__content{
    padding: 0;
    max-width: 425px;
    width: 100%;
  }

  .free__title{
    h4{
      font-weight: 400;
    }
  }

  .free__text{
    p{
      max-width: 280px;
      width: 100%;
    }
  }

  .orders__image{
    max-width: 450px;
    width: 100%;
    height: auto;

    img{
      width: 100%;
      height: 100%;
    }
  }

  .free__link{
    .btn{
      max-width: 315px;
      width: 100%;
    }
  }
}

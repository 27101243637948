.try-dial {
    @include adaptive-value("padding-top", 0, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);
    // .try-dial_content

    &_content {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include adaptive-value("row-gap", 50, 30, 1);
    }

    // .try-dial_text-block

    &_text-block {
        max-width: 675px;
        text-align: center;
    }

    // .try-dial_title

    &_title {
        @include adaptive-value("font-size", 40, 28, 1);
        @include adaptive-value("margin-bottom", 30, 20, 1);

        @include xs {
            text-align: start
        }
    }

    // .try-dial_text

    &_text {
        //max-width: 400px;
        width: 100%;
        margin: 0 auto;
        @include adaptive-value("font-size", 18, 16, 1);
        color: var(--grey);

        @include xs {
            text-align: start
        }
    }

    .try-dial-deck__image {
        display: inline-block;
        max-width: 1140px;
        width: 100%;
        height: auto;

        @include sm {
            display: none;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    .try-dial-mob__image {
        display: none;
        max-width: 350px;
        width: 100%;
        height: auto;

        @include sm {
            display: block;
        }

        img {
            height: 100%;
            width: 100%;
        }

    }

    @media (max-width: #{$md-and-sm}) {
        &_content {
            flex-direction: column;
            text-align: center;
        }

        &_text-block {
            max-width: 100%;
        }
    }
}

.try-dial-en {
    .try-dial_text-block {
        max-width: 675px;
    }

    .try-dial_text {
        max-width: 100%;
    }
}
.accordion {

  .accordion__item {
    width: calc(50% - 20px);
    background-color: var(--white);
    border-radius: 5px 30px 5px 30px;
    overflow: hidden;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--blue);
      transition: all .3s ease 0s;

      span {
        color: var(--white);
        transition: all .5s ease 0s;
      }

      .open_status {
        background-color: var(--white);
        transition: all .3s ease 0s;

        &::after,
        &::before {
          background-color: var(--blue);
          transition: all .3s ease 0s;
        }
      }
    }

    &.active {
      background-color: var(--blue);

      .accordion__toggler {
        background-color: var(--blue);
      }

      span {
        color: var(--white);
        transition: all .3s ease 0s;
      }

      .item_text-wrap {
        p {
          margin-bottom: 25px;
        }
      }

      .open_status {
        background-color: var(--white);
        transition: all .3s ease 0s;

        &::after,
        &::before {
          background-color: var(--blue);
          transition: all .3s ease 0s;
        }

        &::after {
          transform: translate(-50%, -50%) rotate(180deg);
          transition: all .3s ease 0s;
        }
      }
    }
  }

  .accordion__content {
    display: none;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 5px;
    padding: 25px 25px 0 25px;
    text-align: left;
    transform: translate(0px, -25px);
  }

  .accordion__toggler {
    min-height: 105px;
    padding: 25px;
    transition: all .3s ease 0s;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    cursor: pointer;

    @include xs {
      min-height: auto;
    }
  }

  @media (max-width: 1024px) {
    .accordion__item {
      width: 100%;
      height: auto;
    }
  }

  span {
    color: var(--blue);
    font-size: 18px;
    line-height: calc(26 / 18) * 100%;
    font-weight: 700;
    transition: all .3s ease 0s;
    text-align: start;
  }

  @media (max-width: #{$xs-and-sm}) {
    span {
      font-size: 16px;
    }
  }

  .open_status {
    position: relative;
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    border-radius: 50%;
    background-color: var(--blue);
    transition: all .3s ease 0s;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--white);
      width: 7.45px;
      height: 2px;
      transition: all .3s ease 0s;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  p,
  li,
  b {
    font-size: 14px;
    line-height: calc(20 / 14) * 100%;
    color: var(--white);
    height: auto;
  }

  b {
    display: inline-block;
  }

  ul,
  ol {
    padding-left: 15px;
  }

  ul {
    li {
      list-style: outside;

      b {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: auto;

      b {
        margin: 0;
      }
    }
  }

  li {
    margin-top: 0;
    margin-bottom: 3px;
  }
}
.benefit {
  padding: 100px 0;

  .benefit_tabs {
    @include xs {
      display: none;
    }

    .tabs__head-col {
      width: max-content;
    }

    .tabs__btn {
      font-size: 18px;
    }

    .benefit_card-title {
      min-height: 55px;
    }

    // .benefit_card-text {
    //   min-height: 140px;
    // }
  }

  &_ip-pbx {
    .benefit_tabs {
      .benefit_card-title {
        min-height: auto;
      }
    }

    .benefit_accordion {
      .benefit_card-title {
        font-size: 18px;
        min-height: 40px;
      }
    }
  }

  .benefit_accordion {
    display: none;

    @include xs {
      display: block;
    }

    .accordion__item {

      &.active {
        .accordion__toggler {
          color: var(--white);
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .accordion__toggler {
      font-size: 16px;
      font-weight: 700;
      color: var(--blue);
    }
  }

  .benefit_card-wrap {
    display: grid;
    grid-template: 1fr / repeat(auto-fit, minmax(240px, 270px));
    justify-content: center;
    gap: 20px;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    counter-reset: num;
  }

  @media (max-width:1170px) {
    .benefit_card-wrap {
      grid-template: 1fr / repeat(auto-fit, minmax(330px, 48%));
    }
  }

  @media (max-width:720px) {
    .benefit_card-wrap {
      grid-template: 1fr / 1fr;
    }
  }

  .benefit_card {
    transition: all .3s ease 0s;
    background-color: var(--whiteF8);
    border-radius: 5px 30px 5px 30px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    padding: 25px 20px 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    position: relative;

    &.familiar {
      row-gap: 0;
    }

    @include sm {
      row-gap: 20px;
    }

    &:nth-child(4n + 1) {
      .benefit_card-number {
        background-color: var(--blue);
      }
    }

    &:nth-child(4n + 2) {
      .benefit_card-number {
        background-color: var(--green);
      }
    }

    &:nth-child(4n + 3) {
      .benefit_card-number {
        background-color: var(--yellow);
      }
    }

    &:nth-child(4n + 4) {
      .benefit_card-number {
        background-color: var(--black2F);
      }
    }
  }

  .benefit_card-number {
    color: var(--white);
    font-size: 18px;
    font-weight: 700;
    line-height: calc(28 / 18) * 100%;
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    counter-increment: num;

    &::before {
      content: "0"counter(num)
    }
  }

  .benefit_card-title {
    @include adaptive-value("font-size", 18, 14, 1);
    line-height: calc(28 / 18) * 100%;
    font-weight: 700;
    color: var(--black2F);
  }

  .benefit_card-text {
    font-size: 14px;
    line-height: calc(20 / 14) * 100%;
    color: var(--grey);
  }

  .benefit_tab_pane {
    opacity: 0;
    visibility: hidden;
    height: 0;

    .benefit_card {
      opacity: 0;
      transition: all .3s ease 0s;
    }
  }

  .active-pane {
    display: block;
    height: auto;
    visibility: visible;
    opacity: 1;
    transition: all .3s ease 0s;

    .benefit_card {
      opacity: 1;
      transition: all 1s ease 0s;

      &:nth-child(1) {
        transition-delay: .1s;
      }

      &:nth-child(2) {
        transition-delay: .4s;
      }

      &:nth-child(3) {
        transition-delay: .8s;
      }

      &:nth-child(4) {
        transition-delay: 1.2s;
      }
    }
  }
}

@media (max-width:768px) {
  .benefit {
    padding: 50px 0;
  }
}
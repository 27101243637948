.tabs {
    .tabs__head-row {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 40px;
        margin-bottom: 30px;
    }

    @media (max-width:#{$sm-and-sm}) {
        .tabs__head-row {
            column-gap: 25px;
        }
    }


    @media (max-width: #{$xs-and-sm}) {
        .tabs__head-row {
            white-space: nowrap;
            overflow: scroll;
            justify-content: space-between;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .tabs__head-col{
        width: 100%;
    }

    .tabs__btn {
        padding: 13px 5px;
        color: var(--grey);
        line-height: calc(26 / 18) * 100%;
        border-bottom: 1px solid var(--grey);
        transition: all .3s ease 0s;
        opacity: 0.5;
        width: 100%;

        &.active {
            color: var(--blue);
            border-color: var(--blue);
            transition: all .3s ease 0s;
            opacity: 1;
        }
    }

    .tabs__item {
        opacity: 0;
        display: none;

        &.active {
          opacity: 1;
          display: flex;
        }
    }
}
.dial-main {
    background-color: var(--whiteF8);
    max-width: 1400px;
    width: calc(100% - 20px);
    margin: 0 auto;
    border-radius: 5px 30px;
    padding-top: 30px;
    @include adaptive-value("padding-bottom", 60, 10, 1);
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        @include adaptive-value("top", -1100, 300, 1);
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);

        @include sm {
            width: 638px;
            top: -100px;
            left: -50%;
            transform: translateX(-20%);
        }
    }

    .container {
        padding: 0 10px;
        position: relative;
        z-index: 2;
    }

    // .dial-main_content

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        @include xs {
            gap: 50px;
        }
    }

    // .dial-main_text-block

    &_text-block {
        max-width: 495px;
        width: 100%;
    }

    // .dial-main_title

    &_title {
        @include adaptive-value("font-size", 40, 28, 1);
        color: var(--blue);
        font-weight: 700;
        @include adaptive-value("margin-bottom", 40, 20, 1);
    }

    // .dial-main_text

    &_text {
        @include adaptive-value("font-size", 18, 16, 1);
        line-height: 144%;
        color: var(--grey);
        @include adaptive-value("margin-bottom", 60, 30, 1);
    }

    // .dial-main_video-block

    &_video-block {
        max-width: 600px;
        width: 100%;

    }

    // .dial-main_video

    &_video {
        border-radius: 5px 30px;
        overflow: hidden;
        position: relative;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        &.disabled {
          pointer-events: none;
          filter: grayscale(0.5);
        }
        .dial_video {
            width: 100%;
            height: auto;
        }
    }

    @media (max-width: #{$md-and-sm}) {
        &_content {
            flex-direction: column;
        }

        &_text-block {
            max-width: 100%;
        }
    }

    .btn {
        @include xs {
            width: 100%;
        }
    }
}
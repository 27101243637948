.blog {
    padding-top: 30px;
    @include adaptive-value("padding-bottom", 60, 10, 1);
    background-color: var(--whiteF8);
    max-width: 1400px;
    width: calc(100% - 20px);
    margin: 0 auto;
    border-radius: 5px 30px;

    .container {
        padding: 0 10px;
    }

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }

    // .blog_text-wrap

    &_text-wrap {
        max-width: 460px;
        width: 100%;
    }

    // .blog_title

    &_title {
        color: var(--blue);
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        margin-bottom: 40px;
    }

    // .blog_descr

    &_descr {
        color: var(--grey);
        font-size: 18px;
        font-weight: 400;
        line-height: 144%;
    }


    @media (max-width: #{$lg-and-sm}) {
        &_descr {
            font-size: 16px;
        }
    }

    @media (max-width: #{$md-and-sm}) {
        &_content {
            flex-direction: column;
        }

        &_text-wrap {
            max-width: 100%;
        }
    }


}
.voice-familiar {
    @include adaptive-value("padding-top", 0, 0, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);

    // .voice-familiar_board

    &_board {
        border-radius: 5px 30px;
        background-color: var(--blue);
        @include adaptive-value("padding-top", 60, 40, 1);
        @include adaptive-value("padding-bottom", 60, 20, 1);
        @include adaptive-value("padding-right", 60, 20, 1);
        @include adaptive-value("padding-left", 60, 20, 1);
        text-align: center
    }

    // .voice-familiar_board-title

    &_board-title {
        @include adaptive-value("font-size", 28, 22, 1);
        color: var(--white);
        max-width: 590px;
        width: 100%;
        margin: 0 auto 30px auto;
        line-height: 145%;

        @include xs {
            text-align: start
        }
    }

    // .voice-familiar_board-text

    &_board-text {
        @include adaptive-value("font-size", 18, 16, 1);
        color: var(--white);
        margin: 0 auto 50px auto;
        line-height: 150%;

        @include xs {
            text-align: start
        }
    }

    // .voice-familiar_board-columns

    &_board-columns {
        display: grid;
        grid-template: 1fr / 230px 1px 230px 1px 230px;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40px;
        margin-bottom: 50px;

        @include md {
            grid-template: 1fr / 1fr;
        }
    }

    // .voice-familiar_board-column

    &_board-column {
        text-align: start;
        width: 100%;

        .icon {
            @include adaptive-value("margin-bottom", 40, 30, 1);
            @include adaptive-value("width", 48, 36, 1);
            @include adaptive-value("height", 48, 36, 1);
            color: var(--white);
        }
    }

    .column-title {
        @include adaptive-value("font-size", 22, 20, 1);
        color: var(--white);
        margin-bottom: 20px;
        line-height: 150%;
    }

    .column-text {
        color: var(--white);
        font-size: 14px;
        line-height: 142%;
    }

    .divider {
        width: 1px;
        height: 100%;
        background-color: var(--white);
        opacity: 0.25;

        @include md {
            width: 100%;
            height: 1px;
        }
    }

    .btn {
        @include xs {
            width: 100%;
        }
    }
}
.reviews_second {
    padding: 90px 0;

    .container {
        padding: 0;
    }

    .reviews_title {
        margin: 0 0 auto 0;
    }


    @media (max-width: 1024px) {
        .reviews_title {
            margin-bottom: 30px;
        }
    }


    .reviews_second_content {
        display: grid;
        grid-template: 1fr /1fr 1fr;
        gap: 50px;
    }

    @media (max-width: #{$lg-and-sm}) {
        .reviews_second_content {
            gap: 25px;
        }
    }

    @media (max-width: 1024px) {
        .reviews_second_content {
            grid-template: 1fr /1fr;
            gap: 40px;
        }
    }

    .reviews_second-content_wrap {
        max-width: 530px;
        width: 100%;
        padding: 0 15px;
        display: flex;
        flex-direction: column;

    }

    @media (max-width: 1024px) {
        .reviews_second-content_wrap {
            max-width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .reviews_title {
        text-align: start;
    }


    @media (max-width: 1024px) {
        .reviews_title {
            text-align: center;
        }
    }

    .reviews_second-text {
        color: var(--grey);
        font-size: 18px;
        line-height: calc(26 / 18) * 100%;
        margin-bottom: 25px;
        max-width: 460px;
        width: 100%;
    }

    .reviews_items-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    @media (max-width: 1024px) {
        .reviews_items-wrap {
            justify-content: flex-start;
        }
    }

    @media (max-width: #{$xs-and-sm}) {
        .reviews_items-wrap {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .reviews_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        background-color: var(--white);
        border-radius: 5px 30px;
        filter: drop-shadow(0 8px 10px rgba(0, 0, 0, 0.1));
        padding: 25px 18px;
        height: 160px;
    }


    @media (max-width: 1024px) {
        .reviews_item {
            padding: 25px 15px;
        }
    }

    .current-score {
        color: var(--black2F);
        font-weight: 700;
        font-size: 20px;
    }

    .total-score {
        color: var(--grey);
    }

    .reviews_second_swiper {
        max-width: 600px;
        width: 100%;
        max-height: max-content;
        padding: 0 20px;

        .swiper-wrapper {
            height: 440px;
            margin-bottom: 20px;
        }

        .swiper-slide {
            min-height: 100%;
            height: auto;
        }
    }

    @media (max-width: 1024px) {
        .reviews_second_swiper {
            max-width: 100%;
            padding: 15px;
        }
    }

    .reviews_slide {
        background-color: var(--white);
        border-radius: 5px 30px;
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        max-height: 440px;
        height: 100%;
        padding: 40px;
    }

    @media (max-width: 1024px) {
        .reviews_slide {
            padding: 25px;
        }
    }


    .reviews_slide-img {
        height: 160px;
        width: 100%;
        background-color: var(--whiteF8);
        border-radius: 5px 30px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;

        .__image {
            width: 100%;
            height: 100%;
            padding: 10px;
        }

        .__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .reviews_slide-text {
        height: 200px;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}



.slide_text {
    font-size: 14px;
    line-height: calc(20 / 14) * 100%;
    color: var(--grey);
}

.reviews_second-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 1024px) {
    .reviews_second-nav {
        justify-content: center;
    }
}


@media (max-width: #{$sm-and-sm}) {
    .reviews_second {
        padding: 40px 0;
    }
}
.voice-works {
    background-color: var(--whiteF8);
    @include adaptive-value("padding-top", 72, 60, 1);
    @include adaptive-value("padding-bottom", 72, 60, 1);
    // .voice-works_content

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include md {
            flex-direction: column;
        }
    }

    // .voice-works_left-side

    &_left-side {
        max-width: 400px;
        min-width: 330px;
        width: 100%;

        @include md {
            display: none;
        }

        .voice-works_card {
            &:nth-child(1) {
                .icon-wrap {
                    border-right: 1px solid var(--blue);
                }

                .icon {
                    color: var(--blue);
                }
            }

            &:nth-child(2) {

                .icon-wrap {
                    border-right: 1px solid var(--yellow);
                }

                .icon {
                    color: var(--yellow);
                }
            }
        }


    }

    // .voice-works_card

    &_card {
        background-color: var(--white);
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px 25px;
        padding: 20px;
        display: grid;
        grid-template: 1fr / 56px 1fr;
        align-items: flex-start;
        gap: 20px;
        min-height: 150px;
        max-width: 400px;

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        .icon {
            width: 36px;
            height: 36px;
        }
    }

    // .voice-works_center-side

    &_center-side {
        position: relative;
        max-width: 340px;
        width: 100%;
        text-align: center;

        @include md {
            max-width: 100%;
        }
    }

    .robot__image {
        display: inline-block;
        @include adaptive-value("width", 216, 160, 1);
        height: auto;
        position: relative;
        z-index: 1;
        box-shadow: 0 10.7px 26.75px rgba(0, 0, 0, 0.1);
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .line {
        position: absolute;

        @include md {
            display: none;
        }
    }

    .top_left {
        top: 30px;
        right: 0;
    }

    .top_right {
        top: 30px;
        left: 0;
    }

    .bottom_left {
        bottom: 30px;
        left: 0;
    }

    .bottom_right {
        bottom: 30px;
        right: 0;
    }

    .lines {
        display: none;
        margin: 0 auto;

        @include md {
            display: block;
            width: 75%;
        }
    }

    // .voice-works_right-side

    &_right-side {
        max-width: 400px;
        min-width: 330px;
        width: 100%;

        @include md {
            display: none;
        }

        .voice-works_card {
            &:nth-child(1) {
                .icon-wrap {
                    border-right: 1px solid var(--green);
                }

                .icon {
                    color: var(--green);
                }
            }

            &:nth-child(2) {
                .icon-wrap {
                    border-right: 1px solid var(--black2F);
                }

                .icon {
                    color: var(--black2F);
                }
            }
        }
    }

    .icon-wrap {
        height: 100%;
        padding-right: 20px;
    }

    .card {

        // .card_title

        &_title {
            font-weight: 700;
            color: var(--black2F);
            font-size: 14px;
            margin-bottom: 20px;
            line-height: calc(20 / 14)
        }

        // .card_text

        &_text {
            color: var(--grey);
            font-size: 14px;
            line-height: calc(20 / 14)
        }
    }

    .voice_tabs {
        border-radius: 25px;
        background-color: var(--white);
        width: 100%;
        overflow: hidden;
        display: none;

        @include md {
            display: block;
        }

        .tabs__head-row {
            gap: 0;
            margin: 0;
            padding-bottom: 2px;
            position: relative;
            background-color: var(--whiteF8);

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: var(--grey);
                opacity: 0.25;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 52px;
                height: 2px;
                background-color: var(--after-color, var(--blue));
                transition: left 0.3s ease, background-color 0.3s ease;
            }

        }

        .tabs__head-col {

            &:nth-child(1) {
                .tabs__btn {
                    &.active {
                        color: var(--blue);
                    }
                }
            }

            &:nth-child(2) {
                .tabs__btn {
                    &.active {
                        color: var(--yellow);
                    }

                }
            }

            &:nth-child(3) {
                .tabs__btn {
                    &.active {
                        color: var(--green);
                    }

                }
            }

            &:nth-child(4) {
                .tabs__btn {
                    &.active {
                        color: var(--black2F);
                    }
                }
            }
        }

        .tabs__btn {
            padding: 11px 26px;
            border: none;
            background-color: var(--whiteF8);

            &.active {
                background-color: var(--white);

                .icon {
                    opacity: 1;
                }

                &:nth-child(1) {
                    color: var(--blue);
                }

                &:nth-child(2) {
                    color: var(--yellow);
                }

                &:nth-child(3) {
                    color: var(--green);
                }

                &:nth-child(4) {
                    color: var(--black2F);
                }
            }
        }

        .icon {
            width: 28px;
            height: 28px;
            opacity: 0.15;
        }

        .tabs__body {
            padding: 25px;
        }
    }

}
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;

  padding: 0;
  margin: 0;
}

html {
  font-family: var(--font-primary);
  font-size: var(--font-size);
  font-weight: normal;
  line-height: 1.3;
  color: var(--text-color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue);
  }
}

body {
  padding-right: var(--current-scrollbar-width);
  background-color: var(--bg-primary);

  &.lock {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;

  list-style-type: none;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  padding: 0;

  cursor: pointer;

  background-color: initial;
  border: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

video {
  width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  font: inherit;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

fieldset {
  min-inline-size: min-content;
  padding: 0;
  margin: 0;

  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-image: none;
}

// audio::-webkit-media-controls-panel {}
// audio::-webkit-media-controls-mute-button {}
// audio::-webkit-media-controls-play-button {}
// audio::-webkit-media-controls-timeline-container {}
// audio::-webkit-media-controls-current-time-display {}
// audio::-webkit-media-controls-time-remaining-display {}
// audio::-webkit-media-controls-timeline {}
// audio::-webkit-media-controls-volume-slider-container {}
// audio::-webkit-media-controls-volume-slider {}
// audio::-webkit-media-controls-seek-back-button {}
// audio::-webkit-media-controls-seek-forward-button {}
// audio::-webkit-media-controls-fullscreen-button {}
// audio::-webkit-media-controls-rewind-button {}
// audio::-webkit-media-controls-return-to-realtime-button {}
// audio::-webkit-media-controls-toggle-closed-captions-button {}

.wrapper {
  position: relative;

  display: flex;

  flex-direction: column;

  max-width: 100vw;
  min-height: 100vh;
}

.content {
  position: relative;
  z-index: 500;

  flex: 1 0 auto;

  overflow: hidden;
}

.container {
  max-width: px(1180);
  padding-right: 20px;
  padding-left: 20px;
  margin: 0 auto;
}

.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}

.important_none {
  display: none;
}

.swiper-button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.arrow_wrap-mob {
  display: none;

  @include xs {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-color: var(--blue);
  margin-top: 0;
  transition: all .3s ease 0s;

  &:hover {
    background-color: var(--hover-blue);
    transition: all .3s ease 0s;
  }

  &::after {
    display: none;
  }
}

.swiper-button-prev .button__image {
  width: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next .button__image {
  width: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination {
  width: max-content !important;
  position: static;
}

.swiper-pagination-bullet {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  transition: all .3s ease 0s;

  &:hover {
    background-color: var(--blue);
    transition: all .3s ease 0s;
    opacity: 1;

  }
}

.swiper-pagination-bullet-active {
  width: 24px;
  border-radius: 50px;
  transition: all .3s ease 0s;
  background-color: var(--blue)
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  overflow: hidden;
}

.section_bg_gray {
  background-color: var(--whiteF8);
}

.dial_tariff {
  background-color: var(--whiteF8);

  @include xs {
    padding-bottom: 60px;
  }
}

.swiper-pagination {

  @include xs {
    display: none;
  }
}

.drop-shadow {
  display: none;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;

  &.show {
    display: block;
  }
}

.title_blue_text {
  b, strong {
    color: var(--blue);
  }
}
.article-similar {

    .container {
        padding: 0
    }

    &_title {
        @include adaptive-value("font-size", 28, 22, 1);
        color: var(--black2F);
        font-weight: 700;
        margin-bottom: 30px;
        padding: 0 20px;
    }

    .info_body {
        background-color: var(--white);
        padding: 10px;
        border-radius: 5px;
        position: absolute;
        z-index: 2;
        top: 10px;
        left: 10px;
    }

    .blog_card {
        box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
        max-width: 100%;
    }

    .card_text {
        padding: 25px;
    }

    .card_title {
        color: var(--blue);
        -webkit-line-clamp: 5;
        margin: 0;
    }

    .blog__image {
        @include xs {
            height: 220px;
        }
    }

    .article_swiper {
        padding: 0 20px 20px 20px;
        @include adaptive-value("padding-bottom", 20, 25, 1);
    }

    .swiper-button-wrap {
        display: none;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin-top: 20px;

        @media (max-width:1024px) {
            display: flex;

            .swiper-button-prev,
            .swiper-button-next {
                display: flex;
            }
        }
    }

    .btn {
        display: none;

        @include sm {
            display: block;
            height: 40px;
            width: max-content;
            padding: 10px 20px;
            margin: 30px auto 0 auto;
            font-size: 14px;
        }
    }
}
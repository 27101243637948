.faq {
    padding: 100px 0;
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);
    background-color: var(--whiteF8);
    text-align: center;

    .faq_title {
        max-width: 726px;
        width: 100%;
        margin: 0 auto 50px auto;

        @include xs {
            text-align: start;

            br {
                display: none;
            }
        }
    }

    @media (max-width: #{$sm-and-sm}) {
        .faq_title {
            margin-bottom: 30px;
        }
    }

    .faq_wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 50px;

        @include sm {
            margin-bottom: 30px;
        }

        @include xs {
            gap: 10px;
        }
    }

    .accordion__toggler {
        @include xs {
            padding: 20px 15px;
        }
    }

    .accordion__content {
        padding: 5px 25px 0 25px;

        @include xs {
            padding: 20px 15px 0 15px;
        }
    }

    .item_title {
        @include xs {
            br {
                display: none;
            }
        }
    }

    .item_descr {
        font-size: 14px;
        color: var(--white);
        line-height: calc((20 / 14) * 100%);
    }

    .question_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        flex-wrap: wrap;

        @include xs {
            flex-direction: column;
        }
    }

    .question_text {
        @include adaptive-value("font-size", 22, 20, 1);
        font-weight: 500;
        color: var(--grey);
    }

    .question_link {
        color: var(--blue);
        text-decoration: underline;
        transition: all .3s ease 0s;

        &:hover {
            color: var(--hover-blue);
            transition: all .3s ease 0s;
        }
    }
}

.faq_home {
    .item_title {
        max-width: 430px;
    }
}

.faq_contact_center_ua {
    .accordion__item {
        &:last-child {
            b {
                text-decoration: underline;
            }
        }
    }
}
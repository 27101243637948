.proposal {
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);
    background-color: var(--blue);
    text-align: center;

    @include sm {
        padding: 60px 0;
    }

    .proposal_content {
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
    }

    .proposal_title {
        color: var(--white);
        font-weight: 700;
        @include adaptive-value("font-size", 28, 22, 1);
        line-height: calc(36 / 28) * 100%;
        @include adaptive-value("margin-bottom", 50, 40, 1);

        @include xs {
            text-align: start;
            max-width: 305px;
        }
    }

    .proposal_btn {
        width: 316px;
        padding: 23.5px 0;
    }

    .proposal_popup_content {
        display: grid;
        grid-template-columns: 49% 49%;
        grid-template-rows: 1fr;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        max-width: 945px;
        width: 100%;
        margin: 0 auto;
    }

    @media (max-width: 1024px) {
        .proposal_popup_content {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .popup_form-wrap {
        text-align: start;
        max-width: 465px;
        width: 100%;
    }

    @media (max-width: 1024px) {
        .popup_form-wrap {
            max-width: 650px;
            margin: 0 auto;
        }
    }

    .popup_form-wrap {
        form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .input-wrap {
        width: 100%;
        position: relative;
        margin-bottom: 25px;
    }

    .form_input {
        margin: 0;
    }

    .error-message {
        color: var(--white);
    }

    .manager {
        max-width: 440px;
        width: 100%;
        border-radius: 5px 30px;
        overflow: hidden;
        border: 1px solid var(--white);
        height: 100%;
    }

    .info_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-height: 85px;
        padding: 25px;

        @include xs {
            text-align: start;
        }
    }

    .manager__image {
        display: inline-block;
        border-radius: 5px 30px;
        width: 100%;
        max-height: 425px;
        height: 100%;
    }

    .manager__img {
        width: 100%;
        height: 100%;
    }

    .name {
        color: var(--white);
        @include adaptive-value("font-size", 22, 20, 1);
        font-weight: 700;
        line-height: calc(28 / 22) * 100%;
    }

    .role {
        color: var(--white);
        font-size: 14px;
    }

    &_popup-container {
        position: fixed;
        visibility: hidden;
        inset: 0;
        z-index: 4;
        opacity: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        transition: all .3s ease 0s;
    }

    .showProposalContainer {
        visibility: visible;
        opacity: 1;
        transition: all .3s ease 0s;
    }

    .iti__selected-dial-code {
        color: var(--white);
    }

    .close_popUp{
        display: none;
    }

}

.proposal_modal {
    padding: 60px 0;

    .modal_proposal {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 10;
        translate: -50% -50%;
        max-width: 1200px;
        width: calc(100% - 40px);
        scale: 0;
        opacity: 0;
        padding: 100px 40px;
        background-color: var(--blue);
        outline: none;
        border: none;
        border-radius: 8px;
        transition: all .3s ease 0s;

        @include sm {
            padding: 50px 15px 25px 15px;
        }

        @media (max-width:1024px) {
            height: calc(100% - 60px);
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .openProposalPopUp {
        scale: 1;
        opacity: 1;
        transition: all .3s ease 0s;
    }

    .close_popUp {
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 15px;
        right: 15px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            width: 100%;
            height: 1px;
            background-color: var(--white);
        }

        &::before {
            rotate: 45deg;
        }

        &::after {
            rotate: -45deg;
        }
    }

    .proposal_title {
        @include xs {
            text-align: center;
            margin: 0 auto 40px auto;
        }
    }

    .form_title,
    .tel_label {
        @include xs {
            text-align: start;
        }
    }
}
/* Touch screens with fingers */
@mixin touch() {
  @media (hover: hover) and (pointer: coarse) {
    @content;
  }
}

/* Mouse touch pad */
@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

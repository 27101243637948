.articles {
  @include adaptive-value("padding-top", 50, 25, 1);
  @include adaptive-value("padding-bottom", 50, 25, 1);

  .container {
    padding: 0;
  }

  // .articles_header

  &_header {
    position: relative;
    @include adaptive-value("margin-bottom", 50, 30, 1);
  }

  // .articles_nav

  &_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 0 15px;
  }

  @media (max-width: 658px) {

    &_nav {
      padding: 0;
    }
  }

  // .articles_button-wrap

  &_button-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: scroll;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_button-wrap.terms_nav {
    flex-wrap: wrap;
  }

  @media (max-width: 658px) {
    &_button-wrap {
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }

    &_button-wrap.terms_nav {
      .articles_button {
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  // .articles_button

  &_button {
    color: var(--blue);
    text-align: center;
    font-size: 13px;
    min-width: 110px;
    height: 33px;
    padding: 0 15px;
    border-radius: 50px;
    background: rgba(206, 227, 236, 0.50);
    transition: all .3s ease 0s;
    white-space: nowrap;

    @include sm {
      min-width: auto;
      width: calc(50% - 5px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: var(--hover-blue);
      color: var(--white);
    }

    &.active_btn {
      background-color: var(--blue);
      color: var(--white);
      transition: all .3s ease 0s;
    }
  }

  @media (max-width: 658px) {

    &_button {
      &:first-child {
        //margin-left: 15px;
      }

      &:last-child {
        margin-right: 15px;
      }
    }
  }

  &_search-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 50px;
    border: 2px solid var(--blue);
    background: var(--white);
    padding: 10px 15px;
    transition: all .3s ease 0s;
    @media (max-width: 658px) {
      width: 100%;
      justify-content: center;
    }


    &.activeSearchBtn {
      background-color: var(--blue);
      transition: all .3s ease 0s;

      .articles_search-text,
      .icon-search {
        color: var(--white);
        transition: all .3s ease 0s;
      }
    }
  }

  @media (hover: hover) {
    &_search-btn {

      &:hover {
        background-color: var(--blue);
        transition: all .3s ease 0s;

        .articles_search-text,
        .icon-search {
          color: var(--white);
          transition: all .3s ease 0s;
        }
      }
    }

  }

  @media (hover: none) {
    &_search-btn {

      &:active {
        background-color: var(--blue);

        .articles_search-text,
        .icon-search {
          color: var(--white);
        }
      }
    }

  }

  // .articles_search-text

  &_search-text {
    color: var(--blue);
    text-align: center;
    font-size: 13px;
  }

  // .articles_search-input-wrap

  &_search-input-wrap {
    position: absolute;
    z-index: 2;
    right: 50%;
    transform: translateX(50%);
    @include adaptive-value("bottom", -160, -80, 1);
    border-radius: 30px;
    background-color: var(--white);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
    @include adaptive-value("padding-top", 40, 10, 1);
    @include adaptive-value("padding-bottom", 40, 10, 1);
    @include adaptive-value("padding-right", 40, 10, 1);
    @include adaptive-value("padding-left", 40, 10, 1);
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease 0s;
    pointer-events: none;

    &.openInput {
      width: calc(100% - 30px);
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transition: all .3s ease 0s;
    }
  }

  // .articles_search-input-body

  &_search-input-body {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: var(--whiteF8);
    backdrop-filter: blur(7.5px);
    @include adaptive-value("padding-top", 20, 10, 1);
    @include adaptive-value("padding-bottom", 20, 10, 1);
    @include adaptive-value("padding-right", 20, 10, 1);
    @include adaptive-value("padding-left", 20, 10, 1);
    border-radius: 500px;
    border: 1px solid transparent;
    transition: all .3s ease 0s;

    &:focus-within {
      border-color: var(--blue);
      transition: all .3s ease 0s;
    }
  }

  &_search-wrap {
    @media (max-width: 658px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  // .articles_search-input

  &_search-input {
    width: 100%;
    border: none;
    background-color: transparent;
    color: var(--black2F);
    font-size: 14px;
    outline: none;

    &::placeholder {
      color: var(--grey);
    }

    &::-webkit-search-cancel-button {
      cursor: pointer;
    }
  }

  &_content {
    padding: 0 15px;
  }

  &_content-category-name {
    color: var(--black2F);
    @include adaptive-value("font-size", 28, 22, 1);
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 30px;
    text-align: start;
    @include xs {
      font-size: 28px;
    }
  }

  &_search-result {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    @include adaptive-value("font-size", 22, 18, 1);

    span {
      color: var(--black2F);
    }


  }

  &_search-feild {
    display: flex;
    align-items: center;
    gap: 15px;

    strong {
      font-weight: 700;
    }

    .clear_btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      box-shadow: 0px 8px 25px 0px #0000001A;
      margin-left: 10px;
      transition: all .3s ease 0s;

      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 2px;
        background-color: var(--grey);
        transition: all .3s ease 0s;
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    @media (hover:hover) {
      .clear_btn {
        &:hover {
          background-color: var(--blue);
          transition: all .3s ease 0s;

          &::after,
          &::before {
            background-color: var(--white);
            transition: all .3s ease 0s;
          }
        }
      }
    }
  }

  .highlight {
    color: var(--blue);
  }

  &_content-wrap {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .big_card {
      min-height: 100%;

      .blog__image {
        height: 332px;
        @include adaptive-value("height", 332, 230, 1);
      }
    }
  }

  &_all-category {
    display: flex;
    flex-direction: column;
    @include adaptive-value("row-gap", 100, 40, 1);
  }

  &_selected-category {
    text-align: center;
  }

  .btn {
    font-weight: 400;
  }

  &_content-selected {
    display: grid;
    grid-template: 1fr / repeat(auto-fit, minmax(330px, 1fr));
    gap: 30px 20px;
    @include adaptive-value("margin-bottom", 50, 30, 1);

    .category_card {
      display: flex;
      flex-direction: column;
      height: auto;
      opacity: 1;
      visibility: visible;
      transition: all .3s ease 0s;

      &.hidden {
        display: none;
        opacity: 0;
        visibility: hidden;
        height: 0;
        transition: all .3s ease 0s;
      }
    }

    .blog__image {
      height: 200px
    }

    .card_title {
      @include adaptive-value("font-size", 18, 16, 1);
      margin-bottom: 15px;
      text-align: start;
      padding: 35px 25px 0 25px;
      flex: 1 0 auto;
    }

    .card_bottom {
      padding: 0 25px 25px 25px;
    }
  }

  &_content-small-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 512px;
    width: 100%;
  }


  @media (max-width: #{$md-and-sm}) {
    &_nav {
      flex-direction: column;
    }

    &_content-wrap {
      flex-direction: column;

      .big_card {
        max-width: 100%;
      }
    }

    &_content-small-cards {
      max-width: 100%;

      .blog__image {
        min-width: 170px;
      }

      .card_text {
        max-width: 100%;
      }

      .blog_card {
        max-width: 100%;
      }
    }

  }
}

.icon-search {
  width: 20px;
  height: 20px;
  color: var(--blue);
  transition: all .3s ease 0s;
}
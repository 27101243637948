.works {
    background-color: var(--whiteF8);
    @include adaptive-value("padding-top", 100, 60, 1);
    @include adaptive-value("padding-bottom", 100, 60, 1);


    &__row {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        @include lg {
            flex-direction: column;
            gap: 50px;
        }
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 273px;
        height: 273px;
        border-radius: 50%;
        background-color: var(--blue);
        position: relative;
        z-index: 10;

        @include md {
            flex-direction: column;
            background-color: transparent;
            width: auto;
            height: auto;
        }
    }

    &__circle-title {
        @include adaptive-value("font-size", 22, 20, 1);
        color: var(--white);

        @include md {
            margin: 0 auto 20px auto;
        }
    }

    &__left,
    &__right {
        display: flex;
        align-items: center;

        @include md {
            border-radius: 5px 30px;
            background-color: var(--blue);
            padding: 25px 15px;
        }
    }

    &__left {
        position: relative;
        right: -20px;

        .icon-long_arrow {
            left: -30px;

            @include lg {
                display: none;
            }

            @include md {
                display: block;
                left: 50%;
                transform: translateY(50%);
                bottom: -40px;
                rotate: 90deg;
                z-index: 2;
            }
        }

        @include lg {
            right: 0;
        }
    }

    .tip_wrap {
        position: absolute;
        top: -13%;
        right: -8%;
        display: flex;
        align-items: center;
        gap: 8px;

        .icon-tip_arrow {
            width: 24px;
            height: 15px;
            color: var(--grey);
        }

        .tip_text {
            display: inline-block;
            font-size: 13px;
            color: var(--grey);
            max-width: 150px;
        }

        @include lg {
            top: -15%;
            right: -55%;
        }

        @include md {
            display: none;
        }
    }

    &__icon-body-left {
        position: absolute;

        @include md {
            position: static;
            display: flex;
            flex-direction: column;
            row-gap: 25px;
        }

        @include xs {
            row-gap: 10px;
        }

        .works__icon-wrap {

            &:nth-child(1) {
                transform: rotate(15deg) translateY(-155px) rotate(-15deg);

                .icon {
                    color: var(--blue);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--blue)
                    }
                }
            }

            &:nth-child(2) {
                transform: rotate(48deg) translateY(-120px) rotate(-48deg);

                .icon {
                    color: var(--yellow);
                }


                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--yellow)
                    }
                }
            }

            &:nth-child(3) {
                transform: rotate(94deg) translateY(-89px) rotate(-94deg);

                .icon {
                    color: var(--green);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--green)
                    }
                }
            }

            &:nth-child(4) {
                transform: rotate(153deg) translateY(-85px) rotate(-153deg);

                .icon {
                    color: var(--black2F);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--black2F)
                    }
                }
            }

            @media (max-width: #{$lg-and-sm}) {
                &:nth-child(1) {
                    transform: rotate(15deg) translateY(-155px) rotate(-15deg);
                }

                &:nth-child(2) {
                    transform: rotate(48deg) translateY(-120px) rotate(-48deg);
                }

                &:nth-child(3) {
                    transform: rotate(94deg) translateY(-89px) rotate(-94deg);
                }

                &:nth-child(4) {
                    transform: rotate(153deg) translateY(-85px) rotate(-153deg);
                }
            }

            @media (max-width: #{$md-and-sm}) {

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    transform: none;
                }
            }
        }
    }

    &__right {
        flex-direction: row-reverse;
        position: relative;
        left: -20px;

        .icon-long_arrow {
            rotate: 180deg;
            right: -30px;

            @include lg {
                display: none;
            }

            @include md {
                display: block;
                left: 50%;
                transform: translateY(-50%);
                top: -40px;
                rotate: -90deg;
                z-index: 1;
            }
        }

        @include lg {
            left: 0;
        }
    }

    &__icon-body-right {
        position: absolute;

        @include md {
            position: static;
            display: flex;
            flex-direction: column;
            row-gap: 25px;
        }

        @include xs{
            row-gap: 10px;
        }

        .works__icon-wrap {

            &:nth-child(1) {
                transform: rotate(349deg) translateY(-180px) rotate(-349deg);


                .icon {
                    color: var(--black2F);
                }


                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--black2F)
                    }
                }
            }

            &:nth-child(2) {
                transform: rotate(-38deg) translateY(-190px) rotate(38deg);

                .icon {
                    color: var(--blue);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--blue)
                    }
                }

            }

            &:nth-child(3) {
                transform: rotate(-65deg) translateY(-190px) rotate(65deg);

                .icon {
                    color: var(--yellow);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--yellow)
                    }
                }

            }

            &:nth-child(4) {
                transform: rotate(-92deg) translateY(-172px) rotate(93deg);

                .icon {
                    color: var(--green);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--green)
                    }
                }

            }

            &:nth-child(5) {
                transform: rotate(-122deg) translateY(-140px) rotate(123deg);

                .icon {
                    color: var(--black2F);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--black2F)
                    }
                }

            }

            &:nth-child(6) {
                transform: rotate(-160deg) translateY(-100px) rotate(160deg);

                .icon {
                    color: var(--blue);
                }

                @include md {
                    .icon_body {
                        padding-right: 15px;
                        border-right: 1px solid var(--blue)
                    }
                }

            }

            @media (max-width: #{$lg-and-sm}) {
                &:nth-child(1) {
                    transform: rotate(349deg) translateY(-180px) rotate(-349deg);
                }

                &:nth-child(2) {
                    transform: rotate(-38deg) translateY(-190px) rotate(38deg);
                }

                &:nth-child(3) {
                    transform: rotate(-65deg) translateY(-190px) rotate(65deg);
                }

                &:nth-child(4) {
                    transform: rotate(-92deg) translateY(-172px) rotate(92deg);
                }

                &:nth-child(5) {
                    transform: rotate(-122deg) translateY(-140px) rotate(122deg);
                }

                &:nth-child(6) {
                    transform: rotate(-160deg) translateY(-100px) rotate(160deg);
                }
            }

            @media (max-width: #{$md-and-sm}) {

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    transform: none;
                }
            }
        }
    }


    .works__icon-wrap {
        background-color: var(--white);
        width: 75px;
        height: 75px;
        border-radius: 50%;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        transform: translate(-50%, -50%);
        transform-origin: center;

        .tooltip {
            transition: opacity .3s ease 0s, transform .3s ease 0s;
            position: absolute;
            top: -80%;
            left: 50%;
            z-index: 3;
            background-color: var(--white);
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 24px;
            opacity: 0;
            visibility: hidden;
            height: 0;
            transform: translate(-50%, -100%);

            .icon-tooltip_arrow {
                width: 25px;
                height: 19px;
                position: absolute;
                bottom: -13px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                color: var(--white);
                z-index: -1;

                @include md {
                    display: none;
                }
            }

            @include md {
                position: static;
                opacity: 1;
                visibility: visible;
                height: auto;
                transform: none;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                border-radius: 0;
            }
        }

        .tooltip_text {
            white-space: nowrap;
            font-size: 14px;
            color: var(--black2F);

            br {
                display: none;
            }

            @include sm {
                white-space: normal;

                br {
                    display: block;
                }
            }
        }

        .icon {
            width: 32px;
            height: 32px;
        }

        @media (min-width: #{$md-and-sm}) {
            &:hover {
                .tooltip {
                    transition: opacity .3s ease 0s, transform .3s ease 0s;
                    opacity: 1;
                    visibility: visible;
                    height: auto;
                    transform: translate(-50%, 0);
                }
            }
        }

        @include md {
            position: static;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 15px;
            background-color: var(--white);
            border-radius: 5px 25px;
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
            width: auto;
            height: auto;
            padding: 20px;
        }
    }

    .icon-long_arrow {
        color: var(--blue);
        width: 130px;
        position: relative;

        @include lg {
            position: absolute;
        }
    }

    &__center {
        min-width: 370px;

        @include lg {
            min-width: 100%;
            text-align: center;
        }
    }

    .works-logo__image {
        display: inline-block;
        margin-bottom: 18px;
        width: 366px;
        height: auto;

        @include lg {
            max-width: 366px;
            width: 100%;
            margin: 0 auto 25px auto;
        }

        .works-logo__img {
            width: 100%;
            height: auto;
        }
    }

    &__center-text {
        @include adaptive-value("font-size", 22, 16, 1);
        color: var(--grey);
    }

}
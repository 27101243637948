.article-nav {
    padding-top: 30px;
    @include adaptive-value("padding-bottom", 100, 40, 1);

    // .article-nav_categories

    &_categories {
        @include adaptive-value("margin-bottom", 100, 40, 1);
    }

    // .article-nav_categories-title

    &_categories-title {
        @include adaptive-value("font-size", 28, 22, 1);
        color: var(--black2F);
        @include adaptive-value("margin-bottom", 30, 20, 1);
    }

    .articles-button-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    .articles_button {
        margin: 0;
        min-width: 90px;

        @include xs {
            padding: 10px;
        }
    }

    .articles-move {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40px;
    }

    .articles-move.no_prev {
        justify-content: end;
    }

    .left_col {

        .articles-move-link {
            justify-content: flex-start;
        }
    }

    .right_col {
        .articles-move-link {
            justify-content: flex-end;

            @include xs {
                text-align: end;
            }
        }

        .icon-arrow_2 {
            rotate: 180deg;
        }
    }

    .articles-move-link {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        @include adaptive-value("font-size", 18, 16, 1);
        color: var(--blue);
        margin-bottom: 15px;

        .icon-arrow_2 {
            width: 19px;
            height: 16px;
            color: var(--blue);
        }
    }

    @media (hover:hover) {
        .articles-move-link {

            &:hover {
                color: var(--hover-blue);
                transition: all .3s ease 0s;

                .icon-arrow_2 {
                    color: var(--hover-blue);
                }
            }
        }

    }

    @media (hover:none) {
        .articles-move-link {

            &:active {
                background-color: var(--hover-blue);

                .icon-arrow_2 {
                    color: var(--hover-blue);
                }
            }
        }
    }

    .articles-move-title-wrap {
        @include adaptive-value("padding-top", 25, 15, 1);
        @include adaptive-value("padding-bottom", 25, 15, 1);
        @include adaptive-value("padding-right", 25, 15, 1);
        @include adaptive-value("padding-left", 25, 15, 1);
        border-radius: 5px 30px;
        box-shadow: 0px 8px 25px 0px #0000001A;
        max-width: 365px;
    }

    .articles-move-title {
        @include adaptive-value("font-size", 18, 16, 1);
        font-weight: 700;
        line-height: calc(26 / 18) * 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    @media (max-width: #{$xs-and-sm}) {

        .articles-move-title-wrap {
            display: none;
        }

    }
}
.voice-robot {

    .container {
        padding: 0 10px;
        position: relative;
        z-index: 2;
    }

    &_wrap {
        padding-top: 30px;
        @include adaptive-value("padding-bottom", 60, 10, 1);
        max-width: 1400px;
        width: calc(100% - 20px);
        margin: 0 auto;
        background-color: var(--whiteF8);
        border-radius: 5px 30px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            @include adaptive-value("top", -1100, 300, 1);
            z-index: 1;
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            background: linear-gradient(360deg, rgba(255, 255, 255, 0.5) -0.96%, rgba(255, 255, 255, 0) 45.36%);

            @include sm {
                width: 638px;
                top: -100px;
                left: -50%;
                transform: translateX(-20%);
            }
        }
    }


    // .voice-robot_content

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px 30px;

        @include md {
            flex-direction: column;
        }
    }

    // .voice-robot_text-wrap

    &_text-wrap {
        max-width: 492px;
        width: 100%;

        @include md {
            max-width: 100%;
            text-align: center;
        }


        @include xs {
            text-align: start;
        }
    }

    // .voice-robot_title

    &_title {
        text-align: start;
        @include adaptive-value(" margin-bottom", 40, 30, 1);

        @include md {
            text-align: center;
        }

        @include xs {
            text-align: start;
        }
    }

    // .voice-robot_descr

    &_descr {
        @include adaptive-value("font-size", 18, 16, 1);
        line-height: calc(26 / 18);
        max-width: 380px;
        width: 100%;
        color: var(--grey);
        margin-bottom: 60px;

        @include md {
            margin: 0 auto 50px auto;
        }

        @include xs {
            max-width: 100%;
        }
    }

    .btn {

        @include xs {
            width: 100%;
        }
    }

    // .voice-robot_video-wrap

    &_video-wrap {
        position: relative;
        border-radius: 5px 30px;
        overflow: hidden;
        max-width: 588px;
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.1);
    }

    &_reviews {
        @include adaptive-value("margin-top", 30, 50, 1);

        @include md {
            text-align: center;
        }
    }

    .reviews {

        &_text {
            color: var(--grey);
            font-weight: 300;
            font-size: 14px;
        }

        // .reviews_logo-wrap

        &_logo-wrap {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-top: 15px;

            @include md {
                justify-content: center;
            }

            @include xs {
                gap: 5px;
            }
        }

    }

    .logo-img_wrap {
        max-width: 128px;
        width: 100%;
        height: auto;
        background-color: var(--white);
        border-radius: 5px 10px;
        padding: 5px 7px;

        .logo__img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

}

.voice-page-en {
    .proposal {
        padding: 60px 0;
    }
}